// Status Tags Colors

export enum BooleanStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

// Status Boolean stuts
export const getProductStatus = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.ACTIVE:
      return "active";
    case BooleanStatus.INACTIVE:
      return "inactive";
    default:
      return "inactive";
  }
};
export const getProductStatusColor = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.ACTIVE:
      return "green";
    case BooleanStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

export enum BooleanType {
  PHARMACEUTICAL = 1,
  UN_PHARMACEUTICAL = 2,
}

// Status Boolean stuts
export const getProductType = (status: BooleanType) => {
  switch (status) {
    case BooleanType.PHARMACEUTICAL:
      return "pharmaceutical";
    case BooleanType.UN_PHARMACEUTICAL:
      return "un_pharmaceutical";
    default:
      return "pharmaceutical";
  }
};
export const getProductTypeColor = (status: BooleanType) => {
  switch (status) {
    case BooleanType.PHARMACEUTICAL:
      return "blue";
    case BooleanType.UN_PHARMACEUTICAL:
      return "orange";
    default:
      return "red";
  }
};

export enum Operation {
  CREATE_OR_UPDATE = 1,
  DELETE = 2
}

// Status Boolean stuts
export const getOperation = (operation: Operation) => {
  switch (operation) {
    case Operation.CREATE_OR_UPDATE:
      return "create_or_update";
    case Operation.DELETE:
      return "delete";
    default:
      return "delete";
  }
};