import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IDiscountCode,
  IDiscountCodeClient,
  IDiscountCodeDetails,
} from "../../models/discount-code/response";
import { IDiscountCodeQuery } from "../../models/discount-code/query";
import {
  ICreateDiscountCode,
  ICreateDiscountCodeClients,
  IUpdateDiscountCode,
} from "../../models/discount-code/request";
import { IClient } from "../../models/client/response";

export type DiscountCodeLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "clients"
  | "clients";

export interface IInternalState {
  loading: DiscountCodeLoading[];

  list?: IBaseListingResponse<IDiscountCode>;
  clientsList?: IBaseListingResponse<IDiscountCodeClient>;
  query: IDiscountCodeQuery;

  details?: IDiscountCodeDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    getClients: (idCode: number) => void;
    setDetails: (data?: IDiscountCodeDetails) => void;

    createDiscountCode: (request: ICreateDiscountCode) => void;
    updateDiscountCode: (id: number, request: IUpdateDiscountCode) => void;
    deleteDiscountCode: (id: number) => void;
    exportExcel: (data: any) => any;

    createDiscountCodeClient : (request: ICreateDiscountCodeClients) => void;
    deleteDiscountCodeClient: (request: ICreateDiscountCodeClients) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IDiscountCodeQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    getClients: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createDiscountCode: DEFAULT_FUNCTION,
    updateDiscountCode: DEFAULT_FUNCTION,
    deleteDiscountCode: DEFAULT_FUNCTION,

    createDiscountCodeClient :  DEFAULT_FUNCTION,
    deleteDiscountCodeClient:  DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DiscountCodeContext = createContext(externalState);

export default DiscountCodeContext;
