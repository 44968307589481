import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import EndPoints from "../../../services/end-points";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getAreaStatus } from "../../../models/area/enum";
import PaymentPointContext from "../../../context/payment-point/context";
import { IPoint } from "../../../models/point/response";

interface IProps { }

const PaymentPointFilter: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { actions, query } = useContext(PaymentPointContext);
    const { direction } = useContext(AppContext);
    const [point, setPoint] = useState<IPoint[]>([]);
    const [pointLoading, setPointLoading] = useState(true);

    useEffect(() => {
        const getPoint = async () => {
            try {
                const { data } = await EndPoints.point.getAllPoints({
                    light: true,
                    perPage: -1,
                });
                setPoint(data.data);
                setPointLoading(false);
            } catch (error) {
            } finally {
                setPointLoading(false);
            }
        };
        getPoint();
    }, [direction]);

    return (
        <Form
            formId="payment-point-filter"
            onSubmit={(data) => {
                const filter: any = {
                    page: INITIAL_PAGE,
                    perPage: query?.perPage,
                    keyword: data?.keyword,
                    filters: [
                        data?.collectionPointId
                            ? {
                                name: "collectionPointId",
                                value: data.collectionPointId,
                                operation: "eq",
                            }
                            : undefined,
                        data?.createdAt != undefined &&
                            data.createdAt[0] != ""
                            ? {
                                name: "createdAt",
                                value: data.createdAt[0],
                                operation: "gte",
                            }
                            : undefined,
                        data?.createdAt != undefined &&
                            data.createdAt[1] != ""
                            ? {
                                name: "createdAt",
                                value: data.createdAt[1],
                                operation: "lte",
                            }
                            : undefined,
                    ].filter((f) => f),
                };
                actions.setQuery(filter);
            }}
        >
            <Row justify={"start"} gutter={[10, 0]}>
                <Col span={8}>
                    <FieldBuilder
                        name="collectionPointId"
                        label={`${t("point")}`}
                        width="large"
                        input={{
                            type: "select",
                            allowSearch: true,
                            loading: pointLoading,
                            options: point.map((p, index) => {
                                return {
                                    label: p.name,
                                    value: p.id,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="createdAt"
                        label={`${t("created_at")}`}
                        input={{ type: "range-picker" }}
                        rules={{ required: false }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default PaymentPointFilter;
