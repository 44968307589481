import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import CommenQuestionContext from "../../../context/commen-question/context";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import PageDetails from "../../general/details";
import CommenQuestionContextProvider from "../../../context/commen-question/provider";
import { getFor, getForColor } from "../../../models/commen-question/enum";
import Tag from "../../general/antd/tag";

interface Props { }

const CommenQuestion: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(CommenQuestionContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("commen_questions")}
        buttonName={`${t("update_commen_question")}`}
        onClick={() => navigate(`/commen-questions/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("question")}>
              {details?.question ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("answer")}>
              {details?.answer ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("for")}>
              <Tag
                color={getForColor(details?.for)}
                title={getFor(details?.for)}
              />
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const CommenQuestionDetailesPage = () => {
  return (
    <CommenQuestionContextProvider>
      <CommenQuestion />
    </CommenQuestionContextProvider>
  );
};
export default CommenQuestionDetailesPage;
