import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IGeneralSetting } from "../../models/general-setting/response";
import { IUpdateGeneralSetting } from "../../models/general-setting/request";

export type GeneralSettingLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: GeneralSettingLoading[];

  list?: IGeneralSetting;
}

export const internalState: IInternalState = {
  loading: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    getGeneralSetting: () => void;

    updateGeneralSetting: (request: IUpdateGeneralSetting) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getGeneralSetting: DEFAULT_FUNCTION,
    updateGeneralSetting: DEFAULT_FUNCTION,
  },
};

const GeneralSettingContext = createContext(externalState);

export default GeneralSettingContext;
