import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import BannerContext from "../../../context/banner/context";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getUserStatus } from "../../../models/user/enum";
import { getBannerType } from "../../../models/banner/enum";
import EndPoints from "../../../services/end-points";
import DoctorContext from "../../../context/doctor/context";
import { getStorWorkingStatus } from "../../../models/store/enum";
import { IMedicalSpecialties } from "../../../models/medical-specialties/response";

interface IProps {}

const DoctorFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(DoctorContext);

  const statuses = [1, 2];
  const [medicalSpecialties, setMedicalSpecialties] = useState<
    IMedicalSpecialties[]
  >([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);
  // get all roles from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getRoles();
    const getDoctorSpecialty = async () => {
      try {
        const { data } =
          await EndPoints.medicalSspecialties.getAllMedicalSspecialties({
            light: true,
            perPage: -1,
          });
        setMedicalSpecialties(data?.data);
      } catch (error) {
      } finally {
      }
    };
    getDoctorSpecialty();
  }, [direction]);
  return (
    <Form
      formId="delivery-worker-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "workingStatus",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.statusWorker
              ? {
                  name: "statusWorker",
                  value: data.statusWorker,
                  operation: "eq",
                }
              : undefined,
            data?.cityId
              ? {
                  name: "cityId",
                  value: data.cityId,
                  operation: "eq",
                }
              : undefined,
            data?.specialtyId
              ? {
                  name: "specialtyId",
                  value: data.specialtyId,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getStorWorkingStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="specialtyId"
            label={`${t("medical_specialty")}`}
            width="large"
            input={{
              type: "select",
              options: medicalSpecialties.map((medicalSpecialty, index) => {
                return {
                  label: medicalSpecialty?.name,
                  value: medicalSpecialty?.id,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="cityId"
            label={`${t("city")}`}
            width="large"
            input={{
              type: "select",
              loading: citiesLoading,
              options: cities.map((city, index) => {
                return {
                  type: "select",
                  label: city?.name as string,
                  value: city?.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DoctorFilter;
