import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateComplaint,
  IExportComplaintExcel,
  IUpdateComplaint,
} from "../../models/complaint/request";
import { IComplaint, IComplaintDetails } from "../../models/complaint/response";
import { IComplaintQuery } from "../../models/complaint/query";

class ComplaintService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllComplaints = (query: IComplaintQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IComplaint>>({
      path: `/complaints`,
      method: "GET",
      query,
      ...params,
    });

  getComplaint = (id: number, params?: RequestParams) =>
    this.http.request<IComplaintDetails>({
      path: `/complaints/${id}`,
      method: "GET",
      ...params,
    });

  createComplaint = (data: ICreateComplaint, params?: RequestParams) =>
    this.http.request<IComplaint>({
      path: "/complaints",
      method: "POST",
      body: data,
      ...params,
    });

  updateComplaint = (
    id: number,
    data: IUpdateComplaint,
    params?: RequestParams
  ) =>
    this.http.request<IComplaint>({
      path: `/complaints/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteComplaint = (id: number, params?: RequestParams) =>
    this.http.request<IComplaint>({
      path: `/complaints/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportComplaintExcel, query: IComplaintQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/complaints/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default ComplaintService;
