import { IRoleQuery } from "../../models/role/query";
import { IPermission, IRole, IRoleDetails } from "../../models/role/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { toggleLoading } from "../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../base/base-reducer";
import { IInternalState, RoleLoading } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: RoleLoading | RoleLoading[] } }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<IRole> } }
  | {
      type: "SET_PERMISSIONS_LIST";
      payload: { list: IBaseListingResponse<IPermission> };
    }
  | { type: "SET_DETAILSS"; payload: { details?: IRoleDetails } }
  | QueryAction<IRoleQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }
    case "SET_PERMISSIONS_LIST": {
      return {
        ...state,
        permissions: action.payload.list,
      };
    }

    case "SET_DETAILSS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
