import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import { IPrize } from "../../../models/prize/response";
import PrizeContext from "../../../context/prize/context";

interface IProps {}

const PrizeTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(PrizeContext);

  const columns: ColumnProps<IPrize>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("description"),
      dataIndex: "description",
      align: "center",
      key: "description",
    },
    {
      title: t("numbers"),
      dataIndex: "numbers",
      align: "center",
      key: "numbers",
    },
    {
      title: t("store"),
      dataIndex: "store",
      align: "center",
      key: "store",
      render: (_, record) => {
        return <>{record?.store?.name}</>;
      },
    },
   
    {
      title: t("actions"),
      width: 250,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
        
          <DeleteBtn
            onConfirm={async () => {
              await actions.deletePrize(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default PrizeTable;
