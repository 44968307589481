import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import PointContext from "../../../context/point/context";
import Tag from "../../general/antd/tag";
import {
  getPointStatus,
  getPointStatusColor,
} from "../../../models/point/enum";
import PointContextProvider from "../../../context/point/provider";

interface Props {}

const Point: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(PointContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("point")}
        subtitle={t("details")}
        buttonName={`${t("update_point")}`}
        onClick={() => navigate(`/points/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("city")}>
              {details?.city?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("employ")}>
              {details?.employ?.user?.firstName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("area")}>
              {details?.area?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("debt_value")}>
              {details?.debtValue}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("status")}>
              <Tag
                title={getPointStatus(details?.status)}
                color={getPointStatusColor(details?.status)}
              />
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const PointDetailsPage = () => {
  return (
    <PointContextProvider>
      <Point />
    </PointContextProvider>
  );
};
export default PointDetailsPage;
