import { ITranslationRequest } from "../base/translation";
import { ILocation } from "./response";

export interface ICreateStore {
  status: number;
  type: number;
  firstPhone: string;
  secondPhone: string;
  address: string;
  note: ITranslationRequest;
  cityId: number;
  location?: ILocation;
  endWorkingTime: string;
  freeDelivery: boolean;
  pharmacyPromotion: boolean;
  startWorkingTime: string;
  imageId: number;
  logoId: number;
  workStatus: number;
}
export interface ICopyStoreProducts {
  firstStoreId: number;
  secondStoreId: number;
}

export interface IUpdateStore extends ICreateStore {}

export interface IExportStoreExcel {
  id: boolean;
  name: boolean;
  city: boolean;
  type: boolean;
  firstPhone: boolean;
  status: boolean;
  workStatus: boolean;
  createdAt: boolean;
  pharmacyPromotion: boolean;
}

export const colomnsExportStoreExcel = [
  "id",
  "name",
  "city",
  "type",
  "firstPhone",
  "status",
  "workStatus",
  "createdAt",
  "pharmacyPromotion",
];
