export enum ReportType {
  PHARMACIES_ORDERS = "export-pharmacies-Orders",
  ORDER_USER_DETAILS = "export-order-user-details",
  ORDERS_CANCELLATION = "export-orders-cancellation",
  DRIVERS_PERFORMANCE = "export-drivers-Performance",
  ORDERS_DAYLE_INFO = "export-orders-daily-info",
  ORDERS_STATUS = "export-orders-status",
  PAID_ORDERS_JOURNEY = "export-paid-orders-journey",
  ORDERS_DETAILS = "export-orders-details",
  DRIVER_PAYMENTS = "export-drivers-payments",

  PHARMACIES_ORDERS_RES = "pharmaciesOrders",
  ORDER_USER_DETAILS_RES = "orderUserDetailsReport",
  ORDERS_CANCELLATION_RES = "orderCancellation",
  DRIVERS_PERFORMANCE_RES = "driversPerformance",
  ORDERS_DAYLE_INFO_RES = "ordersDailyInfo",
  ORDERS_STATUS_RES = "orderStatus",
  PAID_ORDERS_JOURNEY_RES = "paidOrdersJourney",
  ORDERS_DETAILS_RES = "ordersDetails",
  DRIVER_PAYMENTS_REs = "driversPayments",
}

export const getReportTypeColor = (type: string) => {
  switch (type) {
    case ReportType.PHARMACIES_ORDERS_RES:
      return "red";
    case ReportType.ORDER_USER_DETAILS_RES:
      return "orange";
    case ReportType.ORDERS_CANCELLATION_RES:
      return "yellow";
    case ReportType.DRIVERS_PERFORMANCE_RES:
      return "green";
    case ReportType.ORDERS_DAYLE_INFO_RES:
      return "blue";
    case ReportType.ORDERS_STATUS_RES:
      return "indigo";
    case ReportType.PAID_ORDERS_JOURNEY_RES:
      return "violet";
    case ReportType.ORDERS_DETAILS_RES:
      return "purple";
    case ReportType.DRIVER_PAYMENTS_REs:
      return "pink";
    default:
      return "black"; // Default color for unhandled cases
  }
};

export const getReportType = (type: string) => {
  switch (type) {
    case ReportType.PHARMACIES_ORDERS:
      return "pharmacies_orders";
    case ReportType.ORDER_USER_DETAILS:
      return "order_user_details";
    case ReportType.ORDERS_CANCELLATION:
      return "orders_cancellation";
    case ReportType.DRIVERS_PERFORMANCE:
      return "drivers_performance";
    case ReportType.ORDERS_DAYLE_INFO:
      return "orders_dayle_info";
    case ReportType.ORDERS_STATUS:
      return "orders_status";
    case ReportType.PAID_ORDERS_JOURNEY:
      return "paid_orders_journey";
    case ReportType.ORDERS_DETAILS:
      return "orders_details";
    case ReportType.PHARMACIES_ORDERS_RES:
      return "pharmacies_orders";
    case ReportType.ORDER_USER_DETAILS_RES:
      return "order_user_details";
    case ReportType.ORDERS_CANCELLATION_RES:
      return "orders_cancellation";
    case ReportType.DRIVERS_PERFORMANCE_RES:
      return "drivers_performance";
    case ReportType.ORDERS_DAYLE_INFO_RES:
      return "orders_dayle_info";
    case ReportType.ORDERS_STATUS_RES:
      return "orders_status";
    case ReportType.PAID_ORDERS_JOURNEY_RES:
      return "paid_orders_journey";
    case ReportType.ORDERS_DETAILS_RES:
      return "orders_details";
    case ReportType.DRIVER_PAYMENTS:
      return "driver_payments";
      case ReportType.DRIVER_PAYMENTS_REs:
        return "driver_payments";
    default:
      return "none"; // Default color for unhandled cases
  }
};
