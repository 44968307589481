export enum AreaStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getAreaStatusColor = (status: AreaStatus) => {
  switch (status) {
    case AreaStatus.ACTIVE:
      return "green";
    case AreaStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

export const getAreaStatus = (status: AreaStatus) => {
  switch (status) {
    case AreaStatus.ACTIVE:
      return "active";
    case AreaStatus.INACTIVE:
      return "inactive";
    default:
      return "inactive";
  }
};
