import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ISliceQuery } from "../../models/slice/query";
import { ISlice, ISliceDetails } from "../../models/slice/response";
import { ICreateSlice, IUpdateSlice } from "../../models/slice/request";

class SliceService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSlice = (query: ISliceQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISlice>>({
      path: `/slice`,
      method: "GET",
      query,
      ...params,
    });

  getSlice = (id: number, params?: RequestParams) =>
    this.http.request<ISliceDetails>({
      path: `/slice/${id}`,
      method: "GET",
      ...params,
    });

  createSlice = (data: ICreateSlice, params?: RequestParams) =>
    this.http.request<ISliceDetails>({
      path: "/slice",
      method: "POST",
      body: data,
      ...params,
    });

  updateSlice = (id: number, data: IUpdateSlice, params?: RequestParams) =>
    this.http.request<ISliceDetails>({
      path: `/slice/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteSlice = (id: number, params?: RequestParams) =>
    this.http.request<ISliceDetails>({
      path: `/slice/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default SliceService;
