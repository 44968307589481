import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import ClientContext from "../../../context/clients/client/context";
import ClientContextProvider from "../../../context/clients/client/provider";
import { getGender, getGenderColor } from "../../../models/client/enum";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import Tag from "../../general/antd/tag";
import PageDetails from "../../general/details";
import DiscountCodsClientTable from "../discount-code-client-table";
import ClientPointsTable from "../client-points-table";
import ClientPointsContextProvider from "../../../context/clients/points/provider";
import DiscountCodesClientContextProvider from "../../../context/clients/discount-codes/provider";
import MedicationPackageClientContextProvider from "../../../context/clients/medication-package/provider";
import MedicationPackageTable from "../medication-package-client-table";
import LoactionsClientTable from "../loctions-table";
const { Panel } = Collapse;

interface Props {}

const Client: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(ClientContext);
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("client")}
        buttonName={`${t("update_client")}`}
        onClick={() => navigate(`/clients/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("details")} key={1}>
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("first_name")}>
                      {details?.user?.firstName}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("last_name")}>
                      {details?.user?.lastName}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("phone")}>
                      {details?.user?.phone}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("status")}>
                      {details?.user?.status === 1 ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("blood_type")}>
                      {details?.bloodType}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("all_points")}>
                      {details?.expiredPoints + details?.activePoints}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("active_points")}>
                      {details?.activePoints}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("expired_points")}>
                      {details?.expiredPoints}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("birth_date")}>
                      {details?.birthDate
                        ? moment(details?.birthDate).format("YYYY-MM-DD")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("subscription_date")}>
                      {moment(details?.createdAt).format("YYYY-MM-DD")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("gender")}>
                      {
                        <Tag
                          title={getGender(details?.user?.gender)}
                          color={getGenderColor(details?.user?.gender)}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("height")}>
                      {details?.height}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("weight")}>
                      {details?.weight}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("city")}>
                      {details?.city?.name}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("diseases")}>
                      {details?.diseases.length > 0
                        ? details?.diseases?.map((dis) => {
                            return <Tag title={dis?.name} />;
                          })
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("health_status")}>
                      {details?.healthStatus ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.user?.note ?? "-"}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("client_address")} key={1}>
                  <LoactionsClientTable data={details?.clientAddress} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("discount_codes_table")} key={1}>
                  <DiscountCodsClientTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("client_points")} key={1}>
                  <ClientPointsTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("medication-package")} key={1}>
                  <MedicationPackageTable />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const ClientDetailsPage = () => {
  return (
    <ClientContextProvider>
      <DiscountCodesClientContextProvider>
        <ClientPointsContextProvider>
          <MedicationPackageClientContextProvider>
            <Client />
          </MedicationPackageClientContextProvider>
        </ClientPointsContextProvider>
      </DiscountCodesClientContextProvider>
    </ClientContextProvider>
  );
};
export default ClientDetailsPage;
