import IBaseListingResponse from "../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../utils/api/http-client";

import { IParamQuery } from "../../models/param/query";
import { ICreateParam, IUpdateParam } from "../../models/param/request";
import { IParam, IParamDetails } from "../../models/param/resopnse";

class ParamService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllParams = (attrId: number, query: IParamQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IParam>>({
      path: `/attribute/${attrId}/param`,
      method: "GET",
      query,
      ...params,
    });

  getParam = (attrId: number, paramId: number, params?: RequestParams) =>
    this.http.request<IParamDetails>({
      path: `/attribute/${attrId}/param/${paramId}`,
      method: "GET",
      ...params,
    });

  createParam = (attrId: number, data: ICreateParam, params?: RequestParams) =>
    this.http.request<IParam>({
      path: `/attribute/${attrId}/param`,
      method: "POST",
      body: data,
      ...params,
    });

  updateParam = (
    attrId: number,
    paramId: number,
    data: IUpdateParam,
    params?: RequestParams
  ) =>
    this.http.request<IParam>({
      path: `/attribute/${attrId}/param/${paramId}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteParam = (paramId: number, attrId: number, params?: RequestParams) =>
    this.http.request<IParam>({
      path: `/attribute/${paramId}/param/${attrId}`,
      method: "DELETE",
      ...params,
    });
}

export default ParamService;
