import { useContext, useReducer } from "react";
import { IAttributeQuery } from "../../models/attribute/query";
import {
  ICreateAttribute,
  IUpdateAttribute,
} from "../../models/attribute/request";
import { IAttribute } from "../../models/attribute/resopnse";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import AttributeContext, { internalState } from "./context";
import reducer from "./reducer";
import CategoryContext from "../category/context";
export interface IProps {
  children: React.ReactNode;
}
const AttributeContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { actions } = useContext(CategoryContext);
  const getData = async (catId: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.attribute.getAllAttributes(
          catId,

          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number, catId: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.attribute.getAttribute(id, catId);
        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IAttribute) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createAttribute = async (catId: number, request: ICreateAttribute) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.attribute.createAttribute(catId, request);
        actions.getAllData();
      },

      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateAttribute = async (
    catId: number,
    id: number,
    request: IUpdateAttribute
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.attribute.updateAttribute(catId, id, request);
        actions.getAllData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteAttribute = async (catId: number, id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.attribute.deleteAttribute(catId, id);
        actions.getAllData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IAttributeQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  // const exportExcel = async (request: any) => {
  //   await execute({
  //     callback: async () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //       const data = await EndPoints.Attribute.exportExcel(
  //         request,
  //         state.query
  //       );
  //       const url = window.URL.createObjectURL(new Blob([data.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         `Attributes-${moment().format("yyyy-MM-d")}.xlsx`
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //     },
  //     fallback: (error) => {},
  //     finallyCallback: () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //     },
  //     throwException: true,
  //   });
  // };

  return (
    <AttributeContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createAttribute,
          updateAttribute,
          deleteAttribute,
          // exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </AttributeContext.Provider>
  );
};

export default AttributeContextProvider;
