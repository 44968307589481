import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import InvestorContext from "../../../context/investor/context";
import InvestorContextProvider from "../../../context/investor/provider";
import { ILightResonse } from "../../../models/general-models/general";
import {
  getInvestorStatus,
  getInvestorType,
} from "../../../models/investor/enum";
import { IUpdateInvestor } from "../../../models/investor/request";
import { MediumFor } from "../../../models/medium/enum";
import { IStore } from "../../../models/store/response";
import { getUserStatus } from "../../../models/user/enum";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import ImageUploader from "../../form-components/image-uploader";
import MainForm from "../../form-components/main-form";
import OldImage from "../../form-components/old-image";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(InvestorContext);
  const { id } = useParams();
  const { direction } = useContext(AppContext);
  const [cities, setCities] = useState<ILightResonse[]>([]);
  const [citiesLoading, setCitiesLoading] = useState(true);

  const [stores, setStores] = useState<IStore[]>([]);
  const [storesLoadin, setStoresLoadin] = useState<boolean>(false);
  const investorStatus = [1, 2];
  const investorTypes = [1, 2];
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // get all cities from api
  useEffect(() => {
    const getCities = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getCities();
  }, [direction]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          light: true,
          perPage: -1,
        });
        setStores(data.data);
        setStoresLoadin(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getStores();
  }, [direction]);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateInvestor(details?.id, data)
          : await actions.createInvestor(data);
      }}
      title={details ? t("update_investor") : t("add_investor")}
      defaultValues={
        {
          address: details?.address,
          birthDate: details?.birthDate,
          cityId: details?.city?.id,
          email: details?.user?.email,
          firstName: details?.user?.firstName,
          imageId: details?.user?.image?.id ?? undefined,
          lastName: details?.user?.lastName,
          mobile: details?.user?.cleanMobile,

          note: getTranslations(details?.user?.translations?.note) ?? null,
          phone: details?.user?.phone,
          nationalIdentityNumber: details?.nationalIdentityNumber,
          status: details?.user?.status,
          type: details?.type,
          storeId: details?.store?.id,
          statusInvestor: details?.statusInvestor,
        } as IUpdateInvestor
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="firstName"
                label={t("first_name")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="lastName"
                label={t("last_name")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mobile"
                label={t("mobile")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="email"
                label={t("email")}
                input={{ type: "email" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="address"
                label={t("address")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="phone"
                label={t("phone")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={`${t("status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: investorStatus?.map((state, index) => {
                    return {
                      key: index,
                      label: t(getUserStatus(state)),
                      value: state,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="type"
                label={`${t("type")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: investorTypes?.map((state, index) => {
                    return {
                      key: index,
                      label: t(getInvestorType(state)),
                      value: state,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="statusInvestor"
                label={`${t("current_status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: investorTypes?.map((state, index) => {
                    return {
                      key: index,
                      label: t(getInvestorStatus(state)),
                      value: state,
                    };
                  }),
                }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="birthDate"
                label={`${t("birth_date")}`}
                rules={{ required: false }}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="nationalIdentityNumber"
                label={t("national_identity_number")}
                input={{ type: "text" }}
                rules={{ required: false, minLength: 11, maxLength: 11 }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="cityId"
                label={`${t("city")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: citiesLoading,
                  allowSearch: false,
                  options: cities?.map((city, index) => {
                    return {
                      key: index,
                      label: city.name,
                      value: city.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="storeId"
                label={`${t("store")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: citiesLoading,
                  allowSearch: true,
                  options: stores?.map((store, index) => {
                    return {
                      key: index,
                      label: store.name,
                      value: store.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col offset={8}></Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
            <Col span={24}>
              <FormItem label={t("image")}>
                <Controller
                  name="imageId"
                  rules={{
                    required: {
                      value: false,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details?.user?.image && (
              <OldImage src={details?.user?.image?.url} />
            )}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const InvestorForm = () => {
  return (
    <InvestorContextProvider>
      <Form />
    </InvestorContextProvider>
  );
};
export default InvestorForm;
