import moment from "moment";
import { useReducer } from "react";
import UserContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { IUser } from "../../models/user/response";
import { ICreateUser, IUpdateUser } from "../../models/user/request";
import { IUserQuery } from "../../models/user/query";

export interface IProps {
  children: React.ReactNode;
}
const UserContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.user.getAllUsers(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.user.getUser(id);
        dispatch({ type: "SET_DETAILSS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IUser) => {
    // dispatch({ type: 'SET_DETAILSS', payload: { details: data } })
  };

  const createUser = async (request: ICreateUser) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.user.createUser(request);
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateUser = async (id: number, request: IUpdateUser) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.user.updateUser(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteUser = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.user.deleteUser(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IUserQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.user.exportExcel(request, state.query);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Users-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createUser,
          updateUser,
          deleteUser,
          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
