import { createContext } from "react";

import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IOrderHistory } from "../../models/order/order-history/response";
import { IOrderHistoryQuery } from "../../models/order/order-history/query";

export type OrderHistoryLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OrderHistoryLoading[];

  list?: IBaseListingResponse<IOrderHistory>;
  query: IOrderHistoryQuery;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    setSearch: (search?: string) => void;
    setQuery: (query: IOrderHistoryQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const OrderHistoryContext = createContext(externalState);

export default OrderHistoryContext;
