import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IClient, IMedictionPackage } from "../../models/client/response";
import { IClientQuery } from "../../models/client/query";
import {
  ICreateClient,
  IExportClientExcel,
  IUpdateClient,
} from "../../models/client/request";
import { IDiscountCode } from "../../models/discount-code/response";

class ClientService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClient = (query: IClientQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IClient>>({
      path: `/clients`,
      method: "GET",
      query,
      ...params,
    });

  getClient = (id: number, params?: RequestParams) =>
    this.http.request<IClient>({
      path: `/clients/${id}`,
      method: "GET",
      ...params,
    });

  createClient = (data: ICreateClient, params?: RequestParams) =>
    this.http.request<IClient>({
      path: "/clients",
      method: "POST",
      body: data,
      ...params,
    });

  updateClient = (id: number, data: IUpdateClient, params?: RequestParams) =>
    this.http.request<IClient>({
      path: `/clients/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClient = (id: number, params?: RequestParams) =>
    this.http.request<IClient>({
      path: `/clients/${id}`,
      method: "DELETE",
      ...params,
    });

  getDiscountCodesClient = (
    id: number,
    query: IClientQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDiscountCode>>({
      path: `/client-discount-codes/${id}`,
      method: "GET",
      query,
      ...params,
    });

  getClientPoints = (id: number, query: IClientQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDiscountCode>>({
      path: `/all-points`,
      method: "GET",
      query,
      ...params,
    });

  getMedicationPackage = (
    id: number,
    query: IClientQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IMedictionPackage>>({
      path: `/client-medication-package`,
      method: "GET",
      query,
      ...params,
    });

  exportExcel = (data: IExportClientExcel, query: IClientQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/clients/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default ClientService;
