import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../context/app/context";
import AttributeContext from "../../context/attribute/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import AttributeContextProvider from "../../context/attribute/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import AttributeTable from "../../components/attribute/attribute-table";
import FormHookModal from "../../components/general/modals/form-hook-modal";
import { useModal } from "react-modal-hook";
import { IUpdateAttribute } from "../../models/attribute/request";
import AttributeForm from "../../components/attribute/attribute-form";
import { getTranslations } from "../../utils/helpers/translations";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Attribute: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [infoId, setInfoId] = useState(undefined);

  const { details, actions, loading, query } = useContext(AttributeContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={loading.includes("create")}
        title={t("add_attribute")}
        hideModal={() => {
          hideFormodal();
        }}
        width={"60vw"}
        onSubmit={async (data) => {
          await actions.createAttribute(Number(id), data);
          setInfoId(undefined);
          actions.setDetails(null);
          actions.getData(Number(id));
        }}
        defaultValues={
          {
            isMultiple: false,
            isRequired: false,
          } as IUpdateAttribute
        }
      >
        <AttributeForm />
      </FormHookModal>
    ),
    [loading, infoId]
  );

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData(Number(id));
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("attributes")}
        subTitle={t("attributes_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData(Number(id))}
          >
            {t("refresh")}
          </RefreshBtn>,

          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              title="add_drawing_reservation"
              onClick={async () => {
                // setInfoId(Number(id));
                actions.setDetails();
                showFormModal();
              }}
              icon={<PlusOutlined />}
            >
              {t("add_attribute")}
            </Button>
          </Can>,
          <Tooltip placement="topRight" title={t("go_to_back")}>
            <Button
              onClick={() => navigate(-1)}
              type="primary"
              style={{ width: "5rem" }}
              icon={
                <ArrowLeftOutlined spin={true} style={{ fontSize: "1.2rem" }} />
              }
            />
          </Tooltip>,
        ]}
      />
      <SearchFilterCard
        formId="attribute-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        {/* <UserFilter /> */}
      </SearchFilterCard>

      <AttributeTable />
    </div>
  );
};

const AttributePage = () => {
  return (
    // ContextProvider
    <AttributeContextProvider>
      <Attribute />
    </AttributeContextProvider>
  );
};
export default AttributePage;
