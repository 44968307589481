import { Col, Row } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import BannerContext from "../../../context/banner/context";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import TrademarkContext from "../../../context/trademark/context";

interface IProps {}

const TrademarkFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(TrademarkContext);

  return (
    <Form
      formId="trademark-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.isSpecial
              ? {
                  name: "isSpecial",
                  value: data.isSpecial,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="isSpecial"
            label={`${t("trademark_type")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((cat, index) => {
                return {
                  label: index == 0 ? t("special") : t("not_special"),
                  value: index == 0 ? 1 : "0",
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default TrademarkFilter;
