import moment from "moment";
import { useReducer } from "react";
import GeneralNotificationContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import {
  ICreateGeneralNotification,
  IUpdateGeneralNotification,
} from "../../models/general-notification/request";
import { IGeneralNotification } from "../../models/general-notification/response";
import { IGeneralNotificationQuery } from "../../models/general-notification/query";
import { successNotification } from "../../utils/helpers/notification";
import { useTranslation } from "react-i18next";
export interface IProps {
  children: React.ReactNode;
}
const GeneralNotificationContextProvider: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.generalNotification.getAllGeneralNotifications(
            state.query
          );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.generalNotification.getGeneralNotification(id);
        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IGeneralNotification) => {
    // dispatch({ type: 'SET_DETAILSS', payload: { details: data } })
  };

  const createGeneralNotification = async (
    request: ICreateGeneralNotification
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.generalNotification.createGeneralNotification(request);
        // getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateGeneralNotification = async (
    id: number,
    request: IUpdateGeneralNotification
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.generalNotification.updateGeneralNotification(
          id,
          request
        );

        // getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteGeneralNotification = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.generalNotification.deleteGeneralNotification(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const sendNotification = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "notifi" },
        });
        await EndPoints.generalNotification.sendNotification(id);
        successNotification(
          t("notifications"),

          `${t("sent_notification_successfully")}`
        );
      },

      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "notifi" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IGeneralNotificationQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  // const exportExcel = async (request: any) => {
  //   await execute({
  //     callback: async () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //       const data = await EndPoints.generalNotification.exportExcel(
  //         request,
  //         state.query
  //       );
  //       const url = window.URL.createObjectURL(new Blob([data.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         `GeneralNotifications-${moment().format("yyyy-MM-d")}.xlsx`
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //     },
  //     fallback: (error) => {},
  //     finallyCallback: () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //     },
  //     throwException: true,
  //   });
  // };

  return (
    <GeneralNotificationContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          sendNotification,
          createGeneralNotification,
          updateGeneralNotification,
          deleteGeneralNotification,
          // exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </GeneralNotificationContext.Provider>
  );
};

export default GeneralNotificationContextProvider;
