import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";
import OrderContext from "../../../context/order/context";
import { IClient } from "../../../models/client/response";
import { IDeliveryWorker } from "../../../models/delivery-workers/response";
import { IStore } from "../../../models/store/response";
import {
  getOrderStatus,
  getOrderStyle,
  getOrderType,
} from "../../../models/order/enum";

interface IProps {}

const OrderShortFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(OrderContext);

  const [store, setSorte] = useState<IStore[]>([]);
  const [loadingStore, setLoadingStore] = useState(true);

  const { direction } = useContext(AppContext);

  useEffect(() => {
    const getStore = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          light: true,
          perPage: -1,
        });
        setSorte(data.data);
        setLoadingStore(false);
      } catch (error) {
      } finally {
        setLoadingStore(false);
      }
    };
    getStore();
  }, [direction]);

  const status = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <Form
      formId="order-short-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.storeId
              ? {
                  name: "storeId",
                  value: data.storeId,
                  operation: "eq",
                }
              : undefined,
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="storeId"
            label={`${t("store")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: loadingStore,
              options: store.map((store, index) => {
                return {
                  label: store.name,
                  value: store.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: status.map((status, index) => {
                return {
                  label: t(getOrderStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default OrderShortFilter;
