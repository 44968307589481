import React, { useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import AppContext from "../../../context/app/context";
interface IProps {
  value: string;
  onChange: (value: string) => void;
}

const RichEditor: React.FC<IProps> = ({ value, onChange }) => {
  const { direction } = useContext(AppContext);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ align: "center" }, { align: "right" }, { align: "justify" }],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }],
      [
        // custom dropdown
        { header: [1, 2, 3, 4, 5, 6, false] },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
    "direction",
  ];

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        style={{
          direction: direction === "rtl" ? "rtl" : "ltr",
        }}
      />
    </div>
  );
};

export default RichEditor;
