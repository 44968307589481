import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IDeliveryWorker, IDeliveryWorkerDetails } from "../../models/delivery-workers/response";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import { ICreateDeliveryWorker, IUpdateDeliveryWorker } from "../../models/delivery-workers/request";

export type deliveryWorkerLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: deliveryWorkerLoading[];

  list?: IBaseListingResponse<IDeliveryWorker>;
  query: IDeliveryWorkerQuery;

  details?: IDeliveryWorkerDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDeliveryWorker) => void;

    createDeliveryWorker: (request: ICreateDeliveryWorker) => void;
    updateDeliveryWorker: (id: number, request: IUpdateDeliveryWorker) => void;
    deleteDeliveryWorker: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDeliveryWorkerQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createDeliveryWorker: DEFAULT_FUNCTION,
    updateDeliveryWorker: DEFAULT_FUNCTION,
    deleteDeliveryWorker: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DeliveryWorkerContext = createContext(externalState);

export default DeliveryWorkerContext;
