import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import CategoryContext from "../../context/category/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import PageHeader from "../../components/general/layout/page-header";
import styles from "./style.module.scss";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import {
  IExportCategoryExcel,
  columnsCategory,
} from "../../models/category/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import SearchFilterCard from "../../components/general/filter-card";
import CategoryTable from "../../components/category/category-table";
import CategoryContextProvider from "../../context/category/provider";
import CategoryFilter from "../../components/category/category-filter";

interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Category: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { actions, loading, query } = useContext(CategoryContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("categories")}
        subTitle={t("categories_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                actions.setDetails(null);
                navigate(`/categories/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_category")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-category"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            name: false,
            id: false,
            parent: false,
            order: false,
          } as IExportCategoryExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={columnsCategory} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="category-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <CategoryFilter />
      </SearchFilterCard>
      <CategoryTable />
    </div>
  );
};

const CategoryPage = () => {
  return <Category />;
};
export default CategoryPage;
