import { Row, Col, Divider } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import CommenQuestionContext from "../../../context/commen-question/context";
import { IUpdateCommenQuestion } from "../../../models/commen-question/request";
import CommenQuestionContextProvider from "../../../context/commen-question/provider";
import { getFor } from "../../../models/commen-question/enum";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CommenQuestionContext);
  const { id } = useParams();
  const forType = [1, 2, 3];

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateCommenQuestion(details?.id, data)
          : actions.createCommenQuestion(data);
      }}
      title={details ? t("update_commen_question") : t("add_commen_question")}
      defaultValues={
        {
          answer: getTranslations(details?.translations?.answer),
          question: getTranslations(details?.translations?.question),
          for: details?.for
        } as IUpdateCommenQuestion
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`question[${lang?.code}]`}
                    label={`${t("question")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`answer[${lang?.code}]`}
                    label={`${t("answer")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <FieldBuilder
                name="for"
                label={`${t("for")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: forType?.map((forTupe, index) => {
                    return {
                      key: index,
                      label: t(getFor(forTupe)),
                      value: forTupe,
                    };
                  }),
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const CommenQuestionForm = () => {
  return (
    <CommenQuestionContextProvider>
      <Form />
    </CommenQuestionContextProvider>
  );
};
export default CommenQuestionForm;
