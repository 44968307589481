import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ErrorLayout from '../../components/general/layout/error-layout'

interface Props {}

const Error404: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ErrorLayout>
      <Result
        status='404'
        title='404'
        subTitle={t('page_not_found')}
        extra={
          <Button
            type='primary'
            onClick={() => {
              navigate('/')
            }}
          >
            {t('back_to_home_page')}
          </Button>
        }
      />
    </ErrorLayout>
  )
}

export default Error404
