import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import GeneralSettingContext from "../../../context/general-setting/context";
import GeneralSettingContextProvider from "../../../context/general-setting/provider";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import PageDetails from "../../general/details";
import OldImage from "../../form-components/old-image";

interface Props {}

const GeneralSetting: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { list, actions, loading } = useContext(GeneralSettingContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getGeneralSetting = async () => {
      await actions.getGeneralSetting();
    };
    getGeneralSetting();
  }, [direction]);
  return (
    <>
      <PageDetails
        navigate
        title={t("details")}
        subtitle={t("general_settings")}
        buttonName={`${t("update_general_settings")}`}
        onClick={() => navigate(`/general-settings/update`)}
      >
        {loading.includes("list") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={2} bordered>
            <AntdDescriptions.Item label={t("publicMessageClient")} span={2}>
              {list?.publicMessageClient}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item
              label={t("publicMessagePharmacist")}
              span={2}
            >
              {list?.publicMessagePharmacist}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("publicMessageDlivery")} span={2}>
              {list?.publicMessageDlivery}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("currencySymbol")} span={2}>
              {list?.currencySymbol}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("client_acceptance_time")}>
              {list?.clientAcceptanceTime}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("maxNumberPharmaciesFindMedicine")}>
              {list?.maxNumberPharmaciesFindMedicine}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("scoreValidity")}>
              {list?.scoreValidity}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("fixed_delivery_price_on_client")}>
              {list?.fixedDeliveryPriceOnClient}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("kilometerCost")}>
              {list?.kilometerCost}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("priceKilometerCustomer")}>
              {list?.priceKilometerCustomer}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item
              label={t("maxNumberPharmaciesFindPharmacie")}
            >
              {list?.maxNumberPharmaciesFindPharmacie}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("maxNumOfUnResponseOrder")}>
              {list?.maxNumOfUnResponseOrder}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("pointMultiplicationFactor")}>
              {list?.pointMultiplicationFactor}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("numberTimesIgnoredAllowed")}>
              {list?.numberTimesIgnoredAllowed}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("radiusSearchForDriver")}>
              {list?.radiusSearchForDriver}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("commission")}>
              {list?.commission}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("deliveryWorkerAcceptanceTime")}>
              {list?.deliveryWorkerAcceptanceTime}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("driverTimeBroadcast")}>
              {list?.driverTimeBroadcast}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("timeBroadcast")}>
              {list?.timeBroadcast}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("pharmacyAcceptanceTime")}>
              {list?.pharmacyAcceptanceTime}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("loyaltyProgramLink")}>
              {list?.loyaltyProgramLink}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("delayedOrderSchedulingTime")}>
              {list?.delayedOrderSchedulingTime}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("pointPrice")}>
              {list?.pointPrice}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("welcomeCoupon")}>
              {list?.welcomeCoupon?.name}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("welcomeCouponActive")}>
              {list?.welcomeCouponActive ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("welcome_message")}>
              {list?.welcomeMessage}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("welcomeMessageActivation")}>
              {list?.welcomeMessageActivation ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("showMedicalSpecialties")}>
              {list?.showMedicalSpecialties ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("showCompanies")}>
              {list?.showCompanies ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("activatingPublicService")}>
              {list?.activatingPublicService ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("activatePublicMessageClient")}>
              {list?.activatePublicMessageClient ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("activatePublicMessagePharmacist")}>
              {list?.activatePublicMessagePharmacist ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("activatePublicMessageDelivery")}>
              {list?.activatePublicMessageDelivery ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("showListPharmacies")}>
              {list?.showListPharmacies ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("freeDelivery")}>
              {list?.freeDelivery ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("freeDeliveryPharmacyPromotion")}>
              {list?.freeDeliveryPharmacyPromotion ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("first_order_free")}>
              {list?.firstOrderFree ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("facebook")}>
              {list?.facebook ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("instagram")}>
              {list?.instagram ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("phone")}>
              {list?.phone ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("hotLine")}>
              {list?.hotLine ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("telegram")}>
              {list?.telegram ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("whatsApp")}>
              {list?.whatsApp ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("minimum_limit")}>
              {list?.minimumLimit ?? "--"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("upper_limit")}>
              {list?.upperLimit ?? "--"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("homeHeaderClientEn")}>
              <OldImage src={list?.homeHeaderClientEn?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderClientAr")}>
              <OldImage src={list?.homeHeaderClientAr?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderDriverEn")}>
              <OldImage src={list?.homeHeaderDriverEn?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderDriverAr")}>
              <OldImage src={list?.homeHeaderDriverAr?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderInvestorEn")}>
              <OldImage src={list?.homeHeaderInvestorEn?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderInvestorAr")}>
              <OldImage src={list?.homeHeaderInvestorAr?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderClient")}>
              <OldImage src={list?.homeHeaderClient?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderDriver")}>
              <OldImage src={list?.homeHeaderDriver?.url} preview />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("homeHeaderInvestor")}>
              <OldImage src={list?.homeHeaderInvestor?.url} preview />
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const GeneralSettingDetailesPage = () => {
  return (
    <GeneralSettingContextProvider>
      <GeneralSetting />
    </GeneralSettingContextProvider>
  );
};
export default GeneralSettingDetailesPage;
