import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { ITrademark, ITrademarkDetails } from "../../models/trademark/response";
import { ITrademarkQuery } from "../../models/trademark/query";
import {
  ICreateTrademark,
  IUpdateTrademark,
} from "../../models/trademark/request";

export type TrademarkLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkLoading[];

  list?: IBaseListingResponse<ITrademark>;
  query: ITrademarkQuery;

  details?: ITrademarkDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ITrademarkDetails) => void;

    createTrademark: (request: ICreateTrademark) => void;
    updateTrademark: (id: number, request: IUpdateTrademark) => void;
    deleteTrademark: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ITrademarkQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createTrademark: DEFAULT_FUNCTION,
    updateTrademark: DEFAULT_FUNCTION,
    deleteTrademark: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const TrademarkContext = createContext(externalState);

export default TrademarkContext;
