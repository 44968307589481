import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { ICityQuery } from "../../models/city/query";
import { ICity, ICityDetails } from "../../models/city/response";
import { ICreateCity, IUpdateCity } from "../../models/city/request";

export type CityLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: CityLoading[];

  list?: IBaseListingResponse<ICity>;
  query: ICityQuery;

  details?: ICityDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICity) => void;

    createCity: (request: ICreateCity) => void;
    updateCity: (id: number, request: IUpdateCity) => void;
    deleteCity: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ICityQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createCity: DEFAULT_FUNCTION,
    updateCity: DEFAULT_FUNCTION,
    deleteCity: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CityContext = createContext(externalState);

export default CityContext;
