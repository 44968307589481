import { Button, Col, Divider, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import RewardContext from "../../../context/rewaed/context";
import { ICreateReward } from "../../../models/reward/request";
import { getRewardStatus, getRewardType } from "../../../models/reward/enum";
import RewardContextProvider from "../../../context/rewaed/provider";
import styles from "./style.module.scss";
import { execute } from "../../../utils/helpers/execute";
import moment from "moment";

const Option = Select.Option;

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(RewardContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [id]);

  const methods = useForm<any>({
    defaultValues: {
      startDatetime: details?.startDatetime,
      endDatetime: details?.endDatetime,
      status: details?.status,
      type: details?.type,
      value: details?.value,
      distance: details?.distance || undefined,
    } as ICreateReward,
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const typeReward = useWatch({
    control,
    name: "type",
  });
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        data.v;
        setLoadingSubmit(true);
        details
          ? await actions.updateReward(details?.id, {
              ...data,
              startDatetime: moment(data?.startDatetime),
              endDatetime: moment(data?.endDatetime),
            })
          : await actions.createReward({
              ...data,
              startDatetime: moment(data?.startDatetime),
              endDatetime: moment(data?.endDatetime),
            });
        actions.setDetails(null);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  useEffect(() => {
    reset({
      startDatetime: details?.startDatetime,
      endDatetime: details?.endDatetime,
      status: details?.status,
      type: details?.type,
      value: details?.value,
      distance: details?.distance || undefined,
    } as ICreateReward);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_reward") : t("add_reward")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <FieldBuilder
                    name="status"
                    label={`${t("status")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2]?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getRewardStatus(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FormItem label={t("type")}>
                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={false}
                            {...field}
                            style={{ width: "100%" }}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            allowClear
                            placeholder={t("type")}
                            options={[1, 2].map((type, index) => {
                              return {
                                key: index,
                                label: t(getRewardType(type)),
                                value: type,
                              };
                            })}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    name="value"
                    label={`${t("value")}`}
                    input={{ type: "number" }}
                    rules={{ required: true }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    disabled={typeReward == 1 ? true : false}
                    name="distance"
                    label={`${t("distance")}`}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    name="startDatetime"
                    label={`${t("start_datetime")}`}
                    input={{
                      type: "date-picker",
                      format: "YYYY-MM-DD HH:mm:ss",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    name="endDatetime"
                    label={`${t("end_datetime")}`}
                    input={{
                      type: "date-picker",
                      format: "YYYY-MM-DD HH:mm:ss",
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const RewardForm = () => {
  return (
    <RewardContextProvider>
      <Form />
    </RewardContextProvider>
  );
};
export default RewardForm;
