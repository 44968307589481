import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IStore } from "../../models/store/response";
import { IStoreQuery } from "../../models/store/query";
import {
  ICopyStoreProducts,
  ICreateStore,
  IExportStoreExcel,
  IUpdateStore,
} from "../../models/store/request";

class StoreService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStores = (query: IStoreQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStore>>({
      path: `/store`,
      method: "GET",
      query,
      ...params,
    });

  getStore = (id: number, params?: RequestParams) =>
    this.http.request<IStore>({
      path: `/store/${id}`,
      method: "GET",
      ...params,
    });

  createStore = (data: ICreateStore, params?: RequestParams) =>
    this.http.request<IStore>({
      path: "/store",
      method: "POST",
      body: data,
      ...params,
    });
  copyStoreProducts = (data: ICopyStoreProducts, params?: RequestParams) =>
    this.http.request<undefined>({
      path: "/copy-store-product",
      method: "POST",
      body: data,
      ...params,
    });

  updateStore = (id: number, data: IUpdateStore, params?: RequestParams) =>
    this.http.request<IStore>({
      path: `/store/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteStore = (id: number, params?: RequestParams) =>
    this.http.request<IStore>({
      path: `/store/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportStoreExcel, query: IStoreQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/store/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default StoreService;
