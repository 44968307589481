import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateGeneralNotification,
  IUpdateGeneralNotification,
} from "../../models/general-notification/request";
import {
  IGeneralNotification,
  IGeneralNotificationDetails,
} from "../../models/general-notification/response";
import { IGeneralNotificationQuery } from "../../models/general-notification/query";

class GeneralNotificationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllGeneralNotifications = (
    query: IGeneralNotificationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IGeneralNotification>>({
      path: `/general-notification`,
      method: "GET",
      query,
      ...params,
    });

  getGeneralNotification = (id: number, params?: RequestParams) =>
    this.http.request<IGeneralNotificationDetails>({
      path: `/general-notification/${id}`,
      method: "GET",
      ...params,
    });

  createGeneralNotification = (
    data: ICreateGeneralNotification,
    params?: RequestParams
  ) =>
    this.http.request<IGeneralNotification>({
      path: "/general-notification",
      method: "POST",
      body: data,
      ...params,
    });

  updateGeneralNotification = (
    id: number,
    data: IUpdateGeneralNotification,
    params?: RequestParams
  ) =>
    this.http.request<IGeneralNotification>({
      path: `/general-notification/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteGeneralNotification = (id: number, params?: RequestParams) =>
    this.http.request<IGeneralNotification>({
      path: `/general-notification/${id}`,
      method: "DELETE",
      ...params,
    });

  sendNotification = (id: number, params?: RequestParams) =>
    this.http.request<IGeneralNotification>({
      path: `/general-notification/send/${id}`,
      method: "GET",
      ...params,
    });
}

export default GeneralNotificationService;
