import { useReducer } from "react";
import { IUpdateGeneralSetting } from "../../models/general-setting/request";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import GeneralSettingContext, { internalState } from "./context";
import reducer from "./reducer";
export interface IProps {
  children: React.ReactNode;
}
const GeneralSettingContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getGeneralSetting = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.generalSettings.getGeneralSetting();

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const updateGeneralSetting = async (request: IUpdateGeneralSetting) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.generalSettings.updateGeneralSetting(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  return (
    <GeneralSettingContext.Provider
      value={{
        ...state,
        actions: {
          getGeneralSetting,
          updateGeneralSetting,
        },
      }}
    >
      {props.children}
    </GeneralSettingContext.Provider>
  );
};

export default GeneralSettingContextProvider;
