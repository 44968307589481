import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import HomePageListContext from "../../../context/home-page-list/context";
import HomePageListContextProvider from "../../../context/home-page-list/provider";
import {
  getCardStyle,
  getCardStyleColor,
  getHomePageStatus,
  getHomePageStatusColor,
  getHomePageType,
  getHomePageTypeColor,
} from "../../../models/home-page-list/enum";
import Tag from "../../general/antd/tag";

interface Props {}

const HomePageList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(HomePageListContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("home_page_list")}
        buttonName={`${t("update_home_page_list")}`}
        onClick={() => navigate(`/home-page-list/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("title")}>
              {details?.title}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("status")}>
              <Tag
                title={t(getHomePageStatus(details?.status))}
                color={getHomePageStatusColor(details?.status)}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("style")}>
              <Tag
                title={t(getCardStyle(details?.style))}
                color={getCardStyleColor(details?.style)}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("type")}>
              <Tag
                title={t(getHomePageType(details?.type))}
                color={getHomePageTypeColor(details?.type)}
              />
            </AntdDescriptions.Item>

            {[1, 2, 4].includes(details?.type) && (
              <AntdDescriptions.Item label={t(getHomePageType(details?.type))}>
                {details?.subBrand ? (
                  <Tag title={details?.subBrand?.name} />
                ) : (
                  "-"
                )}
              </AntdDescriptions.Item>
            )}

            {details?.products?.length > 0 && (
              <AntdDescriptions.Item label={t("products")}>
                {details?.products?.map((pr) => {
                  return <Tag title={pr?.name} />;
                })}
              </AntdDescriptions.Item>
            )}

            {details?.categories?.length > 0 && (
              <AntdDescriptions.Item label={t("categories")}>
                {details?.categories?.map((pr) => {
                  return <Tag title={pr?.name} />;
                })}
              </AntdDescriptions.Item>
            )}
            <AntdDescriptions.Item label={t("description")}>
              {details?.description}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const HomePageListDetailsPage = () => {
  return (
    <HomePageListContextProvider>
      <HomePageList />
    </HomePageListContextProvider>
  );
};
export default HomePageListDetailsPage;
