import { ITranslationRequest } from "../base/translation";
export interface ICreateOrder {
  name: ITranslationRequest;
  note: ITranslationRequest;
}

export interface IUpdateOrder extends ICreateOrder {}

export interface IExportOrderExcel {
  id: boolean;
  number: boolean;
  client: boolean;
  clientAddress: boolean;
  totalPrice: boolean;
  totalPriceOnClient: boolean;
  priceDeliveryService: boolean;
  priceDeliveryServiceOnClient: boolean;
  priceDeliveryYield: boolean;
  productYield: boolean;
  orderBroadcastArea: boolean;
  orderBroadcastCity: boolean;
  orderSchedulingType: boolean;
  productBroadcastArea: boolean;
  productBroadcastCity: boolean;
  recipeAsText: boolean;
  distance: boolean;
  rating: boolean;
  contact: boolean;
  clientNoteDelivery: boolean;
  clientRateNote: boolean;
  selectedStore: boolean;
  reasonCancel: boolean;
  startDatetimeDriver: boolean;
  finishDatetime: boolean;
  scheduleTime: boolean;
  updatedAt: boolean;
  deletedAt: boolean;
  driver: boolean;
  type: boolean;
  store: boolean;
  status: boolean;
  finalPrice: boolean;
  isEmergency: boolean;
  isRecipe: boolean;
  clientNote: boolean;
  note: boolean;
  createdAt: boolean;
  clientMobile: boolean;
  driverMobile: boolean;
  systemNote: boolean;
  productsDiscount: boolean;
  discountCodeCode: boolean
  ringingBell: boolean
  pharmacist: boolean
  storeHaveRecipe:boolean
}

export const colomnsExportOrderExcel = [
  "id",
  "number",
  "client",
  "clientAddress",
  "totalPrice",
  "totalPriceOnClient",
  "priceDeliveryService",
  "priceDeliveryServiceOnClient",
  "priceDeliveryYield",
  "productYield",
  "orderBroadcastArea",
  "orderBroadcastCity",
  "orderSchedulingType",
  "productBroadcastArea",
  "productBroadcastCity",
  "recipeAsText",
  "distance",
  "productsDiscount",
  "discountCodeCode",
  "ringingBell",
  "rating",
  "contact",
  "clientNoteDelivery",
  "clientRateNote",
  "selectedStore",
  "reasonCancel",
  "startDatetimeDriver",
  "finishDatetime",
  "scheduleTime",
  "updatedAt",
  "deletedAt",
  "driver",
  "type",
  "store",
  "status",
  "finalPrice",
  "isEmergency",
  "isRecipe",
  "clientNote",
  "note",
  "createdAt",
  "clientMobile",
  "driverMobile",
  "systemNote",
  "pharmacist",
  "storeHaveRecipe"
];

export interface Update {
  workerId: number;
  status: number;
}
