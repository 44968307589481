import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import ComplaintContext from "../../context/complaint/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import ComplaintContextProvider from "../../context/complaint/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import ComplaintTable from "../../components/complaint/complaint-table";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import {
  IExportComplaintExcel,
  colomnsExportComplaintExcel,
} from "../../models/complaint/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import ComplaintFilter from "../../components/complaint/complaint-filter";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Complaint: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(ComplaintContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("complaints")}
        subTitle={t("complaints_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
        ]}
      />
      <ExportToExcelModal
        id="export-complaint"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            status: false,
            type: false,
            description: false,
            title: false,
            user: false,
          } as IExportComplaintExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportComplaintExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="complaint-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <ComplaintFilter></ComplaintFilter>
      </SearchFilterCard>

      <ComplaintTable />
    </div>
  );
};

const ComplaintPage = () => {
  return (
    // ContextProvider
    <ComplaintContextProvider>
      <Complaint />
    </ComplaintContextProvider>
  );
};
export default ComplaintPage;
