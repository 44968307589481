import { IOrderQuery } from "../../models/order/query";
import { HttpClient, RequestParams } from "../../utils/api/http-client";

class NotificationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getMyNotification = (query: IOrderQuery, params?: RequestParams) =>
    this.http.request<any>({
      path: `notifications/my-notifications`,
      method: "GET",
      query,
      ...params,
    });

  redNotification = (id: number, params?: RequestParams) =>
    this.http.request<any>({
      path: `/notifications/${id}/read`,
      method: "PUT",
      ...params,
    });
}

export default NotificationService;
