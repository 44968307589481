import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AppContextProvider from "./context/app/provider";
import AuthContextProvider from "./context/auth/provider";
import "./index.css";
import CategoryContextProvider from "./context/category/provider";
import TrackingContextProvider from "./context/tracking-map/provider";
import NotificationContextProvider from "./context/notification/provider";
import ProductContextProvider from "./context/product/provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ProductContextProvider>
      <NotificationContextProvider>
        <CategoryContextProvider>
          <AuthContextProvider>
            <TrackingContextProvider>
              <AppContextProvider>
                <App />
              </AppContextProvider>
            </TrackingContextProvider>
          </AuthContextProvider>
        </CategoryContextProvider>
      </NotificationContextProvider>
    </ProductContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
