import { Button, Col, Divider, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BannerContext from "../../../../context/banner/context";
import BannerContextProvider from "../../../../context/banner/provider";
import CategoryContext from "../../../../context/category/context";
import { getBannerType } from "../../../../models/banner/enum";
import { IUpdateBanner } from "../../../../models/banner/request";
import { ICategory } from "../../../../models/category/response";
import { MediumFor } from "../../../../models/medium/enum";
import { getUserStatus } from "../../../../models/user/enum";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { execute } from "../../../../utils/helpers/execute";
import { getTranslations } from "../../../../utils/helpers/translations";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import ImageUploader from "../../../form-components/image-uploader";
import OldImage from "../../../form-components/old-image";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import styles from "./style.module.scss";
import { IProduct } from "../../../../models/product/response";
import AppContext from "../../../../context/app/context";
import { IStore } from "../../../../models/store/response";

const Form = () => {
  const { details, actions, loading } = useContext(BannerContext);
  const { allCategories } = useContext(CategoryContext);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [stores, setStores] = useState<IStore[]>([]);
  const [storeLoading, setStoreLoading] = useState(true);
  const { direction } = useContext(AppContext);
  const Option = Select.Option;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const banners = [1, 2, 3, 4, 5, 6, 7];

  const methods = useForm<any>({
    defaultValues: {
      imageId: details?.image?.id ?? undefined,
      link: details?.link,
      rateableId: details?.rateableId ?? undefined,
      for: 2,
    } as IUpdateBanner,
  });
  const { handleSubmit, reset, control, setValue } = methods;

  const rateableType = useWatch({
    control,
    name: "rateableType",
  });

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollHeight - scrollTop === clientHeight &&
      hasMore &&
      !productsLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        const { data } = await EndPoints.product.getAllProducts({
          light: true,
          page: page,
          perPage: 250,
        });
        const newOptions = data?.data;
        setProducts((prevOptions) => [...prevOptions, ...newOptions]);
        setHasMore(data?.lastPage == page ? false : true);
      } catch (error) {
      } finally {
        setProductsLoading(false);
      }
    };
    getProducts();
  }, [direction,page]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          light: true,
          perPage: -1,
        });
        setStores(data.data);
      } catch (error) {
      } finally {
        setStoreLoading(false);
      }
    };
    getStores();
  }, [direction]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    console.log("data", data);

    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.banner.updateBanner(details?.id, {
              ...data,
              link: data?.rateableType === 5 ? data?.link : null,
            })
          : await EndPoints.banner.createBanner({
              ...data,
              link: data?.rateableType === 5 ? data?.link : null,
            });
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    reset({
      description: getTranslations(details?.translations?.description) ?? null,
      text: getTranslations(details?.translations?.text),
      imageId: details?.image?.id,
      link: details?.link,
      rateableId: details?.rateableId,
      rateableType: details?.rateableType,
      for: 2,
      status: details?.status,
    } as IUpdateBanner);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_banner") : t("add_banner")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[8, 8]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`text[${lang?.code}]`}
                        label={`${t("text")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: true }}
                      />
                    </Col>
                  );
                })}
                <Col span={8}>
                  <FormItem label={t("rateable_type")} required>
                    <Controller
                      name="rateableType"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            onSelect={() => {
                              setValue("rateableId", null);
                            }}
                            style={{ width: "100%" }}
                            placeholder={t("rateable_type")}
                          >
                            {banners?.map((inc, index) => {
                              return (
                                <Option key={index} value={inc}>
                                  {t(getBannerType(inc))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {!rateableType && (
                  <Col span={8}>
                    <FieldBuilder
                      name=""
                      label={t("choice_rateable_type")}
                      input={{ type: "text" }}
                      disabled
                      rules={{ required: false }}
                    />
                  </Col>
                )}
                {rateableType === 1 && (
                  <Col span={8}>
                    <FormItem label={t("rateableId")}>
                      <Controller
                        name="rateableId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              loading={productsLoading}
                              onPopupScroll={handleScroll}
                              // disabled={countryId ? false : true}
                              {...field}
                              style={{ width: "100%" }}
                              allowClear
                              placeholder={t("rateableId")}
                            >
                              {products?.map((product, index) => {
                                return (
                                  <Option key={index} value={product?.id}>
                                    {product?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                {rateableType === 2 && (
                  <Col span={8}>
                    <FormItem label={t("categories")}>
                      <Controller
                        name="rateableId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              loading={false}
                              {...field}
                              style={{ width: "100%" }}
                              allowClear
                              placeholder={t("categories")}
                            >
                              {allCategories
                                ?.filter(
                                  (cat) =>
                                    cat.grand === null && cat.parent === null
                                )
                                ?.map((tr, index) => {
                                  return (
                                    <Option key={index} value={tr?.id}>
                                      {tr?.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                {rateableType === 3 && (
                  <Col span={8}>
                    <FormItem label={t("parent_category")}>
                      <Controller
                        name="rateableId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              loading={false}
                              {...field}
                              style={{ width: "100%" }}
                              allowClear
                              placeholder={t("parent_category")}
                            >
                              {allCategories
                                ?.filter(
                                  (cat) =>
                                    cat.grand !== null && cat.parent === null
                                )
                                ?.map((tr, index) => {
                                  return (
                                    <Option key={index} value={tr?.id}>
                                      {tr?.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                {rateableType === 7 && (
                  <Col span={8}>
                    <FormItem label={t("child_category")}>
                      <Controller
                        name="rateableId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              loading={false}
                              {...field}
                              style={{ width: "100%" }}
                              allowClear
                              placeholder={t("child_category")}
                            >
                              {allCategories
                                ?.filter(
                                  (cat) =>
                                    cat.grand !== null && cat.parent !== null
                                )
                                ?.map((tr, index) => {
                                  return (
                                    <Option key={index} value={tr?.id}>
                                      {tr?.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                {rateableType === 4 && (
                  <Col span={8}>
                    <FormItem label={t("store")}>
                      <Controller
                        name="rateableId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              loading={storeLoading}
                              {...field}
                              style={{ width: "100%" }}
                              allowClear
                              placeholder={t("rateableId")}
                            >
                              {stores?.map((tr, index) => {
                                return (
                                  <Option key={index} value={tr?.id}>
                                    {tr?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}

                {rateableType === 5 && (
                  <Col span={8}>
                    <FieldBuilder
                      name="link"
                      label={t("link")}
                      input={{ type: "text" }}
                      rules={{ required: false }}
                    />
                  </Col>
                )}

                {rateableType === 6 && (
                  <Col span={8}>
                    <FieldBuilder
                      name="rateableId"
                      disabled
                      label={t("not_related")}
                      input={{ type: "text" }}
                      rules={{ required: false }}
                    />
                  </Col>
                )}

                <Col span={8}>
                  <FormItem label={t("status")}>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={false}
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("status")}
                          >
                            {[1, 2]?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {t(getUserStatus(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`description[${lang?.code}]`}
                        label={`${t("description")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}

                <Col span={24}>
                  <FormItem label={t("image")} required>
                    <Controller
                      name="imageId"
                      rules={{
                        required: {
                          value: true,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <ImageUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              // setfileId(response);
                              field.onChange(response?.id);
                            }}
                            imageFor={MediumFor.UserProfile}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {details?.image && <OldImage src={details?.image?.url} />}
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const BannerPharmatyictsForm = () => {
  return (
    <BannerContextProvider>
      <Form />
    </BannerContextProvider>
  );
};
export default BannerPharmatyictsForm;
