import { Col, Descriptions as AntdDescriptions, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";
import CategoryContext from "../../../context/category/context";
import { useNavigate, useParams } from "react-router-dom";
import { ColumnProps } from "antd/es/table";
import { ICategory } from "../../../models/category/response";
import PageDetails from "../../general/details";
import Spin from "../../general/antd/spin";
import Descriptions from "../../general/antd/descriptions";
import Table from "../../general/table-components/table";
import CategoryContextProvider from "../../../context/category/provider";
import Image from "../../general/antd/image";
interface Props {}

const CategoryDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CategoryContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const columns: ColumnProps<ICategory>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
    },
  ];

  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("category")}`}
        buttonName={`${t("update_category")}`}
        onClick={() => navigate(`/categories/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("name")}>
                  {details?.name ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("parent")}>
                  {details?.parent?.name ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("order")}>
                  {details?.order ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("note")}>
                  {details?.note ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("image")}>
                  <Image src={details?.image?.url} preview />
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("main_imag")}>
                  <Image src={details?.mainImage?.url} preview />
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const CategoryDetailsPage = () => {
  return <CategoryDetails />;
};
export default CategoryDetailsPage;
