import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateProductStore,
  IExportProductStoreExcel,
  IUpdateProductStore,
} from "../../models/product-store/request";
import {
  IProductStore,
  IProductStoreDetails,
} from "../../models/product-store/response";
import { IProductStoreQuery } from "../../models/product-store/query";

class ProductStoreService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllProductStores = (
    id: number,
    query: IProductStoreQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IProductStore>>({
      path: `/product-dash/store/${id}`,
      method: "GET",
      query,
      ...params,
    });

  getProductStore = (id: number, params?: RequestParams) =>
    this.http.request<IProductStoreDetails>({
      path: `/product-dash-store/${id}`,
      method: "GET",
      ...params,
    });

  createProductStore = (data: ICreateProductStore, params?: RequestParams) =>
    this.http.request<IProductStore>({
      path: "/product-dash/store",
      method: "POST",
      body: data,
      ...params,
    });

  updateProductStore = (
    id: number,
    data: IUpdateProductStore,
    params?: RequestParams
  ) =>
    this.http.request<IProductStore>({
      path: `/product-dash/store/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteProductStore = (id: number, params?: RequestParams) =>
    this.http.request<IProductStore>({
      path: `/product-dash/store/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportProductStoreExcel, query: IProductStoreQuery) =>
    axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/productStore/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default ProductStoreService;
