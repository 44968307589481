import { Spin as AntdSpin, SpinProps as AntdSpinProps } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import a from "../../../../assets/images/marker.png";
interface Props extends AntdSpinProps {
  notViewWordLoading?: boolean;
}

const Spin: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <AntdSpin
      className={styles.spin}
      tip={props.notViewWordLoading ? " " : t("loading")}
      {...props}
    ></AntdSpin>
  );
};

export default Spin;
