import moment from "moment";
import { useReducer } from "react";
import DeliveryWorkerContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { IDeliveryWorker } from "../../models/delivery-workers/response";
import {
  ICreateDeliveryWorker,
  IUpdateDeliveryWorker,
} from "../../models/delivery-workers/request";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import DoctorContext from "./context";
import { ICreateDoctor, IUpdateDoctor } from "../../models/doctors/request";
import { IDoctorQuery } from "../../models/doctors/query";
import MedicalSspecialtiesContext from "./context";
import { IMedicalSpecialtiesDetails } from "../../models/medical-specialties/response";
import { ICreateMedicalSpecialties, IUpdateMedicalSpecialties } from "../../models/medical-specialties/request";

export interface IProps {
  children: React.ReactNode;
}
const MedicalSspecialtiesContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.medicalSspecialties.getAllMedicalSspecialties(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.medicalSspecialties.getMedicalSspecialties(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IMedicalSpecialtiesDetails) => { };

  const createMedical = async (request: ICreateMedicalSpecialties) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.medicalSspecialties.createMedicalSspecialties(request);
        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateMedical = async (id: number, request: IUpdateMedicalSpecialties) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.medicalSspecialties.updateMedicalSspecialties(id, request);

        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteMedical = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.medicalSspecialties.deleteMedicalSspecialties(id);

        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDoctorQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.deliveryWorkers.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `MedicalSspecialties-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <MedicalSspecialtiesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          createMedical,
          updateMedical,
          deleteMedical,
          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </MedicalSspecialtiesContext.Provider>
  );
};

export default MedicalSspecialtiesContextProvider;
