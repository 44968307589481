import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderContext from "../../../context/order/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IOrderShort } from "../../../models/order/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import {
  getOrderStatus,
  getOrderStatusColor,
} from "../../../models/order/enum";
import moment from "moment";
import EditBtn from "../../general/table-components/actions/edit-btn";

interface IProps {}

const OrderShortTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { listShort, actions, loading, query } = useContext(OrderContext);

  const columns: ColumnProps<IOrderShort>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("number_id"),
      dataIndex: "number",
      key: "number",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("store"),
      dataIndex: "store",
      align: "center",
      key: "store",
      width: 200,
      render: (_, record) => {
        return <Tag title={record?.store?.name ?? "-"} />;
      },
    },
    {
      title: t("current_order_status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      width: 250,
      render: (_, record) => {
        return (
          <Tag
            title={getOrderStatus(record?.status)}
            color={getOrderStatusColor(record?.status)}
          />
        );
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      width: 100,
      render: (_, record) => {
        return moment(record?.createdAt).format("YYYY-MM-DD");
      },
    },
    
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={listShort?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={listShort?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
       
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default OrderShortTable;
