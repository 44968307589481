import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import CityContext from "../../../context/city/context";
import CityContextProvider from "../../../context/city/provider";

interface Props {}

const City: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(CityContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("city")}
        buttonName={`${t("update_city")}`}
        onClick={() => navigate(`/cities/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("name_ar")}>
              {details?.translations?.name[0].value ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name_en")}>
              {details?.translations?.name[1].value ?? "-"}
            </AntdDescriptions.Item>

            {details?.translations?.note && (
              <>
                <AntdDescriptions.Item label={t("note_ar")}>
                  {details?.translations?.note[0]?.value ?? "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("note_en")}>
                  {details?.translations?.note[1]?.value ?? "-"}
                </AntdDescriptions.Item>
              </>
            )}
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const CityDetailesPage = () => {
  return (
    <CityContextProvider>
      <City />
    </CityContextProvider>
  );
};
export default CityDetailesPage;
