import { ITranslationResponse } from "../../models/base/translation";
import { languages } from "./constants";

export const getTranslations = (translation?: ITranslationResponse[]) => {
  return translation
    ? translation?.reduce(
        (prev, { lang, value }) => ((prev[lang] = value), prev),
        {} as any
      )
    : null;
};
export const getDumpTranslations = (translation?: ITranslationResponse[]) => {
  let step = translation;
  if (translation) {
    for (const [key, value] of Object.entries(translation)) {
      if (!value) {
        step[key] = "empty note";
      }
    }

    return step;
  }
  return null;
};
