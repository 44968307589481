import { HttpClient, RequestParams } from "../../utils/api/http-client";

class MediaService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getMedia = (id: number, params?: RequestParams) =>
    this.http.request<any>({
      path: `/media/${id}`,
      method: "GET",
      ...params,
    });
}

export default MediaService;
