import { Col, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AttributeType,
  getAttributeType,
} from "../../../models/attribute/enums";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import styles from "./style.module.scss";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import { ErrorMessage } from "@hookform/error-message";
import Button from "../../general/antd/button";
import classNames from "classnames";
import { TwitterPicker } from "react-color";
import { IParamDetails } from "../../../models/param/resopnse";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
import EndPoints from "../../../services/end-points";
import { IStore } from "../../../models/store/response";
import FileUploader from "../../form-components/file-uploader";

interface IProps {
  type?: number;
  details?: IParamDetails;
}

const UploadForm: React.FC<IProps> = ({ type, details }) => {
  const { t } = useTranslation();
  const [stores, setStores] = useState<IStore[]>();
  useEffect(() => {
    const getStores = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          light: true,
          perPage: -1,
        });
        setStores(data.data);
      } catch (error) {
      } finally {
      }
    };
    getStores();
  }, []);
  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <FieldBuilder
            key={"storeId"}
            name={`storeId`}
            label={`${t("store")}`}
            input={{
              type: "select",
              allowSearch: true,
              options: stores?.map((s) => ({ label: s.name, value: s.id })),
            }}
            rules={{ required: true }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            key={"available"}
            name={`available`}
            label={`${t("available")}`}
            input={{
              type: "select",
              options: [1, 0]?.map((s) => ({
                label: s == 0 ? t("no") : t("yes"),
                value: s,
              })),
            }}
            rules={{ required: true }}
          />
        </Col>{" "}
        <Col span={24}>
          <FormItem label={t("file")}>
            <Controller
              name="fileId"
              rules={{
                required: {
                  value: false,
                  message: `${t("field_is_required_message")}`,
                },
              }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <FileUploader
                    {...field}
                    type="DRAGGER"
                    onChange={(response) => {
                      field.onChange(response?.id);
                    }}
                    fileFor={MediumFor.PRODUCT_PDF}
                  />
                );
              }}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default UploadForm;
