export enum OrderStatus {
  SEARCHING_FOR_PHARMACY = 1,
  PREPARING = 2,
  COMPLETING_ORDER = 3,
  CUSTOMER_ACCEPTED = 4,
  SEARCHING_FOR_DELIVERY_DRIVER = 5,
  EN_ROUTE_TO_CUSTOMER_PRESCRIPTION_RECEIVED = 6,
  EN_ROUTE_TO_CUSTOMER_PRODUCT_PICKUP = 7,
  COMPLETED_AND_PAID = 8,
  CANCELLED_BY_PHARMACIST = 9,
  CANCELLED_BY_CUSTOMER = 10,
  CANCELLED_BY_ADMINISTRATION = 11,
  EMERGENCY_BY_DELIVERY_DRIVER = 12,
  PENDING = 13,
}

export const getOrderStatusColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CANCELLED_BY_ADMINISTRATION:
      return "red";
    case OrderStatus.CANCELLED_BY_CUSTOMER:
      return "orange";
    case OrderStatus.CANCELLED_BY_PHARMACIST:
      return "yellow";
    case OrderStatus.COMPLETED_AND_PAID:
      return "green";
    case OrderStatus.COMPLETING_ORDER:
      return "blue";
    case OrderStatus.CUSTOMER_ACCEPTED:
      return "indigo";
    case OrderStatus.EMERGENCY_BY_DELIVERY_DRIVER:
      return "violet";
    case OrderStatus.EN_ROUTE_TO_CUSTOMER_PRESCRIPTION_RECEIVED:
      return "purple";
    case OrderStatus.EN_ROUTE_TO_CUSTOMER_PRODUCT_PICKUP:
      return "pink";
    case OrderStatus.PREPARING:
      return "brown";
    case OrderStatus.SEARCHING_FOR_DELIVERY_DRIVER:
      return "cyan";
    case OrderStatus.SEARCHING_FOR_PHARMACY:
      return "grey";
    default:
      return "black"; // Default color for unhandled cases
  }
};

export const getOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.CANCELLED_BY_ADMINISTRATION:
      return "cancelled_by_administration";
    case OrderStatus.CANCELLED_BY_CUSTOMER:
      return "cancelled_by_customer";
    case OrderStatus.CANCELLED_BY_PHARMACIST:
      return "cancelled_by_pharmacist";
    case OrderStatus.COMPLETED_AND_PAID:
      return "completed_and_paid";
    case OrderStatus.COMPLETING_ORDER:
      return "completing_order";
    case OrderStatus.CUSTOMER_ACCEPTED:
      return "customer_accepted";
    case OrderStatus.EMERGENCY_BY_DELIVERY_DRIVER:
      return "emergency_by_delivery_driver";
    case OrderStatus.EN_ROUTE_TO_CUSTOMER_PRESCRIPTION_RECEIVED:
      return "en_route_to_customer_prescription_received";
    case OrderStatus.EN_ROUTE_TO_CUSTOMER_PRODUCT_PICKUP:
      return "en_route_to_customer_product_pickup";
    case OrderStatus.PREPARING:
      return "preparing";
    case OrderStatus.SEARCHING_FOR_DELIVERY_DRIVER:
      return "searching_for_delivery_driver";
    case OrderStatus.SEARCHING_FOR_PHARMACY:
      return "searching_for_pharmacy";
    case OrderStatus.PENDING:
      return "pending";
    default:
      return "order_status";
  }
};

// ................order type
export enum OrderType {
  ORDER_ACCEPT = 1,
  PRODUCT_ACCEPT = 2,
  PENDING_ORDER = 3,
  PENDING_PRODUCT = 4,
  HAND_ORDER_REJECT = 5,
  ORDER_NOT_RESPOND = 6,
  HAND_PRODUCT_REJECT = 7,
  PRODUCT_NOT_RESPOND = 8,
}
export enum OrderStyle {
  NEAREST_PHARMACY = 1,
  SPECIFIC_PHARMACY = 2,
}

export const getOrderTypeColor = (Type: OrderType) => {
  switch (Type) {
    case OrderType.ORDER_ACCEPT:
      return "green";
    case OrderType.PRODUCT_ACCEPT:
      return "cyan";
    case OrderType.PENDING_ORDER:
      return "gray";
    case OrderType.PENDING_PRODUCT:
      return "red";
    case OrderType.HAND_ORDER_REJECT:
      return "violet";
    case OrderType.HAND_PRODUCT_REJECT:
      return "blue";
    case OrderType.ORDER_NOT_RESPOND:
      return "pink";
    case OrderType.PRODUCT_NOT_RESPOND:
      return "purple";
    default:
      return "red";
  }
};
export const getOrderStyle = (Type: OrderStyle) => {
  switch (Type) {
    case OrderStyle.NEAREST_PHARMACY:
      return "nearest_pharmacy";
    case OrderStyle.SPECIFIC_PHARMACY:
      return "specific_pharmacy";

    default:
      return "bug";
  }
};
export const getOrderType = (Type: OrderType) => {
  switch (Type) {
    case OrderType.ORDER_ACCEPT:
      return "order_accept";
    case OrderType.PRODUCT_ACCEPT:
      return "product_accept";
    case OrderType.PENDING_ORDER:
      return "pending_order";
    case OrderType.PENDING_PRODUCT:
      return "pending_product";
    case OrderType.HAND_ORDER_REJECT:
      return "hand_order_reject";
    case OrderType.HAND_PRODUCT_REJECT:
      return "hand_product_reject";
    case OrderType.ORDER_NOT_RESPOND:
      return "order_not_respond";
    case OrderType.PRODUCT_NOT_RESPOND:
      return "product_not_respond";
    default:
      return "bug";
  }
};

//-------- order scheduling type
export enum orderSchedulingType {
  quickly = 1,
  later = 2,
}

export const getorderSchedulingTypeStyle = (type: orderSchedulingType) => {
  switch (type) {
    case orderSchedulingType.quickly:
      return "quickly";
    case orderSchedulingType.later:
      return "later";

    default:
      return "bug";
  }
};

export const getorderSchedulingTypeColor = (type: orderSchedulingType) => {
  switch (type) {
    case orderSchedulingType.quickly:
      return "green";
    case orderSchedulingType.later:
      return "blue";

    default:
      return "grey";
  }
};
//-------- order history type
export enum OrderHistoryType {
  ORDER_ACCEPT = 1,
  PRODUCT_ACCEPT = 2,
  PENDING_ORDER = 3,
  PENDING_PRODUCT = 4,
  HAND_ORDER_REJECT = 5,
  ORDER_NOT_RESPOND = 6,
  HAND_PRODUCT_REJECT = 7,
  PRODUCT_NOT_RESPOND = 8,
  PRODUCT_CANCELING = 9,
  ORDER_CANCELING = 10,
}

export const getOrderHistoryTypeColor = (type: OrderHistoryType) => {
  switch (type) {
    case OrderHistoryType.ORDER_ACCEPT:
      return "red";
    case OrderHistoryType.PRODUCT_ACCEPT:
      return "orange";
    case OrderHistoryType.PENDING_ORDER:
      return "yellow";
    case OrderHistoryType.PENDING_PRODUCT:
      return "green";
    case OrderHistoryType.HAND_ORDER_REJECT:
      return "blue";
    case OrderHistoryType.ORDER_NOT_RESPOND:
      return "indigo";
    case OrderHistoryType.HAND_PRODUCT_REJECT:
      return "violet";
    case OrderHistoryType.PRODUCT_NOT_RESPOND:
      return "purple";
    case OrderHistoryType.PRODUCT_CANCELING:
      return "brown";
    case OrderHistoryType.ORDER_CANCELING:
      return "cyan";
    default:
      return "black"; // Default color for unhandled cases
  }
};

export const getOrderHistoryTypeStatus = (type: OrderHistoryType) => {
  switch (type) {
    case OrderHistoryType.ORDER_ACCEPT:
      return "order_accept";
    case OrderHistoryType.PRODUCT_ACCEPT:
      return "product_accept";
    case OrderHistoryType.PENDING_ORDER:
      return "pending_order";
    case OrderHistoryType.PENDING_PRODUCT:
      return "pending_product";
    case OrderHistoryType.HAND_ORDER_REJECT:
      return "hand_order_reject";
    case OrderHistoryType.ORDER_NOT_RESPOND:
      return "order_not_respond";
    case OrderHistoryType.HAND_PRODUCT_REJECT:
      return "hand_product_reject";
    case OrderHistoryType.PRODUCT_NOT_RESPOND:
      return "product_not_respond";
    case OrderHistoryType.PRODUCT_CANCELING:
      return "product_canceling";
    case OrderHistoryType.ORDER_CANCELING:
      return "order_Canceling";
    default:
      return "red";
  }
};
