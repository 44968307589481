import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import discountCodeContext from "../../../context/discount-code/context";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import { IDiscountCode } from "../../../models/discount-code/response";
import {
  getDiscountStatus,
  getDiscountStatusColor,
  getDiscountType,
  getDiscounteTypeColor,
} from "../../../models/discount-code/enum";
import Tag from "../../general/antd/tag";
import moment from "moment";

interface IProps {}

const DiscountCodeTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(discountCodeContext);

  const columns: ColumnProps<IDiscountCode>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 100,
      align: "center",
    },

    {
      title: t("code"),
      dataIndex: "code",
      align: "center",
      key: "code",
      sorter: {
        multiple: 2,
      },
    },

    {
      title: t("type"),
      dataIndex: "type_discount",
      align: "center",
      key: "type",
      render: (_, record) => {
        return (
          <Tag
            title={getDiscountType(record?.type)}
            color={getDiscounteTypeColor(record?.type)}
          />
        );
      },
    },
    {
      title: t("validity_date"),
      dataIndex: "validityDate",
      align: "center",
      key: "validityDate",

      render: (_, record) => {
        return record?.validityDate
          ? moment(record?.validityDate).format("YYYY-MM-DD")
          : "-";
      },
    },
    {
      title: t("status_discount"),
      dataIndex: "status_discount",
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            title={getDiscountStatus(record?.status)}
            color={getDiscountStatusColor(record?.status)}
          />
        );
      },
    },

    {
      title: t("code_validity"),
      dataIndex: "codeValidity",
      align: "center",
      key: "codeValidity",
      render: (_, record) => {
        return record?.codeValidity ?? "-";
      },
    },
    {
      title: t("number_points"),
      dataIndex: "numberPoints",
      align: "center",
      width: "15%",
      key: "numberPoints",
      render: (_, record) => {
        return record?.numberPoints ?? "-";
      },
    },
    {
      title: t("frequency_of_use"),
      dataIndex: "maxOfUse",
      align: "center",
      key: "maxOfUse",
      render: (_, record) => {
        return record?.maxOfUse ?? "-";
      },
    },
    {
      title: t("minimum_limit"),
      dataIndex: "minimumLimit",
      align: "center",
      key: "minimumLimit",
      render: (_, record) => {
        return record?.minimumLimit ?? "-";
      },
    },
    {
      title: t("upper_limit"),
      dataIndex: "upperLimit",
      align: "center",
      key: "upperLimit",
      render: (_, record) => {
        return record?.upperLimit ?? "-";
      },
    },

    {
      title: t("actions"),
      width: 250,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteDiscountCode(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{ x: 1400 }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default DiscountCodeTable;
