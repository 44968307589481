import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IStore, IStoreDetails } from "../../models/store/response";
import { IStoreQuery } from "../../models/store/query";
import {
  ICopyStoreProducts,
  ICreateStore,
  IUpdateStore,
} from "../../models/store/request";

export type StoreLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: StoreLoading[];

  list?: IBaseListingResponse<IStore>;
  query: IStoreQuery;

  details?: IStoreDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IStoreDetails) => void;
    copyStoreProducts: (data?: ICopyStoreProducts) => void;

    createStore: (request: ICreateStore) => void;
    updateStore: (id: number, request: IUpdateStore) => void;
    deleteStore: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IStoreQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,
    copyStoreProducts: DEFAULT_FUNCTION,

    createStore: DEFAULT_FUNCTION,
    updateStore: DEFAULT_FUNCTION,
    deleteStore: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const StoreContext = createContext(externalState);

export default StoreContext;
