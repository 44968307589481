import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.scss";
import AppContext from "../../context/app/context";
import RoleContext from "../../context/role/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import { IExportRoleExcel, RolesColoums } from "../../models/role/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import SearchFilterCard from "../../components/general/filter-card";
import RoleContextProvider from "../../context/role/provider";
import RoleTable from "../../components/role/role-table";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import PageHeader from "../../components/general/layout/page-header";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";

interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Role: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(RoleContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("roles")}
        subTitle={t("roles_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/roles/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_role")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-roles"
        title={t("export_roles_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            name: false
          } as IExportRoleExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={RolesColoums} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="user-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      ></SearchFilterCard>

      <RoleTable />
    </div>
  );
};

const RolePage = () => {
  return (
    // ContextProvider
    <RoleContextProvider>
      <Role />
    </RoleContextProvider>
  );
};
export default RolePage;
// export default User
