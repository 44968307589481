import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ClientContext from "../../../context/clients/client/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IClient } from "../../../models/client/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import AppContext from "../../../context/app/context";
import DiscountCodesClientContext from "../../../context/clients/discount-codes/context";
import moment from "moment";

interface IProps { }

const DiscountCodsClientTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, query, listDiscountCodes } = useContext(
    DiscountCodesClientContext);
  const { id } = useParams();
  const { direction } = useContext(AppContext);

  useEffect(() => {
    id && actions.getListDiscountCodes(Number(id));
  }, [query, direction]);

  const columns: ColumnProps<IClient>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: t("code"),
      dataIndex: "code",
      align: "center",
      key: "code",
    },
    {
      title: t("number_of_available_use"),
      dataIndex: "maxOfUse",
      align: "center",
      key: "maxOfUse",
    },
    {
      title: t("actual_usage_number"),
      dataIndex: "numOfUse",
      align: "center",
      key: "numOfUse",
    },
    {
      title: t("validity_date"),
      dataIndex: "validityDate",
      align: "center",
      key: "validityDate",
      render: (validityDate) => {
        return moment(validityDate).format( "YYYY-MM-DD HH:mm:ss")
      }
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={listDiscountCodes?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={listDiscountCodes?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default DiscountCodsClientTable;
