import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IPaymentDeliveryWorker } from "../../models/payment-delivery-worker/response";
import { IPaymentDeliveryWorkerQuery } from "../../models/payment-delivery-worker/query";
import {
  ICreatePaymentDeliveryWorker,
  IExportPaymentDeliveryWorkerExcel,
  IUpdatePaymentDeliveryWorker,
} from "../../models/payment-delivery-worker/request";

class PaymentDeliveryWorkersservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPaymentDeliveryWorkers = (
    query: IPaymentDeliveryWorkerQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPaymentDeliveryWorker>>({
      path: `/payment`,
      method: "GET",
      query,
      ...params,
    });

  getPaymentDeliveryWorker = (id: number, params?: RequestParams) =>
    this.http.request<IPaymentDeliveryWorker>({
      path: `/payment/${id}`,
      method: "GET",
      ...params,
    });

  createPaymentDeliveryWorker = (
    data: ICreatePaymentDeliveryWorker,
    params?: RequestParams
  ) =>
    this.http.request<IPaymentDeliveryWorker>({
      path: "/payment",
      method: "POST",
      body: data,
      ...params,
    });

  updatePaymentDeliveryWorker = (
    id: number,
    data: IUpdatePaymentDeliveryWorker,
    params?: RequestParams
  ) =>
    this.http.request<IPaymentDeliveryWorker>({
      path: `/payment/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePaymentDeliveryWorker = (id: number, params?: RequestParams) =>
    this.http.request<IPaymentDeliveryWorker>({
      path: `/payment/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportPaymentDeliveryWorkerExcel,
    query: IPaymentDeliveryWorkerQuery
  ) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/payment/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default PaymentDeliveryWorkersservice;
