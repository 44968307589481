export enum PaymentStatus {
  ACCEPTED = 1,
  PENDING = 2,
  REJECTED = 3,
}

// Status Boolean stuts
export const getPaymentStatus = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.ACCEPTED:
      return "accepted";
    case PaymentStatus.PENDING:
      return "pending";
    case PaymentStatus.REJECTED:
      return "rejected";
    default:
      return "rejected";
  }
};
export const getPaymentStatusColor = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.ACCEPTED:
      return "green";
    case PaymentStatus.REJECTED:
      return "red";
    case PaymentStatus.PENDING:
      return "orange";
    default:
      return "red";
  }
};
