import { useReducer } from "react";
import PointContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { ICreatePoint, IUpdatePoint } from "../../models/point/request";
import { IPoint } from "../../models/point/response";
import { IPointQuery } from "../../models/point/query";
import PaymentPointContext from "./context";
import { ICreatePaymentPoint, IUpdatePaymentPoint } from "../../models/payment-point/request";
import moment from "moment";
export interface IProps {
  children: React.ReactNode;
}
const PaymentPointContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.paymentPoint.getAllPaymentPoints(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.paymentPoint.getPaymentPoint(id);
        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IPoint) => {
    // dispatch({ type: 'SET_DETAILSS', payload: { details: data } })
  };

  const createPaymentPoint = async (request: ICreatePaymentPoint) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.paymentPoint.createPaymentPoint(request);
        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updatePaymentPoint = async (id: number, request: IUpdatePaymentPoint) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.paymentPoint.updatePaymentPoint(id, request);

        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deletePaymentPoint = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.paymentPoint.deletePaymentPoint(id);

        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IPointQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.paymentPoint.exportExcel(request, state.query);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Point-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };


  return (
    <PaymentPointContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          //setDetails,
          exportExcel,

          createPaymentPoint,
          updatePaymentPoint,
          deletePaymentPoint,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </PaymentPointContext.Provider>
  );
};

export default PaymentPointContextProvider;
