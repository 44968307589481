import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ClientContext from "../../../context/clients/client/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IClient } from "../../../models/client/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import AppContext from "../../../context/app/context";
import moment from "moment";
import { ImCheckmark, ImCross } from "react-icons/im";
import ClientPointsContext from "../../../context/clients/points/context";

interface IProps {}

const ClientPointsTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, query, listClientPoints } =
    useContext(ClientPointsContext);
  const { id } = useParams();
  const { direction } = useContext(AppContext);

  useEffect(() => {
    id && actions.getListClientPoints(Number(id));
  }, [query, direction, id]);

  const columns: ColumnProps<any>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("main_points_numbers"),
      dataIndex: "mainPointsNumbers",
      align: "center",
      key: "mainPointsNumbers",
    },
    {
      title: t("current_points_numbers"),
      dataIndex: "currentPointsNumbers",
      align: "center",
      key: "currentPointsNumbers",
    },
    {
      title: t("order_id"),
      dataIndex: "order",
      key: "order",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (_, record) => {
        return record.order.id ?? "-";
      },
    },
    {
      title: t("consumed"),
      dataIndex: "isConsumed",
      key: "isConsumed",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (isConsumed) => {
        return (
          <>
            {isConsumed ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD");
      },
    },
    {
      title: t("expiration_date"),
      dataIndex: "expirationDate",
      key: "expirationDate",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (expirationDate) => {
        return moment(expirationDate).format("YYYY-MM-DD");
      },
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={listClientPoints?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={listClientPoints?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ClientPointsTable;
