import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ComplaintContext from "../../../context/complaint/context";
import { IComplaint } from "../../../models/complaint/response";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import {
  getComplaintStatus,
  getComplaintStatusColor,
  getComplaintType,
  getComplaintTypeColor,
} from "../../../models/complaint/enum";

interface IProps {}

const ComplaintTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(ComplaintContext);

  const columns: ColumnProps<IComplaint>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: t("created"),
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (_, recode) => {
        return  recode?.user?.firstName +  " " + recode?.user?.lastName ;
      },
    },
    {
      title: t("mobile"),
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
      render: (_, recode) => {
        return  recode?.user?.cleanMobile ;
      },
    },
    {
      title: t("type_acount"),
      dataIndex: "userType",
      key: "userType",
      align: "center",
      render: (_, recode) => {
        return  t(recode?.userType) ;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, recode) => {
        return (
          <Tag
            color={getComplaintStatusColor(recode?.status)}
            title={getComplaintStatus(recode?.status)}
          />
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (_, recode) => {
        return (
          <Tag
            color={getComplaintTypeColor(recode?.type)}
            title={getComplaintType(recode?.type)}
          />
        );
      },
    },
    // {
    //   title: t("request"),
    //   dataIndex: "request",
    //   key: "request",
    //   align: "center",
    //   render: (_, recode) => {
    //     return recode?.order ? recode?.order?.name : "-";
    //   },
    // },
    {
      title: t("actions"),
      width: 250,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteComplaint(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ComplaintTable;
