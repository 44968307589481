import React, { useContext } from "react";
import { Tag, Image } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";
import CategoryContext from "../../../../../context/category/context";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const ImageDropdownAttribute: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { allCategories } = useContext(CategoryContext);
  const fetchParam = (id: number) => {
    let param = allCategories
      ?.filter(
        (cat) => cat.id == attributeWithValue?.attribute?.category?.id
      )[0]
      .attributes?.filter(
        (attr) => attr.id == attributeWithValue?.attribute?.id
      )[0]
      .attributeParams.filter((param) => param.id == id)[0];

    return param;
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      {attributeWithValue?.values?.map((value, index) => {
        return (
          <Tag key={index} className={styles.flexCenter}>
            <Image
              src={fetchParam(value)?.data?.image?.url}
              className={styles.imageIcon}
            />
            <span className={styles.mS05}>{fetchParam(value)?.name}</span>
          </Tag>
        );
      })}
    </div>
  );
};

export default ImageDropdownAttribute;
