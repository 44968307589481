import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IPoint, IPointDetails } from "../../models/point/response";
import { IPointQuery } from "../../models/point/query";
import { ICreatePoint, IUpdatePoint } from "../../models/point/request";

export type PointLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: PointLoading[];

  list?: IBaseListingResponse<IPoint>;
  query: IPointQuery;

  details?: IPointDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IPointDetails) => void;

    createPoint: (request: ICreatePoint) => void;
    updatePoint: (id: number, request: IUpdatePoint) => void;
    deletePoint: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IPointQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createPoint: DEFAULT_FUNCTION,
    updatePoint: DEFAULT_FUNCTION,
    deletePoint: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PointContext = createContext(externalState);

export default PointContext;
