// Status Tags Colors
export enum BoldType {
  A_POSITIVE = 1,
  A_NEGATIVE = 2,
  B_POSITIVE = 3,
  B_NEGATIVE = 4,
  AB_POSITIVE = 5,
  AB_NEGATIVE = 6,
  O_POSITIVE = 7,
  O_NEGATIVE = 8,
}

export enum Status {
  MALE = 1,
  FEMALE = 2,
}

export const getGenderColor = (status: Status) => {
  switch (status) {
    case Status.MALE:
      return "green";
    case Status.FEMALE:
      return "orange";

    default:
      return "red";
  }
};

// Status Boolean status
export const getGender = (status: Status) => {
  switch (status) {
    case Status.MALE:
      return "male";
    case Status.FEMALE:
      return "female";

    default:
      return "male";
  }
};

export const getBloodType = (status: BoldType) => {
  switch (status) {
    case BoldType.AB_NEGATIVE:
      return "AB-";
    case BoldType.AB_POSITIVE:
      return "AB+";
    case BoldType.A_NEGATIVE:
      return "A-";
    case BoldType.A_POSITIVE:
      return "A+";
    case BoldType.B_NEGATIVE:
      return "B-";
    case BoldType.B_POSITIVE:
      return "B+";
    case BoldType.O_NEGATIVE:
      return "O-";
    case BoldType.O_POSITIVE:
      return "O+";

    default:
      return "other";
  }
};
