import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { FC, useContext } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../context/app/context";

ChartJS.register(ArcElement, Tooltip, Legend);
interface IProps {
  lables?: any[];
  count?: any[];
  colors?: any[];
}
const Chart: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(AppContext);

  const data = {
    labels: props?.lables,
    datasets: [
      {
        label: t("count"),
        data: props?.count,
        backgroundColor: props?.colors ?? [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],

        borderColor: props?.colors ?? [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderWidth: 1.3,
        rotation: 50,
        hoverOffset: 10,
        hoverBorderWidth: 6,
      },
    ],
  };

  const options = {
    plugins: {
      animation: {
        animateRotate: true,
        animateScale: true,
      },
      legend: {
        labels: {
          color: theme === "dark" ? "white" : "black",
        },
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default Chart;
