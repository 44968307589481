import React from "react";

import moment from "moment";
import { DATE_FORMATE } from "../../../../../utils/helpers/constants";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const DateAttribute: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  return (
    <div>{moment(attributeWithValue?.values[0]).format(DATE_FORMATE)}</div>
  );
};

export default DateAttribute;
