import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import PolicyTermContext from "../../../context/policy-term/context";
import PolicyTermContextProvider from "../../../context/policy-term/provider";
import { IUpdatePolicyTerm } from "../../../models/policy-term/request";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import RichEditor from "../../form-components/rich-text-editor";

const Form = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { list, actions, loading } = useContext(PolicyTermContext);
  useEffect(() => {
    const getPolicyTerm = async () => {
      await actions.getPolicyTerm();
    };
    getPolicyTerm();
  }, [direction]);
  return (
    <MainForm
      onSubmit={async (data) => {
        await actions.updatePolicyTerm(data);
      }}
      title={t("update_policy_term")}
      defaultValues={
        {
          policy: getTranslations(list?.policy?.translations?.policyTerm),
          term: getTranslations(list?.term?.translations?.policyTerm),
        } as IUpdatePolicyTerm
      }
    >
      {loading.includes("list") || loading.includes("update") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={24}>
                  <div>
                    <FormItem label={`${t("policy")} (${lang?.name})`}>
                      <Controller
                        name={`policy[${lang?.code}]`}
                        rules={{
                          required: {
                            value: false,
                            message: `${t("field_is_required_message")}`,
                          },
                        }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <RichEditor
                              value={field?.value ?? ""}
                              onChange={field?.onChange}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={24}>
                  <div>
                    <FormItem label={`${t("term")} (${lang?.name})`}>
                      <Controller
                        name={`term[${lang?.code}]`}
                        rules={{
                          required: {
                            value: false,
                            message: `${t("field_is_required_message")}`,
                          },
                        }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <RichEditor
                              value={field?.value ?? ""}
                              onChange={field?.onChange}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const PolicyTermForm = () => {
  return (
    <PolicyTermContextProvider>
      <Form />
    </PolicyTermContextProvider>
  );
};
export default PolicyTermForm;
