import { Button, Col, Divider, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./style.module.scss";

import AppContext from "../../../context/app/context";
import CategoryContext from "../../../context/category/context";
import HomePageListContext from "../../../context/home-page-list/context";
import HomePageListContextProvider from "../../../context/home-page-list/provider";
import {
  getCardStyle,
  getHomePageStatus,
  getHomePageType,
} from "../../../models/home-page-list/enum";
import { IUpdateHomePageList } from "../../../models/home-page-list/request";
import { IProduct } from "../../../models/product/response";
import { ITrademark } from "../../../models/trademark/response";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import { execute } from "../../../utils/helpers/execute";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import { useIsMount } from "../../../utils/hooks/is-mount";

const Option = Select.Option;

const Form = () => {
  const { details, actions, loading } = useContext(HomePageListContext);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const { direction } = useContext(AppContext);
  const [trademarks, setTrademarks] = useState<ITrademark[]>([]);
  const [trademarkLoading, setTrademarkLoading] = useState(true);

  const { id } = useParams();
  const { allCategories: Categories } = useContext(CategoryContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);
  const methods = useForm<any>({
    defaultValues: {
      description: getTranslations(details?.translations?.description) ?? null,
      title: getTranslations(details?.translations?.title),
      multiBrandsId: [3, 5].includes(details?.type)
        ? details?.products?.map((product) => product?.id)
        : [],
      status: details?.status ?? false,
      subBrandId: [1, 2, 4].includes(details?.type)
        ? details?.subBrand?.id
        : null,
      type: details?.type,
      style: details?.style,
    } as IUpdateHomePageList,
  });
  const { handleSubmit, reset, control, setValue } = methods;

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await actions.updateHomePageList(details?.id, data)
          : await actions.createHomePageList(data);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };
  const watchType = useWatch({
    control,
    name: "type",
  });

  useEffect(() => {
    reset({
      description: getTranslations(details?.translations?.description) ?? null,
      title: getTranslations(details?.translations?.title),
      multiBrandsId:
        details?.type === 5
          ? details?.categories?.map((category) => category?.id)
          : details?.products?.map((product) => product?.id) ?? [],
      status: details?.status,
      subBrandId: details?.subBrand?.id,
      type: details?.type,
      style: details?.style,
    } as IUpdateHomePageList);
  }, [details]);

  // get all products from api
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState({ page: 1, search: undefined });

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollHeight - scrollTop === clientHeight &&
      hasMore &&
      !productsLoading
    ) {
      setQuery({ ...query, page: query.page + 1 });
    }
  };

  const handleSearch = (value) => {
    setQuery({ page: 1, search: value });
    setProducts([]);
  };

  const getProducts = async () => {
    try {
      const { data } = await EndPoints.product.getAllProducts({
        light: true,
        page: query.page,
        perPage: 250,
        keyword: query?.search == "" ? undefined : query?.search,
      });
      const newOptions = data?.data;
      setProducts((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMore(data.lastPage == query.page ? false : true);
      setProductsLoading(false);
    } catch (error) {
    } finally {
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [direction, query]);

  // get all trademarks from api
  useEffect(() => {
    const getTrademarks = async () => {
      try {
        const { data } = await EndPoints.trademark.getAllTrademark({
          light: true,
          perPage: -1,
        });
        setTrademarks(data.data);
        setTrademarkLoading(false);
      } catch (error) {
      } finally {
        setTrademarkLoading(false);
      }
    };
    getTrademarks();
  }, [direction]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div>
          <span className={styles.title}>
            {details ? t("update_home_page_list") : t("add_home_page_list")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 12]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`title[${lang?.code}]`}
                        label={`${t("title")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: true }}
                      />
                    </Col>
                  );
                })}

                <Col span={12}>
                  <FieldBuilder
                    name="status"
                    label={`${t("status")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2]?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getHomePageStatus(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>

                <Col span={12}>
                  <FieldBuilder
                    name="style"
                    label={`${t("style")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2, 3]?.map((style, index) => {
                        return {
                          key: index,
                          label: t(getCardStyle(style)),
                          value: style,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FormItem label={t("type")}>
                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            onSelect={() => {
                              setValue("subBrandId", null);
                              setValue("multiBrandsId", []);
                            }}
                            loading={false}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            disabled={details ? true : false}
                            placeholder={t("type")}
                          >
                            {[1, 2, 3, 4, 5]?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {t(getHomePageType(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {(watchType === undefined || watchType === null) && (
                  <Col span={12}>
                    <FieldBuilder
                      name=""
                      label={t("choice_type")}
                      disabled
                      input={{ type: "text" }}
                      rules={{ required: false }}
                    />
                  </Col>
                )}

                {watchType === 1 && (
                  <Col span={12}>
                    <FieldBuilder
                      name="subBrandId"
                      label={`${t("grands_or_subcategory")}`}
                      width="large"
                      rules={{ required: true }}
                      input={{
                        type: "select",
                        loading: false,
                        options: Categories?.filter(
                          (cat) =>
                            cat?.parent === null ||
                            cat?.grand !== null ||
                            cat?.grand === null
                        )?.map((cat, index) => {
                          return {
                            key: index,
                            label: cat?.name,
                            value: cat?.id,
                          };
                        }),
                      }}
                    />
                  </Col>
                )}

                {watchType === 2 && (
                  <Col span={12}>
                    <FieldBuilder
                      name="subBrandId"
                      label={`${t("trademarks")}`}
                      width="large"
                      rules={{ required: true }}
                      input={{
                        type: "select",
                        loading: false,
                        options: trademarks?.map((cat, index) => {
                          return {
                            key: index,
                            label: cat?.name,
                            value: cat?.id,
                          };
                        }),
                      }}
                    />
                  </Col>
                )}

                {watchType === 3 && (
                  <Col span={12}>
                    <FieldBuilder
                      name="multiBrandsId"
                      label={`${t("products")}`}
                      width="large"
                      rules={{ required: true }}
                      input={{
                        type: "select-pagination-multiple",
                        handleScroll: handleScroll,
                        handleSearch: handleSearch,
                        allowSearch: true,
                        loading: productsLoading,
                        options: products?.map((product, index) => {
                          return {
                            key: index,
                            label: product?.name,
                            value: product?.id,
                          };
                        }),
                      }}
                    />
                  </Col>
                )}
                {watchType === 4 && (
                  <Col span={12}>
                    <FieldBuilder
                      name="subBrandId"
                      label={`${t("children")}`}
                      width="large"
                      rules={{ required: true }}
                      input={{
                        type: "select",
                        loading: productsLoading,
                        allowSearch: true,
                        options: Categories?.filter(
                          (cat) => cat?.parent !== null && cat?.parent !== null
                        )?.map((cat, index) => {
                          return {
                            key: index,
                            label: cat?.name,
                            value: cat?.id,
                          };
                        }),
                      }}
                    />
                  </Col>
                )}

                {watchType === 5 && (
                  <Col span={12}>
                    <FieldBuilder
                      name="multiBrandsId"
                      label={`${t("categories")}`}
                      width="large"
                      rules={{ required: true }}
                      input={{
                        type: "multiSelect",
                        loading: false,
                        options: Categories?.map((cat, index) => {
                          return {
                            key: index,
                            label: cat?.name,
                            value: cat?.id,
                          };
                        }),
                      }}
                    />
                  </Col>
                )}
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`description[${lang?.code}]`}
                        label={`${t("description")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
const HomePageListForm = () => {
  return (
    <HomePageListContextProvider>
      <Form />
    </HomePageListContextProvider>
  );
};
export default HomePageListForm;
