import AreaService from "./area";
import AttributeService from "./attribute";
import AuthService from "./auth";
import BannerService from "./banner";
import CategoryService from "./categories";
import ChronicDiseasesService from "./chronic-diseases";
import CityService from "./city";
import ClientService from "./client";
import CommenQuestionService from "./commen-question";
import ComplaintService from "./complaint";
import DeliveryWorkersService from "./delivery-worker";
import DiscountCodeService from "./discount-code";
import DoctorService from "./doctor";
import GeneralNotificationService from "./general-notification";
import GeneralSettingervice from "./general-setting";
import HomePageListService from "./home-page-list";
import { httpclient } from "./http-client";
import Investorsservice from "./investor";
import MedicalSspecialtiesService from "./medical-specialties";
import NotificationService from "./notifications";
import OrderService from "./order";
import OrderNoteService from "./order-note";
import ParamService from "./param";
import PaymentDeliveryWorkersservice from "./payment-delivery-worker";
import PaymentPointService from "./payment-point";
import PointService from "./point";
import PolicyTermService from "./policy-term";
import PrizeService from "./prize";
import ProductService from "./product";
import ProductStoreService from "./product-store";
import RoleService from "./role";
import SliceService from "./slice";
import StatisticsService from "./statistics";
import StoreService from "./stroe";
import TagsService from "./tag";
import DriverTrackingService from "./tracking-driver";
import TrademarkService from "./trademark";
import UnitsService from "./unit";
import UserService from "./user";
import MediaService from "./media";
import repotservice from "./report";
import RewardService from "./reward";

export default class EndPoints {
  public static auth = new AuthService(httpclient);
  public static user = new UserService(httpclient);
  public static city = new CityService(httpclient);
  public static role = new RoleService(httpclient);
  public static unit = new UnitsService(httpclient);
  public static tag = new TagsService(httpclient);
  public static area = new AreaService(httpclient);
  public static trademark = new TrademarkService(httpclient);
  public static deliveryWorkers = new DeliveryWorkersService(httpclient);
  public static doctor = new DoctorService(httpclient);
  public static category = new CategoryService(httpclient);
  public static client = new ClientService(httpclient);
  public static store = new StoreService(httpclient);
  public static investor = new Investorsservice(httpclient);
  public static discountCode = new DiscountCodeService(httpclient);
  public static banner = new BannerService(httpclient);
  public static attribute = new AttributeService(httpclient);
  public static param = new ParamService(httpclient);
  public static product = new ProductService(httpclient);
  public static commenQuestion = new CommenQuestionService(httpclient);
  public static chronicDiseases = new ChronicDiseasesService(httpclient);
  public static orderNote = new OrderNoteService(httpclient);
  public static generalSettings = new GeneralSettingervice(httpclient);
  public static policyTerm = new PolicyTermService(httpclient);
  public static homePageList = new HomePageListService(httpclient);
  public static generalNotification = new GeneralNotificationService(
    httpclient
  );
  public static complaint = new ComplaintService(httpclient);
  public static statistics = new StatisticsService(httpclient);
  public static driverTracking = new DriverTrackingService(httpclient);
  public static productStore = new ProductStoreService(httpclient);
  public static order = new OrderService(httpclient);
  public static paymentDeliveryWorker = new PaymentDeliveryWorkersservice(
    httpclient
  );
  public static point = new PointService(httpclient);
  public static paymentPoint = new PaymentPointService(httpclient);
  public static medicalSspecialties = new MedicalSspecialtiesService(
    httpclient
  );
  public static prize = new PrizeService(httpclient);
  public static slice = new SliceService(httpclient);
  public static notification = new NotificationService(httpclient);
  public static media = new MediaService(httpclient);
  public static report = new repotservice(httpclient);
  public static reward = new RewardService(httpclient)
}
