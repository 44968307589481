import { Row, Col, Divider, Checkbox } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import TagContext from "../../../context/tag/context";
import TagContextProvider from "../../../context/tag/provider";
import { IUpdateTag } from "../../../models/tag/request";
import PointContext from "../../../context/point/context";
import { UserStatus } from "../../../models/user/enum";
import { ICity } from "../../../models/city/response";
import { IArea } from "../../../models/area/response";
import { IUser } from "../../../models/user/response";
import EndPoints from "../../../services/end-points";
import { IUpdatePoint } from "../../../models/point/request";
import { getPointStatus } from "../../../models/point/enum";
import PointContextProvider from "../../../context/point/provider";
import { IProduct } from "../../../models/product/response";
import { IStore } from "../../../models/store/response";
import ProductContext from "../../../context/product/context";
import ProductContextProvider from "../../../context/product/provider";
import { getOperation } from "../../../models/product/enum";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";

const Form = () => {
  const { t } = useTranslation();
  const { actions, loading } = useContext(ProductContext);
  const formMethod = useRef<UseFormReturn<any, any>>();

  const operation = [1, 2];

  const [product, setProduct] = useState<IProduct[]>([]);
  const [IsDiscount, setIsDiscount] = useState<boolean>();
  const [stores, setStore] = useState<IStore[]>([]);

  const [productLoading, setProductLoading] = useState(true);
  const [storeLoading, setStoreLoading] = useState(true);

  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState({ page: 1, search: undefined });

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollHeight - scrollTop === clientHeight &&
      hasMore &&
      !productLoading
    ) {
      setQuery({ ...query, page: query.page + 1 });
    }
  };

  const handleSearch = (value) => {
    setQuery({ page: 1, search: value });
    setProduct([]);
  };

  const getProduct = async () => {
    try {
      const { data } = await EndPoints.product.getAllProducts({
        light: true,
        page: query.page,
        perPage: 250,
        keyword: query?.search == "" ? undefined : query?.search,
      });
      const newOptions = data?.data;
      setProduct((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMore(data.lastPage == query.page ? false : true);
      setProductLoading(false);
    } catch (error) {
    } finally {
      setProductLoading(false);
    }
  };

  const getStores = async () => {
    try {
      const { data } = await EndPoints.store.getAllStores({
        light: true,
        perPage: -1,
      });
      //@ts-ignore
      data.data.unshift({ id: 0, name: t("all_store") });
      setStore(data.data);
      setStoreLoading(false);
    } catch (error) {
    } finally {
      setStoreLoading(false);
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  useEffect(() => {
    getProduct();
  }, [query]);

  return (
    <MainForm
      methods={formMethod}
      onSubmit={(data) => {
        data?.stores?.includes(0)
          ? (data.stores = stores.filter((el) => el.id != 0).map((st) => st.id))
          : data.stores;
        actions.manageProductStore(data);
      }}
      defaultValues={
        {
          isDiscount: false,
          available: false,
        } as any
      }
      title={t("manage_product_store")}
    >
      {loading.includes("manage") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="operation"
                label={`${t("operation")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: operation?.map((status, index) => {
                    return {
                      key: index,
                      label: t(getOperation(status)),
                      value: status,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="productId"
                label={`${t("product")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select-pagination",
                  handleScroll: handleScroll,
                  handleSearch: handleSearch,
                  loading: productLoading,
                  allowSearch: true,
                  options: product?.map((product, index) => {
                    return {
                      key: index,
                      label: product.name,
                      value: product.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="stores"
                label={`${t("stores")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "multiSelect",
                  loading: storeLoading,
                  allowSearch: true,
                  options: stores?.map((store, index) => {
                    return {
                      key: index,
                      label: store.name,
                      value: store.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="price"
                label={t("price")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="unitPrice"
                label={t("unit_price")}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>{" "}
            <Col span={8}>
              <FieldBuilder
                name="discountPercentage"
                label={t("discount_percentage")}
                input={{ type: "number" }}
                rules={{
                  required: IsDiscount ?? false,
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="available"
                label={t("available")}
                input={{ type: "checkBox" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <Controller
                name={"isDiscount"}
                control={formMethod?.current?.control}
                rules={{ required: false }}
                render={({ field: { ...field } }) => {
                  return (
                    <div>
                      {/* <label style={{ marginInlineEnd: '.8rem' }}> </label> */}

                      <Checkbox
                        {...field}
                        checked={field.value}
                        className="shadow"
                        defaultChecked={false}
                        style={{ width: "100%" }}
                        onChange={async (v) => {
                          await field.onChange(v);
                          setIsDiscount(!v.target.value);
                        }}
                      >
                        {t(`${"is_discount"}`)}
                      </Checkbox>
                    </div>
                  );
                }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="note"
                label={t("note")}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const ManageProductStoreForm = () => {
  return (
    <ProductContextProvider>
      <Form />
    </ProductContextProvider>
  );
};
export default ManageProductStoreForm;
