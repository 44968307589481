import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ComplaintContext from "../../../context/complaint/context";
import ComplaintContextProvider from "../../../context/complaint/provider";
import { IUpdateComplaint } from "../../../models/complaint/request";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { getComplaintStatus } from "../../../models/complaint/enum";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(ComplaintContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={(data) => {
        console.log(data)
        data.note =='' || data.note == null ? data.note = undefined  : ''  
        details
          ? actions.updateComplaint(details?.id, data)
          : actions.createComplaint(data);
      }}
      title={details ? t("update_complaint") : t("add_complaint")}
      defaultValues={
        {
          complaintReply: details?.complaintReply,
          note: details?.note,
          status: details?.status,
        } as IUpdateComplaint
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={16}>
              <FieldBuilder
                name="complaintReply"
                label={t("complaint_reply")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={`${t("status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  allowSearch: true,
                  options: [1, 2, 3]?.map((status, index) => {
                    return {
                      key: index,
                      label: t(getComplaintStatus(status)),
                      value: status,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={t("note")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const ComplaintForm = () => {
  return (
    <ComplaintContextProvider>
      <Form />
    </ComplaintContextProvider>
  );
};
export default ComplaintForm;
