import clientImg from "../../assets/images/deliveryMarker.svg";
import storeImg from "../../assets/images/storeMarker.svg";
import pharmacyImg from "../../assets/images/pharmacyMarker.svg";
export const POINT = "Point";
export const MULTI_PINTS = "p";
export const POLYNGO = "s";

export interface ICenter {
  lat: number;
  lng: number;
}

export const getImageByType = (type: number) => {
  switch (type) {
    case 1:
      return pharmacyImg;
    case 2:
      return storeImg;
    case 3:
      return clientImg;
    default:
      return storeImg;
  }
};
