import { ITranslationRequest } from "../base/translation";
import { ILocation } from "../store/response";
export interface ICreateCity {
  name: ITranslationRequest;
  note: ITranslationRequest;
  center?: ILocation;
}

export interface IUpdateCity extends ICreateCity {}

export interface IExportCityExcel {
  id: boolean;
  name: boolean;
  note: boolean;
  
}

export const columnsExportCityExcel = ["id", "name", "note"];
