import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import BannerContext from "../../../context/banner/context";
import { IRole } from "../../../models/role/response";
import { getUserStatus } from "../../../models/user/enum";
import EndPoints from "../../../services/end-points";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import UserContext from "../../../context/user/context";

interface IProps {}

const UserFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(UserContext);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);
  const statuses = [1, 2];

  // get all roles from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.role.getAllroles({
          light: true,
          perPage: -1,
        });
        setRoles(data.data);
        setRolesLoading(false);
      } catch (error) {
      } finally {
        setRolesLoading(false);
      }
    };
    getRoles();
  }, [direction]);
  return (
    <Form
      formId="user-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.role
              ? {
                  name: "role",
                  value: data.role,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getUserStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="role"
            label={`${t("role")}`}
            width="large"
            input={{
              type: "select",
              loading: rolesLoading,
              options: roles?.map((role, index) => {
                return {
                  label: role?.name as string,
                  value: role?.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default UserFilter;
