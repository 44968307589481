import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";
import EndPoints from "../../../services/end-points";
import { IUser } from "../../../models/user/response";

interface IProps {}

const GiveClientDiscount: React.FC<IProps> = ({}) => {
  const { t } = useTranslation();
  const [clients, setClient] = useState<any[]>([]);
  const [clientLoading, setClientLoading] = useState(true);

  useEffect(() => {
    const getClient = async () => {
      try {
        const { data } = await EndPoints.client.getAllClient({
          light: true,
          perPage: -1,
        });
        setClient(data.data);
        setClientLoading(false);
      } catch (error) {
      } finally {
        setClientLoading(false);
      }
    };
    getClient();
  }, []);

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <FieldBuilder
            name="clientId"
            label={`${t("client")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: clientLoading,
              options: clients.map((client, index) => {
                return {
                  label:
                    client?.firstName +
                    " " +
                    client?.lastName +
                    " ( " +
                    client?.cleanMobile +
                    " )",
                  value: client.id,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default GiveClientDiscount;
