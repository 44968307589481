import { HttpClient, RequestParams } from "../../utils/api/http-client";

import { IUpdatePolicyTerm } from "../../models/policy-term/request";
import { IPolicyTerm } from "../../models/policy-term/response";

class PolicyTermService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getPolicyTerm = (params?: RequestParams) =>
    this.http.request<any>({
      path: `/policy-term`,
      method: "GET",
      ...params,
    });

  updatePolicyTerm = (data: IUpdatePolicyTerm, params?: RequestParams) =>
    this.http.request<IPolicyTerm>({
      path: "/policy-term",
      method: "POST",
      body: data,
      ...params,
    });
}

export default PolicyTermService;
