import { ITranslationRequest } from "../base/translation";
export interface ICreateDiscountCode {
  name: ITranslationRequest;
  code: string;
  validityDate: string;
  codeValidity: number;
  numberPoints: number;
  maxOfUse: number;
  status: number;
  type: number;
  imageId: number;
  note: ITranslationRequest;
  showInApp: boolean;
  typeApp: number;
  typeValue: number;
  minimumLimit: number;
  upperLimit: number;
  numberOfUseAllowed: number;
  discountValueProduct: number;
  discountValueDelivery: number;
  canBePurchased: boolean;
}

export interface ICreateDiscountCodeClients {
  clientId: number;
  discountCodeId: number;
}

export interface IUpdateDiscountCode extends ICreateDiscountCode {}

export interface IExportDiscountCodeExcel {
  code: boolean;
  status: boolean;
  type: boolean;
  discountValueProduct: boolean;
  discountValueDelivery: boolean;
  validityDate: boolean;
  codeValidity: boolean;
  numberPoints: boolean;
  maxOfUse: boolean;
  frequencyOfUse: boolean;
  id: boolean;
  typeDiscountCode: boolean;
  typeValueDiscountCode: boolean;
  upperLimit: boolean;
  minimumLimit: boolean;
}

export const colomnsExportDiscountCodeExcel = [
  "id",
  "code",
  "status",
  "type",
  "discountValueProduct",
  "discountValueDelivery",
  "validityDate",
  "codeValidity",
  "numberPoints",
  "maxOfUse",
  "frequencyOfUse",
  "typeDiscountCode",
  "typeValueDiscountCode",
  "upperLimit",
  "minimumLimit",
];
