import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import EndPoints from "../../../services/end-points";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getAreaStatus } from "../../../models/area/enum";
import PointContext from "../../../context/point/context";
import { IArea } from "../../../models/area/response";

interface IProps {}

const PointFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(PointContext);
  const { direction } = useContext(AppContext);
  const [cities, setCities] = useState<ICity[]>([]);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [citiesLoading, setCitiesLoading] = useState(true);

  const statuses = [1, 2];

  // get all cities from api
  useEffect(() => {
    const getcities = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    const getAreas = async () => {
      try {
        const { data } = await EndPoints.area.getAllAreas({
          light: true,
          perPage: -1,
        });
        setAreas(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };

    getAreas();
    getcities();
  }, [direction]);

  return (
    <Form
      formId="point-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.cityId
              ? {
                  name: "cityId",
                  value: data.cityId,
                  operation: "eq",
                }
              : undefined,
            data?.areaId
              ? {
                  name: "areaId",
                  value: data.areaId,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getAreaStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="cityId"
            label={`${t("city")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: citiesLoading,
              options: cities.map((jop, index) => {
                return {
                  label: jop.name,
                  value: jop.id,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="areaId"
            label={`${t("area")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: citiesLoading,
              options: areas?.map((jop, index) => {
                return {
                  label: jop.name,
                  value: jop.id,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default PointFilter;
