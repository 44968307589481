import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IRewardQuery } from "../../models/reward/query";
import { IReward, IRewardDetails } from "../../models/reward/response";
import { ICreateReward, IUpdateReward } from "../../models/reward/request";

class RewardService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRewards = (query: IRewardQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IReward>>({
      path: `/rewards`,
      method: "GET",
      query,
      ...params,
    });

  getReward = (id: number, params?: RequestParams) =>
    this.http.request<IRewardDetails>({
      path: `/rewards/${id}`,
      method: "GET",
      ...params,
    });

  getHistory = (query: IRewardQuery, params?: RequestParams) =>
    this.http.request<any>({
      path: `/rewards-histories`,
      method: "GET",
      query,
      ...params,
    });

  createReward = (data: ICreateReward, params?: RequestParams) =>
    this.http.request<IRewardDetails>({
      path: "/rewards",
      method: "POST",
      body: data,
      ...params,
    });

  updateReward = (id: number, data: IUpdateReward, params?: RequestParams) =>
    this.http.request<IRewardDetails>({
      path: `/rewards/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteReward = (id: number, params?: RequestParams) =>
    this.http.request<IRewardDetails>({
      path: `/rewards/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default RewardService;
