import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IDeliveryWorker,
  IDeliveryWorkerDetails,
} from "../../models/delivery-workers/response";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import {
  ICreateDeliveryWorker,
  IUpdateDeliveryWorker,
} from "../../models/delivery-workers/request";
import { IDoctor, IDoctorDetails } from "../../models/doctors/response";
import { IDoctorQuery } from "../../models/doctors/query";
import { ICreateDoctor, IUpdateDoctor } from "../../models/doctors/request";
import { IMedicalSpecialties, IMedicalSpecialtiesDetails } from "../../models/medical-specialties/response";
import { ICreateMedicalSpecialties, IUpdateMedicalSpecialties } from "../../models/medical-specialties/request";

export type doctorLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: doctorLoading[];

  list?: IBaseListingResponse<IMedicalSpecialties>;
  query: IDoctorQuery;

  details?: IMedicalSpecialtiesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IMedicalSpecialtiesDetails) => void;

    createMedical: (request: ICreateMedicalSpecialties) => void;
    updateMedical: (id: number, request: IUpdateMedicalSpecialties) => void;
    deleteMedical: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDeliveryWorkerQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    deleteMedical: DEFAULT_FUNCTION,
    updateMedical: DEFAULT_FUNCTION,
    createMedical: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const MedicalSspecialtiesContext = createContext(externalState);

export default MedicalSspecialtiesContext;
