import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IPointQuery } from "../../models/point/query";
import { IPoint, IPointDetails } from "../../models/point/response";
import { ICreatePoint, IExportPointCollection, IUpdatePoint } from "../../models/point/request";
import axios from "axios";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

class PointService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPoints = (query: IPointQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPoint>>({
      path: `/collection-point`,
      method: "GET",
      query,
      ...params,
    });

  getPoint = (id: number, params?: RequestParams) =>
    this.http.request<IPointDetails>({
      path: `/collection-point/${id}`,
      method: "GET",
      ...params,
    });

  createPoint = (data: ICreatePoint, params?: RequestParams) =>
    this.http.request<IPoint>({
      path: "/collection-point",
      method: "POST",
      body: data,
      ...params,
    });

  updatePoint = (id: number, data: IUpdatePoint, params?: RequestParams) =>
    this.http.request<IPoint>({
      path: `/collection-point/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePoint = (id: number, params?: RequestParams) =>
    this.http.request<IPoint>({
      path: `/collection-point/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportPointCollection, query: IPointQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/collection-point/export`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
          }`,
        "Content-Type": "application/json",
        "accept-language": `${localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
      },
      params: query,
      responseType: "arraybuffer",
    });

}




export default PointService;