import moment from "moment";
import { useReducer } from "react";
import DeliveryWorkerContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { IDeliveryWorker } from "../../models/delivery-workers/response";
import { ICreateDeliveryWorker, IUpdateDeliveryWorker } from "../../models/delivery-workers/request";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";

export interface IProps {
  children: React.ReactNode;
}
const DeliveryWorkerContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.deliveryWorkers.getAllDeliveryWorkers(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.deliveryWorkers.getDeliveryWorker(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDeliveryWorker) => {
  };

  const createDeliveryWorker = async (request: ICreateDeliveryWorker) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.deliveryWorkers.createDeliveryWorker(request);
        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateDeliveryWorker = async (id: number, request: IUpdateDeliveryWorker) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.deliveryWorkers.updateDeliveryWorker(id, request);

        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteDeliveryWorker = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.deliveryWorkers.deleteDeliveryWorker(id);

        getData();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDeliveryWorkerQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.deliveryWorkers.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DeliveryWorkers-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <DeliveryWorkerContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          createDeliveryWorker,
          updateDeliveryWorker,
          deleteDeliveryWorker,
          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DeliveryWorkerContext.Provider>
  );
};

export default DeliveryWorkerContextProvider;
