import { IGeneralSettingQuery } from "../../models/general-setting/query";
import { IGeneralSetting } from "../../models/general-setting/response";
import toggleLoading from "../../utils/helpers/xor";
import paramsReducer, { QueryAction } from "../base/base-reducer";
import { GeneralSettingLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: GeneralSettingLoading | GeneralSettingLoading[] };
    }
  | { type: "SET_LIST"; payload: { list: IGeneralSetting } }
  | QueryAction<IGeneralSettingQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
