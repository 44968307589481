import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IPointQuery } from "../../models/point/query";
import { IPoint, IPointDetails } from "../../models/point/response";
import { ICreatePoint, IUpdatePoint } from "../../models/point/request";
import { IPaymentPointQuery } from "../../models/payment-point/query";
import { IPaymentPoint, IPaymentPointDetails } from "../../models/payment-point/response";
import { ICreatePaymentPoint, IExportPaymentPointCollection, IUpdatePaymentPoint } from "../../models/payment-point/request";
import axios from "axios";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

class PaymentPointService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPaymentPoints = (query: IPaymentPointQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPaymentPoint>>({
      path: `/collection-point-payment`,
      method: "GET",
      query,
      ...params,
    });

  getPaymentPoint = (id: number, params?: RequestParams) =>
    this.http.request<IPaymentPointDetails>({
      path: `/collection-point-payment/${id}`,
      method: "GET",
      ...params,
    });

  createPaymentPoint = (data: ICreatePaymentPoint, params?: RequestParams) =>
    this.http.request<IPaymentPoint>({
      path: "/collection-point-payment",
      method: "POST",
      body: data,
      ...params,
    });

  updatePaymentPoint = (id: number, data: IUpdatePaymentPoint, params?: RequestParams) =>
    this.http.request<IPaymentPoint>({
      path: `/collection-point-payment/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePaymentPoint = (id: number, params?: RequestParams) =>
    this.http.request<IPaymentPoint>({
      path: `/collection-point-payment/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportPaymentPointCollection, query: IPaymentPointQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/collection-point-payment/export`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
          }`,
        "Content-Type": "application/json",
        "accept-language": `${localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default PaymentPointService;