import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IHomePageList, IHomePageListDetails } from "../../models/home-page-list/response";
import { IHomePageListQuery } from "../../models/home-page-list/query";
import { ICreateHomePageList, IUpdateHomePageList } from "../../models/home-page-list/request";

export type HomePageListLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: HomePageListLoading[];

  list?: IBaseListingResponse<IHomePageList>;
  query: IHomePageListQuery;

  details?: IHomePageListDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IHomePageListDetails) => void;

    createHomePageList: (request: ICreateHomePageList) => void;
    updateHomePageList: (id: number, request: IUpdateHomePageList) => void;
    deleteHomePageList: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IHomePageListQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createHomePageList: DEFAULT_FUNCTION,
    updateHomePageList: DEFAULT_FUNCTION,
    deleteHomePageList: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const HomePageListContext = createContext(externalState);

export default HomePageListContext;
