import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IInvestor, IInvestorDetails } from "../../models/investor/response";
import { IInvestorQuery } from "../../models/investor/query";
import {
  ICreateInvestor,
  IUpdateInvestor,
} from "../../models/investor/request";

export type InvestorLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: InvestorLoading[];

  list?: IBaseListingResponse<IInvestor>;
  query: IInvestorQuery;

  details?: IInvestorDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IInvestorDetails) => void;

    createInvestor: (request: ICreateInvestor) => void;
    updateInvestor: (id: number, request: IUpdateInvestor) => void;
    deleteInvestor: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IInvestorQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createInvestor: DEFAULT_FUNCTION,
    updateInvestor: DEFAULT_FUNCTION,
    deleteInvestor: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const InvestorContext = createContext(externalState);

export default InvestorContext;
