import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import PaymentPointContext from "../../../context/payment-point/context";
import { IPaymentPoint } from "../../../models/payment-point/response";
import { ImCross } from "react-icons/im";
import { getPaymentStatus, getPaymentStatusColor } from "../../../models/payment-delivery-worker/enum";
import moment from "moment";

interface IProps { }

const PaymentPointTable: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { list, actions, loading, query } = useContext(PaymentPointContext);

    const columns: ColumnProps<IPaymentPoint>[] = [
        {
            title: t("id"),
            dataIndex: "id",
            key: "id",
            sorter: {
                multiple: 1,
            },
            width: 100,
            align: "center",
        },
        {
            title: t("employ"),
            dataIndex: "employ",
            align: "center",
            key: "employ",
            render: (_, record) => {
                return <Tag title={record?.employ?.user?.firstName} />;
            },
        },
        {
            title: t("point"),
            dataIndex: "collectionPoint",
            align: "center",
            key: "collectionPoint",
            render: (_, record) => {
                return <Tag title={record?.collectionPoint?.name} />;
            },
        },
        {
            title: t("value"),
            dataIndex: "value",
            align: "center",
            key: "value",
            sorter: {
                multiple: 2,
            },
        },
        {
            title: t("receipt_number"),
            dataIndex: "receiptNumber",
            align: "center",
            key: "receiptNumber"
        },
        {
            title: t("created_at"),
            dataIndex: "createdAt",
            align: "center",
            key: "createdAt",
            render: (_, record) => {
                return moment(record?.createdAt).format("YYYY-MM-DD");
            },
            sorter: {
                multiple: 3,
            },
        },
        {
            title: t("actions"),
            width: 250,
            dataIndex: "",
            align: "center",
            key: "x",
            render: (_: any, record) => (
                <Space>
                    <ViewBtn
                        onClick={async () => {
                            navigate(`details/${record?.id}`);
                        }}
                    />

                </Space>
            ),
        },
    ];

    // Filters && Sorts
    let tableFiltersProps = {};
    const [sorts, setSorts] = useState<ISort[]>([]);
    const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

    useEffect(() => {
        sorts.length > 0 &&
            actions.setQuery({
                ...query,
                orders: sorts,
            });
    }, [sorts]);

    return (
        <>
            <Table<any>
                rowKey="id"
                showPagination={true}
                columns={columns}
                dataSource={list?.data ?? []}
                loading={loading.includes("list")}
                size="small"
                total={list?.total}
                pageSize={query.perPage}
                page={query.page}
                onPaginationChange={(page, pageSize) => {
                    actions.setQuery({
                        ...query,
                        page,
                        perPage: pageSize,
                    });
                }}
                onChange={(_, tableFilters, tabelSorters) => {
                    tableOnChange(
                        tableFilters,
                        tabelSorters,
                        tableFiltersProps,
                        setSorts,
                        setStaticFilters
                    );
                }}
            />
        </>
    );
};

export default PaymentPointTable;
