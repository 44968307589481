import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IChronicDiseases,
  IChronicDiseasesDetails,
} from "../../models/chronic-diseases/response";
import { IChronicDiseasesQuery } from "../../models/chronic-diseases/query";
import {
  ICreateChronicDiseases,
  IUpdateChronicDiseases,
} from "../../models/chronic-diseases/request";

export type ChronicDiseasesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ChronicDiseasesLoading[];

  list?: IBaseListingResponse<IChronicDiseases>;
  query: IChronicDiseasesQuery;

  details?: IChronicDiseasesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IChronicDiseasesDetails) => void;

    createChronicDiseases: (request: ICreateChronicDiseases) => void;
    updateChronicDiseases: (
      id: number,
      request: IUpdateChronicDiseases
    ) => void;
    deleteChronicDiseases: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IChronicDiseasesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createChronicDiseases: DEFAULT_FUNCTION,
    updateChronicDiseases: DEFAULT_FUNCTION,
    deleteChronicDiseases: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ChronicDiseasesContext = createContext(externalState);

export default ChronicDiseasesContext;
