import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import ComplaintContext from "../../../context/complaint/context";
import ComplaintContextProvider from "../../../context/complaint/provider";
import {
  getComplaintStatus,
  getComplaintStatusColor,
  getComplaintType,
  getComplaintTypeColor,
} from "../../../models/complaint/enum";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import Tag from "../../general/antd/tag";
import PageDetails from "../../general/details";
import moment from "moment";

interface Props {}

const Complaint: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(ComplaintContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("complaint")}
        buttonName={`${t("update_complaint")}`}
        onClick={() => navigate(`/complaint/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("title")}>
              {details?.title}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("status")}>
              <Tag
                title={getComplaintStatus(details?.status)}
                color={getComplaintStatusColor(details?.status)}
              />
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("type")}>
              <Tag
                title={getComplaintType(details?.type)}
                color={getComplaintTypeColor(details?.type)}
              />
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("complaint_reply")}>
              {details?.complaintReply ?? "-"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("rating_reply")}>
              {details?.ratingReply ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("description")}>
              {details?.description ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("created_at")}>
              {details?.createdAt
                ? moment(details?.createdAt).format("YYYY-MM-DD")
                : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("created")}>
              {details?.user?.firstName + " " + details?.user?.lastName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("mobile")}>
              {details?.user?.cleanMobile}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("type_acount")}>
              {t(details?.userType)}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ?? "-"}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const ComplaintDetailesPage = () => {
  return (
    <ComplaintContextProvider>
      <Complaint />
    </ComplaintContextProvider>
  );
};
export default ComplaintDetailesPage;
