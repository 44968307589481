import { Descriptions as AntdDescriptions, Collapse, Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import Tag from "../../general/antd/tag";
import {
  getPointStatus,
  getPointStatusColor,
} from "../../../models/point/enum";
import Spin from "../../general/antd/spin";
import AuthContext from "../../../context/auth/context";

interface Props {}
const { Panel } = Collapse;
const Point: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { userDetails: user } = useContext(AuthContext);

  return (
    <Row gutter={[8, 8]} style={{ margin: "0px 10px" }}>
      <Col span={24}>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header={t("point")} key={1}>
            <Descriptions size="small" column={2} bordered>
              <AntdDescriptions.Item label={t("id")}>
                {user?.collectionPoint?.id}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("name")}>
                {user?.collectionPoint?.name ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("city")}>
                {user?.collectionPoint?.city?.name}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("employ")}>
                {user?.collectionPoint?.employ?.user?.firstName}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("area")}>
                {user?.collectionPoint?.area?.name}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("debt_value")}>
                {user?.collectionPoint?.debtValue}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("mobile")}>
                {user?.cleanMobile}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("status")}>
                <Tag
                  title={getPointStatus(user?.collectionPoint?.status)}
                  color={getPointStatusColor(user?.collectionPoint?.status)}
                />
              </AntdDescriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const PointDetails = () => {
  return <Point />;
};
export default PointDetails;
