import { ITranslationRequest } from "../base/translation";
export interface ICreateUser {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address: string;
  phone: string;
  status: number;
  note: ITranslationRequest;
  roles: string[];
  imageId: number;
  password:string,
}

export interface IUpdateUser extends ICreateUser {}

export interface IExportUserExcel {
  id: boolean;
  firstName: boolean;
  lastName: boolean;
  status: boolean;
  mobile: boolean;
  address: boolean;
  roles: boolean;
  createdAt: boolean;
}

export const colomnsExportUserExcel = [
  "id",
  "firstName",
  "lastName",
  "status",
  "mobile",
  "address",
  "roles",
  "createdAt",
];
