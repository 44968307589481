import moment from "moment";
import { useReducer } from "react";
import InvestorContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import {
  ICreateInvestor,
  IUpdateInvestor,
} from "../../models/investor/request";
import { IInvestor } from "../../models/investor/response";
import { IInvestorQuery } from "../../models/investor/query";
export interface IProps {
  children: React.ReactNode;
}
const InvestorContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.investor.getAllInvestors(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.investor.getInvestor(id);
        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IInvestor) => {
    // dispatch({ type: 'SET_DETAILSS', payload: { details: data } })
  };

  const createInvestor = async (request: ICreateInvestor) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.investor.createInvestor(request);
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateInvestor = async (id: number, request: IUpdateInvestor) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.investor.updateInvestor(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteInvestor = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.investor.deleteInvestor(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IInvestorQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.investor.exportExcel(request, state.query);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Investors-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <InvestorContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createInvestor,
          updateInvestor,
          deleteInvestor,
          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </InvestorContext.Provider>
  );
};

export default InvestorContextProvider;
