import { ITranslationRequest } from "../base/translation";
export interface ICreateDeliveryWorker {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address: string;
  phone: string;
  status: number;
  statusWorker: number;
  birthDate: string;
  note: ITranslationRequest;
  nationalIdentityNumber: string;
  cityId: number;
  imageId: number;
}

export interface IUpdateDeliveryWorker extends ICreateDeliveryWorker {}

export interface IExportDeliveryWorkerExcel {
  id: boolean;
  firstName: boolean;
  lastName: boolean;
  status: boolean;
  city: boolean;
  mobile: boolean;
  createdAt: boolean;
  statusWorker: boolean;
}
export const columnsExportDeliveryWorkerExcel = [
  "id",
  "firstName",
  "lastName",
  "status",
  "city",
  "mobile",
  "createdAt",
  "statusWorker",
];
