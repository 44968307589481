import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IDoctorQuery } from "../../models/doctors/query";
import { IDoctor, IDoctorDetails } from "../../models/doctors/response";
import { ICreateDoctor, IExportDoctorExcel, IUpdateDoctor } from "../../models/doctors/request";

class DoctorService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDoctors = (query: IDoctorQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDoctor>>({
      path: `/doctor`,
      method: "GET",
      query,
      ...params,
    });

  getDoctor = (id: number, params?: RequestParams) =>
    this.http.request<IDoctorDetails>({
      path: `/doctor/${id}`,
      method: "GET",
      ...params,
    });

  createDoctor = (data: ICreateDoctor, params?: RequestParams) =>
    this.http.request<IDoctorDetails>({
      path: "/doctor",
      method: "POST",
      body: data,
      ...params,
    });

  updateDoctor = (id: number, data: IUpdateDoctor, params?: RequestParams) =>
    this.http.request<IDoctorDetails>({
      path: `/doctor/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDoctor = (id: number, params?: RequestParams) =>
    this.http.request<IDoctorDetails>({
      path: `/doctor/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportDoctorExcel, query: IDoctorQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/doctor/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default DoctorService;
