import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import {
  IDeliveryWorker,
  IDeliveryWorkerDetails,
} from "../../models/delivery-workers/response";
import {
  ICreateDeliveryWorker,
  IExportDeliveryWorkerExcel,
  IUpdateDeliveryWorker,
} from "../../models/delivery-workers/request";

class DeliveryWorkersService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDeliveryWorkers = (
    query: IDeliveryWorkerQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDeliveryWorker>>({
      path: `/delivery-workers`,
      method: "GET",
      query,
      ...params,
    });

  getDeliveryWorker = (id: number, params?: RequestParams) =>
    this.http.request<IDeliveryWorkerDetails>({
      path: `/delivery-workers/${id}`,
      method: "GET",
      ...params,
    });

  getHistory = (
    id: number,
    query: IDeliveryWorkerQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<History>>({
      path: `/delivery-workers-histories/${id}`,
      method: "GET",
      query,
      ...params,
    });

  getHistoryAssentOrder = (
    orderId: number,
    query: IDeliveryWorkerQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<History>>({
      path: `/order/driver-histories/${orderId}`,
      method: "GET",
      query,
      ...params,
    });

  processHistory = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<History>>({
      path: `/delivery-workers/histories/processed/${id}`,
      method: "POST",
      ...params,
    });

  createDeliveryWorker = (
    data: ICreateDeliveryWorker,
    params?: RequestParams
  ) =>
    this.http.request<IDeliveryWorker>({
      path: "/delivery-workers",
      method: "POST",
      body: data,
      ...params,
    });

  updateDeliveryWorker = (
    id: number,
    data: IUpdateDeliveryWorker,
    params?: RequestParams
  ) =>
    this.http.request<IDeliveryWorker>({
      path: `/delivery-workers/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDeliveryWorker = (id: number, params?: RequestParams) =>
    this.http.request<IDeliveryWorker>({
      path: `/delivery-workers/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportDeliveryWorkerExcel,
    query: IDeliveryWorkerQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/delivery-workers/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default DeliveryWorkersService;
