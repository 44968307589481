// .....................status
export enum DiscountStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getDiscountStatusColor = (status: DiscountStatus) => {
  switch (status) {
    case DiscountStatus.ACTIVE:
      return "green";
    case DiscountStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getDiscountStatus = (status: DiscountStatus) => {
  switch (status) {
    case DiscountStatus.ACTIVE:
      return "active";
    case DiscountStatus.INACTIVE:
      return "inactive";
    default:
      return "active";
  }
};

// ....................type
export enum DiscountType {
  FIXED = 1,
  REPLACEABLE = 2,
}

export const getDiscounteTypeColor = (Type: DiscountType) => {
  switch (Type) {
    case DiscountType.FIXED:
      return "blue";
    case DiscountType.REPLACEABLE:
      return "orange";
    default:
      return "green";
  }
};

// Type Boolean stuts
export const getDiscountType = (Type: DiscountType) => {
  switch (Type) {
    case DiscountType.FIXED:
      return "fixed";
    case DiscountType.REPLACEABLE:
      return "replaceable";
    default:
      return "fixed";
  }
};

// .....................type vlaue

export enum DiscountTypeValue {
  FIXED_AMOUNT = 1,
  PERCENT = 2,
}
export const getDiscounteTypeValueColor = (Type: DiscountTypeValue) => {
  switch (Type) {
    case DiscountTypeValue.FIXED_AMOUNT:
      return "blue";
    case DiscountTypeValue.PERCENT:
      return "orange";
    default:
      return "green";
  }
};

// Type Boolean stuts
export const getDiscountTypeValue = (Type: DiscountTypeValue) => {
  switch (Type) {
    case DiscountTypeValue.FIXED_AMOUNT:
      return "fixed_amount";
    case DiscountTypeValue.PERCENT:
      return "percent";
    default:
      return "percent";
  }
};

// .....................type vlaue

export enum DiscountTypeApp {
  DRIVE = 1,
  PRODUCT = 2,
  PRODUCT_DRIVE = 3,
}
export const getDiscounteTypeAppColor = (Type: DiscountTypeApp) => {
  switch (Type) {
    case DiscountTypeApp.DRIVE:
      return "blue";
    case DiscountTypeApp.PRODUCT:
      return "orange";
    case DiscountTypeApp.PRODUCT_DRIVE:
      return "orange";
    default:
      return "green";
  }
};

// Type Boolean stuts
export const getDiscountTypeApp = (Type: DiscountTypeApp) => {
  switch (Type) {
    case DiscountTypeApp.DRIVE:
      return "driver";
    case DiscountTypeApp.PRODUCT:
      return "product";
    case DiscountTypeApp.PRODUCT_DRIVE:
      return "all";
    default:
      return "drive";
  }
};
