import { IInternalState, AuthLoading } from "./context";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import toggleLoading from "../../utils/helpers/xor";
import { User } from "../../models/user/loginResponse";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "IS_AUTHENTICATED"; payload: { isAuthenticated: boolean } }
  | { type: "SET_USER_DETAILS"; payload: { user?: User } }
  | {
      type: "USER_PERMISSIONS";
      payload: { permissions: string[] };
    }
  | {
      type: "SET_GRANTED_PERMISSIONS";
      payload: { permissions: string[] };
    }
  | { type: "LOGOUT_SUCCESS" }
  | { type: "LOGIN_SUCCESS"; payload: { user?: User } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_GRANTED_PERMISSIONS":
      return { ...state, grantedPermissions: action.payload.permissions };
    case "USER_PERMISSIONS":
      return { ...state, userPermissions: action.payload.permissions };
    case "IS_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload.isAuthenticated };
    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        userDetails: undefined,
        isAuthenticated: false,
        grantedPermissions: [CAN_VIEW_HOME_PAGE],
      };
    case "LOGIN_SUCCESS":
      let grantedPermissions = [CAN_VIEW_HOME_PAGE];
      if (action.payload.user?.permissions) {
        // grantedPermissions?.push(
        //   ...action.payload.user.permissions.map((p) => p.code!)
        // )
      }
      return {
        ...state,
        userDetails: action.payload.user,
        isAuthenticated: true,
        grantedPermissions,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload.user,
      };
    default:
      return state;
  }
};

export default reducer;
