import { ITranslationRequest } from "../base/translation";
export interface ICreatePaymentDeliveryWorker {
  employId: number;
  receiptNumber: number;
  value: number;
  driverId: number;
  collectionPointId: number;
  note: string;
}

export interface IUpdatePaymentDeliveryWorker
  extends ICreatePaymentDeliveryWorker {}

export interface IExportPaymentDeliveryWorkerExcel {
  id: boolean;
  employ: boolean;
  collectionPoint: boolean;
  isConfirmed: boolean;
  value: boolean;
  receiptNumber: boolean;
  note: boolean;
  driver: boolean;
}

export const colomnsExportPaymentDeliveryWorkerExcel = [
  "id",
  "employ",
  "collectionPoint",
  "isConfirmed",
  "value",
  "receiptNumber",
  "note",
  "driver",
];
