import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import InvestorContext from "../../../context/investor/context";
import InvestorContextProvider from "../../../context/investor/provider";
import moment from "moment";
import {
  getInvestorStatus,
  getInvestorType,
  getInvestoreStatusColor,
  getInvestoreTypeColor,
} from "../../../models/investor/enum";
import Tag from "../../general/antd/tag";
import { getGender, getGenderColor } from "../../../models/client/enum";
import OldImage from "../../form-components/old-image";

interface Props { }

const Investor: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(InvestorContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("investor")}
        buttonName={`${t("update_investor")}`}
        onClick={() => navigate(`/investors/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("first_name")}>
              {details?.user?.firstName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("last_name")}>
              {details?.user?.lastName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("phone")}>
              {details?.user?.phone}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("mobile")}>
              {details?.user?.cleanMobile}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("address")}>
              {details?.address ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("subscription_date")}>
              {moment(details?.createdAt).format("YYYY-MM-DD")}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("birth_date")}>
              {moment(details?.birthDate).format("YYYY-MM-DD") ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("city")}>
              {details?.city?.name ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("national_identity_number")}>
              {details?.nationalIdentityNumber ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("status")}>
              {(
                <Tag
                  title={t(getInvestorStatus(details?.statusInvestor))}
                  color={getInvestoreStatusColor(details?.statusInvestor)}
                />
              ) ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("store")}>
              {details?.store?.name ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("type")}>
              {(
                <Tag
                  title={t(getInvestorType(details?.type))}
                  color={getInvestoreTypeColor(details?.type)}
                />
              ) ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.user?.note ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("image")}>
              {details?.user?.image ? (
                <OldImage preview src={details?.user.image?.url} />
              ) : (
                "-"
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const InvestorDetailesPage = () => {
  return (
    <InvestorContextProvider>
      <Investor />
    </InvestorContextProvider>
  );
};
export default InvestorDetailesPage;
