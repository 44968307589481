import { Form as AntdForm } from "antd";
import { PropsWithChildren, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface Props<DataType> {
  formId: string;
  defaultValues?: DataType;
  onSubmit: (data: any) => void;
}

const Form = function <DataType = any>(
  props: PropsWithChildren<Props<DataType>>
) {
  const methods = useForm<any>({
    defaultValues: props.defaultValues as any,
  });

  const [submittingForm, setSubmittingForm] = useState(false);

  useEffect(() => {
    if (props.defaultValues && !submittingForm) {
      methods.reset({
        ...methods.getValues(),
        ...(props.defaultValues as any),
      });
    }
  }, [props.defaultValues]);

  return (
    <FormProvider {...methods}>
      <AntdForm
        labelCol={{ span: 24 }}
        onSubmitCapture={() => {
          setSubmittingForm(true);
        }}
        onFinish={methods.handleSubmit(props.onSubmit)}
        id={props?.formId}
      >
        {props.children}
      </AntdForm>
    </FormProvider>
  );
};

export default Form;
