import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import ClientContext from "../../context/clients/client/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import ClientContextProvider from "../../context/clients/client/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import ClientTable from "../../components/client/client-table";
import ClientFilter from "../../components/client/client-filter";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import { IExportClientExcel, columnsExportClientExcel } from "../../models/client/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Client: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [exportModalVisible, setExportModalVisible] = useState(false)

  const { actions, loading, query } = useContext(ClientContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("clients")}
        subTitle={t("clients_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          // <Divider key={1} type="vertical" />,
          // <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
          //   <Button
          //     type="primary"
          //     onClick={() => {
          //       navigate(`/clients/create`);
          //     }}
          //     icon={<PlusOutlined />}
          //   >
          //     {t("add_client")}
          //   </Button>
          // </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-complaint"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            firstName: false,
            lastName: false,
            city: false,
            status: false,
            mobile: false,
            createdAt: false,
          } as IExportClientExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={columnsExportClientExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="client-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <ClientFilter />
      </SearchFilterCard>

      <ClientTable />
    </div>
  );
};

const ClientPage = () => {
  return (
    // ContextProvider
    <ClientContextProvider>
      <Client />
    </ClientContextProvider>
  );
};
export default ClientPage;
