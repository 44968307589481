import { createContext } from "react";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";
import { IResponseTracking } from "../../models/map/response";
import {
  IConversation,
  IConversations,
} from "../../models/messages/conversations";
import { IMessage, IMessages } from "../../models/messages/messages";
import { IDeletedUser } from "../../models/map/deletedUserModel";

export interface IInternalState {
  users?: any[];
  conversations?: IConversations;
  selectedConversationId?: string;
  messages?: IMessages;
  newMessage?: IMessage;
  newConversation?: IConversation;
  allUsers?: any[];
  deletedUser?: IDeletedUser;
  userChangesLocations?: IResponseTracking;
}

export const internalState: IInternalState = {};

export interface IExternalState extends IInternalState {
  actions: {
    setUsers: (data?: any[]) => void;
    setAllUsers: (data?: any[]) => void;

    unSubscribeFromUserUpdates: (data?: any[]) => void;
    getMessages: (conversationId: string) => void;
    setSelectedConversationId: (conversationId: string) => void;
    sendMessage: (message: string) => void;
    getConversations: () => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    setUsers: DEFAULT_FUNCTION,
    setAllUsers: DEFAULT_FUNCTION,
    unSubscribeFromUserUpdates: DEFAULT_FUNCTION,
    getMessages: DEFAULT_FUNCTION,
    setSelectedConversationId: DEFAULT_FUNCTION,
    sendMessage: DEFAULT_FUNCTION,
    getConversations: DEFAULT_FUNCTION,
  },
};

const TrackingContext = createContext(externalState);

export default TrackingContext;
