// ................. status home page list ....................................
export enum HomePageStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getHomePageStatusColor = (status: HomePageStatus) => {
  switch (status) {
    case HomePageStatus.ACTIVE:
      return "green";
    case HomePageStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

export const getHomePageStatus = (status: HomePageStatus) => {
  switch (status) {
    case HomePageStatus.ACTIVE:
      return "active";
    case HomePageStatus.INACTIVE:
      return "inactive";
    default:
      return "inactive";
  }
};

// ................type home page  list .................................

export enum HomePageType {
  SUB_CATEGORY = 1,
  BRAND = 2,
  PRODUCT = 3,
  CATEGORY = 4,
  MULTI_CATEGORY = 5,
}

export const getHomePageTypeColor = (Type: HomePageType) => {
  switch (Type) {
    case HomePageType.BRAND:
      return "blue";
    case HomePageType.CATEGORY:
      return "orange";
    case HomePageType.PRODUCT:
      return "green";
    case HomePageType.SUB_CATEGORY:
      return "yellow";
    default:
      return "red";
  }
};

export const getHomePageType = (Type: HomePageType) => {
  switch (Type) {
    case HomePageType.BRAND:
      return "trademark";
    case HomePageType.CATEGORY:
      return "childs";
    case HomePageType.PRODUCT:
      return "product";
    case HomePageType.SUB_CATEGORY:
      return "grands_or_subcategory";
    case HomePageType.MULTI_CATEGORY:
      return "multi_categories";
    default:
      return "trademark";
  }
};

export enum CardStyle {
  TEXT_OUT = 1,
  TEXT_IN = 2,
  NO_Text = 3,
}

export const getCardStyleColor = (status: CardStyle) => {
  switch (status) {
    case CardStyle.TEXT_IN:
      return "green";
    case CardStyle.TEXT_OUT:
      return "red";
    case CardStyle.NO_Text:
      return "blue";
    default:
      return "red";
  }
};

export const getCardStyle = (status: CardStyle) => {
  switch (status) {
    case CardStyle.TEXT_IN:
      return "text_in";
    case CardStyle.TEXT_OUT:
      return "text_out";
    case CardStyle.NO_Text:
      return "no_text";
    default:
      return "text_out";
  }
};
