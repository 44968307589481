import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { getImageByType } from "../../../../models/map/enum";
import { IResponseTracking } from "../../../../models/map/response";
import { MAP_DEFAULT_LOCATION } from "../../../../utils/helpers/constants";
import Spin from "../../antd/spin";
import styles from "./style.module.scss";
import { IStore } from "../../../../models/store/response";
import { getStorWorkingStatus } from "../../../../models/store/enum";

interface IProps {
  location?: IResponseTracking[];
  selectedDriver?: IResponseTracking;
  setSelectedDriver?: (val: IResponseTracking) => void;
  stores?: IStore[];
  width?: string;
  height?: string;
}

const ViewMarkers: React.FC<IProps> = ({
  location,
  width,
  height,
  stores,
  selectedDriver,
  setSelectedDriver,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hover, setHover] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<IResponseTracking>(null);
  const [selectedStoreMarker, setSelectedStoreMarker] = useState<IStore>(null);
  const { t } = useTranslation();
  const { isLoaded: isApiLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_KEY}`,
    libraries: ["drawing"],
  });
  const containerStyle = {
    width: width ?? "78vw",
    height: height ?? "55vh",
  };
  const handleMarkerClick = (marker: IResponseTracking | IStore) => {
    //@ts-ignore
    setSelectedMarker(marker);
  };

  const [center, setCenter] = useState({
    lat: selectedMarker
      ? selectedMarker?.latitude
      : location?.[0]
      ? location?.[0].latitude
      : stores[0]?.location?.coordinates[1],
    lng: selectedMarker
      ? selectedMarker?.longitude
      : location?.[0]
      ? location?.[0].longitude
      : stores[0]?.location?.coordinates[0],
  });

  React.useEffect(() => {
    setIsLoaded(isApiLoaded);
  }, [isApiLoaded]);
  React.useEffect(() => {
    if (selectedDriver) {
      setSelectedMarker(selectedDriver);

      setCenter({
        lat: selectedDriver?.latitude,
        lng: selectedDriver?.longitude,
      });
    }
  }, [selectedDriver]);

  return (
    <>
      {!isLoaded ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: selectedMarker
                    ? selectedMarker?.latitude
                    : MAP_DEFAULT_LOCATION.lat,
                  lng: selectedMarker
                    ? selectedMarker?.longitude
                    : MAP_DEFAULT_LOCATION.lng,
                }}
                onCloseClick={() => {
                  setSelectedMarker(null);
                  setSelectedDriver(null);
                }}
              >
                <div className={styles?.container}>
                  <strong>
                    {t("first_name")} :
                    {selectedMarker?.user_data?.info_payload?.firstName}
                  </strong>
                  <strong>
                    {t("last_name")} :
                    {selectedMarker?.user_data?.info_payload?.lastName}
                  </strong>
                  <strong>
                    {t("mobile")} :
                    {selectedMarker?.user_data?.info_payload?.mobile}
                  </strong>
                  <strong>
                    {t("phone")} :
                    {selectedMarker?.user_data?.info_payload?.phone}
                  </strong>
                  <strong>
                    {t("email")} :
                    {selectedMarker?.user_data?.info_payload?.email}
                  </strong>
                  {/* <div>
                    <Tag
                      title={getStorStatus(selectedMarker?.status)}
                      color={getStoreStatusColor(selectedMarker?.status)}
                    />
                  </div> */}
                </div>
              </InfoWindow>
            )}
            {selectedStoreMarker && (
              <InfoWindow
                position={{
                  lat: selectedStoreMarker
                    ? selectedStoreMarker?.location?.coordinates[1]
                    : MAP_DEFAULT_LOCATION.lat,
                  lng: selectedStoreMarker
                    ? selectedStoreMarker?.location?.coordinates[0]
                    : MAP_DEFAULT_LOCATION.lng,
                }}
                onCloseClick={() => setSelectedStoreMarker(null)}
              >
                <div>
                  <div className={styles?.container}>
                    <strong>
                      {t("name")} :{"  "}
                      {selectedStoreMarker?.name}
                    </strong>{" "}
                    <strong>
                      {t("phone")} :{"  "}
                      {selectedStoreMarker?.firstPhone}
                    </strong>
                    <strong>
                      {t("start_working_time")} :{"  "}
                      {selectedStoreMarker?.startWorkingTime}
                    </strong>{" "}
                    <strong>
                      {t("end_working_time")} :{"  "}
                      {selectedStoreMarker?.endWorkingTime}
                    </strong>
                    <strong>
                      {t("work_status")} :{"  "}
                      {t(getStorWorkingStatus(selectedStoreMarker?.workStatus))}
                    </strong>{" "}
                    <strong>
                      {t("pharmacy_promotion")} : {"  "}
                      {selectedStoreMarker?.pharmacyPromotion
                        ? t("yes")
                        : t("no")}
                    </strong>
                    {/* <div>
                    <Tag
                      title={getStorStatus(selectedMarker?.status)}
                      color={getStoreStatusColor(selectedMarker?.status)}
                    />
                  </div> */}
                  </div>{" "}
                </div>
              </InfoWindow>
            )}
            {location?.map((mark, index) => {
              console.log("mark?.user_data", mark?.user_data);

              return (
                <Marker
                  key={index}
                  position={{
                    lat: mark?.latitude,
                    lng: mark?.longitude,
                  }}
                  onClick={async () => {
                    await handleMarkerClick(mark);
                    setHover(true);
                  }}
                  label={{
                    text: mark?.user_data
                      ? t(
                          mark?.user_data.info_payload.firstName +
                            " " +
                            mark?.user_data.info_payload.lastName
                        )
                      : t("location"),
                    className: `${styles.marker}`,
                  }}
                  icon={{
                    url: getImageByType(3),
                    scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
                  }}
                />
              );
            })}
            {stores?.map((mark, index) => {
              return (
                <Marker
                  key={index}
                  position={{
                    lat: mark?.location?.coordinates[1],
                    lng: mark?.location?.coordinates[0],
                  }}
                  onClick={async () => {
                    setSelectedStoreMarker(mark);
                  }}
                  label={{
                    text: mark ? t(mark?.name) : t("location"),
                    className: `${styles.marker}`,
                  }}
                  icon={{
                    url: getImageByType(mark.type == 1 ? 1 : 2),
                    scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
                  }}
                />
              );
            })}
          </GoogleMap>
        </>
      )}
    </>
  );
};

export default React.memo(ViewMarkers);
