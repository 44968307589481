import { AttributeType } from "./../../models/attribute/enums";
import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IAttributeQuery } from "../../models/attribute/query";
import { IAttribute, IAttributeDetails } from "../../models/attribute/resopnse";
import {
  ICreateAttribute,
  IUpdateAttribute,
} from "../../models/attribute/request";

class AttributeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllAttributes = (
    catId: number,
    query: IAttributeQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IAttribute>>({
      path: `/category/${catId}/attributes`,
      method: "GET",
      query,
      ...params,
    });

  getAttribute = (catId: number, id: number, params?: RequestParams) =>
    this.http.request<IAttributeDetails>({
      path: `/category/${catId}/attributes/${id}`,
      method: "GET",
      ...params,
    });

  createAttribute = (
    catId: number,
    data: ICreateAttribute,
    params?: RequestParams
  ) =>
    this.http.request<IAttribute>({
      path: `/category/${catId}/attributes`,
      method: "POST",
      body: data,
      ...params,
    });

  updateAttribute = (
    catId: number,
    id: number,
    data: IUpdateAttribute,
    params?: RequestParams
  ) =>
    this.http.request<IAttribute>({
      path: `/category/${catId}/attributes/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteAttribute = (catId: number, id: number, params?: RequestParams) =>
    this.http.request<IAttribute>({
      path: `/category/${catId}/attributes/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default AttributeService;
