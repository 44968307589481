///
/// Permissions
///

export const PER: string[] = [
  "can-view-home-page",
  "can-update-category",
  "can-delete-category",
];

// Home Page
export const CAN_VIEW_HOME_PAGE = "can-view-home-page";
export const CAN_VIEW_DASHBOARD = "can-view-dashboard";
export const CAN_VIEW_MY_DASHBOARD = "can-view-my-dashboard";

// Banners
export const CAN_VIEW_BANNERS = "can-view-banners";
export const CAN_CREATE_BANNER = "can-create-banner";
export const CAN_UPDATE_BANNER = "can-update-banner";
export const CAN_DELETE_BANNER = "can-delete-banner";
export const CAN_VIEW_BANNERS_WITH_TRASHED = "can-view-banners-with-trashed";
export const CAN_RESTORE_BANNER = "can-restore-banner";

// Categories Permissions
export const CAN_VIEW_SERVICES = "can-view-categories";
export const CAN_CREATE_SERVICE = "can-create-category";
export const CAN_UPDATE_SERVICE = "can-update-category";
export const CAN_DELETE_SERVICE = "can-delete-category";
export const CAN_VIEW_SERVICES_WITH_TRASHED =
  "can-view-categories-with-trashed";
export const CAN_RESTORE_SERVICE = "can-restore-category";

// Clients Permissions
export const CAN_VIEW_CLIENTS = "can-view-clients";
export const CAN_CREATE_CLIENT = "can-create-client";
export const CAN_UPDATE_CLIENT = "can-update-client";
export const CAN_DELETE_CLIENT = "can-delete-client";
export const CAN_VIEW_CLIENTS_WITH_TRASHED = "can-view-clients-with-trashed";
export const CAN_RESTORE_CLIENT = "can-restore-client";

// Partner Permissions
export const CAN_VIEW_PARTNERS = "can-view-partners";
export const CAN_CREATE_PARTNER = "can-create-partner";
export const CAN_UPDATE_PARTNER = "can-update-partner";
export const CAN_DELETE_PARTNER = "can-delete-partner";
export const CAN_VIEW_PARTNERS_WITH_TRASHED = "can-view-partners-with-trashed";
export const CAN_RESTORE_PARTNER = "can-restore-partner";

// Company Info Permissions
export const CAN_VIEW_COMPANY_INFOS = "can-view-company-infos";
export const CAN_CREATE_COMPANY_INFO = "can-create-company-info";
export const CAN_UPDATE_COMPANY_INFO = "can-update-company-info";
export const CAN_DELETE_COMPANY_INFO = "can-delete-company-info";

// Inboxes Permissions
export const CAN_VIEW_INBOXES = "can-view-inboxes";
export const CAN_UPDATE_INBOX = "can-update-inbox";
export const CAN_DELETE_INBOX = "can-delete-inbox";
export const CAN_VIEW_INBOXES_WITH_TRASHED = "can-view-inboxes-with-trashed";
export const CAN_RESTORE_INBOX = "can-restore-inbox";

// Language Permissions
export const CAN_VIEW_LANGUAGES = "can-view-languages";
export const CAN_CREATE_LANGUAGE = "can-create-language";
export const CAN_UPDATE_LANGUAGE = "can-update-language";
export const CAN_DELETE_LANGUAGE = "can-delete-language";

// Medium Permissions
export const CAN_CREATE_MEDIUM = "can-create-medium";

// Team Permissions
export const CAN_VIEW_MEMBERS = "can-view-members";
export const CAN_CREATE_MEMBER = "can-create-member";
export const CAN_UPDATE_MEMBER = "can-update-member";
export const CAN_DELETE_MEMBER = "can-delete-member";
export const CAN_VIEW_MEMBERS_WITH_TRASHED = "can-view-members-with-trashed";
export const CAN_RESTORE_MEMBER = "can-restore-member";

// Notifications Permissions
export const CAN_VIEW_NOTIFICATIONS = "can-view-notifications";
export const CAN_UPDATE_NOTIFICATION = "can-update-notifications";

// Configs Permissions
export const CAN_VIEW_CONFIGS = "can-view-configs";
export const CAN_UPDATE_CONFIG = "can-update-configs";

// Roles Permissions
export const CAN_VIEW_ROLES = "can-view-roles";
export const CAN_CREATE_ROLE = "can-create-role";
export const CAN_UPDATE_ROLE = "can-update-role";
export const CAN_DELETE_ROLE = "can-delete-role";
export const CAN_VIEW_PERMISSIONS = "can-view-permissions";

// Team Permissions
export const CAN_VIEW_TEAMS = "can-view-teams";
export const CAN_CREATE_TEAM = "can-create-team";
export const CAN_UPDATE_TEAM = "can-update-team";
export const CAN_DELETE_TEAM = "can-delete-team";

export const CAN_VIEW_TEAMS_WITH_TRASHED = "can-view-teams-with-trashed";
export const CAN_RESTORE_TEAM = "can-restore-team";

// Users Permissions
export const CAN_VIEW_USERS = "can-view-users";
export const CAN_CREATE_USER = "can-create-user";
export const CAN_UPDATE_USER = "can-update-user";
export const CAN_DELETE_USER = "can-delete-user";
export const CAN_VIEW_USERS_WITH_TRASHED = "can-view-users-with-trashed";
export const CAN_RESTORE_USER = "can-restore-user";

// Plans
export const CAN_VIEW_PLANS = "can-view-plans";
export const CAN_CREATE_PLAN = "can-create-plan";
export const CAN_UPDATE_PLAN = "can-update-plan";
export const CAN_DELETE_PLAN = "can-delete-plan";
export const CAN_VIEW_PLANS_WITH_TRASHED = "can-view-plans-with-trashed";
export const CAN_RESTORE_PLAN = "can-restore-plan";

// Services
// export const CAN_VIEW_SERVICES = 'can-view-services'
// export const CAN_CREATE_SERVICE = 'can-create-service'
// export const CAN_UPDATE_SERVICE = 'can-update-service'
// export const CAN_DELETE_SERVICE = 'can-delete-service'
// export const CAN_VIEW_SERVICES_WITH_TRASHED = 'can-view-services-with-trashed'
// export const CAN_RESTORE_SERVICE = 'can-restore-service'

// Solution
export const CAN_VIEW_SOLUTIONS = "can-view-solutions";
export const CAN_CREATE_SOLUTION = "can-create-solution";
export const CAN_UPDATE_SOLUTION = "can-update-solution";
export const CAN_DELETE_SOLUTION = "can-delete-solution";
export const CAN_VIEW_SOLUTIONS_WITH_TRASHED =
  "can-view-solutions-with-trashed";
export const CAN_RESTORE_SOLUTION = "can-restore-solution";

// Products
export const CAN_VIEW_PRODUCTS = "can-view-products";
export const CAN_CREATE_PRODUCT = "can-create-product";
export const CAN_UPDATE_PRODUCT = "can-update-product";
export const CAN_DELETE_PRODUCT = "can-delete-product";
export const CAN_VIEW_PRODUCTS_WITH_TRASHED = "can-view-products-with-trashed";
export const CAN_RESTORE_PRODUCT = "can-restore-product";

// Testimonial Permissions
export const CAN_VIEW_TESTIMONIALS = "can-view-testimonials";
export const CAN_CREATE_TESTIMONIAL = "can-create-testimonial";
export const CAN_UPDATE_TESTIMONIAL = "can-update-testimonial";
export const CAN_DELETE_TESTIMONIAL = "can-delete-testimonial";
export const CAN_VIEW_TESTIMONIALS_WITH_TRASHED =
  "can-view-testimonials-with-trashed";
export const CAN_RESTORE_TESTIMONIAL = "can-restore-testimonial";

// App Content
export const CAN_UPDATE_CONTENT = "can-update-content";
