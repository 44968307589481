import { ITranslationRequest } from "../base/translation";
export interface ICreateComplaint {
  complaintReply: string;
  note: string;
  status: number;
}

export interface IUpdateComplaint extends ICreateComplaint {}

export interface IExportComplaintExcel {
  id: boolean;
  status: boolean;
  type: boolean;
  description: boolean;
  title: boolean;
  user: boolean;
}

export const colomnsExportComplaintExcel = [
  "id",
  "status",
  "type",
  "description",
  "title",
  "user",
];
