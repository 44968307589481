import { Tag } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import CategoryContext from "../../../../../context/category/context";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const ColorAttribute: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { allCategories } = useContext(CategoryContext);
  const fetchParam = (id: number) => {
    let param = allCategories
      ?.filter(
        (cat) => cat.id == attributeWithValue?.attribute?.category?.id
      )[0]
      ?.attributes?.filter(
        (attr) => attr.id == attributeWithValue?.attribute?.id
      )[0]
      ?.attributeParams.filter((param) => param?.id == id)[0];

    return param;
  };
  return (
    <div>
      {attributeWithValue?.values?.map((value, index) => {
        return (
          <Tag
            key={index}
            color={fetchParam(value)?.data?.hex}
            style={{ width: "1.5rem", height: "1.5rem" }}
          ></Tag>
        );
      })}
    </div>
  );
};

export default ColorAttribute;
