import { Button, Divider, Modal, Spin } from "antd";

import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { execute } from "../../../../utils/helpers/execute";

interface IProps<T> {
  id: string;
  title: string;
  hideModal: () => void;
  defaultValues?: T;
  children?: React.ReactNode;
  onSubmit: (data: any) => void;
  confirmButtonText: string;
  loading: boolean;
  danger?: boolean;
  maskClosable?: boolean;
  style?: React.CSSProperties;
  width?: string | number;
  confirmClick?: () => void;
}

function FormHookModal<T>(props: IProps<T>) {
  const {
    id,
    title,
    hideModal,
    defaultValues,
    children,
    confirmButtonText,
    loading,
    danger,
    maskClosable = true,
    style,
    width,
  } = props;

  const { t } = useTranslation();

  const methods = useForm<any>({
    defaultValues: { ...defaultValues },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        await props.onSubmit(data);
        hideModal();
      },
      fallback: (error) => {},
      finallyCallback: () => {},
      throwException: false,
    });
  };

  return (
    <FormProvider {...methods}>
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <Modal
          key={id}
          title={title}
          open
          closable
          maskClosable={maskClosable}
          destroyOnClose
          onCancel={() => hideModal()}
          afterClose={() => hideModal()}
          width={width ?? "50vw"}
          style={style ?? { top: "20px" }}
          bodyStyle={{
            maxHeight: "75vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          footer={
            <>
              <Divider style={{ margin: "10px" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => hideModal()}
                  style={{ marginInlineEnd: 8 }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  htmlType="submit"
                  form={id}
                  type="primary"
                  loading={loading}
                  danger={danger}
                >
                  {confirmButtonText}
                </Button>
              </div>
            </>
          }
        >
          <Divider style={{ margin: "8px 0 8px 0" }} />
          <Spin spinning={loading}>{children}</Spin>
        </Modal>
      </form>
    </FormProvider>
  );
}

export default FormHookModal;
