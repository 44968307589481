import { Button, Divider, Modal, Spin } from "antd";

import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IProps<T> {
  id: string;
  title: string;
  visible: boolean;
  toggleVisible: (visible: boolean) => void;
  onSubmit?: (data: any) => any;
  children?: React.ReactNode;
  loading: boolean;
  width?: string;
  defaultValues?: T;
}

function ExportToExcelModal<T>(props: IProps<T>) {
  const { id, title, toggleVisible, visible, children, loading } = props;

  const { t } = useTranslation();

  const methods = useForm<any>({
    defaultValues: { ...props.defaultValues },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (props.defaultValues) {
      reset(props.defaultValues);
    }
  }, [props.defaultValues]);

  const onSubmit = async (data) => {
    return (await props.onSubmit(data)) && toggleVisible(false);
  };

  return (
    <FormProvider {...methods}>
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <Modal
          title={title}
          visible={visible}
          closable
          destroyOnClose
          onCancel={() => toggleVisible(false)}
          afterClose={() => toggleVisible(false)}
          width={props.width ? props.width : "50vw"}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => toggleVisible(false)}
                style={{ marginInlineEnd: 8 }}
              >
                {t("cancel")}
              </Button>
              <Button
                loading={loading}
                htmlType="submit"
                form={id}
                type="primary"
              >
                {t("export")}
              </Button>
            </div>
          }
        >
          <Divider style={{ margin: "10px 0 10px 0" }} />
          <Spin spinning={loading}>{children}</Spin>
          <Divider />
        </Modal>
      </form>
    </FormProvider>
  );
}

export default ExportToExcelModal;
