import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";

import { ISlice, ISliceDetails } from "../../models/slice/response";
import { ISliceQuery } from "../../models/slice/query";
import { ICreateSlice, IUpdateSlice } from "../../models/slice/request";

export type sliceLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: sliceLoading[];

  list?: IBaseListingResponse<ISlice>;
  query: ISliceQuery;

  details?: ISliceDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ISliceDetails) => void;

    createSlice: (request: ICreateSlice) => void;
    updateSlice: (id: number, request: IUpdateSlice) => void;
    deleteSlice: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ISliceQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    deleteSlice: DEFAULT_FUNCTION,
    updateSlice: DEFAULT_FUNCTION,
    createSlice: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const SliceContext = createContext(externalState);

export default SliceContext;
