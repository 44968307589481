import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import ReportContext from "../../../context/report/context";
import { getReportType } from "../../../models/report/enum";

interface IProps {}

const ReportFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(ReportContext);

  const type = [
    "pharmaciesOrders",
    "orderUserDetailsReport",
    "orderCancellation",
    "driversPerformance",
    "ordersDayleInfo",
    "orderStatus",
    "paidOrdersJourney",
    "ordersDetails",
  ];

  return (
    <Form
      formId="report-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: type.map((el, index) => {
                return {
                  label: t(getReportType(el) as string),
                  value: el as string,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ReportFilter;
