import React, { useContext, useEffect, useReducer } from "react";
import AuthContext, { internalState } from "./context";
import reducer from "./reducer";
import { CAN_VIEW_PERMISSIONS } from "../../utils/rbac/permissions";
import { useNavigate } from "react-router-dom";

import { message } from "antd";
import { useTranslation } from "react-i18next";
import { execute } from "../../utils/helpers/execute";
import { httpclient } from "../../services/http-client";
import EndPoints from "../../services/end-points";
import AppContext from "../app/context";
import { successNotification } from "../../utils/helpers/notification";
import { IChangePassword } from "../../models/auth/request";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import CategoryContext from "../category/context";

interface AuxProps {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuxProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  /**
   * Check if there is access token
   */

  useEffect(() => {
    const token =
      localStorage.getItem(ACCESS_TOKEN) ||
      sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
      httpclient.setSecurityData({
        token: token,
      });
      me();
    } else {
      navigate("/login");
    }
  }, []);

  /**
   *  Get Roles & Permissions
   */
  const { actions: AppActions } = useContext(AppContext);
  useEffect(() => {
    if (state.isAuthenticated) {
      // state.grantedPermissions.includes(CAN_VIEW_ROLES) && AppActions.getRoles()
      state.grantedPermissions.includes(CAN_VIEW_PERMISSIONS) &&
        AppActions.getPermissions();
    }
  }, [state.isAuthenticated]);

  // Me
  const me = async () => {
    await execute({
      callback: async () => {
        const { data } = await EndPoints.auth.me();
        dispatch({
          type: "SET_USER_DETAILS",
          payload: { user: data?.user },
        });
        dispatch({
          type: "USER_PERMISSIONS",
          payload: { permissions: data?.user?.permissions },
        });
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };

  // Login
  const login = async (request: any) => {
    await execute({
      callback: async () => {
        await dispatch({ type: "LOADING", payload: { loading: "login" } });

        const { data } = await EndPoints.auth.login(request);

        await dispatch({ type: "LOGIN_SUCCESS", payload: { user: data.user } });
        localStorage.setItem(ACCESS_TOKEN, data.token);
        localStorage.setItem("loginInfo", "true");
        await dispatch({
          type: "USER_PERMISSIONS",
          payload: { permissions: data?.user?.permissions },
        });
        //@ts-ignore
        await localStorage.setItem("permisionStoroge", data?.user?.permissions);

        await httpclient.setSecurityData({
          token: data.token,
        });
        await successNotification(
          t("loggedIn"),
          // `${t("welcome")} ${data?.user?.id} ${t("message")}`
          `${t("welcome")} ${t("message")}`
        );

        navigate("/");
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
      },
      throwException: false,
    });
  };

  const changePassword = async (request: IChangePassword) => {
    await execute({
      callback: async () => {
        await EndPoints.auth.changePassword(request);

        navigate("/login");
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: 'LOADING', payload: { loading: 'logout' } })
      },
      throwException: false,
    });
  };

  // Logout
  const logout = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });

        await EndPoints.auth.logout();

        dispatch({ type: "LOGOUT_SUCCESS" });

        localStorage.removeItem(ACCESS_TOKEN);
        sessionStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem("loginInfo");

        navigate("/login");
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });
      },
      throwException: false,
    });
  };

  // Reset Code
  const resetCodeUser = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });

        const { data } = await EndPoints.auth.changePassword(request);
        message.success(t("success"));
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        throw error;
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });
      },
      throwException: false,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          login,
          logout,
          changePassword,
          resetCodeUser,
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
