export enum RewardStatus {
    ACTIVE = 1,
    INACTIVE = 2,
  }
  
  export enum  RewardType {
     GENERAL = 1,
     DISTANCE = 2
  }  

  export const getRewardStatusColor = (status: RewardStatus) => {
    switch (status) {
      case RewardStatus.ACTIVE:
        return "green";
      case RewardStatus.INACTIVE:
        return "red";
      default:
        return "red";
    }
  };
  
  export const getRewardStatus = (status: RewardStatus) => {
    switch (status) {
      case RewardStatus.ACTIVE:
        return "active";
      case RewardStatus.INACTIVE:
        return "inactive";
      default:
        return "inactive";
    }
  };
  
  
  export const getRewardTypeColor = (type: RewardType) => {
    switch (type) {
      case RewardType.GENERAL:
        return "green";
      case RewardType.DISTANCE:
        return "red";
      default:
        return "red";
    }
  };
  
  export const getRewardType = (type: RewardType) => {
    switch (type) {
      case RewardType.GENERAL:
        return "general";
      case RewardType.DISTANCE:
        return "distance";
      default:
        return "distance";
    }
  };