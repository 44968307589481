import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IStoreQuery } from "../../models/store/query";
import { IPrize } from "../../models/prize/response";
import { ICreatePrize } from "../../models/prize/request";

export type Prizeoading = "list" | "details" | "create" | "delete";

export interface IInternalState {
  loading: Prizeoading[];

  list?: IBaseListingResponse<IPrize>;
  query: IStoreQuery;

  details?: IPrize;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
   
    createPrize: (request: ICreatePrize) => void;
    deletePrize: (id: number) => void;
  
    setSearch: (search?: string) => void;
    setQuery: (query: IStoreQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    
    createPrize: DEFAULT_FUNCTION,
    deletePrize: DEFAULT_FUNCTION,
  
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PrizeContext = createContext(externalState);

export default PrizeContext;
