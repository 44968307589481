import { ITranslationRequest } from "../base/translation";
import { Location } from "./response";
export interface ICreateDoctor {
  id?: number;
  endWorkingTime: string;
  startWorkingTime: string;
  offDays: string;
  workingStatus: number;
  address: string;
  street: string;
  building: string;
  apartmentNo: string;
  near: string;
  moreDetails: string;
  location: Location;
  birthDate: string;
  cityId: number;
  medicalSpecialtyId: number;
}

export interface IUpdateDoctor extends ICreateDoctor {}

export interface IExportDoctorExcel {
  id: boolean;
  endWorkingTime: boolean;
  startWorkingTime: boolean;
  offDays: boolean;
  workingStatus: boolean;
  address: boolean;
  street: boolean;
  building: boolean;
  apartmentNo: boolean;
  near: boolean;
  moreDetails: boolean;
  location: boolean;
  birthDate: boolean;
  city: boolean;
  medicalSpecialty: boolean;
  user: boolean;
  firstName: boolean;
  lastName: boolean;
  status: boolean;
  createdAt: boolean;
  mobile: boolean;
  specialty: boolean;
}
export const columnsExportDoctorExcel = [
  "id",
  "endWorkingTime",
  "startWorkingTime",
  "offDays",
  "workingStatus",
  "address",
  "street",
  "building",
  "apartmentNo",
  "near",
  "moreDetails",
  "location",
  "birthDate",
  "city",
  "medicalSpecialty",
  "firstName",
  "lastName",
  "status",
  "createdAt",
  "mobile",
  "specialty",
];
