import { Checkbox, Col, Input, Row, Select } from "antd";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import CategoryContext from "../../../context/category/context";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import { ICategory } from "../../../models/category/response";
import { IProduct } from "../../../models/product/response";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";
const { Option } = Select;
const ProductStoreForm = () => {
  const { t } = useTranslation();
  const { allCategories } = useContext(CategoryContext);
  const { direction } = useContext(AppContext);
  const [subCategories, setsubCategories] = useState<ICategory[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const { control, setValue } = useFormContext();
  const [productDetails, setProductDetails] = useState<IProduct>();
  const parentCategoryId = useWatch({
    control,
    name: "parentCategoryId",
  });

  const mainCategoryId = useWatch({
    control,
    name: "mainCategoryId",
  });

  const productId = useWatch({
    control,
    name: "productId",
  });

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        setProductsLoading(true);
        const { data } = await EndPoints.product.getProduct(productId);
        setProductDetails(data);
      } catch (error) {
      } finally {
      }
    };
    parentCategoryId && getProductDetails();
  }, [direction, productId]);

  useEffect(() => {
    mainCategoryId &&
      setsubCategories(
        allCategories?.filter(
          (cat) => cat?.grand?.id === mainCategoryId && cat?.parent === null
        )
      );
  }, [mainCategoryId]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setProductsLoading(true);
        const { data } = await EndPoints.product.getAllProducts({
          light: true,
          perPage: -1,
          filters: [
            {
              name: "parentCategoryId",
              operation: "eq",
              value: parentCategoryId,
            },
          ],
        });
        setProducts(data.data);
      } catch (error) {
      } finally {
        setProductsLoading(false);
      }
    };
    parentCategoryId && getProducts();
  }, [direction, parentCategoryId]);

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <FormItem label={t("grand")} required>
            <Controller
              name="mainCategoryId"
              control={control}
              rules={{ required: true }}
              render={({ field: { ...field } }) => {
                return (
                  <Select
                    loading={false}
                    {...field}
                    style={{ width: "100%" }}
                    allowClear
                    onSelect={() => {
                      setValue("parentCategoryId", null);
                      setValue("productId", null);
                    }}
                    placeholder={t("grand")}
                  >
                    {allCategories
                      ?.filter(
                        (cat) => cat?.parent === null && cat?.grand === null
                      )
                      ?.map((tr, index) => {
                        return (
                          <Option key={index} value={tr?.id}>
                            {tr?.name}
                          </Option>
                        );
                      })}
                  </Select>
                );
              }}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label={t("parent_category")} required>
            <Controller
              name="parentCategoryId"
              control={control}
              rules={{ required: true }}
              render={({ field: { ...field } }) => {
                return (
                  <Select
                    loading={false}
                    {...field}
                    style={{ width: "100%" }}
                    allowClear
                    disabled={mainCategoryId ? false : true}
                    placeholder={t("categories")}
                  >
                    {subCategories?.map((tr, index) => {
                      return (
                        <Option key={index} value={tr?.id}>
                          {tr?.name}
                        </Option>
                      );
                    })}
                  </Select>
                );
              }}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label={t("product")} required>
            <Controller
              name="productId"
              control={control}
              rules={{ required: true }}
              render={({ field: { ...field } }) => {
                return (
                  <Select
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    loading={productsLoading}
                    {...field}
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    placeholder={t("product")}
                  >
                    {products?.map((product, index) => {
                      return (
                        <Option key={index} value={product?.id}>
                          {product?.name}
                        </Option>
                      );
                    })}
                  </Select>
                );
              }}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t("trademark")}>
            <Input value={productDetails?.trademark?.name} disabled />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t("unit")}>
            <Input value={productDetails?.unit?.name} disabled />
          </FormItem>
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="price"
            label={t("price")}
            input={{ type: "number" }}
            rules={{ required: false }}
          />
        </Col>
        <Col span={8}>
          <Checkbox checked={productDetails?.soldByUnit} disabled>{`${t(
            "sold_by_unit"
          )}`}</Checkbox>

          {/* <FieldBuilder
            label={`${t("soldByUnit")}`}
            name="soldByUnit"
            disabled
            input={{ type: "checkBox" }}
          /> */}
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("available")}`}
            name="available"
            input={{ type: "checkBox" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("is_discount")}`}
            name="isDiscount"
            input={{ type: "checkBox" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="unitPrice"
            label={t("unit_price")}
            input={{ type: "number" }}
            rules={{ required: false }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="discountPercentage"
            label={t("discount_percentage")}
            input={{ type: "number" }}
            rules={{ required: false }}
          />
        </Col>

        <Col span={24}>
          <FieldBuilder
            name="note"
            label={t("note")}
            input={{ type: "text-area" }}
            rules={{ required: false }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductStoreForm;
