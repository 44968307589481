import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateChronicDiseases,
  IExportChronicDiseasesExcel,
  IUpdateChronicDiseases,
} from "../../models/chronic-diseases/request";
import {
  IChronicDiseases,
  IChronicDiseasesDetails,
} from "../../models/chronic-diseases/response";
import { IChronicDiseasesQuery } from "../../models/chronic-diseases/query";

class ChronicDiseasesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllChronicDiseasess = (
    query: IChronicDiseasesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IChronicDiseases>>({
      path: `/chronic-disease`,
      method: "GET",
      query,
      ...params,
    });

  getChronicDiseases = (id: number, params?: RequestParams) =>
    this.http.request<IChronicDiseasesDetails>({
      path: `/chronic-disease/${id}`,
      method: "GET",
      ...params,
    });

  createChronicDiseases = (
    data: ICreateChronicDiseases,
    params?: RequestParams
  ) =>
    this.http.request<IChronicDiseases>({
      path: "/chronic-disease",
      method: "POST",
      body: data,
      ...params,
    });

  updateChronicDiseases = (
    id: number,
    data: IUpdateChronicDiseases,
    params?: RequestParams
  ) =>
    this.http.request<IChronicDiseases>({
      path: `/chronic-disease/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteChronicDiseases = (id: number, params?: RequestParams) =>
    this.http.request<IChronicDiseases>({
      path: `/chronic-disease/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportChronicDiseasesExcel,
    query: IChronicDiseasesQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/chronic-disease/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default ChronicDiseasesService;
