import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { MAP_DEFAULT_LOCATION } from "../../../../utils/helpers/constants";

import { useTranslation } from "react-i18next";
// @ts-ignore
import icon from "../../../../assets/images/a1.ico";
import { ICenter } from "../../../../models/map/enum";
import { ILocation } from "../../../../models/store/response";
import styles from "./style.module.scss";

interface IProps {
  draggable: boolean;
  location?: ILocation;
  name?: string;
  width?: string;
  onChange?: (e: ICenter) => void;
}

const Marker: React.FC<IProps> = ({
  onChange,
  location,
  draggable,
  name,
  width,
}) => {
  const containerStyle = {
    width: width ?? "70vw",
    height: "55vh",
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();
  const [markerPoint, setmarkerPoint] = useState<ICenter[]>([
    {
      lng: MAP_DEFAULT_LOCATION.lng,

      lat: MAP_DEFAULT_LOCATION.lat,
    },
  ]);

  const { isLoaded: isApiLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_KEY}`,
    libraries: ["drawing"],
  });

  React.useEffect(() => {
    setIsLoaded(isApiLoaded);
  }, [isApiLoaded]);
  useEffect(() => {
    location
      ? setmarkerPoint([
          { lat: location?.coordinates[1], lng: location?.coordinates[0] },
        ])
      : setmarkerPoint([
          {
            lng: MAP_DEFAULT_LOCATION.lng,

            lat: MAP_DEFAULT_LOCATION.lat,
          },
        ]);
  }, []);
  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lng: markerPoint[markerPoint?.length - 1]?.lng,

            lat: markerPoint[markerPoint?.length - 1]?.lat,
          }}
          zoom={10}
        >
          {markerPoint?.map((mark, index) => {
            return (
              <>
                <MarkerF
                  key={index}
                  position={{
                    lat: mark.lat,
                    lng: mark.lng,
                  }}
                  draggable={draggable ? true : false}
                  animation={google.maps.Animation.DROP}
                  icon={icon}
                  label={{
                    text: name ? t(name) : t("location"),
                    className: `${styles.marker}`,
                  }}
                  onDragEnd={(e) => {
                    onChange(e.latLng.toJSON());
                    setmarkerPoint([e.latLng.toJSON()]);
                  }}
                />
              </>
            );
          })}
        </GoogleMap>
      )}
    </>
  );
};

export default React.memo(Marker);
