import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IProduct, IProductDetails } from "../../models/product/response";
import { IProductQuery } from "../../models/product/query";
import {
  ICreateProduct,
  IImportExcelProductStore,
  IManageProductStore,
  IUpdateProduct,
} from "../../models/product/request";

export type ProductLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "manage";

export interface IInternalState {
  loading: ProductLoading[];

  list?: IBaseListingResponse<IProduct>;
  query: IProductQuery;

  details?: IProductDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IProductDetails) => void;
    circulateProduct : (id: number) => void;

    createProduct: (request: ICreateProduct) => void;
    manageProductStore: (request: IManageProductStore) => void;
    updateProduct: (id: number, request: IUpdateProduct) => void;
    deleteProduct: (id: number) => void;
    exportExcel: (data: any) => any;
    downloadFileExcel: (id: number) => any;
    importExcelProductStore: (request: IImportExcelProductStore) => void;
    setSearch: (search?: string) => void;
    setQuery: (query: IProductQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,
    circulateProduct : DEFAULT_FUNCTION,

    createProduct: DEFAULT_FUNCTION,
    manageProductStore: DEFAULT_FUNCTION,
    updateProduct: DEFAULT_FUNCTION,
    importExcelProductStore: DEFAULT_FUNCTION,
    downloadFileExcel: DEFAULT_FUNCTION,
    deleteProduct: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ProductContext = createContext(externalState);

export default ProductContext;
