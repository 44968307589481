import { ITranslationRequest } from "../base/translation";

export interface ICreateCategory {
  name: ITranslationRequest;
  note: ITranslationRequest;
  order: number;
  parentId: number;
  imageId: number;
  mainImageId: number;
  grandId: number;
}

export interface IUpdateCategory extends ICreateCategory {}

export interface IExportCategoryExcel {
  id: boolean;
  name: boolean;
  parent: boolean;
  order: boolean;
}
export const columnsCategory = ["name", "order", "id", "parent"];
