import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { ICityQuery } from "../../models/city/query";
import { ICity } from "../../models/city/response";
import {
  ICreateCity,
  IExportCityExcel,
  IUpdateCity,
} from "../../models/city/request";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

class citieservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCities = (query: ICityQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICity>>({
      path: `/cities`,
      method: "GET",
      query,
      ...params,
    });

  getCity = (id: number, params?: RequestParams) =>
    this.http.request<ICity>({
      path: `/cities/${id}`,
      method: "GET",
      ...params,
    });

  createCity = (data: ICreateCity, params?: RequestParams) =>
    this.http.request<ICity>({
      path: "/cities",
      method: "POST",
      body: data,
      ...params,
    });

  updateCity = (id: number, data: IUpdateCity, params?: RequestParams) =>
    this.http.request<ICity>({
      path: `/cities/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCity = (id: number, params?: RequestParams) =>
    this.http.request<ICity>({
      path: `/cities/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportCityExcel, query: ICityQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/cities/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default citieservice;
