import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import EndPoints from "../../../services/end-points";
import ComplaintContext from "../../../context/complaint/context";
import { IClient } from "../../../models/client/response";
import { getComplaintStatus, getComplaintType } from "../../../models/complaint/enum";

interface IProps { }

const ComplaintFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(ComplaintContext);

  const [clients, setClients] = useState<any[]>([]);
  const [ClientLoading, setClientLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);

  const types = [1, 2, 3]
  const status = [1, 2, 3]
  // get all roles from api
  useEffect(() => {
    const getClient = async () => {
      try {
        const { data } = await EndPoints.client.getAllClient({
          light: true,
          perPage: -1,
        });
        setClients(data.data);
        setClientLoading(false);
      } catch (error) {
      } finally {
        setClientLoading(false);
      }
    };
    getClient();
  }, [direction]);
  return (
    <Form
      formId="complaint-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.clientId
              ? {
                name: "clientId",
                value: data.clientId,
                operation: "eq",
              }
              : undefined,
            data?.type
              ? {
                name: "type",
                value: data.type,
                operation: "eq",
              }
              : undefined,
            data?.status
              ? {
                name: "status",
                value: data.status,
                operation: "eq",
              }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="clientId"
            label={`${t("client")}`}
            width="large"
            input={{
              type: "select",
              options: clients.map((client, index) => {
                return {
                  label: client?.firstName,
                  value: client.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            input={{
              type: "select",
              options: types?.map((type, index) => {
                return {
                  key: index,
                  label: t(getComplaintType(type)),
                  value: type,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: status.map((statu, index) => {
                return {
                  label: t(getComplaintStatus(statu)),
                  value: statu as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ComplaintFilter;
