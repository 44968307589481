export enum UserStatus {
  NOT_VERIFIED = 1,
  VERIFIED = 2,
  BLOCKED = 3,
}

// Status Tags Colors

export enum BooleanStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getUserStatusColor = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.ACTIVE:
      return "green";
    case BooleanStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getBooleanStatus = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.ACTIVE:
      return "active";
    case BooleanStatus.INACTIVE:
      return "inactive";
    default:
      return "inactive";
  }
};

export type AllCountriesStatus = true | false;

// Status Boolean stuts
export const getAllCountriesStatus = (status: AllCountriesStatus) => {
  switch (status) {
    case true:
      return "yes";
    case false:
      return "no";
    default:
      return "no";
  }
};
