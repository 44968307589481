import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  ICommenQuestion,
  ICommenQuestionDetails,
} from "../../models/commen-question/response";
import { ICommenQuestionQuery } from "../../models/commen-question/query";
import {
  ICreateCommenQuestion,
  IUpdateCommenQuestion,
} from "../../models/commen-question/request";

export type CommenQuestionLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CommenQuestionLoading[];

  list?: IBaseListingResponse<ICommenQuestion>;
  query: ICommenQuestionQuery;

  details?: ICommenQuestionDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICommenQuestionDetails) => void;

    createCommenQuestion: (request: ICreateCommenQuestion) => void;
    updateCommenQuestion: (id: number, request: IUpdateCommenQuestion) => void;
    deleteCommenQuestion: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ICommenQuestionQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createCommenQuestion: DEFAULT_FUNCTION,
    updateCommenQuestion: DEFAULT_FUNCTION,
    deleteCommenQuestion: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CommenQuestionContext = createContext(externalState);

export default CommenQuestionContext;
