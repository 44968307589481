import { Row, Col, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import TagContext from "../../../context/tag/context";
import TagContextProvider from "../../../context/tag/provider";
import { IUpdateTag } from "../../../models/tag/request";
import PointContext from "../../../context/point/context";
import { UserStatus } from "../../../models/user/enum";
import { ICity } from "../../../models/city/response";
import { IArea } from "../../../models/area/response";
import { IUser } from "../../../models/user/response";
import EndPoints from "../../../services/end-points";
import { IUpdatePoint } from "../../../models/point/request";
import { getPointStatus } from "../../../models/point/enum";
import PointContextProvider from "../../../context/point/provider";

const Form = () => {
    const { t } = useTranslation();
    const { details, actions, loading } = useContext(PointContext);
    const { id } = useParams();
    const status = [1, 2];

    const [city, setCity] = useState<ICity[]>([]);
    const [area, setArea] = useState<IArea[]>([]);
    const [employ, setEmploy] = useState<IUser[]>([]);

    const [cityLoading, setCityLoading] = useState(true);
    const [areaLoading, setAreaLoading] = useState(true);
    const [employLoading, setEmployLoading] = useState(true);

    const getCities = async () => {
        try {
            const { data } = await EndPoints.city.getAllCities({
                light: true,
                perPage: -1,
            });
            setCity(data.data);
            setCityLoading(false);
        } catch (error) {
        } finally {
            setCityLoading(false);
        }
    };

    const getAreas = async () => {
        try {
            const { data } = await EndPoints.area.getAllAreas({
                light: true,
                perPage: -1,
            });
            setArea(data.data);
            setAreaLoading(false);
        } catch (error) {
        } finally {
            setAreaLoading(false);
        }
    };

    const getEmploy = async () => {
        try {
            const { data } = await EndPoints.user.getAllUsers({
                light: true,
                perPage: -1,
            });
            setEmploy(data.data);
            setEmployLoading(false);
        } catch (error) {
        } finally {
            setEmployLoading(false);
        }
    };

    useEffect(() => {
        getCities();
        getAreas();
        getEmploy();

    }, []);

    useEffect(() => {
        const getDetails = async () => {
            id && (await actions.getDetails(Number(id)));
        };
        getDetails();
    }, []);
    return (
        <MainForm
            onSubmit={(data) => {
                details
                    ? actions.updatePoint(details?.id, data)
                    : actions.createPoint(data);
            }}
            title={details ? t("update_point") : t("add_point")}
            defaultValues={
                {
                    name: getTranslations(details?.translations?.name),
                    status: details?.status,
                    employId: details?.employ?.id,
                    cityId: details?.city?.id,
                    areaId: details?.area?.id,
                    //note: details?.note
                } as IUpdatePoint
            }
        >
            {loading.includes("details") ? (
                <div style={{ height: "50vh" }}>
                    <Spin />
                </div>
            ) : (
                <>
                    <Row gutter={[16, 8]}>
                        {languages?.map((lang, index) => {
                            return (
                                <Col span={8}>
                                    <FieldBuilder
                                        key={index}
                                        name={`name[${lang?.code}]`}
                                        label={`${t("name")} (${lang?.name})`}
                                        input={{ type: "text" }}
                                        rules={{ required: true }}
                                    />
                                </Col>
                            );
                        })}
                        <Col span={8}>
                            <FieldBuilder
                                name="status"
                                label={`${t("status")}`}
                                width="large"
                                rules={{ required: true }}
                                input={{
                                    type: "select",
                                    loading: false,
                                    options: status?.map((status, index) => {
                                        return {
                                            key: index,
                                            label: t(getPointStatus(status)),
                                            value: status,
                                        };
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name="cityId"
                                label={`${t("city")}`}
                                width="large"
                                rules={{ required: true }}
                                input={{
                                    type: "select",
                                    loading: cityLoading,
                                    allowSearch: true,
                                    options: city?.map((city, index) => {
                                        return {
                                            key: index,
                                            label: city.name,
                                            value: city.id,
                                        };
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name="employId"
                                label={`${t("employ")}`}
                                width="large"
                                rules={{ required: true }}
                                input={{
                                    type: "select",
                                    loading: employLoading,
                                    allowSearch: true,
                                    options: employ?.map((employ, index) => {
                                        return {
                                            key: index,
                                            label: employ.user.firstName,
                                            value: employ.id,
                                        };
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={8}>
                            <FieldBuilder
                                name="areaId"
                                label={`${t("area")}`}
                                width="large"
                                rules={{ required: true }}
                                input={{
                                    type: "select",
                                    loading: areaLoading,
                                    allowSearch: true,
                                    options: area?.map((area, index) => {
                                        return {
                                            key: index,
                                            label: area.name,
                                            value: area.id,
                                        };
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <FieldBuilder
                                name={'note'}
                                label={`${t("note")}`}
                                input={{ type: "text-area" }}
                                rules={{ required: false }}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </MainForm>
    );
};

const PointForm = () => {
    return (
        <PointContextProvider>
            <Form />
        </PointContextProvider>
    );
};
export default PointForm;
