import React from "react";
import { Layout } from "antd";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";

const { Footer: AntdFooter } = Layout;

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <span>{`©2023 `}</span>
      <span className={styles.logo}>{`${t("e_pharmacy")}`}</span>
      <span>{`${t("created_by")} `}</span>
      <a
        href="http://www.autozonegroup.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={cn("text-primary", styles.autoZoneLogo)}
      >
        AutoZone
      </a>
    </div>
  );
};

export default Footer;
