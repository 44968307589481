import { createContext } from "react";

import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IRoleQuery } from "../../models/role/query";
import { IPermission, IRole, IRoleDetails } from "../../models/role/response";
import { ICreateRole, IUpdateRole } from "../../models/role/request";

export type RoleLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: RoleLoading[];

  list?: IBaseListingResponse<IRole>;
  permissions?: IBaseListingResponse<IPermission>;
  query: IRoleQuery;

  details?: IRoleDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IRoleDetails) => void;
    exportExcel: (data: any) => any;

    createRole: (request: ICreateRole) => void;
    updateRole: (id: number, request: IUpdateRole) => void;
    deleteRole: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IRoleQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createRole: DEFAULT_FUNCTION,
    updateRole: DEFAULT_FUNCTION,
    deleteRole: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const RoleContext = createContext(externalState);

export default RoleContext;
