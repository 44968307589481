import { CAN_VIEW_HOME_PAGE } from "./utils/rbac/permissions";

import { FaHome, FaRocketchat, FaSnapchat, FaUserTie } from "react-icons/fa";
import AreaDetailesPage from "./components/area/area-details";
import AreaForm from "./components/area/area-form";
import BannerClientDetailesPage from "./components/banners/banner-client/details";
import { default as BannerClientForm } from "./components/banners/banner-client/form";
import BannerDleiveryDetailesPage from "./components/banners/banner-delivery/details";
import BannerDeliveryForm from "./components/banners/banner-delivery/form";
import BannersPharmaceuticalDetailesPage from "./components/banners/banner-phrmaticty/details";
import BannerPharmatyictsForm from "./components/banners/banner-phrmaticty/form";
import CategoryDetailsPage from "./components/category/category-details";
import CategoryForm from "./components/category/category-form";
import ChronicDiseasesDetailesPage from "./components/chronic-diseases/chronic-diseases-details";
import ChronicDiseasesForm from "./components/chronic-diseases/chronic-diseases-form";
import CityDetailesPage from "./components/city/city-details";
import CityForm from "./components/city/city-form";
import ClientDetailsPage from "./components/client/client-details";
import ClientForm from "./components/client/client-form";
import CommenQuestionDetailesPage from "./components/commen-question/commen-question-details";
import CommenQuestionForm from "./components/commen-question/commen-question-form";
import ComplaintDetailesPage from "./components/complaint/complaint-details";
import ComplaintForm from "./components/complaint/complaint-form";
import DeliveryWorkerDetailsPage from "./components/delivery-worker/delivery-worker-details";
import DeliveryWorkerForm from "./components/delivery-worker/delivery-worker-form";
import DiscountCodeDetailesPage from "./components/discount-code/discount-code-details";
import DiscountCodeForm from "./components/discount-code/discount-code-form";
import GeneralNotificationDetailesPage from "./components/general-notification/general-notification-details";
import GeneralNotificationForm from "./components/general-notification/general-notification-form";
import GeneralSettingDetailesPage from "./components/general-setting/general-setting-details";
import GeneralSettingForm from "./components/general-setting/general-settings-form";
import HomePageListDetailsPage from "./components/home-page-list/home-page-list-details";
import HomePageListForm from "./components/home-page-list/home-page-list-form";
import InvestorDetailesPage from "./components/investor/investor-details";
import InvestorForm from "./components/investor/investor-form";
import OrderNoteDetailesPage from "./components/order-note/order-note-details";
import OrderNoteForm from "./components/order-note/order-note-form";
import OrderDetailesPage from "./components/order/order-details";
import PaymentDeliveryWorkerDetailesPage from "./components/payment-delivery-worker/details";
import PaymentDeliveryWorkerForm from "./components/payment-delivery-worker/form";
import PolicyTermDetailesPage from "./components/policy-term/policy-term-details";
import PolicyTermForm from "./components/policy-term/policy-term-form";
import ProductDetailesPage from "./components/product/product-details";
import ProductForm from "./components/product/product-form";
import RoleDetailesPage from "./components/role/role-details";
import RoleForm from "./components/role/role-form";
import StoreDetailesPage from "./components/store/store-details";
import StoreForm from "./components/store/store-form";
import TagDetailesPage from "./components/tag/tag-details";
import TagForm from "./components/tag/tag-form";
import TrademarkDetailsPage from "./components/trademark/trademark-details";
import TrademarkForm from "./components/trademark/trademark-form";
import UnitDetailesPage from "./components/unit/unit-details";
import UnitForm from "./components/unit/unit-form";
import UserDetailesPage from "./components/user/user-details";
import UserForm from "./components/user/user-form";
import AreaPage from "./pages/area";
import AttributePage from "./pages/attribute";
import BannerClientPage from "./pages/banner/banner-client";
import BannerDeliveryPage from "./pages/banner/banner-delivery";
import BannerPharmaticysPage from "./pages/banner/banner-pharmastyics";
import CategoryPage from "./pages/categories";
import ChronicDiseasesPage from "./pages/chronic-diseases";
import CityPage from "./pages/city";
import ClientPage from "./pages/client";
import CommenQuestionPage from "./pages/commen-question";
import ComplaintPage from "./pages/complaint";
import DeliveryWorkersPage from "./pages/delivery-worker";
import DiscountCodePage from "./pages/discount-code";
import GeneralNotificationPage from "./pages/general-notification";
import Home from "./pages/home";
import HomePageListPage from "./pages/home-page-list";
import InvestorPage from "./pages/investor";
import OrderPage from "./pages/order";
import OrderNotePage from "./pages/order-note";
import PaymentDeliveryWorkerPage from "./pages/payment-delivery-worker";
import ProductPage from "./pages/product";
import RolePage from "./pages/role";
import StorePage from "./pages/store";
import TagPage from "./pages/tag";
import TrademarkPage from "./pages/trademark";
import UnitPage from "./pages/unit";
import UserPage from "./pages/user";
import PublicSurveillance from "./pages/public-surveillance";
import PointPage from "./pages/point";
import PointForm from "./components/point/point-form";
import PointDetailsPage from "./components/point/point-details";
import PaymentPointPage from "./pages/payment-point";
import PaymentPointForm from "./components/payment-point/payment-point-form";
import PaymentPointDetailsPage from "./components/payment-point/payment-point-details";
import ManageProductStoreForm from "./components/product/product-manage-store";
import { Permissions } from "./utils/Permissions/Permissions";
import OrderForm from "./components/order/order-update";
import Chat from "./pages/chat/Chat";
import TrackingContextProvider from "./context/tracking-map/provider";
import DoctorForm from "./components/doctor/doctor-form";
import DoctorDetailsPage from "./components/doctor/doctor-details";
import DoctorPage from "./pages/doctor";
import MedicalSpecialtiesPage from "./pages/medical-specialties";
import MedicalSspecialtiesForm from "./components/doctor copy/medical-specialties-form";
import MedicalSspecialtiesDetails from "./components/doctor copy/medical-specialties-details";
import SlicePage from "./pages/slice";
import SliceForm from "./components/slice/slice-form";
import SliceDetailesPage from "./components/slice/slice-details";
import PrizePage from "./pages/prize";
import PrizeForm from "./components/prize/prize-form";
import PrizeDetailesPage from "./components/prize/prize-details";
import ReportPage from "./pages/report";
import { FileTextOutlined } from "@ant-design/icons";
import OrderShortPage from "./pages/order-short";
import RewardPage from "./pages/reward";
import RewardForm from "./components/reward/reward-form";
import RewardDetailesPage from "./components/reward/reward-details";

export interface ICrudRoutes {
  path: string;
  component: React.ReactNode;
}
export interface ISubRoute {
  labelKey: string;
  icon?: JSX.Element;
  path: string;
  permissions: string[];
  component: any;
  crudRoutes?: ICrudRoutes[];
  exact?: boolean;
}

export interface IRoute {
  labelKey: string;
  icon: JSX.Element;
  path: string;
  crudRoutes?: ICrudRoutes[];

  hasSubMenus: boolean;
  subMenus?: ISubRoute[];

  permissions: string[];
  component: any;
  exact?: boolean;
}

export const routes: IRoute[] = [
  {
    labelKey: "home",
    icon: <FaUserTie />,
    path: "/",
    hasSubMenus: false,
    permissions: [Permissions.CAN_VIEW_STATISTICS],
    component: <Home />,
  },
  {
    labelKey: "report",
    icon: <FileTextOutlined />,
    path: "/report",
    hasSubMenus: false,
    permissions: [Permissions.CAN_VIEW_REPORTS],

    component: <ReportPage />,
  },
  {
    labelKey: "setting",
    icon: <FaHome />,
    path: "/",
    hasSubMenus: true,
    permissions: [],
    component: <Home />,
    subMenus: [
      {
        labelKey: "roles",
        icon: <FaUserTie />,
        path: "/roles",
        permissions: [Permissions.CAN_VIEW_ROLE],
        component: <RolePage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <RoleForm />,
          },
          {
            component: <RoleForm />,
            path: "create",
          },
          {
            component: <RoleDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "areas",
        icon: <FaUserTie />,
        path: "/areas",
        permissions: [Permissions.CAN_VIEW_AREA],
        component: <AreaPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <AreaForm />,
          },
          {
            component: <AreaForm />,
            path: "create",
          },
          {
            component: <AreaDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "cities",
        icon: <FaUserTie />,
        path: "/cities",
        permissions: [Permissions.CAN_VIEW_CITY],
        component: <CityPage />,
        crudRoutes: [
          {
            path: "update/:id",

            component: <CityForm />,
          },
          {
            component: <CityForm />,
            path: "create",
          },
          {
            component: <CityDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "rewards",
        icon: <FaUserTie />,
        path: "/rewards",
        permissions: [Permissions.CAN_VIEW_ROLE],
        component: <RewardPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <RewardForm />,
          },
          {
            component: <RewardForm />,
            path: "create",
          },
          {
            component: <RewardDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "general_settings",
        icon: <FaUserTie />,
        path: "/general-settings",
        permissions: [Permissions.CAN_VIEW_GENERAL_SETTING],
        component: <GeneralSettingDetailesPage />,
        crudRoutes: [
          {
            path: "update",
            component: <GeneralSettingForm />,
          },
        ],
      },
      {
        labelKey: "policy_term",
        icon: <FaUserTie />,
        path: "/policy-term",
        permissions: [Permissions.CAN_VIEW_POLICY_TERM],
        component: <PolicyTermDetailesPage />,
        crudRoutes: [
          {
            path: "update",
            component: <PolicyTermForm />,
          },
        ],
      },
    ],
  },
  {
    labelKey: "general_data",
    icon: <FaHome />,
    path: "/aa",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_GENERAL_DATA],
    component: <Home />,
    subMenus: [
      {
        labelKey: "order_notes",
        icon: <FaUserTie />,
        path: "/order-note",
        permissions: [Permissions.CAN_VIEW_ORDER_NOTE],
        component: <OrderNotePage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <OrderNoteForm />,
          },
          {
            component: <OrderNoteForm />,
            path: "create",
          },
          {
            component: <OrderNoteDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "tags",
        icon: <FaUserTie />,
        path: "/tags",
        permissions: [Permissions.CAN_VIEW_TAG],
        component: <TagPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <TagForm />,
          },
          {
            component: <TagForm />,
            path: "create",
          },
          {
            component: <TagDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "units",
        icon: <FaUserTie />,
        path: "/units",
        permissions: [Permissions.CAN_VIEW_UNIT],
        component: <UnitPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <UnitForm />,
          },
          {
            component: <UnitForm />,
            path: "create",
          },
          {
            component: <UnitDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "slices",
        icon: <FaUserTie />,
        path: "/slices",
        permissions: [Permissions.CAN_VIEW_SLICE],
        component: <SlicePage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <SliceForm />,
          },
          {
            component: <SliceForm />,
            path: "create",
          },
          {
            component: <SliceDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "chronic_diseases",
        icon: <FaUserTie />,
        path: "/chronic-diseases",
        permissions: [Permissions.CAN_VIEW_CHRONIC_DISEASE],
        component: <ChronicDiseasesPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <ChronicDiseasesForm />,
          },
          {
            component: <ChronicDiseasesForm />,
            path: "create",
          },
          {
            component: <ChronicDiseasesDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "discount_codes",
        icon: <FaUserTie />,
        path: "/discount-codes",
        permissions: [Permissions.CAN_VIEW_DISCOUNT_CODES],
        component: <DiscountCodePage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <DiscountCodeForm />,
          },
          {
            component: <DiscountCodeForm />,
            path: "create",
          },
          {
            component: <DiscountCodeDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "commen_questions",
        icon: <FaUserTie />,
        path: "/commen-questions",
        permissions: [Permissions.CAN_VIEW_COMMON_QUESTIONS],
        component: <CommenQuestionPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <CommenQuestionForm />,
          },
          {
            component: <CommenQuestionForm />,
            path: "create",
          },
          {
            component: <CommenQuestionDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "medical_specialties",
        icon: <FaUserTie />,
        path: "/medical-specialties",

        permissions: [Permissions.CAN_VIEW_MEDICAL_SPECIALTY],
        component: <MedicalSpecialtiesPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <MedicalSspecialtiesForm />,
          },
          {
            component: <MedicalSspecialtiesForm />,
            path: "create",
          },
          {
            component: <MedicalSspecialtiesDetails />,
            path: "details/:id",
          },
        ],
      },
    ],
  },
  {
    labelKey: "application_management",
    icon: <FaHome />,
    path: "/application-management",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_APPS_MANAGE],
    component: <Home />,
    subMenus: [
      {
        labelKey: "home_page_list",
        icon: <FaUserTie />,
        path: "/home-page-list",
        permissions: [Permissions.CAN_VIEW_HOMEPAGE_LISTING],
        component: <HomePageListPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <HomePageListForm />,
          },
          {
            component: <HomePageListForm />,
            path: "create",
          },
          {
            component: <HomePageListDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "banners_for_client",
        icon: <FaUserTie />,
        path: "/banners-client",
        permissions: [Permissions.CAN_VIEW_BANNER],
        component: <BannerClientPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <BannerClientForm />,
          },
          {
            component: <BannerClientForm />,
            path: "create",
          },
          {
            component: <BannerClientDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "banners_pharmaceutical",
        icon: <FaUserTie />,
        path: "/banners-pharmaceutical",
        permissions: [Permissions.CAN_VIEW_BANNER],
        component: <BannerPharmaticysPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <BannerPharmatyictsForm />,
          },
          {
            component: <BannerPharmatyictsForm />,
            path: "create",
          },
          {
            component: <BannersPharmaceuticalDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      // {
      //   labelKey: "banners_for_delivery",
      //   icon: <FaUserTie />,
      //   path: "/banners-delivery",
      //   permissions: [Permissions.CAN_VIEW_BANNER],
      //   component: <BannerDeliveryPage />,
      //   crudRoutes: [
      //     {
      //       path: "update/:id",
      //       component: <BannerDeliveryForm />,
      //     },
      //     {
      //       component: <BannerDeliveryForm />,
      //       path: "create",
      //     },
      //     {
      //       component: <BannerDleiveryDetailesPage />,
      //       path: "details/:id",
      //     },
      //   ],
      // },
      {
        labelKey: "generals_notification",
        icon: <FaUserTie />,
        path: "/general-notification",
        permissions: [Permissions.CAN_VIEW_GENERAL_NOTIFICATION],
        component: <GeneralNotificationPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <GeneralNotificationForm />,
          },
          {
            component: <GeneralNotificationForm />,
            path: "create",
          },
          {
            component: <GeneralNotificationDetailesPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },

  {
    labelKey: "clients_and_pharmacies",
    icon: <FaHome />,
    path: "/client-pharmacies",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_CLIENTS_STORES],
    component: <Home />,
    subMenus: [
      {
        labelKey: "investors",
        icon: <FaUserTie />,

        path: "/investors",
        permissions: [Permissions.CAN_VIEW_INVESTOR],
        component: <InvestorPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <InvestorForm />,
          },
          {
            component: <InvestorForm />,
            path: "create",
          },
          {
            component: <InvestorDetailesPage />,
            path: "details/:id",
          },
        ],
      },

      {
        labelKey: "stores",
        icon: <FaUserTie />,
        path: "/stores",

        permissions: [Permissions.CAN_VIEW_STORE],
        component: <StorePage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <StoreForm />,
          },
          {
            component: <StoreForm />,
            path: "create",
          },
          {
            component: <StoreDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "clients",
        icon: <FaUserTie />,
        path: "/clients",
        permissions: [Permissions.CAN_VIEW_CLIENT],
        component: <ClientPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <ClientForm />,
          },
          {
            component: <ClientForm />,
            path: "create",
          },
          {
            component: <ClientDetailsPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },

  {
    labelKey: "pharmacy_team",
    icon: <FaHome />,
    path: "/pharmacy-team",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_PHARMACY_TEAM],
    component: <Home />,
    subMenus: [
      {
        labelKey: "users",
        icon: <FaUserTie />,
        path: "/users",

        permissions: [Permissions.CAN_VIEW_EMPLOYEE],
        component: <UserPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <UserForm />,
          },
          {
            component: <UserForm />,
            path: "create",
          },
          {
            component: <UserDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "delivery_workers",
        icon: <FaUserTie />,
        path: "/delivery-worker",

        permissions: [Permissions.CAN_VIEW_DRIVER],
        component: <DeliveryWorkersPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <DeliveryWorkerForm />,
          },
          {
            component: <DeliveryWorkerForm />,
            path: "create",
          },
          {
            component: <DeliveryWorkerDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "doctors",
        icon: <FaUserTie />,
        path: "/doctor",

        permissions: [Permissions.CAN_VIEW_DOCTOR],
        component: <DoctorPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <DoctorForm />,
          },
          {
            component: <DoctorForm />,
            path: "create",
          },
          {
            component: <DoctorDetailsPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },

  {
    labelKey: "products_and_categories",
    icon: <FaHome />,
    path: "/products_and_categories",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_CATEGORIES_PRODUCTS],
    component: <Home />,
    subMenus: [
      {
        labelKey: "products",
        icon: <FaUserTie />,

        path: "/products",
        permissions: [Permissions.CAN_VIEW_PRODUCT],
        component: <ProductPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <ProductForm />,
          },
          {
            component: <ProductForm />,
            path: "create",
          },
          {
            component: <ManageProductStoreForm />,
            path: "manage-store",
          },
          {
            component: <ProductDetailesPage />,
            path: "details/:id",
          },
        ],
      },

      {
        labelKey: "trademarks",
        icon: <FaUserTie />,
        path: "/trademarks",

        permissions: [Permissions.CAN_VIEW_TRADEMARK],
        component: <TrademarkPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <TrademarkForm />,
          },
          {
            component: <TrademarkForm />,
            path: "create",
          },
          {
            component: <TrademarkDetailsPage />,
            path: "details/:id",
          },
        ],
      },

      {
        labelKey: "categories",
        icon: <FaUserTie />,
        path: "/categories",

        permissions: [Permissions.CAN_VIEW_CATEGORY],
        component: <CategoryPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <CategoryForm />,
          },
          {
            component: <CategoryForm />,
            path: "create",
          },
          {
            component: <CategoryDetailsPage />,
            path: "details/:id",
          },
          {
            path: ":id/attributes",
            component: <AttributePage />,
          },
        ],
      },
    ],
  },
  {
    labelKey: "clients_service",
    icon: <FaHome />,
    path: "/clients_service",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_CLIENT_SERVICE],
    component: <Home />,
    subMenus: [
      {
        labelKey: "orders",
        icon: <FaUserTie />,
        path: "/orders",
        permissions: [Permissions.CAN_VIEW_ORDER],
        component: <OrderPage />,
        crudRoutes: [
          {
            component: <OrderDetailesPage />,
            path: "details/:id",
          },
          {
            component: <OrderForm />,
            path: "update/:id",
          },
        ],
      },
      {
        labelKey: "orders_short",
        icon: <FaUserTie />,
        path: "/orders/short",
        permissions: [Permissions.CAN_VIEW_ALL_ORDERS_SHORT],
        component: <OrderShortPage />,
      },
      {
        labelKey: "complaints",
        icon: <FaUserTie />,
        path: "/complaint",
        permissions: [Permissions.CAN_VIEW_COMPLAINTS],
        component: <ComplaintPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <ComplaintForm />,
          },
          {
            component: <ComplaintForm />,
            path: "create",
          },
          {
            component: <ComplaintDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "public_surveillance",
        icon: <FaUserTie />,
        path: "/public-surveillance",
        //TODO:permissions
        permissions: [Permissions.CAN_TRACK_USERS],
        component: <PublicSurveillance />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <ComplaintForm />,
          },
          {
            component: <ComplaintForm />,
            path: "create",
          },
          {
            component: <ComplaintDetailesPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },
  {
    labelKey: "financial_statements",
    icon: <FaHome />,
    path: "/payment-delivery-worker",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_PADS],
    component: <Home />,
    subMenus: [
      {
        labelKey: "payment_delivery_worker",
        icon: <FaUserTie />,
        path: "/payment-delivery-worker",
        permissions: [Permissions.CAN_VIEW_PAYMENTS_MANAGEMENT],
        component: <PaymentDeliveryWorkerPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <PaymentDeliveryWorkerForm />,
          },
          {
            component: <PaymentDeliveryWorkerForm />,
            path: "create",
          },
          {
            component: <PaymentDeliveryWorkerDetailesPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },

  {
    labelKey: "financial_statements_genral",
    icon: <FaHome />,
    path: "/financial_statements_general",
    hasSubMenus: true,
    permissions: [Permissions.CAN_VIEW_PAYMENTS_MANAGEMENT],
    component: <Home />,
    subMenus: [
      {
        labelKey: "points",
        icon: <FaUserTie />,
        path: "/points",
        permissions: [Permissions.CAN_VIEW_COLLECTION_POINT],
        component: <PointPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <PointForm />,
          },
          {
            component: <PointForm />,
            path: "create",
          },
          {
            component: <PointDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "payment_point",
        icon: <FaUserTie />,
        path: "/payment-point",
        permissions: [Permissions.CAN_VIEW_PAYMENTS_MANAGEMENT],
        component: <PaymentPointPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <PaymentPointForm />,
          },
          {
            component: <PaymentPointForm />,
            path: "create",
          },
          {
            component: <PaymentPointDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "payment_delivery_worker",
        icon: <FaUserTie />,
        path: "/payment-delivery-worker",
        permissions: [Permissions.CAN_VIEW_PAYMENTS_MANAGEMENT],
        component: <PaymentDeliveryWorkerPage />,
        crudRoutes: [],
      },
    ],
  },
  {
    labelKey: "prize",
    icon: <FaHome />,
    path: "/award",
    hasSubMenus: false,
    permissions: [Permissions.CAN_VIEW_AWARD],
    component: <PrizePage />,
    crudRoutes: [
      {
        component: <PrizeForm />,
        path: "create",
      },
      {
        component: <PrizeDetailesPage />,
        path: "details/:id",
      },
    ],
  },

  {
    labelKey: "chat",
    icon: <FaRocketchat />,
    path: "/chat",
    hasSubMenus: false,
    permissions: [Permissions.CAN_VIEW_CHAT],

    component: <Chat />,
  },
];
