import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";

interface IProps {
  data: any;
}

const LoactionsClientTable: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const columns: ColumnProps<any>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
    },

    {
      title: t("street"),
      dataIndex: "street",
      align: "center",
      key: "street",
    },
    {
      title: t("construction"),
      dataIndex: "construction",
      align: "center",
      key: "construction",
    },
    {
      title: t("near"),
      dataIndex: "near",
      align: "center",
      key: "near",
    },
    {
      title: t("flat"),
      dataIndex: "flat",
      align: "center",
      key: "flat",
    },
  ];

  return (
    <>
      <Table<any>
        rowKey="id"
        columns={columns}
        dataSource={data ?? []}
        size="small"
      />
    </>
  );
};

export default LoactionsClientTable;
