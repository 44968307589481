import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import AttributeContext from "../../../context/attribute/context";
import {
  getAttributeType,
  getAttributeTypeColor,
} from "../../../models/attribute/enums";
import { IUpdateAttribute } from "../../../models/attribute/request";
import { IAttribute } from "../../../models/attribute/resopnse";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import { getTranslations } from "../../../utils/helpers/translations";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import FormHookModal from "../../general/modals/form-hook-modal";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import AttributeDetails from "../attribute-details";
import AttributeForm from "../attribute-form";
import { Divider, Modal, Spin } from "antd";

interface IProps {}

const AttributeTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { list, details, actions, loading, query } =
    useContext(AttributeContext);
  const { id } = useParams();

  const [infoId, setInfoId] = useState(undefined);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  useEffect(() => {
    infoId && actions.getDetails(Number(id), infoId);
  }, [infoId]);

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={
          loading.includes("create") ||
          loading.includes("details") ||
          loading.includes("update")
        }
        title={details ? t("update_attribute") : t("add_attribute")}
        hideModal={async () => {
          await hideFormodal();
        }}
        width={"60vw"}
        style={{
          marginTop: "1.6rem",
        }}
        onSubmit={async (data) => {
          details
            ? await actions.updateAttribute(Number(id), infoId, data)
            : await actions.createAttribute(infoId, data);
          setInfoId(undefined);
          actions.setDetails(null);
          actions.getData(Number(id));
        }}
        defaultValues={
          {
            isMultiple: details?.isMultiple ?? false,
            isRequired: details?.isRequired,
            name: getTranslations(details?.translations?.name),
            type: details?.type,
            order: details?.order,
          } as IUpdateAttribute
        }
      >
        <AttributeForm details={details} />
      </FormHookModal>
    ),
    [loading, infoId]
  );

  const columns: ColumnProps<IAttribute>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      // sorter: {
      //   multiple: 1,
      // },
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("order"),
      dataIndex: "order",
      key: "order",
      align: "center",
      render: (_, record) => {
        return record?.order ?? "-";
      },
    },
    {
      title: t("isMultiple"),
      dataIndex: "isMultiple",
      width: 200,
      align: "center",
      key: "isMultiple",
      render: (_, record) => {
        return (
          <>
            {record.isMultiple ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("isRequired"),
      dataIndex: "isRequired",
      width: 200,
      align: "center",
      key: "isRequired",
      render: (_, record) => {
        return (
          <>
            {record.isRequired ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (_, record) => {
        return (
          <Tag
            title={getAttributeType(record?.type)}
            color={getAttributeTypeColor(record?.type)}
          />
        );
      },
    },
    {
      title: t("actions"),
      width: 250,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              setInfoId(record?.id);
              setIsInfoModalVisible(true);
            }}
          />
          <EditBtn
            onClick={async () => {
              setInfoId(record?.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteAttribute(Number(id), record?.id);
              eventManager.emit(EVENT_SUCCESS);
              await actions?.getData(Number(id));
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
      <Modal
        destroyOnClose
        title={t("attribute_details")}
        open={isInfoModalVisible}
        onOk={() => {
          setInfoId(null);
          actions.setDetails(null);
          setIsInfoModalVisible(false);
        }}
        onCancel={() => {
          setInfoId(null);
          actions.setDetails(null);
          setIsInfoModalVisible(false);
        }}
        cancelText={t("cancel")}
        okText={t("ok")}
        centered={true}
        okButtonProps={{ style: { textAlign: "center" } }}
        bodyStyle={{
          minHeight: 200,
          maxHeight: "60vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        width={1000}
      >
        <Divider style={{ margin: "8px 0 8px 0" }} />
        <Spin spinning={loading.includes("details")}>
          <AttributeDetails attribute={details} />
        </Spin>
      </Modal>
    </>
  );
};

export default AttributeTable;
