import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import PageDetails from "../../general/details";
import moment from "moment";
import OldImage from "../../form-components/old-image";
import MedicalSspecialtiesContext from "../../../context/medical-specialties/context";
import MedicalSspecialtiesContextProvider from "../../../context/medical-specialties/provider";

const { Panel } = Collapse;
interface Props {}

const MedicalSspecialties: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(MedicalSspecialtiesContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("medical_specialties")}
        buttonName={`${t("update_medical_specialties")}`}
        onClick={() => navigate(`/medical-specialties/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("name")}>
                  {details?.name}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("created_at")}>
                  {details?.createdAt
                    ? moment(details?.createdAt).format("YYYY-MM-DD")
                    : "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("image")}>
                  <OldImage src={details?.image?.url} preview />
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>
            {/* <Col span={24}></Col> */}
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const MedicalSspecialtiesDetails = () => {
  return (
    <MedicalSspecialtiesContextProvider>
      <MedicalSspecialties />
    </MedicalSspecialtiesContextProvider>
  );
};
export default MedicalSspecialtiesDetails;
