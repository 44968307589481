import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../general/antd/descriptions";
import PageDetails from "../../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../../general/antd/spin";
import AppContext from "../../../../context/app/context";
import BannerContext from "../../../../context/banner/context";
import BannerContextProvider from "../../../../context/banner/provider";
import Tag from "../../../general/antd/tag";
import {
  getUserStatus,
  getUserStatusColor,
} from "../../../../models/user/enum";
import {
  getBannerType,
  getBannerTypeColor,
} from "../../../../models/banner/enum";
import OldImage from "../.././../form-components/old-image";

interface Props {}

const Banner: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(BannerContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("banner")}
        buttonName={`${t("update_banner")}`}
        onClick={() => navigate(`/banners-pharmaceutical/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("text")}>
              {details?.text ?? "-"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("link")}>
              {details?.link}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("status")}>
              {
                <Tag
                  title={getUserStatus(details?.status)}
                  color={getUserStatusColor(details?.status)}
                />
              }
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("rateable_type")}>
              {
                <Tag
                  title={getBannerType(details?.rateableType)}
                  color={getBannerTypeColor(details?.rateableType)}
                />
              }
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("rateableId")}>
              {<Tag title={details?.rateableId ?? "-"} />}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("description")}>
              {details?.description ?? "-"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("image")}>
              <OldImage src={details?.image?.url} preview />
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const BannersPharmaceuticalDetailesPage = () => {
  return (
    <BannerContextProvider>
      <Banner />
    </BannerContextProvider>
  );
};
export default BannersPharmaceuticalDetailesPage;
