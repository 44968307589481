import { Button, Image, List, Popconfirm, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { AttributeType } from "../../../models/attribute/enums";
import { IParam } from "../../../models/param/resopnse";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styles from "./style.module.scss";

interface IAttributeParamProps {
  attributeType: AttributeType;
  param: IParam;
  onEdit: () => void;
  onDelete: () => void;
}

const AttributeParam: React.FC<IAttributeParamProps> = ({
  attributeType,
  param,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <List.Item
        key={param?.id}
        actions={[
          <Button
            size="small"
            shape="circle"
            icon={<EditOutlined />}
            type="link"
            onClick={onEdit}
          />,

          <Popconfirm
            title={t("delete_confirm")}
            onConfirm={onDelete}
            onCancel={() => {}}
            okButtonProps={{ danger: true }}
            placement="bottomRight"
          >
            <Button
              size="small"
              // shape='circle'
              icon={<DeleteOutlined />}
              type="link"
              danger
            />
          </Popconfirm>,
        ]}
      >
        {attributeType == AttributeType.COLOR && (
          <div>
            <Tag color={param?.data?.hex}>{param?.data?.hex}</Tag>
          </div>
        )}

        {attributeType == AttributeType.IMAGE_DROP_DOWN && (
          <div className={styles.imageIcon}>
            <Image src={param?.data?.image?.url} />
          </div>
        )}
        <div>{`${t("id")}: ${param?.id}`}</div>
        <div>{`${t("name")}: ${param?.name}`}</div>
      </List.Item>
    </>
  );
};

export default AttributeParam;
