import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IParamQuery } from "../../models/param/query";
import { IParam, IParamDetails } from "../../models/param/resopnse";
import { ICreateParam, IUpdateParam } from "../../models/param/request";

export type ParamLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: ParamLoading[];

  list?: IBaseListingResponse<IParam>;
  query: IParamQuery;

  details?: IParamDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (attrId: number) => void;
    getDetails: (attrId: number, paramId: number) => void;
    setDetails: (data?: IParamDetails) => void;

    createParam: (attrId: number, request: ICreateParam) => void;
    updateParam: (
      attrId: number,
      paramId: number,
      request: IUpdateParam
    ) => void;
    deleteParam: (paramId: number, attrId: number) => void;
    // exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IParamQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    // exportExcel: DEFAULT_FUNCTION,

    createParam: DEFAULT_FUNCTION,
    updateParam: DEFAULT_FUNCTION,
    deleteParam: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ParamContext = createContext(externalState);

export default ParamContext;
