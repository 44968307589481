import { IDeletedUser } from "../../models/map/deletedUserModel";
import { IResponseTracking } from "../../models/map/response";
import {
  IConversation,
  IConversations,
} from "../../models/messages/conversations";
import { IMessage, IMessages } from "../../models/messages/messages";
import { IInternalState } from "./context";

type Action =
  | { type: "SET_USERS"; payload: { users: any[] } }
  | { type: "SET_ALL_USERS"; payload: { allUsers: any[] } }
  | {
      type: "SET_ALL_CONVERSATIONS";
      payload: { conversations: IConversations };
    }
  | {
      type: "SET_ALL_CONVERSATIONS_ID";
      payload: { id: string };
    }
  | {
      type: "SET_ALL_MESSAGES";
      payload: { messages: IMessages };
    }
  | {
      type: "SET_NEW_MESSAGES";
      payload: { newMessage: IMessage };
    }
  | {
      type: "SET_NEW_CONVERSATION";
      payload: { newConversation: IConversation };
    }
  | {
      type: "SET_DELETED_USER";
      payload: { deletedUser: IDeletedUser };
    }
  | {
      type: "SET_USER_CHANGED_LOCATION";
      payload: { userChangesLocations: IResponseTracking };
    };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_USERS": {
      return {
        ...state,
        users: action.payload.users,
      };
    }
    case "SET_ALL_USERS": {
      return {
        ...state,
        allUsers: action.payload.allUsers,
      };
    }
    case "SET_ALL_CONVERSATIONS": {
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    }
    case "SET_ALL_CONVERSATIONS_ID": {
      return {
        ...state,
        selectedConversationId: action.payload.id,
      };
    }
    case "SET_ALL_MESSAGES": {
      return {
        ...state,
        messages: action.payload.messages,
      };
    }
    case "SET_NEW_MESSAGES": {
      return {
        ...state,
        newMessage: action.payload.newMessage,
      };
    }
    case "SET_NEW_CONVERSATION": {
      return {
        ...state,
        newConversation: action.payload.newConversation,
      };
    }
    case "SET_DELETED_USER": {
      return {
        ...state,
        deletedUser: action.payload.deletedUser,
      };
    }
    case "SET_USER_CHANGED_LOCATION": {
      return {
        ...state,
        userChangesLocations: action.payload.userChangesLocations,
      };
    }
  }
};

export default reducer;
