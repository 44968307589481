import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IUnit, IUnitDetails } from "../../models/unit/response";
import { IUnitQuery } from "../../models/unit/query";
import { ICreateUnit, IUpdateUnit } from "../../models/unit/request";

export type UnitLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: UnitLoading[];

  list?: IBaseListingResponse<IUnit>;
  query: IUnitQuery;

  details?: IUnitDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IUnit) => void;

    createUnit: (request: ICreateUnit) => void;
    updateUnit: (id: number, request: IUpdateUnit) => void;
    deleteUnit: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IUnitQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createUnit: DEFAULT_FUNCTION,
    updateUnit: DEFAULT_FUNCTION,
    deleteUnit: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const UnitContext = createContext(externalState);

export default UnitContext;
