import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateOrderNote,
  IExportOrderNoteExcel,
  IUpdateOrderNote,
} from "../../models/order-note/request";
import {
  IOrderNote,
  IOrderNoteDetails,
} from "../../models/order-note/response";
import { IOrderNoteQuery } from "../../models/order-note/query";

class OrderNoteService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOrderNotes = (query: IOrderNoteQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IOrderNote>>({
      path: `/order-note`,
      method: "GET",
      query,
      ...params,
    });

  getOrderNote = (id: number, params?: RequestParams) =>
    this.http.request<IOrderNoteDetails>({
      path: `/order-note/${id}`,
      method: "GET",
      ...params,
    });

  createOrderNote = (data: ICreateOrderNote, params?: RequestParams) =>
    this.http.request<IOrderNote>({
      path: "/order-note",
      method: "POST",
      body: data,
      ...params,
    });

  updateOrderNote = (
    id: number,
    data: IUpdateOrderNote,
    params?: RequestParams
  ) =>
    this.http.request<IOrderNote>({
      path: `/order-note/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOrderNote = (id: number, params?: RequestParams) =>
    this.http.request<IOrderNote>({
      path: `/order-note/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportOrderNoteExcel, query: IOrderNoteQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/order-note/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default OrderNoteService;
