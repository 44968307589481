import { Col, Row, Select } from "antd";
import { useContext, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CategoryContext from "../../../context/category/context";
import { ICreateCategory } from "../../../models/category/request";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import GeneralForm from "../../form-components/general-form";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
const Option = Select.Option;

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading, allCategories } =
    useContext(CategoryContext);
  const { id } = useParams();
  const { control, setValue, getValues } = useForm();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const grandCategory = useWatch({
    control,
    name: "grandId",
  });

  useEffect(() => {
    details && setValue("grandId", details?.grand?.id);
  }, [details, grandCategory]);

  return (
    <GeneralForm
      values={[
        {
          key: "grandId",
          value: grandCategory ?? undefined,
        },
      ]}
      onSubmit={async (data) => {
        details
          ? await actions.updateCategory(details?.id, data)
          : await actions.createCategory(data);
        actions.setDetails(null);
      }}
      title={details ? t("update_category") : t("add_category")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          note: getTranslations(details?.translations?.note) ?? null,
          order: details?.order,
          parentId: details?.parent?.id ?? undefined,
          grandId: details?.grand?.id ?? undefined,
          imageId: details?.image?.id,
          mainImageId: details?.mainImage?.id,
        } as ICreateCategory
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}

            <Col span={12}>
              <FormItem label={t("grand")}>
                <Controller
                  name="grandId"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { ...field } }) => {
                    return (
                      <Select
                        loading={false}
                        {...field}
                        style={{ width: "100%" }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showSearch
                        allowClear
                        placeholder={t("grand")}
                        options={allCategories
                          ?.filter(
                            (cat) => cat?.parent === null && cat?.grand === null
                          )
                          .filter((cat) => cat?.id !== Number(id))
                          ?.map((tr, index) => {
                            return {
                              label: tr?.name,

                              value: tr?.id,
                            };
                          })}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>

            <Col span={12}>
              <FieldBuilder
                name="parentId"
                label={`${t("parent")}`}
                width="large"
                rules={{ required: false }}
                input={{
                  type: "select",
                  loading: false,
                  allowSearch: true,
                  options: allCategories
                    ?.filter((cat) => cat?.grand?.id === grandCategory)
                    .filter((cat) => cat?.id !== Number(id))
                    .filter((cat) => !cat?.parent)
                    ?.map((role, index) => {
                      return {
                        key: index,
                        label: role.name,
                        value: role.id,
                      };
                    }),
                }}
              />
            </Col>

            <Col span={12}>
              <FieldBuilder
                name="order"
                label={`${t("order")}`}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={12}></Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}

            <Col span={24}>
              <FormItem label={t("image")} required>
                <Controller
                  name="imageId"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details && <OldImage src={details?.image?.url} />}

            <Col span={24}>
              <FormItem label={t("main_image")}>
                <Controller
                  name="mainImageId"
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details && <OldImage src={details?.mainImage?.url} />}
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const CategoryForm = () => {
  return <Form />;
};
export default CategoryForm;
