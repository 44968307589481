export enum StorStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getStoreStatusColor = (status: StorStatus) => {
  switch (status) {
    case StorStatus.ACTIVE:
      return "green";
    case StorStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getStorStatus = (status: StorStatus) => {
  switch (status) {
    case StorStatus.ACTIVE:
      return "active";
    case StorStatus.INACTIVE:
      return "inactive";
    default:
      return "inactive";
  }
};
export const getStorWorkingStatus = (status: StorStatus) => {
  switch (status) {
    case StorStatus.ACTIVE:
      return "working";
    case StorStatus.INACTIVE:
      return "not_working";
    default:
      return "not_working";
  }
};

export enum StorType {
  PHARMACY = 1,
  STORE = 2,
}

export const getStoreTypeColor = (Type: StorType) => {
  switch (Type) {
    case StorType.PHARMACY:
      return "blue";
    case StorType.STORE:
      return "orange";
    default:
      return "green";
  }
};

// Type Boolean stuts
export const getStorType = (Type: StorType) => {
  switch (Type) {
    case StorType.PHARMACY:
      return "pharmacy";
    case StorType.STORE:
      return "type_store";
    default:
      return "type_store";
  }
};
// ...................................
export enum WorkStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getWorkStatusColor = (Type: WorkStatus) => {
  switch (Type) {
    case WorkStatus.ACTIVE:
      return "green";
    case WorkStatus.INACTIVE:
      return "red";
    default:
      return "green";
  }
};

export const getWorkStatus = (Type: WorkStatus) => {
  switch (Type) {
    case WorkStatus.ACTIVE:
      return "working_status";
    case WorkStatus.INACTIVE:
      return "closed";
    default:
      return "closed";
  }
};
