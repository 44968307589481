import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import moment from "moment";
import { IDoctor } from "../../../models/doctors/response";
import MedicalSspecialtiesContext from "../../../context/medical-specialties/context";

interface IProps {}

const MedicalSpecialtiesTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(MedicalSspecialtiesContext);

  const columns: ColumnProps<IDoctor>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      //width: 200,
      sorter: {
        multiple: 2,
      },
      align: "center",
      key: "name",
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      //width: 200,
      sorter: {
        multiple: 2,
      },
      align: "center",
      key: "createdAt",
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD");
      },
    },
    {
      title: t("actions"),
      width: 240,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteMedical(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
       
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default MedicalSpecialtiesTable;
