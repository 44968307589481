import moment from "moment";
import { useReducer } from "react";
import DeliveryWorkerContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { IDeliveryWorker } from "../../models/delivery-workers/response";
import {
  ICreateDeliveryWorker,
  IUpdateDeliveryWorker,
} from "../../models/delivery-workers/request";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import DeliveryWorkerHistoryContext from "./context";

export interface IProps {
  children: React.ReactNode;
}
const DeliveryWorkerHistoryContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.deliveryWorkers.getHistoryAssentOrder(
          id,
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDataNotAccept = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.deliveryWorkers.getHistory(
          id,
          {
            ...state.queryNotAccept,
            filters: [
              ...(state.queryNotAccept?.filters ?? []),

              {
                name: "status",
                value: 1,
                operation: "neq",
              },
            ],
          }
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDataAccept = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.deliveryWorkers.getHistory(
          id,
          {
            ...state.query,
            filters: [
              ...(state.query?.filters ?? []),

              {
                name: "status",
                value: 1,
                operation: "eq",
              },
            ],
          }
        );
        dispatch({ type: "SET_LIST_ACCEPT", payload: { listAccept: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const processedHistoryWorker = async (
    id: number,
    idDriver: number,
    status: number,
    orderAssent?: boolean
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "process" },
        });

        await EndPoints.deliveryWorkers.processHistory(id);
        orderAssent
          ? getData(idDriver)
          : status != 1
          ? getDataNotAccept(idDriver)
          : getDataAccept(idDriver);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "process" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDeliveryWorkerQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const setQueryNotAccept = (queryNotAccept: IDeliveryWorkerQuery) => {
    dispatch({ type: "SET_QUERY_NOT_ACCEPT", payload: { queryNotAccept } });
  };

  return (
    <DeliveryWorkerHistoryContext.Provider
      value={{
        ...state,
        actions: {
          getDataNotAccept,
          getDataAccept,
          getData,

          processedHistoryWorker,

          setSearch,
          setQuery,
          setQueryNotAccept
        },
      }}
    >
      {props.children}
    </DeliveryWorkerHistoryContext.Provider>
  );
};

export default DeliveryWorkerHistoryContextProvider;
