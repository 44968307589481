import {
  IChangePassword,
  ILoginRequest,
  IUpdateInfo,
} from "../../models/auth/request";
import { ILoginResponse } from "../../models/auth/response";
import { User } from "../../models/user/loginResponse";
import { IUser } from "../../models/user/response";
import { HttpClient, RequestParams } from "../../utils/api/http-client";

class AuthService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  login = (data: ILoginRequest, params?: RequestParams) =>
    this.http.request<ILoginResponse>({
      path: `/auth/login`,
      method: "POST",
      secure: false,
      body: data,
      ...params,
    });

  changePassword = (data: IChangePassword, params?: RequestParams) =>
    this.http.request<void>({
      path: `/auth/change-password`,
      method: "POST",
      body: data,
      ...params,
    });
  updateProfile = (data: IUpdateInfo, params?: RequestParams) =>
    this.http.request<void>({
      path: `/auth/change-username`,
      method: "POST",
      body: data,
      ...params,
    });

  logout = (params?: RequestParams) =>
    this.http.request<void>({
      path: `/auth/logout`,
      method: "POST",
      ...params,
    });

  me = (params?: RequestParams) =>
    this.http.request<{user:User}>({
      path: `/auth/me`,
      method: "GET",
      ...params,
    });
}

export default AuthService;
