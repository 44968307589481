export enum For {
    CLIENT = 1,
    INVESTOR = 2,
    DRIVER = 3,
}

export const getForColor = (forType: For) => {
    switch (forType) {
        case For.CLIENT:
            return "red"
        case For.INVESTOR:
            return "orange";
        case For.DRIVER:
            return "green";
        default:
            return "red";
    }
};

// Status Boolean stuts
export const getFor = (forType: For) => {
    switch (forType) {
        case For.CLIENT:
            return "client";
        case For.INVESTOR:
            return "investor";
        case For.DRIVER:
            return "driver";
        default:
            return "other";
    }
};