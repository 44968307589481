import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateProduct,
  IExportProductExcel,
  IImportExcelProductStore,
  IManageProductStore,
  IUpdateProduct,
} from "../../models/product/request";
import { IProduct, IProductDetails } from "../../models/product/response";
import { IProductQuery } from "../../models/product/query";

class ProductService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllProducts = (query: IProductQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IProduct>>({
      path: `/product`,
      method: "GET",
      query,
      ...params,
    });

  getProduct = (id: number, params?: RequestParams) =>
    this.http.request<IProductDetails>({
      path: `/product/${id}`,
      method: "GET",
      ...params,
    });

  createProduct = (data: ICreateProduct, params?: RequestParams) =>
    this.http.request<IProduct>({
      path: "/product",
      method: "POST",
      body: data,
      ...params,
    });

  manageProductStore = (data: IManageProductStore, params?: RequestParams) =>
    this.http.request<IProduct>({
      path: "/product/manage",
      method: "POST",
      body: data,
      ...params,
    });
  importFromExcelProductStore = (
    data: IImportExcelProductStore,
    params?: RequestParams
  ) =>
    this.http.request<IProduct>({
      path: "/product/import-in-store",
      method: "POST",
      body: data,
      ...params,
    });

  updateProduct = (id: number, data: IUpdateProduct, params?: RequestParams) =>
    this.http.request<IProduct>({
      path: `/product/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteProduct = (id: number, params?: RequestParams) =>
    this.http.request<IProduct>({
      path: `/product/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportProductExcel, query: IProductQuery) =>
    this.http.request<IProduct>({
      path: `/product/export`,
      method: "POST",
      body: data,
    });

  circulateProduct = (id: number, params?: RequestParams) =>
    this.http.request<IProduct>({
      path: `/product/circulate-price/${id}`,
      method: "PUT",
      ...params,
    });
}

export default ProductService;
