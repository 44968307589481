import React from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import styles from "./style.module.scss";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const CheckboxAttribute: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  return (
    <div>
      {attributeWithValue?.values[0] === "true" ? (
        <CheckOutlined style={{ color: "green" }} />
      ) : (
        <CloseOutlined style={{ color: "red" }} />
      )}
    </div>
  );
};

export default CheckboxAttribute;
