import { Row, Col } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { IUser } from "../../../models/user/response";
import EndPoints from "../../../services/end-points";
import PaymentPointContext from "../../../context/payment-point/context";
import { IUpdatePaymentPoint } from "../../../models/payment-point/request";
import { IPoint } from "../../../models/point/response";
import PaymentPointContextProvider from "../../../context/payment-point/provider";
import AuthContext from "../../../context/auth/context";

const Form = () => {
    const { t } = useTranslation();
    const { details, actions, loading } = useContext(PaymentPointContext);
    const { id } = useParams();

    const [employ, setEmploy] = useState<IUser[]>([]);
    const [point, setPoint] = useState<IPoint[]>([]);

    const [employLoading, setEmployLoading] = useState(true);
    const [pointLoading, setPointLoading] = useState(true);
    const { userDetails: user } = useContext(AuthContext);

    const getEmploy = async () => {
        try {
            const { data } = await EndPoints.user.getAllUsers({
                light: true,
                perPage: -1,
            });
            setEmploy(data.data);
            setEmployLoading(false);
        } catch (error) {
        } finally {
            setEmployLoading(false);
        }
    };

    const getPoint = async () => {
        try {
            const { data } = await EndPoints.point.getAllPoints({
                light: true,
                perPage: -1,
            });
            setPoint(data.data);
            setPointLoading(false);
        } catch (error) {
        } finally {
            setPointLoading(false);
        }
    };

    useEffect(() => {
        getEmploy();
        getPoint();
    }, []);

    useEffect(() => {
        const getDetails = async () => {
            id && (await actions.getDetails(Number(id)));
        };
        getDetails();
    }, []);
    return (
        <MainForm
            onSubmit={(data) => {
                data.employId = user?.employee?.id
                details
                    ? actions.updatePaymentPoint(details?.id, data)
                    : actions.createPaymentPoint(data);
            }}
            title={details ? t("update_payment_point") : t("add_payment_point")}
            defaultValues={
                {
                    receiptNumber: details?.receiptNumber,
                    employId: user?.employee?.id,
                    value: details?.value,
                    collectionPointId: details?.collectionPoint?.id,
                    note: details?.note
                } as IUpdatePaymentPoint
            }
        >
            {loading.includes("details") ? (
                <div style={{ height: "50vh" }}>
                    <Spin />
                </div>
            ) : (
                <>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <FieldBuilder
                                name="collectionPointId"
                                label={`${t("point")}`}
                                width="large"
                                rules={{ required: true }}
                                input={{
                                    type: "select",
                                    loading: pointLoading,
                                    allowSearch: true,
                                    options: point?.map((point, index) => {
                                        return {
                                            key: index,
                                            label: point.name,
                                            value: point.id,
                                        };
                                    }),
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <FieldBuilder
                                label={`${t("value")}`}
                                name="value"
                                input={{ type: "number" }}
                                rules={{ required: true }}
                            />
                        </Col>
                        <Col span={12}>
                            <FieldBuilder
                                label={`${t("receipt_number")}`}
                                name="receiptNumber"
                                input={{ type: "number" }}
                                rules={{ required: true }}
                            />
                        </Col>
                        <Col span={24}>
                            <FieldBuilder
                                name={'note'}
                                label={`${t("note")}`}
                                input={{ type: "text-area" }}
                                rules={{ required: false }}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </MainForm>
    );
};

const PaymentPointForm = () => {
    return (
        <PaymentPointContextProvider>
            <Form />
        </PaymentPointContextProvider>
    );
};
export default PaymentPointForm;
