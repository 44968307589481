import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IDeliveryWorker,
  IDeliveryWorkerDetails,
} from "../../models/delivery-workers/response";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import {
  ICreateDeliveryWorker,
  IUpdateDeliveryWorker,
} from "../../models/delivery-workers/request";

export type deliveryWorkerLoading = "list" | "edit" | "process";

export interface IInternalState {
  loading: deliveryWorkerLoading[];

  list?: IBaseListingResponse<History>;
  listAccept?: IBaseListingResponse<History>;

  query: IDeliveryWorkerQuery;
  queryNotAccept: IDeliveryWorkerQuery;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
  queryNotAccept: DEFAULT_QUERY
};

export interface IExternalState extends IInternalState {
  actions: {
    getDataNotAccept: (id: number) => void;
    getDataAccept: (id: number) => void;
    getData: (id: number) => void;

    processedHistoryWorker: (
      id: number,
      idDriver: number,
      status?: number,
      orderAssent?: boolean
    ) => void;
    setSearch: (search?: string) => void;
    setQuery: (query: IDeliveryWorkerQuery) => void;
    setQueryNotAccept: (query: IDeliveryWorkerQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getDataNotAccept: DEFAULT_FUNCTION,
    getDataAccept: DEFAULT_FUNCTION,
    getData: DEFAULT_FUNCTION,

    processedHistoryWorker: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
    setQueryNotAccept: DEFAULT_FUNCTION
  },
};

const DeliveryWorkerHistoryContext = createContext(externalState);

export default DeliveryWorkerHistoryContext;
