import { ConfigProvider, Empty, theme } from "antd";
import React, { useEffect, useReducer, useRef } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import ar from "../../utils/antd/ar-locale";
import en from "../../utils/antd/en-locale";
import i18n from "../../utils/localization/i18n";
import ScreenSize from "../../utils/ui/screen-size";
import AppContext, { AppData, Themes, internalState } from "./context";
import reducer from "./reducer";

import { IconContext } from "react-icons";
import { ModalProvider } from "react-modal-hook";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import eventManager, {
  EVENT_ERORR,
  EVENT_FORBIDDEN,
  EVENT_SUCCESS,
  EVENT_UNAOUTHORIZED,
} from "../../utils/events";
import {
  errorNotification,
  successNotification,
} from "../../utils/helpers/notification";

interface IProps {
  children: React.ReactNode;
}
const AppContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const effectCalled = useRef(false);
  // const { actions } = useContext(CategoryContext);
  // useEffect(() => {
  //   actions.getAllData();
  // }, [state?.direction]);

  /**
   * Events
   */
  const navigate = useNavigate();

  useEffect(() => {
    // default or light theme
    if (state.theme === "light") {
      document.documentElement.setAttribute("data-theme", "light");
    } else {
      document.documentElement.setAttribute("data-theme", "dark");
    }
  }, [state.theme]);

  useEffect(() => {
    if (!effectCalled.current) {
      effectCalled.current = true;
      eventManager.on(EVENT_SUCCESS, (message?: string) => {
        successNotification(message ?? t("operationDoneSuccessfully"));
      });
      eventManager.on(EVENT_ERORR, (message) => {
        errorNotification(message);
      });
      eventManager.on(EVENT_UNAOUTHORIZED, () => {
        navigate("/login", { replace: true });
      });
      eventManager.on(EVENT_FORBIDDEN, () => {
        // navigate("/403", { replace: true });
      });
    }
  }, []);

  /**
   * Langauge
   */
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const direction = language === "ar" ? "rtl" : "ltr";
    dispatch({ type: "SET_DIRECTION", payload: { direction } });
    const locale = language === "ar" ? ar : en;
    dispatch({ type: "SET_LOCALE", payload: { locale } });
  }, [language]);

  /**
   * Media queries
   */
  const isLaptopOrDesktop = useMediaQuery({
    minWidth: 992,
  });
  const isMobileOrTablet = useMediaQuery({
    maxWidth: 992,
  });
  useEffect(() => {
    let screenSize: ScreenSize;

    if (isLaptopOrDesktop) screenSize = "laptopOrDesktop";
    else screenSize = "mobileOrTablet";

    dispatch({
      type: "SET_SCREEN_SIZE",
      payload: { screenSize },
    });
  }, [isLaptopOrDesktop, isMobileOrTablet]);

  // Get all Roles
  // const getRoles = async (params: GetAllRolesLightParams = {}) => {
  //   await execute({
  //     callback: async () => {
  //       dispatch({ type: 'LOADING', payload: { loading: 'roles' } })

  //       const { data } = await EndPoints.role.getAllRolesLight(params)

  //       dispatch({ type: 'SET_ROLES', payload: { roles: data } })
  //     },
  //     fallback: (error) => {},
  //     finallyCallback: () => {
  //       dispatch({ type: 'LOADING', payload: { loading: 'roles' } })
  //     },
  //     throwException: false,
  //   })
  // }

  // Get all Permissions
  const getPermissions = async () => {
    // await execute({
    //   callback: async () => {
    //     dispatch({ type: 'LOADING', payload: { loading: 'permissions' } })
    //     const { data } = await EndPoints.role.getAllPermissions()
    //     dispatch({ type: 'SET_PERMISSIONS', payload: { permissions: data } })
    //   },
    //   fallback: (error) => {},
    //   finallyCallback: () => {
    //     dispatch({ type: 'LOADING', payload: { loading: 'permissions' } })
    //   },
    //   throwException: false,
    // })
  };
  const changeTheme = (theme: Themes) => {
    dispatch({ type: "SET_THEME", payload: { theme } });
  };

  // Update App data
  const updateAppData = (update: AppData[]) => {
    update.forEach((data) => {
      switch (data) {
        case "languages":
          // getLanguages()
          break;
        case "roles":
          // getRoles()
          break;
        case "permissions":
          getPermissions();
          break;
        default:
          break;
      }
    });
  };

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        locale={state.locale}
        direction={state.direction}
        theme={{
          components: {
            Layout: {
              colorBgBody: state.theme === "light" ? "#F5F5F5" : "#1E2528",
            },
            Menu: {
              colorItemTextSelected:
                state.theme === "light" ? "#3458A4" : "white",
            },

            Table: {},
            Notification: {
              colorErrorBg: state.theme === "light" ? "green" : "blue",
            },
          },

          token: {
            colorPrimary: "#3458A4",
            colorBgBase: state.theme === "light" ? "white" : "#1e2528",
            controlOutlineWidth: 0,
            fontFamily: "Cairo",
          },

          algorithm:
            state.theme === "light"
              ? theme.defaultAlgorithm
              : theme.darkAlgorithm,
        }}
        renderEmpty={() => (
          <Empty
            image={
              state.theme === "light"
                ? Empty.PRESENTED_IMAGE_DEFAULT
                : Empty.PRESENTED_IMAGE_SIMPLE
            }
          />
        )}
      >
        <IconContext.Provider value={{ className: "react-icons-class" }}>
          <ModalProvider>
            <AppContext.Provider
              value={{
                ...state,
                actions: {
                  //  getRoles,
                  changeTheme,
                  getPermissions,
                  updateAppData,
                },
              }}
            >
              {props.children}
            </AppContext.Provider>
          </ModalProvider>
        </IconContext.Provider>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default AppContextProvider;
