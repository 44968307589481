import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import DeliveryWorkerHistoryContext from "../../../context/delivery-worker-history/context";
import { History } from "../../../models/delivery-workers/response";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import Space from "../../general/antd/space";
import { ImCheckmark, ImCross } from "react-icons/im";
import { CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Button, { ButtonProps } from "../../../components/general/antd/button";
import {
  getStatusDeliveryHistoryColor,
  getStatusDeliveryHistoryWorker,
} from "../../../models/delivery-workers/enum";

interface IProps {
  status?: number;
  orderAssent?: boolean;
}

const WorkerHistoryTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, list, query, queryNotAccept, listAccept } =
    useContext(DeliveryWorkerHistoryContext);
  const { id } = useParams();
  const { direction } = useContext(AppContext);

  useEffect(() => {
    id && props?.orderAssent
      ? actions.getData(Number(id))
      : props.status != 1
      ? actions.getDataNotAccept(Number(id))
      : actions.getDataAccept(Number(id));
  }, [query, queryNotAccept, direction, id, props.status, props?.orderAssent]);

  const columns: ColumnProps<any>[] = [
    {
      title: t("id_order"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.order?.id}</>;
      },
    },
    {
      title: t("id"),
      dataIndex: "driver",
      key: "driver",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.id}</>;
      },
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.driver?.firstName + "  " + recod?.driver?.lastName}</>;
      },
    },
    {
      title: t("mobile"),
      dataIndex: "driver",
      key: "driver",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.driver?.cleanMobile}</>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, recod) => {
        return (
          <Tag
            title={t(getStatusDeliveryHistoryWorker(recod?.status)) ?? "-"}
            color={getStatusDeliveryHistoryColor(recod?.status)}
          />
        );
      },
    },

    {
      title: t("been_processed"),
      dataIndex: "beenProcessed",
      key: "beenProcessed",
      align: "center",
      render: (beenProcessed) => {
        return (
          <>
            {beenProcessed ? (
              <Tag title={t("processed")} color="green" />
            ) : (
              <Tag title={t("not_processed")} color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("created_at_time"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: t("note"),
      dataIndex: "note",
      key: "note",
      align: "center",
    },
    {
      title: t("actions"),
      width: 100,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <Tooltip title={t("process")}>
            <Button
              disabled={record?.status === 1 || record?.beenProcessed}
              type="primary"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={async () => {
                await actions.processedHistoryWorker(
                  record?.id,
                  Number(id),
                  props.status,
                  props?.orderAssent ?? false
                );
                eventManager.emit(EVENT_SUCCESS);
              }}
              loading={loading.includes("process")}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    id && props?.orderAssent
      ? actions.getData(Number(id))
      : props.status != 1
      ? actions.getDataNotAccept(Number(id))
      : actions.getDataAccept(Number(id));
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
    sorts.length > 0 &&
      actions.setQueryNotAccept({
        ...queryNotAccept,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={
          !props?.orderAssent
            ? columns.filter((el) => el.key != "driver")
            : columns.filter((el) => el.dataIndex != "id" && el.key != "x")
        }
        dataSource={props.status != 1 ? list?.data : listAccept?.data}
        loading={loading.includes("list")}
        size="small"
        total={!props.status ? list?.total : listAccept?.total}
        pageSize={!props.status ? queryNotAccept.perPage : query.perPage}
        page={!props.status ? queryNotAccept.page : query.page}
        onPaginationChange={(page, pageSize) => {
          !props.status
            ? actions.setQueryNotAccept({
                ...queryNotAccept,
                page,
                perPage: pageSize,
              })
            : actions.setQuery({
                ...query,
                page,
                perPage: pageSize,
              });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default WorkerHistoryTable;
