import { Col, Row } from "antd";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { useState } from "react";
import EndPoints from "../../../services/end-points";
import { getAreaStatus } from "../../../models/area/enum";
import {
  MAP_DEFAULT_LOCATION,
  languages,
} from "../../../utils/helpers/constants";
import { getStatusDeliveryWorker } from "../../../models/delivery-workers/enum";
import defaultImage from "../../../assets/images/empty_1.png";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
import { getTranslations } from "../../../utils/helpers/translations";
import { getUserStatus } from "../../../models/user/enum";
import DoctorContext from "../../../context/doctor/context";
import { IUpdateDoctor } from "../../../models/doctors/request";
import DoctorContextProvider from "../../../context/doctor/provider";
import Marker from "../../general/map/marker";
import { Control, UseFormReturn, useForm, useWatch } from "react-hook-form";
import { IMedicalSpecialties } from "../../../models/medical-specialties/response";
import { weekDays } from "../../../models/doctors/enum";
import moment from "moment";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(DoctorContext);
  const { id } = useParams();
  const workerStatus = [1, 2, 3];
  const { direction } = useContext(AppContext);
  const [cities, setCities] = useState<ICity[]>([]);
  const [medicalSpecialties, setMedicalSpecialties] = useState<
    IMedicalSpecialties[]
  >([]);
  const [citiesLoading, setCitiesLoading] = useState(true);
  const status = [1, 2];
  const [image, setImage] = useState(defaultImage);
  const formMethod = useRef<UseFormReturn<any, any>>();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // get all cities from api
  useEffect(() => {
    const getCities = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getCities();
    const getDoctorSpecialty = async () => {
      try {
        const { data } =
          await EndPoints.medicalSspecialties.getAllMedicalSspecialties({
            light: true,
            perPage: -1,
          });
        setMedicalSpecialties(data?.data);
      } catch (error) {
      } finally {
      }
    };
    getDoctorSpecialty();
  }, [direction]);

  return (
    <MainForm
      methods={formMethod}
      onSubmit={async (data) => {
        details
          ? await actions.updateDoctor(details?.id, {
              ...data,
              imageId: data?.imageId?.id,
              offDays: data?.offDays ? `${data?.offDays}` :null,
              startWorkingTime: moment(
                "2024-01-18T" + data?.startWorkingTime + ":00.000000"
              )
                .subtract(3, "hours")
                .format("HH:mm"),
              endWorkingTime: moment(
                "2024-01-18T" + data?.endWorkingTime + ":00.000000"
              )
                .subtract(3, "hours")
                .format("HH:mm"),
              location: {
                type: "Point",
                coordinates: data?.map
                  ? [data?.map?.lng, data?.map?.lat]
                  : [
                      details?.location?.coordinates[0],
                      details?.location?.coordinates[1],
                    ],
              },
            })
          : await actions.createDoctor({
              ...data,
              imageId: data?.imageId?.id,
              offDays:  data?.offDays ? `${data?.offDays}` :null,
              startWorkingTime: moment(
                "2024-01-18T" + data?.startWorkingTime + ":00.000000"
              )
                .subtract(3, "hours")
                .format("HH:mm"),
              endWorkingTime: moment(
                "2024-01-18T" + data?.endWorkingTime + ":00.000000"
              )
                .subtract(3, "hours")
                .format("HH:mm"),
              location: {
                type: "Point",
                coordinates: data?.map
                  ? [data?.map?.lng, data?.map?.lat]
                  : [MAP_DEFAULT_LOCATION.lng, MAP_DEFAULT_LOCATION.lat],
              },
            });
      }}
      title={details ? t("update_doctor") : t("add_doctor")}
      defaultValues={
        //@ts-ignore
        {
          address: details?.address,
          birthDate: details?.birthDate,
          cityId: details?.city?.id,
          email: details?.user?.email,
          firstName: details?.user?.firstName,
          imageId: details?.user?.image?.id ?? undefined,
          lastName: details?.user?.lastName,
          mobile: details?.user?.cleanMobile,
          note: getTranslations(details?.user?.translations?.note) ?? null,
          phone: details?.user?.phone,
          apartmentNo: details?.apartmentNo,
          building: details?.building,
          medicalSpecialtyId: details?.medicalSpecialty?.id,
          moreDetails: details?.moreDetails,
          near: details?.near,
          //@ts-ignore
          offDays: details?.offDays,
          startWorkingTime: details?.startWorkingTime
            ? moment(
                "2024-01-18T" + details?.startWorkingTime + ":00.000000Z"
              ).format("HH:mm")
            : "",
          endWorkingTime: details?.endWorkingTime
            ? moment(
                "2024-01-18T" + details?.endWorkingTime + ":00.000000Z"
              ).format("HH:mm")
            : "",
          workingStatus: details?.workingStatus,
          status: details?.user?.status,
          street: details?.street,

          location: details?.location ?? {
            type: "Point",
            coordinates: [1, 2],
          },
        } as IUpdateDoctor
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="firstName"
                label={t("first_name")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="lastName"
                label={t("last_name")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="birthDate"
                label={`${t("birth_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mobile"
                label={t("mobile")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="email"
                label={t("email")}
                input={{ type: "email" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("start_working_time")}`}
                name="startWorkingTime"
                rules={{
                  required: true,
                }}
                input={{ type: "time-picker", format: "HH:mm" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("end_working_time")}`}
                name="endWorkingTime"
                rules={{
                  required: true,
                }}
                input={{ type: "time-picker", format: "HH:mm" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="offDays"
                label={t("off_days")}
                input={{ type: "multiSelect", options: weekDays }}
               
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="street"
                label={t("street")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="building"
                label={t("building")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="apartmentNo"
                label={t("apartment_no")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="near"
                label={t("near")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="moreDetails"
                label={t("more_details")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="address"
                label={t("address")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="phone"
                label={t("phone")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="cityId"
                label={`${t("city")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: citiesLoading,
                  allowSearch: true,
                  options: cities?.map((role, index) => {
                    return {
                      key: index,
                      label: role.name,
                      value: role.id,
                    };
                  }),
                }}
              />
            </Col>{" "}
            <Col span={8}>
              <FieldBuilder
                name="medicalSpecialtyId"
                label={`${t("medical_specialty")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: citiesLoading,
                  allowSearch: true,
                  options: medicalSpecialties?.map((role, index) => {
                    return {
                      key: index,
                      label: role.name,
                      value: role.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="workingStatus"
                label={`${t("worker_status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: workerStatus?.map((state, index) => {
                    return {
                      label: t(getStatusDeliveryWorker(state)),
                      value: state,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={`${t("status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: status?.map((state, index) => {
                    return {
                      key: index,
                      label: t(getUserStatus(state)),
                      value: state,
                    };
                  }),
                }}
              />
            </Col>{" "}
            <Col span={8}></Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
            <Col span={24}>
              <FormItem label={t("image")}>
                <Controller
                  name="imageId"
                  rules={{
                    required: {
                      value: false,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details?.user?.image && (
              <OldImage src={details?.user?.image?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("map")}>
                <Controller
                  name="map"
                  control={formMethod?.current?.control}
                  rules={{ required: false }}
                  render={({ field: { ...field } }) => {
                    return (
                      <Marker
                        name={
                          details?.user?.firstName
                            ? details?.user?.firstName +
                              " " +
                              details?.user?.lastName
                            : t("location")
                        }
                        draggable={true}
                        onChange={field?.onChange}
                        location={details?.location}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const DoctorForm = () => {
  return (
    <DoctorContextProvider>
      <Form />
    </DoctorContextProvider>
  );
};
export default DoctorForm;
