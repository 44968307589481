import { useContext, useEffect, useReducer, useState } from "react";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import TrackingContext, { internalState } from "./context";
import reducer from "./reducer";
import io, { Socket } from "socket.io-client";
import AuthContext from "../auth/context";
import { IResponseTracking } from "../../models/map/response";
import axios from "axios";
import {
  IConversation,
  IConversations,
} from "../../models/messages/conversations";
import { IMessage, IMessages } from "../../models/messages/messages";
import { execute } from "../../utils/helpers/execute";
import { useLocation } from "react-router-dom";
import eventManager, { EVENT_SUCCESS } from "../../utils/events";
import { useTranslation } from "react-i18next";
import { IDeletedUser } from "../../models/map/deletedUserModel";

export interface IProps {
  children: React.ReactNode;
}

const TrackingContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { userDetails: user } = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation();
  // socket test
  const [socket, setSocket] = useState<Socket>();
  const [socketMessages, setMessagesSocket] = useState<Socket>();

  useEffect(() => {
    if (state?.newMessage) {
      let allMessages = [];
      allMessages = state.messages?.data ?? [];
      allMessages.unshift(state?.newMessage);
      let newData: IMessages = {
        total: 1,
        data: allMessages ?? [state?.newMessage],
      };
      newData &&
        dispatch({
          type: "SET_ALL_MESSAGES",
          payload: {
            messages: newData,
          },
        });
      dispatch({
        type: "SET_NEW_MESSAGES",
        payload: {
          newMessage: undefined,
        },
      });
    }
  }, [state?.newMessage]);
  useEffect(() => {
    if (state?.newConversation) {
      if (location.pathname !== "/chat")
        eventManager.emit(EVENT_SUCCESS, t("new_message"));
      let allConversations: IConversation[] = [];
      allConversations = state.conversations?.data ?? [];
      allConversations = allConversations.filter(
        (c) => c.id !== state?.newConversation?.id
      );
      allConversations.unshift(state?.newConversation);

      dispatch({
        type: "SET_ALL_CONVERSATIONS",
        payload: {
          conversations: { ...state.conversations, data: allConversations },
        },
      });
      dispatch({
        type: "SET_NEW_CONVERSATION",
        payload: {
          newConversation: undefined,
        },
      });
    }
  }, [state?.newConversation]);

  // setting to connent socket

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token || token == "undefined") {
      socketMessages?.disconnect();
      setMessagesSocket(undefined);
      return;
    }

    const ioSocket = io(`${process.env.REACT_APP_PUBLIC_SOCKET_SERVER_URL}`, {
      auth: {
        token: token,
      },
      
      transports: ["websocket"],
    });
    setMessagesSocket(ioSocket);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token || token == "undefined") {
      socket?.disconnect();
      setSocket(undefined);
      return;
    }

    if (!user) {
      socket?.disconnect();
      setSocket(undefined);
      return;
    }
    const ioSocket = io(`${process.env.REACT_APP_PUBLIC_SOCKET_SERVER_URL}`, {
      auth: {
        token: token,
      },
      transports: ["websocket"],
    });
    setSocket(ioSocket);
  }, [user]);

  useEffect(() => {
    if (!socket) return;
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("user_went_offline", async (e) => {
      console.log(e);
      let deletedUser: IDeletedUser = await JSON.parse(e);
      setDeletedUser(deletedUser);
    });
  }, [socket]);

  useEffect(() => {
    if (!socketMessages) return;
    if (state?.selectedConversationId)
      socketMessages.emit(
        "subscribe_to_conversation_messages_updates",
        { conversation_id: state?.selectedConversationId },
        (arg1, arg2, arg3) => {
          console.log("arg1", arg1);
          console.log("arg2", arg2);
          console.log("arg3", arg3);
        }
      );
  }, [state?.selectedConversationId]);
  useEffect(() => {
    if (!socketMessages) return;

    socketMessages.on("message", async (e) => {
      console.log(e);
      let newMessage: IMessage = await JSON.parse(e);
      dispatch({
        type: "SET_NEW_MESSAGES",
        payload: { newMessage: newMessage },
      });
    });

    socketMessages.on("conversation_updated", async (e) => {
      console.log(e);
      let newConversation: IConversation = await JSON.parse(e);
      dispatch({
        type: "SET_NEW_CONVERSATION",
        payload: { newConversation: newConversation },
      });
    });

    socketMessages.on("connect", onConnect);
    socketMessages.on("disconnect", onDisconnect);

    socketMessages.emit("subscribe_to_conversations_updates");
  }, [socketMessages]);

  useEffect(() => {
    if (!socket) return;
    socket.emit(
      "subscribe_to_user_updates",
      state?.users,
      (arg1, arg2, arg3) => {}
    );
    socket.on("user_location_updated", onUserUpdateLoction);
  }, [socket, state?.users]);

  // functions socket
  const onUserUpdateLoction = (e: any) => {
    // @ts-ignore
    const a: IResponseTrackings = JSON.parse(e);

    dispatch({
      type: "SET_USER_CHANGED_LOCATION",
      payload: { userChangesLocations: a },
    });
  };
  const onConnect = () => {
    console.log("Socket connected successfully");
  };

  const onDisconnect = () => {
    console.log("onDisconnect");
  };

  const unSubscribeFromUserUpdates = (data: any[]) => {
    if (!socket) return;
    console.log("unSubscribeFromUserUpdates 11111", data);

    socket.emit("unsubscribe_from_user_updates", data, (arg1, arg2, arg3) => {
      console.log("arg1 unsubscribe_from_user_updates ", arg1);
      console.log("arg2  unsubscribe_from_user_updates", arg2);
      console.log("arg3 unsubscribe_from_user_updates", arg3);
    });
  };
  const getConversations = async () => {
    await execute({
      callback: async () => {
        // dispatch({ type: "LOADING", payload: { loading: "list" } });
        const { data } = await axios.get<IConversations>(
          `${process.env.REACT_APP_BASE_API_URL_ORION}/conversations`,
          {
            headers: {
              authorization: `Bearer ${
                localStorage.getItem(ACCESS_TOKEN) ||
                sessionStorage.getItem(ACCESS_TOKEN)
              }`,
              "Content-Type": "application/json",
            },
          }
        );
        await dispatch({
          type: "SET_ALL_CONVERSATIONS",
          payload: { conversations: data },
        });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };
  const getMessages = async (conversationId: string) => {
    await execute({
      callback: async () => {
        // dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await axios.get<IMessages>(
          `${process.env.REACT_APP_BASE_API_URL_ORION}/conversations/${conversationId}/messages`,
          {
            headers: {
              authorization: `Bearer ${
                localStorage.getItem(ACCESS_TOKEN) ||
                sessionStorage.getItem(ACCESS_TOKEN)
              }`,
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: "SET_ALL_MESSAGES",
          payload: { messages: data },
        });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };
  const setSelectedConversationId = async (conversationId: string) => {
    if (state?.selectedConversationId)
      await socketMessages.emit(
        "unsubscribe_from_conversation_messages_updates",
        { conversation_id: state?.selectedConversationId },
        (arg1, arg2, arg3) => {
          console.log("arg1", arg1);
          console.log("arg2", arg2);
          console.log("arg3", arg3);
        }
      );
    await dispatch({
      type: "SET_ALL_CONVERSATIONS_ID",
      payload: { id: conversationId },
    });
  };
  const sendMessage = async (message: string) => {
    console.log(socketMessages);

    if (!socketMessages) return;
    if (state?.conversations)
      socketMessages.emit(
        "message",
        { msg: message, msg_type: "text", to: state?.selectedConversationId },
        (arg1, arg2, arg3) => {
          console.log("message", arg1);
          console.log("message", arg2);
          console.log("message", arg3);
        }
      );
  };
  const setUsers = async (data: any[]) => {
    await dispatch({ type: "SET_USERS", payload: { users: data } });
  };
  const setDeletedUser = async (data: IDeletedUser) => {
    await dispatch({
      type: "SET_DELETED_USER",
      payload: { deletedUser: data },
    });
  };

  const setAllUsers = async (data: any[]) => {
    await dispatch({ type: "SET_ALL_USERS", payload: { allUsers: data } });
  };
  useEffect(() => {
    return () => {
      socket?.disconnect();
      socketMessages?.disconnect();
    };
  }, []);
  return (
    <TrackingContext.Provider
      value={{
        ...state,
        actions: {
          setUsers,
          getConversations,
          setSelectedConversationId,
          getMessages,
          unSubscribeFromUserUpdates,
          sendMessage,
          setAllUsers,
        },
      }}
    >
      {props.children}
    </TrackingContext.Provider>
  );
};

export default TrackingContextProvider;
