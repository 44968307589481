import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IAreaQuery } from "../../models/area/query";
import { IArea } from "../../models/area/response";
import {
  ICreateArea,
  IExportAreaExcel,
  IUpdateArea,
} from "../../models/area/request";

class Areaservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllAreas = (query: IAreaQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IArea>>({
      path: `/areas`,
      method: "GET",
      query,
      ...params,
    });

  getArea = (id: number, params?: RequestParams) =>
    this.http.request<IArea>({
      path: `/areas/${id}`,
      method: "GET",
      ...params,
    });

  createArea = (data: ICreateArea, params?: RequestParams) =>
    this.http.request<IArea>({
      path: "/areas",
      method: "POST",
      body: data,
      ...params,
    });

  updateArea = (id: number, data: IUpdateArea, params?: RequestParams) =>
    this.http.request<IArea>({
      path: `/areas/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteArea = (id: number, params?: RequestParams) =>
    this.http.request<IArea>({
      path: `/areas/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportAreaExcel, query: IAreaQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/areas/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default Areaservice;
