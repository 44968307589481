import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";
import OrderContext from "../../../context/order/context";
import { IClient } from "../../../models/client/response";
import { IDeliveryWorker } from "../../../models/delivery-workers/response";
import { IStore } from "../../../models/store/response";
import { getOrderStatus, getOrderStyle, getOrderType } from "../../../models/order/enum";

interface IProps { }

const OrderFilter: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { actions, query } = useContext(OrderContext);

    const [client, setClient] = useState<any[]>([]);
    const [loadingClient, setLoadingClient] = useState(true);

    const [driver, setDriver] = useState<any[]>([]);
    const [loadingDriver, setLoadingDriver] = useState(true);

    const [store, setSorte] = useState<IStore[]>([]);
    const [loadingStore, setLoadingStore] = useState(true);

    const { direction } = useContext(AppContext);

    useEffect(() => {
        const getClient = async () => {
            try {
                const { data } = await EndPoints.client.getAllClient({
                    light: true,
                    perPage: -1,
                });
                setClient(data.data);
                setLoadingClient(false);
            } catch (error) {
            } finally {
                setLoadingClient(false);
            }
        };
        getClient();
    }, [direction]);

    useEffect(() => {
        const getDriver = async () => {
            try {
                const { data } = await EndPoints.deliveryWorkers.getAllDeliveryWorkers({
                    light: true,
                    perPage: -1,
                });
                setDriver(data.data);
                setLoadingDriver(false);
            } catch (error) {
            } finally {
                setLoadingDriver(false);
            }
        };
        getDriver();
    }, [direction]);

    useEffect(() => {
        const getStore = async () => {
            try {
                const { data } = await EndPoints.store.getAllStores({
                    light: true,
                    perPage: -1,
                });
                setSorte(data.data);
                setLoadingStore(false);
            } catch (error) {
            } finally {
                setLoadingStore(false);
            }
        };
        getStore();
    }, [direction]);

    const status = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const types = [1, 2]

    return (
        <Form
            formId="order-filter"
            onSubmit={(data) => {
                const filter: any = {
                    page: INITIAL_PAGE,
                    perPage: query?.perPage,
                    keyword: data?.keyword,
                    filters: [
                        data?.clientId
                            ? {
                                name: "clientId",
                                value: data.clientId,
                                operation: "eq",
                            }
                            : undefined,
                        data?.workerId
                            ? {
                                name: "workerId",
                                value: data.workerId,
                                operation: "eq",
                            }
                            : undefined,
                        data?.storeId
                            ? {
                                name: "storeId",
                                value: data.storeId,
                                operation: "eq",
                            }
                            : undefined,
                        data?.type
                            ? {
                                name: "type",
                                value: data.type,
                                operation: "eq",
                            }
                            : undefined,
                        data?.status
                            ? {
                                name: "status",
                                value: data.status,
                                operation: "eq",
                            }
                            : undefined,
                        data?.isEmergency
                            ? {
                                name: "isEmergency",
                                value: data.isEmergency,
                                operation: "eq",
                            }
                            : undefined,
                    ].filter((f) => f),
                };
                actions.setQuery(filter);
            }}
        >
            <Row justify={"start"} gutter={[10, 0]}>
                <Col span={8}>
                    <FieldBuilder
                        name="clientId"
                        label={`${t("client")}`}
                        width="large"
                        input={{
                            type: "select",
                            loading: loadingClient,
                            allowSearch: true,
                            options: client.map((client, index) => {
                                return {
                                    label: client.firstName,
                                    value: client.id as number,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="workerId"
                        label={`${t("delivery_worker")}`}
                        width="large"
                        input={{
                            type: "select",
                            allowSearch: true,
                            loading: loadingDriver,
                            options: driver.map((driver, index) => {
                                return {
                                    label: driver.firstName + ' ' + driver.lastName,
                                    value: driver.id as number,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="storeId"
                        label={`${t("store")}`}
                        width="large"
                        input={{
                            type: "select",
                            allowSearch: true,
                            loading: loadingStore,
                            options: store.map((store, index) => {
                                return {
                                    label: store.name,
                                    value: store.id as number,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="status"
                        label={`${t("status")}`}
                        width="large"
                        input={{
                            type: "select",
                            options: status.map((status, index) => {
                                return {
                                    label: t(getOrderStatus(status) as string),
                                    value: status as number,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="type"
                        label={`${t("request_sourse_type")}`}
                        width="large"
                        input={{
                            type: "select",
                            options: types?.map((type, index) => {
                                return {
                                    label: t(getOrderStyle(type)),
                                    value: type as number,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="isEmergency"
                        label={`${t("emergency")}`}
                        width="large"
                        input={{
                            type: "select",
                            options: [1, 2].map((cat, index) => {
                                return {
                                    label: index == 0 ? t("yes") : t("no"),
                                    value: index == 0 ? 1 : "0",
                                };
                            }),
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default OrderFilter;
