import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { ITrademark, ITrademarkDetails } from "../../models/trademark/response";
import { ITrademarkQuery } from "../../models/trademark/query";
import {
  ICreateTrademark,
  IExportTrademarkExcel,
  IUpdateTrademark,
} from "../../models/trademark/request";

class TrademarkService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllTrademark = (query: ITrademarkQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ITrademark>>({
      path: `/trademark`,
      method: "GET",
      query,
      ...params,
    });

  getTrademark = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkDetails>({
      path: `/trademark/${id}`,
      method: "GET",
      ...params,
    });

  createTrademark = (data: ICreateTrademark, params?: RequestParams) =>
    this.http.request<ITrademark>({
      path: "/trademark",
      method: "POST",
      body: data,
      ...params,
    });

  updateTrademark = (
    id: number,
    data: IUpdateTrademark,
    params?: RequestParams
  ) =>
    this.http.request<ITrademark>({
      path: `/trademark/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteTrademark = (id: number, params?: RequestParams) =>
    this.http.request<ITrademark>({
      path: `/trademark/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportTrademarkExcel, query: ITrademarkQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/trademark/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default TrademarkService;
