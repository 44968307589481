import { Col, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { IParamDetails } from "../../../models/param/resopnse";

interface IProps {
  type?: number;
  details?: IParamDetails;
}

const PrizeForm: React.FC<IProps> = ({ type, details }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={[16, 8]}>
        {languages?.map((lang, index) => {
          return (
            <Col span={12}>
              <FieldBuilder
                key={index}
                name={`description[${lang?.code}]`}
                label={`${t("description")} (${lang?.name})`}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
          );
        })}
        <Col span={12}>
          <FieldBuilder
            name="number"
            label={`${t("number")}`}
            input={{ type: "number" }}
            rules={{ required: true }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PrizeForm;
