import { ITranslationRequest } from "../base/translation";
export interface ICreateInvestor {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address: string;
  phone: string;
  type: number;
  statusInvestor: number;
  status: number;
  birthDate: string;
  nationalIdentityNumber: string;
  cityId: number;
  storeId: number;
  imageId: number;
  note: ITranslationRequest;
}
export interface IUpdateInvestor extends ICreateInvestor {}
export interface IExportInvestorExcel {
  id: boolean;
  firstName: boolean;
  lastName: boolean;
  status: boolean;
  type: boolean;
  mobile: boolean;
  createdAt: boolean;
  store: boolean;
  statusInvestor: boolean;
}
export const colomnsExportInvestorExcel = [
  "id",
  "firstName",
  "lastName",
  "status",
  "type",
  "mobile",
  "createdAt",
  "store",
  "statusInvestor",
];
