export enum BannerType {
  PRODUCT = 1,
  MAIN_CATEGORY = 2,
  PARENT_CATEGORY = 3,
  CHILD_CATEGORY = 7,
  STORE = 4,
  ADDRESS = 5,
  NOT_RELATED = 6,
}



export const getBannerTypeColor = (status: BannerType) => {
  switch (status) {
    case BannerType.ADDRESS:
      return "green";
    case BannerType.MAIN_CATEGORY:
      return "red";
    case BannerType.NOT_RELATED:
      return "blue";
    case BannerType.PRODUCT:
      return "orange";
    case BannerType.STORE:
      return "green";
    case BannerType.PARENT_CATEGORY:
      return "yellow";
    case BannerType.PARENT_CATEGORY:
      return "#9c27b0";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getBannerType = (status: BannerType) => {
  switch (status) {
    case BannerType.ADDRESS:
      return "direct_address";
    case BannerType.MAIN_CATEGORY:
      return "main_category";
    case BannerType.NOT_RELATED:
      return "not_related";
    case BannerType.PRODUCT:
      return "product";
    case BannerType.STORE:
      return "store";
    case BannerType.PARENT_CATEGORY:
      return "parent_category";
    case BannerType.CHILD_CATEGORY:
      return "child_category";
    default:
      return "not_related";
  }
};
