import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../context/app/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import styles from "./style.module.scss";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";

import TemplateExportExcel from "../../components/general/excel/expor-excel";
import DoctorContext from "../../context/doctor/context";
import { IExportPaymentDeliveryWorkerExcel } from "../../models/payment-delivery-worker/request";
import DoctorFilter from "../../components/doctor/doctor-filter";
import DoctorTable from "../../components/doctor/doctor-table";
import DoctorContextProvider from "../../context/doctor/provider";
import MedicalSspecialtiesContextProvider from "../../context/medical-specialties/provider";
import MedicalSspecialtiesContext from "../../context/medical-specialties/context";
import MedicalSpecialtiesTable from "../../components/doctor copy/medical-specialties-table";
interface IProps {
  children?: React.ReactNode;
}

const MedicalSpecialties: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const [search, setSearch] = useState<string | undefined>(undefined);


  const { actions: action, query, loading } = useContext(MedicalSspecialtiesContext)

  useEffect(() => {
    action.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && action.getData();
  }, [query, direction]);
  useEffect(() => {
    !isMounted && action.getData();
  }, []);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("medical_specialties")}
        subTitle={t("medical_specialties_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => action.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/medical-specialties/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_medical_specialties")}
            </Button>
          </Can>,
        ]}
      />

      <SearchFilterCard
        formId="delivery-worker-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          action.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
      </SearchFilterCard>

      <MedicalSpecialtiesTable />
    </div>
  );
};

const MedicalSpecialtiesPage = () => {
  return (
    <MedicalSspecialtiesContextProvider>
      <MedicalSpecialties />
    </MedicalSspecialtiesContextProvider>
  );
};
export default MedicalSpecialtiesPage;
