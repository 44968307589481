import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import GeneralNotificationContext from "../../../context/general-notification/context";
import GeneralNotificationContextProvider from "../../../context/general-notification/provider";
import Tag from "../../general/antd/tag";
import {
  getNotificationColor,
  getNotificationType,
} from "../../../models/general-notification/enum";

interface Props { }

const GeneralNotification: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(GeneralNotificationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("general_notification")}
        buttonName={`${t("update_general_notification")}`}
        onClick={() => navigate(`/general-notification/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("title")}>
              {details?.title ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("user_slide")}>
              <Tag
                title={t(getNotificationType(details?.slide))}
                color={getNotificationColor(details?.slide)}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("text")}>
              {details?.text ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("product")}>
              {details?.product?.name ?? "-"}
            </AntdDescriptions.Item>
            {/* to do from back */}

            {/* <AntdDescriptions.Item label={t("note_ar")}>
              {details?.translations?.note[0]?.value ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note_en")}>
              {details?.translations?.note[1]?.value ?? "-"}
            </AntdDescriptions.Item> */}
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const GeneralNotificationDetailesPage = () => {
  return (
    <GeneralNotificationContextProvider>
      <GeneralNotification />
    </GeneralNotificationContextProvider>
  );
};
export default GeneralNotificationDetailesPage;
