import { IPointQuery } from "../../models/point/query";
import { IPoint, IPointDetails } from "../../models/point/response";
import { ITagQuery } from "../../models/tag/query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import toggleLoading from "../../utils/helpers/xor";
import paramsReducer from "../base/base-reducer";
import { QueryAction } from "../base/base-reducer";
import { IInternalState, PointLoading } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: PointLoading | PointLoading[] } }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<IPoint> } }
  | { type: "SET_DETAILS"; payload: { details?: IPointDetails } }
  | QueryAction<IPointQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
