import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import ClientContext from "../../../context/clients/client/context";
import { ICity } from "../../../models/city/response";
import { getUserStatus } from "../../../models/user/enum";
import EndPoints from "../../../services/end-points";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";

interface IProps {}

const ClientFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(ClientContext);

  const statuses = [1, 2];
  const [cities, setCities] = useState<ICity[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);
  // get all roles from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getRoles();
  }, [direction]);
  return (
    <Form
      formId="client-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,

            data?.cityId
              ? {
                  name: "cityId",
                  value: data.cityId,
                  operation: "eq",
                }
              : undefined,
            data?.createdAt != undefined && data.createdAt[0] != ""
              ? {
                  name: "createdAt",
                  value: data.createdAt[0],
                  operation: "gte",
                }
              : undefined,
            data?.createdAt != undefined && data.createdAt[1] != ""
              ? {
                  name: "createdAt",
                  value: data.createdAt[1],
                  operation: "lte",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getUserStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="cityId"
            label={`${t("city")}`}
            width="large"
            input={{
              type: "select",
              loading: citiesLoading,
              options: cities.map((city, index) => {
                return {
                  type: "select",
                  label: city?.name as string,
                  value: city?.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="createdAt"
            label={`${t("subscription_date")}`}
            input={{ type: "range-picker" }}
            rules={{ required: false }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ClientFilter;
