import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateOrder,
  IExportOrderExcel,
  IUpdateOrder,
  Update,
} from "../../models/order/request";
import {
  IOrder,
  IOrderDetails,
  IOrderShort,
} from "../../models/order/response";
import { IOrderQuery } from "../../models/order/query";
import { IOrderHistory } from "../../models/order/order-history/response";
import { IOrderStatus } from "../../models/order/order-status/response";

class OrderService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOrders = (query: IOrderQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IOrder>>({
      path: `/order`,
      method: "GET",
      query,
      ...params,
    });

  getAllOrdersShort = (query: IOrderQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IOrderShort>>({
      path: `/orders-short`,
      method: "GET",
      query,
      ...params,
    });

  getOrder = (id: number, params?: RequestParams) =>
    this.http.request<IOrderDetails>({
      path: `/order/${id}`,
      method: "GET",
      ...params,
    });
  getOrderHistories = (
    id: number,
    query: IOrderQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IOrderHistory>>({
      path: `/order/histories/${id}`,
      method: "GET",
      query,
      ...params,
    });

  getOrderStatus = (id: number, query: IOrderQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IOrderStatus>>({
      path: `/order/status/${id}`,
      method: "GET",
      query,
      ...params,
    });

  updateOrder = (id: number, data: Update, params?: RequestParams) =>
    this.http.request<IOrder>({
      path: `/order/update/${id}`,
      method: "POST",
      body: data,
      ...params,
    });

  exportExcel = (data: IExportOrderExcel, query: IOrderQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/order/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default OrderService;
