import { IClientQuery } from "../../../models/client/query";
import { IMedictionPackage } from "../../../models/client/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import toggleLoading from "../../../utils/helpers/xor";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { ClientLoading, IInternalState } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: ClientLoading | ClientLoading[] } }
  | {
      type: "SET_MEDICATION_PACKAGE";
      payload: {
        listMedicationPackage: IBaseListingResponse<IMedictionPackage>;
      };
    }
  | QueryAction<IClientQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }

    case "SET_MEDICATION_PACKAGE": {
      return {
        ...state,
        listMedicationPackage: action.payload.listMedicationPackage,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
