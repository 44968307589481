import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ClientContext from "../../../context/clients/client/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IClient, IMedictionPackage } from "../../../models/client/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import AppContext from "../../../context/app/context";
import DiscountCodesClientContext from "../../../context/clients/discount-codes/context";
import MedicationPackageClientContext from "../../../context/clients/medication-package/context";
import { ImCheckmark, ImCross } from "react-icons/im";
import Tag from "../../general/antd/tag";

interface IProps {}

const MedicationPackageTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, query, listMedicationPackage } = useContext(
    MedicationPackageClientContext
  );
  const { id } = useParams();
  const { direction, theme } = useContext(AppContext);

  useEffect(() => {
    id && actions.getListMedicationPackage(Number(id));
  }, [query, direction]);

  const columns: ColumnProps<IMedictionPackage>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("name_packege"),
      dataIndex: "name",
      align: "center",

      key: "name",
    },
    {
      title: t("day_number"),
      dataIndex: "day",
      align: "center",
      key: "day",
    },
    {
      title: t("activation_packages"),
      dataIndex: "activationPackages",
      key: "activationPackages",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (activationPackages) => {
        return (
          <>
            {activationPackages ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },

    {
      title: t("products_packeg_countity"),
      dataIndex: "products",
      align: "center",
      key: "products",
      render: (_, record) => {
        return record?.products?.map((product) => (
          <span
            style={{
              marginInlineStart: "4px",
              backgroundColor: theme === "dark" ? "#0d4ea2" : "#FAFAFA",
              borderRadius: "5px",
              padding: "0 3px",
            }}
          >
            {}
            <span>{product?.name}</span>
            <span
              style={{
                marginInlineEnd: "4px",
                backgroundColor: theme === "dark" ? "white" : "#0d4ea2",

                borderRadius: "50%",
                color: theme === "dark" ? " #0d4ea2" : "white",
                padding: "0 5px",
              }}
            >
              {product?.count}
            </span>
          </span>
          // <Tag title={`${product?.name}`} />
        ));
      },
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={listMedicationPackage?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={listMedicationPackage?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default MedicationPackageTable;
