import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import ProductContext from "../../context/product/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import ProductContextProvider from "../../context/product/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import ProductTable from "../../components/product/product-table";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import {
  IExportProductExcel,
  colomnsExportProductExcel,
} from "../../models/product/request";
import ProductFilter from "../../components/product/product-filter";
import { useModal } from "react-modal-hook";
import FormHookModal from "../../components/general/modals/form-hook-modal";
import UploadForm from "../../components/product/upload-file-form";


interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Product: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(ProductContext);
 
  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  
  const [showFormModal, hideForModal] = useModal(
    () => (
      <FormHookModal
        id="4"
        confirmButtonText={t("save")}
        loading={
          loading.includes("create") ||
          loading.includes("details") ||
          loading.includes("update")
        }
        title={t("upload_products_from_excel")}
        hideModal={() => {
          hideForModal();
        }}
        width={"50vw"}
        style={{
          marginTop: "1.6rem",
          height: "59vh",
        }}
        onSubmit={async (data) => {
          await actions.importExcelProductStore(data);
        }}
        defaultValues={{ secondStoreId: 1 }}
      >
        <UploadForm />
      </FormHookModal>
    ),
    [loading]
  );
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("products")}
        subTitle={t("products_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => {
              actions.getData();
            }}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={showFormModal}
            icon={<FileExcelOutlined />}
          >
            {t("upload_products_from_excel")}
          </Button>,
          <Divider key={1} type="vertical" />,

          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/products/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_product")}
            </Button>
          </Can>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/products/manage-store`);
              }}
              icon={<PlusOutlined />}
            >
              {t("manage_product_store")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-category"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            name: false,
            extraName: false,
            type: false,
            status: false,
            needRecipe: false,
            isNew: false,
            soldByUnit: false,
            available: false,
            description: false,
            note: false,
            weighting: false,
            watch: false,
            trademark: false,
            parentCategory: false,
            mainCategory: false,
            unit: false,
            generalDiscount: false,
            specialDiscount: false,
            valueGeneralDiscount: false,
            valueSpecialDiscount: false,
            createdAt: false,
            barcode: false,
            price: false,
            unitPrice: false,
          } as IExportProductExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportProductExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="product-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <ProductFilter />
      </SearchFilterCard>

      <ProductTable />
    </div>
  );
};

const ProductPage = () => {
  return (
    // ContextProvider
    <ProductContextProvider>
        <Product />
    </ProductContextProvider>
  );
};
export default ProductPage;
