import { BarsOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CategoryContext from "../../../context/category/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { ICategory } from "../../../models/category/response";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";

interface IProps {}

const CategoryTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(CategoryContext);

  const columns: ColumnProps<ICategory>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 2,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
      sorter: {
        multiple: 2,
      },
    },
    {
      title: t("grand"),
      dataIndex: "grand",
      width: 200,
      align: "center",
      key: "parent",
      render: (_, record) => {
        return (
          <>
            {record.grand ? (
              <Tag title={record?.grand?.name} color="#d524d2" />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("parent"),
      dataIndex: "parent",
      width: 200,
      align: "center",
      key: "parent",
      render: (_, record) => {
        return (
          <>
            {record.parent ? (
              <Tag title={record?.parent?.name} color="#2F4E8E" />
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: t("order"),
      dataIndex: "order",
      align: "center",
      key: "order",
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD");
      },
    },
    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      fixed: "right",

      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <Button
            shape="circle"
            icon={<BarsOutlined />}
            disabled={
              record?.grand !== null && record?.parent === null ? false : true
            }
            onClick={async () => {
              navigate(`${record?.id}/attributes`);
            }}
          />
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />

          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCategory(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        size="small"
        scroll={{
          x: 1400,
        }}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default CategoryTable;
