import { Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../antd/button'

interface Props extends ButtonProps {}

const EditBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('edit')}>
      <Button
        type='primary'
        shape='circle'
        icon={<EditOutlined />}
        {...props}
      />
    </Tooltip>
  )
}

export default EditBtn
