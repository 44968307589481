import moment from "moment";
import { useReducer } from "react";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import OrderHistoryContext, { internalState } from "./context";
import reducer from "./reducer";
import { IOrderHistoryQuery } from "../../models/order/order-history/query";
export interface IProps {
  children: React.ReactNode;
}
const OrderHistoryContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getData = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.order.getOrderHistories(
          id,
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IOrderHistoryQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <OrderHistoryContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </OrderHistoryContext.Provider>
  );
};

export default OrderHistoryContextProvider;
