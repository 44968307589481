import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getUserStatus } from "../../../models/user/enum";
import DiscountCodeContext from "../../../context/discount-code/context";
import { getDiscountType } from "../../../models/discount-code/enum";

interface IProps {}

const DiscountCodeFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(DiscountCodeContext);

  return (
    <Form
      formId="discount-code-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  label: t(getUserStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  label: t(getDiscountType(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DiscountCodeFilter;
