import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import {
  getStatusDeliveryWorker,
  getStatusDeliveryWorkerColor,
} from "../../../models/delivery-workers/enum";
import { getUserStatus, getUserStatusColor } from "../../../models/user/enum";
import moment from "moment";
import DoctorContext from "../../../context/doctor/context";
import { IDoctor } from "../../../models/doctors/response";

interface IProps {}

const DoctorTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(DoctorContext);

  const columns: ColumnProps<IDoctor>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("first_name"),
      dataIndex: "firstName",
      //width: 200,
      sorter: {
        multiple: 2,
      },
      align: "center",
      key: "firstName",
      render: (_, record) => {
        return <>{record?.user?.firstName ?? "-"}</>;
      },
    },
    {
      title: t("last_name"),
      dataIndex: "lastName",
      //width: 200,
      sorter: {
        multiple: 3,
      },
      align: "center",
      key: "lastName",
      render: (_, record) => {
        return <>{record?.user?.lastName ?? "-"}</>;
      },
    },
    {
      title: t("count_rochita"),
      dataIndex: "countRochita",
      //width: 200,
      sorter: {
        multiple: 3,
      },
      align: "center",
      key: "countRochita",
      render: (_, record) => {
        return <>{record?.countRochita ?? "-"}</>;
      },
    },
    {
      title: t("doctor_status"),
      dataIndex: "workingStatus",
      //width: 200,
      sorter: {
        multiple: 4,
      },
      align: "center",
      key: "workingStatus",
      render: (_, record) => {
        return (
          <Tag
            title={getStatusDeliveryWorker(record?.workingStatus)}
            color={getStatusDeliveryWorkerColor(record?.workingStatus)}
          />
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      // width: 200,
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            title={getUserStatus(record?.user?.status)}
            color={getUserStatusColor(record?.user?.status)}
          />
        );
      },
    },
    {
      title: t("mobile"),
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
      render: (_, record) => {
        return <>{record?.user?.cleanMobile}</>;
      },
    },
    {
      title: t("building"),
      dataIndex: "building",
      key: "building",
      align: "center",
      sorter: {
        multiple: 5,
      },
    },
    {
      title: t("near"),
      dataIndex: "near",
      key: "near",
      align: "center",
      sorter: {
        multiple: 7,
      },
    },
    {
      title: t("medical_specialty"),
      dataIndex: "medicalSpecialty",
      key: "medicalSpecialty",
      align: "center",
      render: (_, record) => {
        return <>{record?.medicalSpecialty?.name}</>;
      },
    },
    {
      title: t("city"),
      dataIndex: "city",
      key: "city",
      align: "center",
      render: (_, record) => {
        return <>{record?.city?.name}</>;
      },
    },
    {
      title: t("start_working_time"),
      dataIndex: "startWorkingTime",
      align: "center",
      sorter: {
        multiple: 6,
      },
      key: "startWorkingTime",
      render: (_, record) => {
        return moment(
          "2024-01-18T" + record?.startWorkingTime + ":00.000000Z"
        ).format("HH:mm");
      },
    },
    {
      title: t("end_working_time"),
      dataIndex: "endWorkingTime",
      align: "center",
      sorter: {
        multiple: 6,
      },
      key: "endWorkingTime",
      render: (_, record) => {
        return moment(
          "2024-01-18T" + record?.endWorkingTime + ":00.000000Z"
        ).format("HH:mm");
      },
    },
    {
      title: t("actions"),
      width: 240,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteDoctor(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{
          x: 1700,
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default DoctorTable;
