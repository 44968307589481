import { Navigate } from "react-router-dom";

import { ReactNode, useContext, useEffect } from "react";
import AuthContext from "../context/auth/context";
import CategoryContext from "../context/category/context";
import AppContext from "../context/app/context";

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { actions } = useContext(CategoryContext);
  const { direction } = useContext(AppContext);
  const { isAuthenticated } = useContext(AuthContext);
  useEffect(() => {
    isAuthenticated && actions.getAllData();
  }, [direction]);
  return isAuthenticated ? <>{children}</> : <Navigate to={"/login"} replace />;
};
export default RequireAuth;
