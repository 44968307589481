import { createContext } from "react";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IOrderQuery } from "../../models/order/query";

export type NotificationLoading =
  | "list"
 
export interface IInternalState {
  loading: NotificationLoading[];
  query: IOrderQuery;
  list?: any;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getMyNotification: () => void;
    readNotification: (id:number) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getMyNotification: DEFAULT_FUNCTION,
    readNotification: DEFAULT_FUNCTION,
  },
};

const NotificationContext = createContext(externalState);

export default NotificationContext;
