import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { IProductsOrder } from "../../../models/order/products-order/response";
import { ImCheckmark, ImCross } from "react-icons/im";

interface IProps {
  data: any[];
}

const ProductsOrderTable: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const { direction } = useContext(AppContext);

  const columns: ColumnProps<IProductsOrder>[] = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => {
        return record?.product?.name;
      },
    },
    {
      title: t("count"),
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (_, record) => {
        return record?.count;
      },
    },
    {
      title: t("store"),
      dataIndex: "store",
      key: "store",
      align: "center",
      render: (_, record) => {
        return record?.store?.name ?? "-";
      },
    },

    {
      title: t("single_pharmacy_price"),
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (_, record) => {
        return record?.price ?? "-";
      },
    },

    {
      title: t("single_selling_price"),
      dataIndex: "single_selling_price",
      key: "single_selling_price",
      align: "center",
      render: (_, record) => {
        return record?.priceOnClient ?? "-";
      },
    },
    {
      title: t("total_selling_price"),
      dataIndex: "total_selling_price",
      key: "total_selling_price",
      align: "center",
      render: (_, record) => {
        return record?.totalPriceOnClient ?? "-";
      },
    },
    {
      title: t("alternative_product"),
      dataIndex: "alternative_product",
      key: "alternative_product",
      align: "center",
      render: (_, record) => {
        return record?.alternativeProduct?.name ?? "-";
      },
    },
    {
      title: t("nameSlice"),
      dataIndex: "nameSlice",
      key: "nameSlice",
      align: "center",
      render: (_, record) => {
        return record?.nameSlice ?? "-";
      },
    },
    {
      title: t("numberPoint"),
      dataIndex: "numberPoint",
      key: "numberPoint",
      align: "center",
      render: (_, record) => {
        return record?.numberPoint ?? "-";
      },
    },
    {
      title: t("required_unit_from_product"),
      dataIndex: "isIndivisible",
      align: "center",
      key: "isIndivisible",
      render: (_, record) => {
        return (
          <>
            {!record.isIndivisible ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("need_recipe"),
      dataIndex: "recipeRequired",
      align: "center",
      key: "recipeRequired",
      render: (_, record) => {
        return (
          <>
            {record.recipeRequired ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table<any>
        rowKey="id"
        columns={columns}
        dataSource={data ?? []}
        size="small"
      />
    </>
  );
};

export default ProductsOrderTable;
