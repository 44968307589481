import { Descriptions as AntdDescriptions, Collapse } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import StoreContext from "../../../context/store/context";
import Table from "../../general/table-components/table";
import { ColumnProps } from "antd/es/table";
import { IInvestor } from "../../../models/investor/response";

interface Props {}
const { Panel } = Collapse;
const InfoStoreModel: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, loading } = useContext(StoreContext);

  const columns: ColumnProps<IInvestor>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("first_name"),
      dataIndex: "firstName",
      align: "center",
      key: "firstName",
    },
    {
      title: t("last_name"),
      dataIndex: "lastName",
      align: "center",
      key: "lastName",
    },
    {
      title: t("mobile"),
      dataIndex: "cleanMobile",
      align: "center",
      key: "cleanMobile",
    },
  ];

  return (
    <>
      <Descriptions size="small" column={2} bordered>
        <AntdDescriptions.Item label={t("accepted_orders")}>
          {details?.acceptedOrders ?? "--"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("cancelled_orders")}>
          {details?.cancelledOrders ?? "--"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("not_responded_orders")}>
          {details?.notRespondedOrders ?? "--"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("rejected_orders")}>
          {details?.rejectedOrders ?? "--"}
        </AntdDescriptions.Item>
      </Descriptions>
      <Collapse style={{ marginTop: "10px" }} defaultActiveKey={['1']}>
        <Panel header={t("onLine_investors")} key={1}>
          <Table<any>
            rowKey="id"
            showPagination={false}
            columns={columns}
            dataSource={details?.onLineInvestors ?? []}
            loading={loading.includes("details")}
            size="small"
          />
        </Panel>
      </Collapse>
    </>
  );
};

export default InfoStoreModel;
