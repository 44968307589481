import { Theme } from "@ant-design/cssinjs";
import { createContext } from "react";
import ar from "../../utils/antd/ar-locale";
import Direction from "../../utils/ui/direction";
import ScreenSize from "../../utils/ui/screen-size";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";

export type AppLoading = "languages" | "roles" | "permissions";

export type AppData = "languages" | "roles" | "permissions";

export type Themes = "dark" | "light";
export interface IInternalState {
  loading: AppLoading[];

  direction: Direction;
  screenSize: ScreenSize;
  locale: any;
  theme: Themes;

  // roles?: RoleLightResponse[]
  // permissions?: PermissionResponse[]
}

export const internalState: IInternalState = {
  loading: [],
  theme: (localStorage.getItem("theme") as Themes) ?? "dark",

  direction: "rtl",
  screenSize: "laptopOrDesktop",
  locale: ar,
};

export interface IExternalState extends IInternalState {
  actions: {
    // getRoles: (params?: GetAllRolesLightParams) => void
    getPermissions: () => void;
    changeTheme: (theme: Themes) => void;

    updateAppData: (update: AppData[]) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    // getRoles: DEFAULT_FUNCTION,
    getPermissions: DEFAULT_FUNCTION,
    changeTheme: DEFAULT_FUNCTION,
    updateAppData: DEFAULT_FUNCTION,
  },
};

const AppContext = createContext(externalState);

export default AppContext;
