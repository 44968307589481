import { Button, Col, Divider, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import StoreContext from "../../../context/store/context";
import StoreContextProvider from "../../../context/store/provider";
import { ICity } from "../../../models/city/response";
import { getStorStatus, getStorType } from "../../../models/store/enum";
import { IUpdateStore } from "../../../models/store/request";
import EndPoints from "../../../services/end-points";
import {
  MAP_DEFAULT_LOCATION,
  languages,
} from "../../../utils/helpers/constants";
import { execute } from "../../../utils/helpers/execute";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import Marker from "../../general/map/marker/index";
import styles from "./style.module.scss";
import { IUpdateCity } from "../../../models/city/request";
import CityContext from "../../../context/city/context";
import CityContextProvider from "../../../context/city/provider";

const Form = () => {
  const { details, actions, loading } = useContext(CityContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const methods = useForm<any>({
    defaultValues: {
      name: getTranslations(details?.translations?.name),
      note: getTranslations(details?.translations?.note) ?? null,
      center: details?.center ?? {
        type: "Point",
        coordinates: [1, 2],
      },
    } as IUpdateCity,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.city.updateCity(details?.id, {
              ...data,
              center: {
                type: "Point",
                coordinates: data.map
                  ? [data.map.lng, data.map.lat]
                  : [
                      details?.center?.coordinates[0],
                      details?.center?.coordinates[1],
                    ],
              },
            })
          : await EndPoints.city.createCity({
              ...data,
              center: {
                type: "Point",
                coordinates: data.map
                  ? [data.map.lng, data.map.lat]
                  : [MAP_DEFAULT_LOCATION.lng , MAP_DEFAULT_LOCATION.lat],
              },
            });
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  useEffect(() => {
    reset({
      name: getTranslations(details?.translations?.name),
      note: getTranslations(details?.translations?.note),
    } as IUpdateCity);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_city") : t("add_city")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 8]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`name[${lang?.code}]`}
                        label={`${t("name")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: true }}
                      />
                    </Col>
                  );
                })}
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`note[${lang?.code}]`}
                        label={`${t("note")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
                <Col span={24}>
                  <FormItem label={t("center_city")}>
                    <Controller
                      name="map"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Marker
                            name={details?.name}
                            draggable={true}
                            onChange={field?.onChange}
                            location={details?.center}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}
        </div>

        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const CityForm = () => {
  return (
    <CityContextProvider>
      <Form />
    </CityContextProvider>
  );
};
export default CityForm;
