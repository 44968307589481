import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";

interface Props {
  required?: boolean;
}

const PasswordInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { required = true } = props;

  return (
    <FieldBuilder
      rules={{ minLength: 8, required: required }}
      name="password"
      formItemLabelStyle={{ color: "gray" }}
      label="password"
      input={{ type: "password" }}
    />
    // <FieldBuilder
    //   name="password"
    //   label={t("password")}
    //   input={{
    //     type: "password",
    //   }}
    //   rules={{ minLength: 8, required: required }}
    // />
  );
};

export default PasswordInput;
