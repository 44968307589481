import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Input as AntdInput,
  Checkbox,
  CheckboxOptionType,
  Col,
  DatePicker,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { a2e } from "../../../utils/helpers/functions";
import Controller from "../../form-components/controller";
import Switch from "../../general/antd/switch";
import styles from "./styles.module.scss";
// import moment from 'moment'
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload, message } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { CSSProperties, useEffect, useState } from "react";
import FormItem from "../../general/form-item";
import { CirclePicker } from "react-color";

const { RangePicker } = DatePicker;
const { TextArea, Search } = AntdInput;

interface Rules {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  pattern?: { value: RegExp; message?: string };
}

type Field =
  | { type: "email"; style?: CSSProperties }
  | { type: "username" }
  | { type: "gsm" }
  | { type: "pin" }
  | { type: "text" }
  | { type: "hidden-type" }
  | { type: "hidden" }
  | {
      type: "select-type";
      options?: { label: string; value: any }[];
      loading?: boolean;
      allowSearch?: boolean;
    }
  | { type: "text-area"; rows?: number; value?: string }
  | { type: "number" }
  | { type: "search" }
  | { type: "password" }
  | { type: "checkBox" }
  | { type: "switch" }
  | { type: "time-picker"; format?: string }
  | { type: "time-range-picker"; format?: string }
  | { type: "date-picker"; format?: string }
  | { type: "range-picker"; format?: string }
  | { type: "radio"; options?: CheckboxOptionType[] }
  | {
      type: "select";
      options?: { label: string; value: any }[];
      loading?: boolean;
      allowSearch?: boolean;
      onChange?: (val) => void;
    }
  | {
      type: "select-pagination";
      options?: { label: string; value: any }[];
      loading?: boolean;
      allowSearch?: boolean;
      onChange?: (val) => void;
      handleScroll?: (val) => void;
      handleSearch?: (val) => void;
    }
  | {
      type: "select-pagination-multiple";
      options?: { label: string; value: any }[];
      loading?: boolean;
      allowSearch?: boolean;
      onChange?: (val) => void;
      handleScroll?: (val) => void;
      handleSearch?: (val) => void;
    }
  | {
      type: "multiSelect";
      options?: { label: string; value: any }[];
      loading?: boolean;
      allowSearch?: boolean;
      handleScroll?: (val) => void;
    }
  | { type: "color-picker" }
  | { type: "file" };

interface Props {
  name: string;
  label?: string;
  labelCol?: number;
  input: Field;
  rules?: Rules;
  width?: "large" | "middle" | "small";
  disabled?: boolean;
  test?: (e) => void;
  formItemLabelStyle?: CSSProperties;
}
const { Dragger } = Upload;

const prop: UploadProps = {
  accept: "image/png, image/jpeg , image/jpg",
  name: "file",
  multiple: true,
  action: "https://chamaa2.autozonegroup.com/public/api/v1/media",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const FieldBuilder: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [width, setWidth] = useState<string>("");

  useEffect(() => {
    switch (props.width) {
      case "large":
        setWidth("100%");
        break;
      case "middle":
        setWidth("50%");
        break;
      case "small":
        setWidth("25%");
        break;

      default:
        setWidth("50%");
        break;
    }
  }, []);

  const rules = (
    rules?: Rules
  ): Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  > => {
    return {
      required: rules?.required
        ? {
            value: rules.required,
            message: t("requiredField", { input: t(props.name) }),
          }
        : undefined,
      minLength: rules?.minLength
        ? {
            value: rules.minLength,
            message: ` ${t("min_length")} ${props.rules?.minLength} `,
            // message: t(`min_length ${props.rules?.minLength}`, {
            //   input: t(props.name),
            //   value: rules.minLength,
            // }),
          }
        : undefined,
      maxLength: rules?.maxLength
        ? {
            value: rules.maxLength,
            message: t("maxLength", {
              input: t(props.name),
              value: rules.maxLength,
            }),
          }
        : undefined,
      max: rules?.max
        ? {
            value: rules.max,
            message: t("max", {
              input: t(props.name),
              value: rules.max,
            }),
          }
        : undefined,
      min: rules?.min
        ? {
            value: rules.min,
            message: t("min", {
              input: t(props.name),
              value: rules.min,
            }),
          }
        : undefined,
      pattern: rules?.pattern
        ? {
            value: rules.pattern.value,
            message:
              rules.pattern.message ??
              t("invalidPattern", { input: t(props.name) }),
          }
        : undefined,
    };
  };
  return (
    <FormItem
      formItemLabelStyle={props?.formItemLabelStyle}
      label={props.input.type == "checkBox" ? "" : props.label}
      required={props.rules?.required}
    >
      <Controller
        name={props.name}
        control={control}
        rules={rules(props.rules)}
        render={({ field: { ...field } }) => {
          const input = props.input;
          switch (input.type) {
            case "text-area":
              return (
                <TextArea
                  value={input.value}
                  {...field}
                  rows={input?.rows ?? 3}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              );
            case "email":
              return (
                <Input
                  {...field}
                  prefix={<MailOutlined />}
                  placeholder={`${t("email")}`}
                  disabled={props?.disabled}
                  //@ts-ignore
                  style={props?.input?.style}
                />
              );
            case "username":
              return (
                <Input
                  {...field}
                  prefix={<UserOutlined />}
                  placeholder={`${t("username")}`}
                  disabled={props?.disabled}
                />
              );
            case "gsm":
              return (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(a2e(e.currentTarget.value));
                  }}
                  prefix="+963"
                  placeholder={`${t("gsm")}`}
                  style={{ direction: "ltr" }}
                  disabled={props?.disabled}
                />
              );
            case "pin":
              return (
                <AntdInput.Password
                  {...field}
                  onChange={(e) => {
                    field.onChange(a2e(e.currentTarget.value));
                  }}
                  size="middle"
                  className="shadow"
                  prefix={<LockOutlined />}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              );
            case "password":
              return (
                <AntdInput.Password
                  {...field}
                  size="middle"
                  className="shadow"
                  prefix={<LockOutlined />}
                  placeholder={props.label}
                />
              );
            case "search":
              return (
                <Search
                  {...field}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              );
            case "number":
              return (
                <Input
                  {...field}
                  type="number"
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              );
            case "hidden":
              return (
                <Input
                  {...field}
                  type="hidden"
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              );
            case "hidden-type":
              return (
                <div>
                  <Input
                    {...field}
                    allowClear={!props.rules?.required}
                    placeholder={props.label}
                    disabled={props?.disabled}
                  />
                </div>
              );
            case "switch":
              return (
                <Switch
                  {...field}
                  checked={field.value}
                  disabled={props?.disabled}
                />
              );
            case "time-picker":
              return (
                <TimePicker
                  {...field}
                  style={{ width: "100%" }}
                  allowClear={!props.rules?.required}
                  value={
                    field.value ? dayjs(field.value, input.format) : undefined
                  }
                  onChange={(_, dateString) => {
                    field.onChange(dateString);
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              );
            case "time-range-picker":
              const timePickerValue: [any, any] = [null, null];

              if (field.value) {
                timePickerValue[0] = !isEmpty(field.value[0])
                  ? dayjs(field.value[0], input.format)
                  : null;

                timePickerValue[1] = !isEmpty(field.value[1])
                  ? dayjs(field.value[1], input.format)
                  : null;
              }
              return (
                <TimePicker.RangePicker
                  {...field}
                  style={{ width: "100%" }}
                  allowClear={!props.rules?.required}
                  value={timePickerValue}
                  onChange={(_, dateString) => {
                    field.onChange(dateString);
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              );
            case "color-picker":
              return (
                <CirclePicker
                  {...field}
                  color={field.value}
                  onChange={(color) => {
                    field.onChange(color?.hex);
                  }}
                />
              );

            case "date-picker":
              return (
                <DatePicker
                  {...field}
                  style={{ width: "100%" }}
                  className="shadow"
                  allowClear={!props.rules?.required}
                  value={field.value ? dayjs(field.value) : undefined}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                  showTime
                />
              );
            case "range-picker":
              const rangePickerValue: [any, any] = [null, null];

              if (field.value) {
                rangePickerValue[0] = !isEmpty(field.value[0])
                  ? dayjs(field.value[0])
                  : null;

                rangePickerValue[1] = !isEmpty(field.value[1])
                  ? dayjs(field.value[1])
                  : null;
              }

              return (
                <RangePicker
                  {...field}
                  className="shadow"
                  style={{ width: "100%" }}
                  allowEmpty={[true, true]}
                  allowClear={!props.rules?.required}
                  value={rangePickerValue}
                  onChange={(dates, datesString) => {
                    field.onChange([datesString[0], datesString[1]]);
                  }}
                  format={input.format}
                  disabled={props?.disabled}
                />
              );
            case "radio":
              return (
                <Radio.Group {...field} optionType="default">
                  <Row gutter={[20, 20]} justify="center">
                    {input.options?.map((option, key) => (
                      <Col key={key}>
                        <Radio
                          key={key}
                          className={styles["radio-option"]}
                          {...option}
                        >
                          {option.label}
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              );

            case "select":
              return (
                <Select
                  {...field}
                  className={`shadow ${styles.width}`}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  options={input.options}
                  onChange={async (v) => {
                    await field.onChange(v);
                    if (input?.onChange) await input?.onChange(v);
                  }}
                  loading={input?.loading}
                  // // onSelect={(aon) => alert(a)}
                  // // onChange={(e: string) => alert(e)}
                  // onChange={(e) => {
                  //   field.value(e);
                  // }}
                  showSearch={input?.allowSearch}
                  optionLabelProp="label"
                  style={{ width: width }}
                  optionFilterProp="label"
                  disabled={props?.disabled}
                />
              );
            case "select-pagination":
              return (
                <Select
                  {...field}
                  className={`shadow ${styles.width}`}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  options={input.options}
                  onChange={async (v) => {
                    await field.onChange(v);
                    if (input?.onChange) await input?.onChange(v);
                  }}
                  onSearch={input?.handleSearch}
                  onPopupScroll={async (v) => {
                    if (input?.handleScroll) await input?.handleScroll(v);
                  }}
                  loading={input?.loading}
                  // // onSelect={(aon) => alert(a)}
                  // // onChange={(e: string) => alert(e)}
                  // onChange={(e) => {
                  //   field.value(e);
                  // }}
                  showSearch={input?.allowSearch}
                  optionLabelProp="label"
                  style={{ width: width }}
                  optionFilterProp="label"
                  disabled={props?.disabled}
                />
              );
            case "select-pagination-multiple":
              return (
                <Select
                  {...field}
                  className={`shadow ${styles.width}`}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  options={input.options}
                  onChange={async (v) => {
                    await field.onChange(v);
                    if (input?.onChange) await input?.onChange(v);
                  }}
                  onSearch={input?.handleSearch}
                  onPopupScroll={async (v) => {
                    if (input?.handleScroll) await input?.handleScroll(v);
                  }}
                  loading={input?.loading}
                  // // onSelect={(aon) => alert(a)}
                  // // onChange={(e: string) => alert(e)}
                  // onChange={(e) => {
                  //   field.value(e);
                  // }}
                  showSearch={input?.allowSearch}
                  optionLabelProp="label"
                  style={{ width: width }}
                  optionFilterProp="label"
                  disabled={props?.disabled}
                  mode="multiple"
                />
              );
            case "select-type":
              return (
                <Select
                  {...field}
                  className={`shadow ${styles.width}`}
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  options={input.options}
                  loading={input?.loading}
                  showSearch={input?.allowSearch}
                  optionLabelProp="label"
                  style={{ width: width }}
                  optionFilterProp="label"
                  disabled={props?.disabled}
                ></Select>
              );
            case "multiSelect":
              return (
                <Select
                  {...field}
                  className={`shadow ${styles.width}`}
                  allowClear={!props.rules?.required}
                  mode="multiple"
                  placeholder={props.label}
                  options={input.options}
                  style={{ width: width }}
                  loading={input?.loading}
                  showSearch={input?.allowSearch}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  disabled={props?.disabled}
                  onPopupScroll={async (v) => {
                    if (input?.handleScroll) await input?.handleScroll(v);
                  }}
                />
              );
            case "checkBox":
              return (
                <div>
                  {/* <label style={{ marginInlineEnd: '.8rem' }}> </label> */}
                  <Checkbox
                    {...field}
                    checked={field.value}
                    className="shadow"
                    defaultChecked={false}
                    style={{ width: "100%" }}
                    disabled={props?.disabled}
                  >
                    {t(`${props.label}`)}
                  </Checkbox>
                </div>
              );
            case "file":
              return (
                <Dragger {...prop}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                  </p>
                </Dragger>
              );
            default:
              return (
                <Input
                  {...field}
                  hidden
                  allowClear={!props.rules?.required}
                  placeholder={props.label}
                  disabled={props?.disabled}
                />
              );
          }
        }}
      />
    </FormItem>
  );
};

export default FieldBuilder;
