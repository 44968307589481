import { ITranslationRequest } from "../base/translation";
export interface ICreateProduct {
  name: ITranslationRequest;
  note: ITranslationRequest;
  extraName: ITranslationRequest;
  description: ITranslationRequest;
  type: number;
  status: number;
  trademarkId: number;
  mainCategoryId: number;
  parentCategoryId: number;
  childCategoryId: number[];
  needRecipe: boolean;
  soldByUnit: boolean;
  available: boolean;
  attributes: IAttributes[];
  tagIds: number[];
  imageId?: Number;
  unitId: number;
  isNew: boolean;
  generalDiscount: boolean;
  specialDiscount: boolean;
  valueGeneralDiscount?: number;
  valueSpecialDiscount?: number;
  price: number;
  unitPrice: number;
  sliceId: number
}

export interface IUpdateProduct extends ICreateProduct {}

export interface IManageProductStore {
  operation: number;
  productId: number;
  stores: number[];
  price: number;

  available: boolean;
  unitPrice: number;
  discountPercentage: number;
  isDiscount: boolean;
  note: string;
}
export interface IImportExcelProductStore {
  storeId: number;
  available: boolean;
  isDiscount: boolean;
  fileId: number;
}

export interface IExportProductExcel {
  id: boolean;
  name: boolean;
  extraName: boolean;
  type: boolean;
  status: boolean;
  needRecipe: boolean;
  isNew: boolean;
  soldByUnit: boolean;
  available: boolean;
  description: boolean;
  note: boolean;
  weighting: boolean;
  watch: boolean;
  trademark: boolean;
  parentCategory: boolean;
  mainCategory: boolean;
  unit: boolean;
  generalDiscount: boolean;
  specialDiscount: boolean;
  valueGeneralDiscount: boolean;
  valueSpecialDiscount: boolean;
  createdAt: boolean;
  barcode: boolean;
  price: boolean;
  unitPrice: boolean;
}

export const colomnsExportProductExcel = [
  "id",
  "name",
  "extraName",
  "type",
  "status",
  "needRecipe",
  "isNew",
  "soldByUnit",
  "available",
  "description",
  "note",
  "weighting",
  "watch",
  "trademark",
  "parentCategory",
  "mainCategory",
  "unit",
  "generalDiscount",
  "specialDiscount",
  "valueGeneralDiscount",
  "valueSpecialDiscount",
  "createdAt",
  "barcode",
  "price",
  "unitPrice",
];

interface IAttributes {
  attributeId: number;
  values: string[];
}
