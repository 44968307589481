import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IPaymentPointQuery } from "../../models/payment-point/query";
import { IPaymentPoint, IPaymentPointDetails } from "../../models/payment-point/response";
import { ICreatePaymentPoint, IUpdatePaymentPoint } from "../../models/payment-point/request";

export type PaymentPointLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: PaymentPointLoading[];

  list?: IBaseListingResponse<IPaymentPoint>;
  query: IPaymentPointQuery;

  details?: IPaymentPointDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
   // setDetails: (data?: IPaymentPointDetails) => void;

    createPaymentPoint: (request: ICreatePaymentPoint) => void;
    updatePaymentPoint: (id: number, request: IUpdatePaymentPoint) => void;
    deletePaymentPoint: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IPaymentPointQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
   // setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createPaymentPoint: DEFAULT_FUNCTION,
    updatePaymentPoint: DEFAULT_FUNCTION,
    deletePaymentPoint: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PaymentPointContext = createContext(externalState);

export default PaymentPointContext;
