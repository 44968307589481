import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BannerContext from "../../../context/banner/context";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import TrademarkContext from "../../../context/trademark/context";
import ProductContext from "../../../context/product/context";
import { ImCheckmark } from "react-icons/im";
import Tag from "../../general/antd/tag";
import { getProductStatus, getProductType } from "../../../models/product/enum";
import { ICategory } from "../../../models/category/response";
import AppContext from "../../../context/app/context";
import EndPoints from "../../../services/end-points";
import { ITrademark } from "../../../models/trademark/response";
import CategoryContext from "../../../context/category/context";

interface IProps {}

const ProductFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(ProductContext);

  const { allCategories } = useContext(CategoryContext);
  const [mainCat, setMainCat] = useState<number>();
  const [parentCat, setParentCat] = useState<number>();
  const [subCategories, setSubCategories] = useState<ICategory[]>([]);
  const [subCategoryLoadign, setSubCategoriesLoading] =
    useState<boolean>(false);

  const [trademarks, setTrademarks] = useState<ITrademark[]>([]);
  const [trademarkLoading, setTrademarkLoading] = useState(false);
  const { direction } = useContext(AppContext);
  const userPermissions = localStorage?.getItem("permisionStoroge")?.split(",");

  // get all subCategories from api
  useEffect(() => {
    if (!userPermissions.includes("can_view_pads")) {
       
      const getSubCategories = async () => {
        try {
          const { data } = await EndPoints.category.getAllCategories({
            light: true,
            perPage: -1,
            filters: [
              {
                name: "parentId",
                operation: "neq",
                value: null,
              },
            ],
          });
          setSubCategories(data.data);
          setSubCategoriesLoading(false);
        } catch (error) {
        } finally {
          setSubCategoriesLoading(false);
        }
      };
      getSubCategories();
    }
  }, [direction]);

  useEffect(() => {
    const getTrademrks = async () => {
      try {
        const { data } = await EndPoints.trademark.getAllTrademark({
          light: true,
          perPage: -1,
        });
        setTrademarks(data.data);
        setTrademarkLoading(false);
      } catch (error) {
      } finally {
        setTrademarkLoading(false);
      }
    };
    getTrademrks();
  }, [direction]);
  return (
    <Form
      formId="product-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.isNew
              ? {
                  name: "isNew",
                  value: data.isNew,
                  operation: "eq",
                }
              : undefined,
            data?.trademarkId
              ? {
                  name: "trademarkId",
                  value: data.trademarkId,
                  operation: "eq",
                }
              : undefined,
            data?.needRecipe
              ? {
                  name: "needRecipe",
                  value: data?.needRecipe,
                  operation: "eq",
                }
              : undefined,
            data?.mainCategoryId
              ? {
                  name: "mainCategoryId",
                  value: data?.mainCategoryId,
                  operation: "eq",
                }
              : undefined,
            data?.parentCategoryId
              ? {
                  name: "parentCategoryId",
                  value: data?.parentCategoryId,
                  operation: "eq",
                }
              : undefined,
            data?.childCategoryId
              ? {
                  name: "childCategoryId",
                  value: data?.childCategoryId,
                  operation: "eq",
                }
              : undefined,
            data?.status
              ? {
                  name: "status",
                  value: data?.status,
                  operation: "eq",
                }
              : undefined,
            data?.available
              ? {
                  name: "available",
                  value: data?.available,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: [1, 2].map((type, index) => {
                return {
                  label: t(getProductType(type) as string),
                  value: type as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="isNew"
            label={`${t("new")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              loading: subCategoryLoadign,
              options: [1, 2].map((cat, index) => {
                return {
                  label: index == 0 ? t("yes") : t("no"),
                  value: index == 0 ? 1 : "0",
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="trademarkId"
            label={`${t("trademark")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              loading: trademarkLoading,
              options: trademarks.map((trademark, index) => {
                return {
                  key: index,
                  label: trademark?.name,
                  value: trademark?.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="mainCategoryId"
            label={`${t("main_category")}`}
            width="large"
            input={{
              type: "select",
              options: allCategories
                ?.filter((cat) => cat?.parent === null && cat?.grand === null)
                .map((grand, index) => {
                  return {
                    type: "select",
                    label: grand?.name as string,
                    value: grand?.id as number,
                  };
                }),
              onChange: (val) => {
                setMainCat(val);
              },
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="parentCategoryId"
            label={`${t("parent_category")}`}
            width="large"
            input={{
              type: "select",

              options: allCategories
                ?.filter((cat) =>
                  mainCat
                    ? cat?.parent === null && cat?.grand?.id == mainCat
                    : cat?.parent === null && cat?.grand?.id !== null
                )
                .map((grand, index) => {
                  return {
                    type: "select",
                    label: grand?.name as string,
                    value: grand?.id as number,
                  };
                }),
              onChange: (val) => {
                setParentCat(val);
              },
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="childCategoryId"
            label={`${t("child_category")}`}
            width="large"
            input={{
              type: "multiSelect",

              options: allCategories
                ?.filter((cat) =>
                  mainCat && parentCat
                    ? cat?.parent?.id === parentCat && cat?.grand?.id == mainCat
                    : cat?.parent !== null && cat?.grand?.id !== null
                )
                .map((grand, index) => {
                  return {
                    type: "select",
                    label: grand?.name as string,
                    value: grand?.id as number,
                  };
                }),
            }}
          />
        </Col>
      </Row>

      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="needRecipe"
            label={`${t("need_recipe")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  key: index,
                  label: status === 1 ? t("yes") : t("no"),
                  value: status === 1 ? 1 : "0",
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="available"
            label={`${t("available")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: [1, 2].map((cat, index) => {
                return {
                  label: index == 0 ? t("yes") : t("no"),
                  value: index == 0 ? 1 : "0",
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: [1, 2].map((statu, index) => {
                return {
                  label: t(getProductStatus(statu) as string),
                  value: statu as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ProductFilter;
