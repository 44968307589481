import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../context/app/context";
import BannerContext from "../../../context/banner/context";
import { useIsMount } from "../../../utils/hooks/is-mount";
import styles from "../style.module.scss";
import BannerContextProvider from "../../../context/banner/provider";
import SearchFilterCard from "../../../components/general/filter-card";
import Can from "../../../utils/rbac/can";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import BannerTable from "../../../components/banners/banner-table";
import BannerFilter from "../../../components/banners/banner-filter";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Banner: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(BannerContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    
    !isMounted && actions.getData(1);
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("banners")}
        subTitle={t("banners_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData(1)}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/banners-client/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_banner")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="banner-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <BannerFilter />
      </SearchFilterCard>

      <BannerTable />
    </div>
  );
};

const BannerClientPage = () => {
  return (
    // ContextProvider
    <BannerContextProvider>
      <Banner />
    </BannerContextProvider>
  );
};
export default BannerClientPage;
