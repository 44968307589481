import { createContext } from "react";

import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IOrderStatus } from "../../models/order/order-status/response";
import { IOrderStatusQuery } from "../../models/order/order-status/query";

export type OrderStatusLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OrderStatusLoading[];

  list?: IBaseListingResponse<IOrderStatus>;
  query: IOrderStatusQuery;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    setSearch: (search?: string) => void;
    setQuery: (query: IOrderStatusQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const OrderStatusContext = createContext(externalState);

export default OrderStatusContext;
