import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import PageDetails from "../../general/details";
import moment from "moment";
import {
  getStatusDeliveryWorker,
  getStatusDeliveryWorkerColor,
} from "../../../models/delivery-workers/enum";
import Tag from "../../general/antd/tag";
import { getUserStatus, getUserStatusColor } from "../../../models/user/enum";

import OldImage from "../../form-components/old-image";
import DoctorContext from "../../../context/doctor/context";
import DoctorContextProvider from "../../../context/doctor/provider";
import Marker from "../../general/map/marker";
import { getWorkDay } from "../../../models/doctors/enum";

const { Panel } = Collapse;
interface Props {}

const Doctor: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(DoctorContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  details?.offDays?.map((n) => {
    getWorkDay(n);
  });

  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("doctors")}
        buttonName={`${t("update_doctor")}`}
        onClick={() => navigate(`/doctor/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("count_Rochita")}>
                  {details?.countRochita}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("address")}>
                  {details?.address}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("first_name")}>
                  {details?.user?.firstName}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("last_name")}>
                  {details?.user?.lastName}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("mobile")}>
                  {details?.user?.cleanMobile}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("phone")}>
                  {details?.user?.phone}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("birthDate")}>
                  {details?.birthDate
                    ? moment(details?.birthDate).format("YYYY-MM-DD")
                    : "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("city")}>
                  {details?.city?.name}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("created_at")}>
                  {details?.createdAt
                    ? moment(details?.createdAt).format("YYYY-MM-DD")
                    : "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("apartment_no")}>
                  {details?.apartmentNo}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("building")}>
                  {details?.building}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("start_working_time")}>
                  {moment(
                    "2024-01-18T" + details?.startWorkingTime + ":00.000000Z"
                  ).format("HH:mm")}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("end_working_time")}>
                  {moment(
                    "2024-01-18T" + details?.endWorkingTime + ":00.000000Z"
                  ).format("HH:mm")}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("doctor_status")}>
                  <Tag
                    title={getStatusDeliveryWorker(details?.workingStatus)}
                    color={getStatusDeliveryWorkerColor(details?.workingStatus)}
                  />
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("status")}>
                  <Tag
                    title={getUserStatus(details?.user?.status)}
                    color={getUserStatusColor(details?.user?.status)}
                  />
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("medical_specialty")}>
                  {details?.medicalSpecialty?.name}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("more_details")}>
                  {details?.moreDetails}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("near")}>
                  {details?.near}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("off_days")}>
                  {details?.offDays?.map((n) => t(getWorkDay(n)) + "  ")}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("street")}>
                  {details?.street}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("image")}>
                  <OldImage src={details?.user?.image?.url} preview />
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Collapse>
                <Panel header={t("location")} key={1}>
                  <Marker
                    width="75vw"
                    name={details?.user?.firstName}
                    draggable={false}
                    location={details?.location}
                  />
                </Panel>
              </Collapse>
            </Col>
            {/* <Col span={24}></Col> */}
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const DoctorDetailsPage = () => {
  return (
    <DoctorContextProvider>
      <Doctor />
    </DoctorContextProvider>
  );
};
export default DoctorDetailsPage;
