export enum PointStatus {
    ACTIVE = 1,
    INACTIVE = 2,
  }
  
  export const getPointStatusColor = (status: PointStatus) => {
    switch (status) {
      case PointStatus.ACTIVE:
        return "green";
      case PointStatus.INACTIVE:
        return "red";
      default:
        return "red";
    }
  };
  
  export const getPointStatus = (status: PointStatus) => {
    switch (status) {
      case PointStatus.ACTIVE:
        return "active";
      case PointStatus.INACTIVE:
        return "inactive";
      default:
        return "inactive";
    }
  };
  
  