export interface ICreateRole {
  name: string;
  permissions: string[];
}

export interface IUpdateRole extends ICreateRole {}

export interface IExportRoleExcel {
  id: boolean,
  name: boolean;
}

export const RolesColoums = [ "id","name"];
