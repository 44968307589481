import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import RewardContext from "../../../context/rewaed/context";

interface IProps {
  RewardId?: number;
  driverId?: number;
}

const RewardHistoryTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, history ,query } = useContext(RewardContext);
  const { direction } = useContext(AppContext);

  useEffect(() => {
    actions.getHistory(props?.RewardId, props?.driverId);
  }, [direction]);

  const columns: ColumnProps<any>[] = [
    {
      title: t("id"),
      dataIndex: "driver",
      key: "driver",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.id}</>;
      },
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.driver?.firstName + "  " + recod?.driver?.lastName}</>;
      },
    },
    {
      title: t("value"),
      dataIndex: "value",
      key: "value",
      align: "center",
      render: (_, recod) => {
        return <>{recod?.value ?? "---"}</>;
      },
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={history?.data}
        loading={loading.includes("list")}
        size="small"
        total={history?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default RewardHistoryTable;
