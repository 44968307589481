import moment from "moment";
import { useReducer } from "react";
import ClientContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../../utils/helpers/execute";
import EndPoints from "../../../services/end-points";
import { ICreateClient, IUpdateClient } from "../../../models/client/request";
import { IClient } from "../../../models/client/response";
import { IClientQuery } from "../../../models/client/query";
import ClientPointsContext from "./context";

export interface IProps {
  children: React.ReactNode;
}
const ClientPointsContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getListClientPoints = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.client.getClientPoints(id, {
          ...state.query,
          filters: [
            {
              name: "clientId",
              operation: "eq",
              value: id,
            },
          ],
        });

        dispatch({
          type: "SET_LIST_CLIENT_POINTS",
          payload: { listClientPoints: data },
        });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IClientQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };


  return (
    <ClientPointsContext.Provider
      value={{
        ...state,
        actions: {

          getListClientPoints,

  
          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </ClientPointsContext.Provider>
  );
};

export default ClientPointsContextProvider;
