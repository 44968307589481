import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import DiscountCodeContext from "../../context/discount-code/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import DiscountCodeContextProvider from "../../context/discount-code/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import DiscountCodeTable from "../../components/discount-code/discount-code-table";
import DiscountCodeFilter from "../../components/discount-code/discount-code-filter";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import {
  IExportDiscountCodeExcel,
  colomnsExportDiscountCodeExcel,
} from "../../models/discount-code/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const DiscountCode: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(DiscountCodeContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("discount_codes")}
        subTitle={t("discount_codes_management")}
        extra={[
          <RefreshBtn
            key={2}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                setExportModalVisible(true);
              }}
              icon={<FileExcelOutlined />}
            >
              {t("export_to_excel")}
            </Button>
            <Divider key={3} type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                navigate(`/discount-codes/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_discount_code")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="discount-code-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <DiscountCodeFilter />
      </SearchFilterCard>

      <DiscountCodeTable />

      <ExportToExcelModal
        id="export-category"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            code: false,
            status: false,
            type: false,
            discountValueProduct: false,
            discountValueDelivery: false,
            validityDate: false,
            codeValidity: false,
            numberPoints: false,
            maxOfUse: false,
            frequencyOfUse: false,
            typeDiscountCode: false,
            typeValueDiscountCode: false,
            upperLimit: false,
            minimumLimit: false,
          } as IExportDiscountCodeExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportDiscountCodeExcel} />
      </ExportToExcelModal>
    </div>
  );
};

const DiscountCodePage = () => {
  return (
    // ContextProvider
    <DiscountCodeContextProvider>
      <DiscountCode />
    </DiscountCodeContextProvider>
  );
};
export default DiscountCodePage;
