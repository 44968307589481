import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateHomePageList,
  IExportHomePageListExcel,
  IUpdateHomePageList,
} from "../../models/home-page-list/request";
import { IHomePageList, IHomePageListDetails } from "../../models/home-page-list/response";
import { IHomePageListQuery } from "../../models/home-page-list/query";

class HomePageListService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllHomePageLists = (query: IHomePageListQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHomePageList>>({
      path: `/homepage-list`,
      method: "GET",
      query,
      ...params,
    });

  getHomePageList = (id: number, params?: RequestParams) =>
    this.http.request<IHomePageListDetails>({
      path: `/homepage-list/${id}`,
      method: "GET",
      ...params,
    });

  createHomePageList = (data: ICreateHomePageList, params?: RequestParams) =>
    this.http.request<IHomePageList>({
      path: "/homepage-list",
      method: "POST",
      body: data,
      ...params,
    });

  updateHomePageList = (id: number, data: IUpdateHomePageList, params?: RequestParams) =>
    this.http.request<IHomePageList>({
      path: `/homepage-list/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteHomePageList = (id: number, params?: RequestParams) =>
    this.http.request<IHomePageList>({
      path: `/homepage-list/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportHomePageListExcel, query: IHomePageListQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/homepage-list/export`, data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
          }`,
        "Content-Type": "application/json",
        "accept-language": `${localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default HomePageListService;
