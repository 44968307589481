import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import CityContext from "../../context/city/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import PointTable from "../../components/point/point-table";
import PaymentPointContext from "../../context/payment-point/context";
import PaymentPointContextProvider from "../../context/payment-point/provider";
import PaymentPointTable from "../../components/payment-point/payment-point-table";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import { IExportPaymentPointCollection, colomnsExportPaymentPointExcel } from "../../models/payment-point/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import PaymentPointFilter from "../../components/payment-point/payment-point-filter";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const PaymentPoint: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const { actions, loading, query } = useContext(PaymentPointContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("payment_point")}
        subTitle={t("payment_point_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/payment-point/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_payment_point")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-category"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            employ: false,
            collectionPoint: false,
            isConfirmed: false,
            value: false,
            receiptNumber: false,
            note: false,
            createdAt: false,
          } as IExportPaymentPointCollection
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportPaymentPointExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="payment-point-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <PaymentPointFilter></PaymentPointFilter>
      </SearchFilterCard>
      {/* <Outlet /> */}
      <PaymentPointTable />
    </div>
  );
};

const PaymentPointPage = () => {
  return (
    // ContextProvider
    <PaymentPointContextProvider>
      <PaymentPoint />
    </PaymentPointContextProvider>
  );
};
export default PaymentPointPage;