export enum ComplaintStatus {
  SUSPENDED = 1,
  PROCESSED = 2,
  CANCELED = 3,
}

export const getComplaintStatusColor = (status: ComplaintStatus) => {
  switch (status) {
    case ComplaintStatus.PROCESSED:
      return "green";
    case ComplaintStatus.CANCELED:
      return "red";
    case ComplaintStatus.SUSPENDED:
      return "orange";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getComplaintStatus = (status: ComplaintStatus) => {
  switch (status) {
    case ComplaintStatus.CANCELED:
      return "canceled";
    case ComplaintStatus.PROCESSED:
      return "processed";
    case ComplaintStatus.SUSPENDED:
      return "suspended";
    default:
      return "canceled";
  }
};

// .....................

export enum ComplaintType {
  PUBLIC_SERVICE = 1,
  DELIVERY_AGENT = 2,
  DELIVERED_PRODUCTS = 3,
}

export const getComplaintTypeColor = (status: ComplaintType) => {
  switch (status) {
    case ComplaintType.DELIVERED_PRODUCTS:
      return "blue";
    case ComplaintType.DELIVERY_AGENT:
      return "green";
    case ComplaintType.PUBLIC_SERVICE:
      return "orange";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getComplaintType = (status: ComplaintType) => {
  switch (status) {
    case ComplaintType.DELIVERED_PRODUCTS:
      return "delivered_products";
    case ComplaintType.DELIVERY_AGENT:
      return "delivery_agent";
    case ComplaintType.PUBLIC_SERVICE:
      return "public_service";
    default:
      return "canceled";
  }
};
