import { Row, Col, Divider } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import OrderNoteContext from "../../../context/order-note/context";
import OrderNoteContextProvider from "../../../context/order-note/provider";
import { IUpdateOrderNote } from "../../../models/order-note/request";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(OrderNoteContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateOrderNote(details?.id, data)
          : actions.createOrderNote(data);
      }}
      title={details ? t("update_order_note") : t("add_order_note")}
      defaultValues={
        {
          text: getTranslations(details?.translations?.text),
          note: getTranslations(details?.translations?.note),
        } as IUpdateOrderNote
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`text[${lang?.code}]`}
                    label={`${t("text")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const OrderNoteForm = () => {
  return (
    <OrderNoteContextProvider>
      <Form />
    </OrderNoteContextProvider>
  );
};
export default OrderNoteForm;
