import { Button, Col, Divider, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import StoreContext from "../../../context/store/context";
import StoreContextProvider from "../../../context/store/provider";
import { ICity } from "../../../models/city/response";
import {
  getStorStatus,
  getStorType,
  getWorkStatus,
} from "../../../models/store/enum";
import { IUpdateStore } from "../../../models/store/request";
import EndPoints from "../../../services/end-points";
import {
  MAP_DEFAULT_LOCATION,
  languages,
} from "../../../utils/helpers/constants";
import { execute } from "../../../utils/helpers/execute";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import Marker from "../../general/map/marker/index";
import styles from "./style.module.scss";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
const Option = Select.Option;

const Form = () => {
  const { details, actions, loading } = useContext(StoreContext);
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const storeStatus = [1, 2];
  const storeTypes = [1, 2];
  const [cities, setCities] = useState<ICity[]>([]);

  const [citiesLoading, setCitiesLoading] = useState(true);
  const methods = useForm<any>({
    defaultValues: {
      pharmacyPromotion: details?.pharmacyPromotion ?? false,
      freeDelivery: details?.freeDelivery ?? false,
      location: details?.location ?? {
        type: "Point",
        coordinates: [1, 2],
      },
    } as IUpdateStore,
  });

  useEffect(() => {
    const getCities = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getCities();
  }, [direction]);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  const pointMapChanges = useWatch({
    control,
    name: "map",
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.store.updateStore(details?.id, {
              ...data,

              location: {
                type: "Point",
                coordinates: data.map
                  ? [data.map.lng, data.map.lat]
                  : [
                      details?.location?.coordinates[0],
                      details?.location?.coordinates[1],
                    ],
              },
            })
          : await EndPoints.store.createStore({
              ...data,
              location: {
                type: "Point",
                coordinates: data.map
                  ? [data.map.lng, data.map.lat]
                  : [MAP_DEFAULT_LOCATION.lng, MAP_DEFAULT_LOCATION.lat],
              },
            });
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  useEffect(() => {
    reset({
      pharmacyPromotion: details?.pharmacyPromotion ?? false,
      address: details?.address,
      cityId: details?.city?.id,
      firstPhone: details?.firstPhone,
      note: getTranslations(details?.translations?.note) ?? null,
      name: getTranslations(details?.translations?.name),
      secondPhone: details?.secondPhone,
      status: details?.status,
      type: details?.type,
      freeDelivery: details?.freeDelivery,
      endWorkingTime: details?.endWorkingTime,
      startWorkingTime: details?.startWorkingTime,
      imageId: details?.image?.id,
      logoId: details?.logo?.id,
      workStatus: details?.workStatus,
    } as IUpdateStore);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_store") : t("add_store")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 8]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`name[${lang?.code}]`}
                        label={`${t("name")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: true }}
                      />
                    </Col>
                  );
                })}

                <Col span={8}>
                  <FieldBuilder
                    name="firstPhone"
                    label={t("first_phone")}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="secondPhone"
                    label={t("second_phone")}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="status"
                    label={`${t("status")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: storeStatus?.map((status, index) => {
                        return {
                          key: index,
                          label: t(getStorStatus(status)),
                          value: status,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="type"
                    label={`${t("type")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: storeTypes?.map((type, index) => {
                        return {
                          key: index,
                          label: t(getStorType(type)),
                          value: type,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="workStatus"
                    label={`${t("current_status")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2]?.map((type, index) => {
                        return {
                          key: index,
                          label: t(getWorkStatus(type)),
                          value: type,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="cityId"
                    label={`${t("city")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: citiesLoading,
                      allowSearch: true,
                      options: cities?.map((city, index) => {
                        return {
                          key: index,
                          label: city.name,
                          value: city.id,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    label={`${t("start_working_time")}`}
                    name="startWorkingTime"
                    rules={{
                      required: true,
                    }}
                    input={{ type: "time-picker", format: "HH:mm" }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    label={`${t("end_working_time")}`}
                    name="endWorkingTime"
                    rules={{
                      required: true,
                    }}
                    input={{ type: "time-picker", format: "HH:mm" }}
                  />
                </Col>

                <Col span={8}></Col>

                <Col span={12}>
                  <FieldBuilder
                    label={`${t("promotion_pharmacy")}`}
                    name="pharmacyPromotion"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    label={`${t("free_delivery")}`}
                    name="freeDelivery"
                    input={{ type: "checkBox" }}
                  />
                </Col>

                <Col span={24}>
                  <FieldBuilder
                    name="address"
                    label={t("store_address")}
                    input={{ type: "text-area" }}
                    rules={{ required: true }}
                  />
                </Col>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`note[${lang?.code}]`}
                        label={`${t("note")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
                <Col span={24}>
                  <FormItem label={t("image")} required>
                    <Controller
                      name="imageId"
                      rules={{
                        required: {
                          value: true,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <ImageUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              // setfileId(response);
                              field.onChange(response?.id);
                            }}
                            imageFor={MediumFor.UserProfile}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {details?.image && <OldImage src={details?.image?.url} />}

                <Col span={24}>
                  <FormItem label={t("logo")} required>
                    <Controller
                      name="logoId"
                      rules={{
                        required: {
                          value: true,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <ImageUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              // setfileId(response);
                              field.onChange(response?.id);
                            }}
                            imageFor={MediumFor.UserProfile}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {details?.image && <OldImage src={details?.logo?.url} />}
                <FormProvider {...methods}>
                  <Col span={24}>
                    <FormItem label={t("map")}>
                      <Controller
                        name="map"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Marker
                              name={details?.name}
                              draggable={true}
                              onChange={field?.onChange}
                              location={details?.location}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                </FormProvider>
              </Row>
            </div>
          )}
        </div>

        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const StoreForm = () => {
  return (
    <StoreContextProvider>
      <Form />
    </StoreContextProvider>
  );
};
export default StoreForm;
