import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import OrderContext from "../../context/order/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import OrderContextProvider from "../../context/order/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import OrderTable from "../../components/order/order-table";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import {
  IExportOrderExcel,
  colomnsExportOrderExcel,
} from "../../models/order/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import OrderFilter from "../../components/order/order-filter";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Order: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const { actions, loading, query } = useContext(OrderContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("orders")}
        subTitle={t("orders_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
        ]}
      />
      <ExportToExcelModal
        id="export-order"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            number: false,
            client: false,
            clientAddress: false,
            totalPrice: false,
            totalPriceOnClient: false,
            priceDeliveryService: false,
            priceDeliveryServiceOnClient: false,
            priceDeliveryYield: false,
            productYield: false,
            orderBroadcastArea: false,
            orderBroadcastCity: false,
            orderSchedulingType: false,
            productBroadcastArea: false,
            productBroadcastCity: false,
            recipeAsText: false,
            distance: false,
            rating: false,
            contact: false,
            clientNoteDelivery: false,
            clientRateNote: false,
            selectedStore: false,
            reasonCancel: false,
            startDatetimeDriver: false,
            finishDatetime: false,
            scheduleTime: false,
            updatedAt: false,
            deletedAt: false,
            driver: false,
            type: false,
            store: false,
            status: false,
            finalPrice: false,
            isEmergency: false,
            isRecipe: false,
            clientNote: false,
            note: false,
            createdAt: false,
            clientMobile: false,
            driverMobile: false,
            systemNote: false,
            productsDiscount: false,
            discountCodeCode: false,
            ringingBell: false,
            pharmacist : false,
            storeHaveRecipe: false
          } as IExportOrderExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportOrderExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="order-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <OrderFilter></OrderFilter>
      </SearchFilterCard>

      <OrderTable />
    </div>
  );
};

const OrderPage = () => {
  return (
    <OrderContextProvider>
      <Order />
    </OrderContextProvider>
  );
};
export default OrderPage;
