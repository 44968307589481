import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import PaymentDeliveryWorkerTable from "../../components/payment-delivery-worker/table";
import AppContext from "../../context/app/context";
import PaymentDeliveryWorkerContext from "../../context/payment-delivery-worker/context";
import PaymentDeliveryWorkerContextProvider from "../../context/payment-delivery-worker/provider";
import { useIsMount } from "../../utils/hooks/is-mount";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import styles from "./style.module.scss";
import PointDetails from "../../components/payment-delivery-worker/point";
import AuthContext from "../../context/auth/context";
import PaymentDeliveryWorkerFilter from "../../components/payment-delivery-worker/filter";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import {
  IExportPaymentDeliveryWorkerExcel,
  colomnsExportPaymentDeliveryWorkerExcel,
} from "../../models/payment-delivery-worker/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const PaymentDeliveryWorker: React.FC<IProps> = (props) => {
  const userPermissions = localStorage?.getItem("permisionStoroge")?.split(",");

  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { userDetails: user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const { actions, loading, query } = useContext(PaymentDeliveryWorkerContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    if (user) !isMounted && actions.getData();
  }, [query, direction, user]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("payment_delivery_worker")}
        subTitle={t("payment_delivery_worker_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <>
            {userPermissions?.includes("can_view_pads") && (
              <Divider key={1} type="vertical" />
            )}
          </>,
          <>
            <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
              {userPermissions?.includes("can_view_pads") && (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/payment-delivery-worker/create`);
                  }}
                  icon={<PlusOutlined />}
                >
                  {t("add_payment_delivery_worker")}
                </Button>
              )}
            </Can>
          </>,
        ]}
      />
      <ExportToExcelModal
        id="export-payment_delivery"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            id: false,
            employ: false,
            note: false,
            isConfirmed: false,
            collectionPoint: false,
            receiptNumber: false,
            value: false,
            driver: false,
          } as IExportPaymentDeliveryWorkerExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportPaymentDeliveryWorkerExcel} />
      </ExportToExcelModal>
      {userPermissions?.includes("can_view_pads") && (
        <>
          <PointDetails></PointDetails>
          <Divider />
        </>
      )}

      <SearchFilterCard
        formId="payment-delivery-worker-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <PaymentDeliveryWorkerFilter></PaymentDeliveryWorkerFilter>
      </SearchFilterCard>

      <PaymentDeliveryWorkerTable />
    </div>
  );
};

const PaymentDeliveryWorkerPage = () => {
  return (
    // ContextProvider
    <PaymentDeliveryWorkerContextProvider>
      <PaymentDeliveryWorker />
    </PaymentDeliveryWorkerContextProvider>
  );
};
export default PaymentDeliveryWorkerPage;
