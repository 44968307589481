import { ITranslationRequest } from "../base/translation";
export interface ICreateClient {
  firstName: string;
  lastName: string;
  mobile: string;
  phone: string;
  status: number;
  gender: number;
  bloodType: string;
  weight: number;
  email: string;
  height: number;
  birthDate: string;
  note: ITranslationRequest;
  healthStatus: ITranslationRequest;
  cityId: number;
  address: string;
  imageId: number;
  diseaseId: number[];
  nationalIdentityNumber: string;
}

export interface IUpdateClient extends ICreateClient { }

export interface IExportClientExcel {
  id: boolean
  firstName: boolean
  lastName: boolean
  city: boolean
  status: boolean
  mobile: boolean
  createdAt: boolean
}

export const columnsExportClientExcel = ['id',
  'firstName',
  'lastName',
  'city',
  'status',
  'mobile',
  'createdAt'];
