import {
  Descriptions as AntdDescriptions,
  Col,
  Collapse,
  Row,
  Button,
} from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import StoreContext from "../../../context/store/context";
import StoreContextProvider from "../../../context/store/provider";
import Marker from "../../general/map/marker";
import {
  getStorStatus,
  getStorType,
  getStoreStatusColor,
  getStoreTypeColor,
  getWorkStatus,
  getWorkStatusColor,
} from "../../../models/store/enum";
import Tag from "../../general/antd/tag";
import { ImCheckmark, ImCross } from "react-icons/im";
import OldImage from "../../form-components/old-image";
import ProductStoreContextProvider from "../../../context/product-store/provider";
import ProductStoreTable from "../../product-store/produc-store-table";
import PrizeDetails from "../prize-details";
import PrizeContext from "../../../context/prize/context";
import PrizeContextProvider from "../../../context/prize/provider";

const { Panel } = Collapse;

interface Props {}

const Store: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(StoreContext);
  const { actions: actionsPrize, loading: loadingPrize } =
    useContext(PrizeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("store")}
        buttonName={`${t("update_store")}`}
        onClick={() => navigate(`/stores/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("details")} key={1}>
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("name")}>
                      {details?.name}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("city")}>
                      {details?.city?.name}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("pharmacy_account_status")}>
                      <Tag
                        title={getStorStatus(details?.status)}
                        color={getStoreStatusColor(details?.status)}
                      />
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("type")}>
                      <Tag
                        title={getStorType(details?.type)}
                        color={getStoreTypeColor(details?.type)}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("current_status")}>
                      <Tag
                        title={getWorkStatus(details?.workStatus)}
                        color={getWorkStatusColor(details?.workStatus)}
                      />
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("promotion_pharmacy")}>
                      {details?.pharmacyPromotion ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("points_balance")}>
                      {details?.pointsBalance}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("first_phone")}>
                      {details?.firstPhone}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("second_phone")}>
                      {details?.secondPhone}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("start_working_time")}>
                      {details?.startWorkingTime ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("end_working_time")}>
                      {details?.endWorkingTime ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("address")}>
                      {details?.address}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("busy")}>
                      <>
                        {details?.busy ? (
                          <ImCheckmark color="green" />
                        ) : (
                          <ImCross color="red" />
                        )}
                      </>
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("numOfUnResponseOrder")}>
                      {details?.numOfUnResponseOrder}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("image")}>
                      {details?.image ? (
                        <OldImage preview src={details?.image?.url} />
                      ) : (
                        "-"
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("image")}>
                      {details?.image ? (
                        <OldImage preview src={details?.logo?.url} />
                      ) : (
                        "-"
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>

            <Col span={24}>
              <Collapse>
                <Panel header={t("products")} key={1}>
                  <ProductStoreTable />
                </Panel>
              </Collapse>
            </Col>

            <Col span={24}>
              <Collapse>
                <Panel header={t("location")} key={1}>
                  <Marker
                    width="75vw"
                    name={details?.name}
                    draggable={false}
                    location={details?.location}
                  />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const StoreDetailesPage = () => {
  return (
    <StoreContextProvider>
      <ProductStoreContextProvider>
        <PrizeContextProvider>
          <Store />
        </PrizeContextProvider>
      </ProductStoreContextProvider>
    </StoreContextProvider>
  );
};
export default StoreDetailesPage;
