import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IDeliveryWorker,
  IDeliveryWorkerDetails,
} from "../../models/delivery-workers/response";
import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import {
  ICreateDeliveryWorker,
  IUpdateDeliveryWorker,
} from "../../models/delivery-workers/request";
import { IDoctor, IDoctorDetails } from "../../models/doctors/response";
import { IDoctorQuery } from "../../models/doctors/query";
import { ICreateDoctor, IUpdateDoctor } from "../../models/doctors/request";

export type doctorLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: doctorLoading[];

  list?: IBaseListingResponse<IDoctor>;
  query: IDoctorQuery;

  details?: IDoctorDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDeliveryWorker) => void;

    createDoctor: (request: ICreateDoctor) => void;
    updateDoctor: (id: number, request: IUpdateDoctor) => void;
    deleteDoctor: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDeliveryWorkerQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    deleteDoctor: DEFAULT_FUNCTION,
    updateDoctor: DEFAULT_FUNCTION,
    createDoctor: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DoctorContext = createContext(externalState);

export default DoctorContext;
