import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IDiscountCodeQuery } from "../../models/discount-code/query";
import {
  IDiscountCode,
  IDiscountCodeClient,
  IDiscountCodeDetails,
} from "../../models/discount-code/response";
import {
  ICreateDiscountCode,
  ICreateDiscountCodeClients,
  IExportDiscountCodeExcel,
  IUpdateDiscountCode,
} from "../../models/discount-code/request";
import { IClient } from "../../models/client/response";

class DiscountCodeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDiscountCode = (query: IDiscountCodeQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDiscountCode>>({
      path: `/discount-codes`,
      method: "GET",
      query,
      ...params,
    });

  getDiscountCode = (id: number, params?: RequestParams) =>
    this.http.request<IDiscountCodeDetails>({
      path: `/discount-codes/${id}`,
      method: "GET",
      ...params,
    });

  createDiscountCode = (data: ICreateDiscountCode, params?: RequestParams) =>
    this.http.request<IDiscountCode>({
      path: "/discount-codes",
      method: "POST",
      body: data,
      ...params,
    });

  updateDiscountCode = (
    id: number,
    data: IUpdateDiscountCode,
    params?: RequestParams
  ) =>
    this.http.request<IDiscountCode>({
      path: `/discount-codes/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDiscountCode = (id: number, params?: RequestParams) =>
    this.http.request<IDiscountCode>({
      path: `/discount-codes/${id}`,
      method: "DELETE",
      ...params,
    });

  getClients = (
    idCode: number,
    query: IDiscountCodeQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDiscountCodeClient>>({
      path: `/discount-codes/clients/${idCode}`,
      method: "GET",
      ...params,
    });

  createDiscountCodeClients = (
    data: ICreateDiscountCodeClients,
    params?: RequestParams
  ) =>
    this.http.request<IDiscountCodeClient>({
      path: "/discount-codes/give/client",
      method: "POST",
      body: data,
      ...params,
    });

  deleteDiscountCodeClients = (
    data: ICreateDiscountCodeClients,
    params?: RequestParams
  ) =>
    this.http.request<IDiscountCodeClient>({
      path: `/discount-codes/remove/client`,
      method: "DELETE",
      body: data,
      ...params,
    });

  exportExcel = (data: IExportDiscountCodeExcel, query: IDiscountCodeQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/discount-codes/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default DiscountCodeService;
