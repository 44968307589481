import axios from "axios";
import { HttpClient } from "../../utils/api/http-client";

class DriverTrackingService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }
  getAllDriverTrackings: any = (
    user_type?: string,
    longitude?: number,
    latitude?: number,
    radius?: number,
    unit?: string,
    count?: number
  ) => {
    const url = `${process.env.REACT_APP_BASE_API_URL_ORION}/nearby-search`;
    const params = {
      user_type: user_type,
      longitude: longitude,
      latitude: latitude,
      radius: radius,
      unit: unit,
      count: count,
    };

    axios.get(url, { params });
  };
}
export default DriverTrackingService;
