export enum Permissions {
  CAN_VIEW_PERMISSIONS = "can_view_permissions",

  CAN_VIEW_ALL_ROLES = "can_view_all_roles",
  CAN_VIEW_ROLE = "can_view_role",
  CAN_CREATE_ROLE = "can_create_role",
  CAN_UPDATE_ROLE = "can_update_role",
  CAN_DELETE_ROLE = "can_delete_role",
  CAN_EXPORT_ROLE = "can_export_role",

  CAN_VIEW_ALL_AREA = "can_view_all_area",
  CAN_VIEW_AREA = "can_view_area",
  CAN_CREATE_AREA = "can_create_area",
  CAN_UPDATE_AREA = "can_update_area",
  CAN_DELETE_AREA = "can_delete_area",
  CAN_EXPORT_AREA = "can_export_area",

  CAN_VIEW_ALL_BANNERS = "can_view_all_banners",
  CAN_VIEW_BANNER = "can_view_banner",
  CAN_CREATE_BANNER = "can_create_banner",
  CAN_UPDATE_BANNER = "can_update_banner",
  CAN_DELETE_BANNER = "can_delete_banner",
  CAN_EXPORT_BANNER = "can_export_banner",

  CAN_VIEW_ALL_CATEGORIES = "can_view_all_categories",
  CAN_VIEW_CATEGORY = "can_view_category",
  CAN_CREATE_CATEGORY = "can_create_category",
  CAN_UPDATE_CATEGORY = "can_update_category",
  CAN_DELETE_CATEGORY = "can_delete_category",
  CAN_EXPORT_CATEGORY = "can_export_category",

  CAN_VIEW_ALL_CHRONIC_DISEASE = "can_view_all_chronic_disease",
  CAN_VIEW_CHRONIC_DISEASE = "can_view_chronic_disease",
  CAN_CREATE_CHRONIC_DISEASE = "can_create_chronic_disease",
  CAN_UPDATE_CHRONIC_DISEASE = "can_update_chronic_disease",
  CAN_DELETE_CHRONIC_DISEASE = "can_delete_chronic_disease",

  CAN_VIEW_ALL_CITIES = "can_view_all_cities",
  CAN_VIEW_CITY = "can_view_city",
  CAN_CREATE_CITY = "can_create_city",
  CAN_UPDATE_CITY = "can_update_city",
  CAN_DELETE_CITY = "can_delete_city",
  CAN_EXPORT_CITY = "can_export_city",

  CAN_VIEW_CLIENT_ADDRESS = "can_view_client_address",
  CAN_CREATE_CLIENT_ADDRESS = "can_create_client_address",
  CAN_UPDATE_CLIENT_ADDRESS = "can_update_client_address",
  CAN_DELETE_CLIENT_ADDRESS = "can_delete_client_address",

  CAN_VIEW_ALL_CLIENTS = "can_view_all_clients",
  CAN_VIEW_CLIENT = "can_view_client",
  CAN_UPDATE_CLIENT = "can_update_client",
  CAN_DELETE_CLIENT = "can_delete_client",
  CAN_EXPORT_CLIENT = "can_export_client",

  CAN_VIEW_ALL_COLLECTION_POINTS = "can_view_all_collection_points",
  CAN_VIEW_COLLECTION_POINT = "can_view_collection_point",
  CAN_CREATE_COLLECTION_POINT = "can_create_collection_point",
  CAN_UPDATE_COLLECTION_POINT = "can_update_collection_point",
  CAN_DELETE_COLLECTION_POINT = "can_delete_collection_point",
  CAN_EXPORT_COLLECTION_POINT = "can_export_collection_point",

  CAN_VIEW_ALL_COLLECTION_POINT_PAYMENTS = "can_view_all_collection_point_payments",
  CAN_VIEW_COLLECTION_POINT_PAYMENT = "can_view_collection_point_payment",
  CAN_CREATE_COLLECTION_POINT_PAYMENT = "can_create_collection_point_payment",
  CAN_UPDATE_COLLECTION_POINT_PAYMENT = "can_update_collection_point_payment",
  CAN_DELETE_COLLECTION_POINT_PAYMENT = "can_delete_collection_point_payment",
  CAN_EXPORT_COLLECTION_POINT_PAYMENT = "can_export_collection_point_payment",

  CAN_VIEW_COMMON_ALL_QUESTIONS = "can_view_common_all_questions",
  CAN_VIEW_COMMON_QUESTIONS = "can_view_common_questions",
  CAN_CREATE_COMMON_QUESTIONS = "can_create_common_questions",
  CAN_UPDATE_COMMON_QUESTIONS = "can_update_common_questions",
  CAN_DELETE_COMMON_QUESTIONS = "can_delete_common_questions",
  IEW_ALL_COMPLAINTS = "can_view_all_complaints",
  CAN_VIEW_COMPLAINTS = "can_view_complaint",
  CAN_CREATE_COMPLAINTS = "can_create_complaint",
  CAN_UPDATE_COMPLAINTS = "can_update_complaint",
  CAN_DELETE_COMPLAINTS = "can_delete_complaint",
  CAN_EXPORT_COMPLAINTS = "can_export_complaint",
  CAN_CANCEL_COMPLAINTS = "can_cancel_complaint",
  CAN_RATE_COMPLAINTS = "can_rate_complaint",

  CAN_VIEW_ALL_DISCOUNT_CODES = "can_view_all_discount_code",
  CAN_VIEW_DISCOUNT_CODES = "can_view_discount_code",
  CAN_CREATE_DISCOUNT_CODES = "can_create_discount_code",
  CAN_UPDATE_DISCOUNT_CODES = "can_update_discount_code",
  CAN_DELETE_DISCOUNT_CODES = "can_delete_discount_code",
  CAN_EXPORT_DISCOUNT_CODES = "can_export_discount_code",
  CAN_BUY_DISCOUNT_CODES = "can_buy_discount_code",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Driver">
  /**
   * Drivers Permissions
   */
  CAN_VIEW_ALL_DRIVERS = "can_view_all_drivers",
  CAN_VIEW_DRIVER = "can_view_driver",
  CAN_CREATE_DRIVER = "can_create_driver",
  CAN_UPDATE_DRIVER = "can_update_driver",
  CAN_DELETE_DRIVER = "can_delete_driver",
  CAN_EXPORT_DRIVER = "can_export_driver",

  // </editor-fold> // <editor-fold default-state="collapsed" desc="Driver">
  /**
   * Drivers Permissions
   */
  CAN_VIEW_ALL_DOCTOR = "can_view_all_doctors",
  CAN_VIEW_DOCTOR = "can_view_doctor",
  CAN_CREATE_DOCTOR = "can_create_doctor",
  CAN_UPDATE_DOCTOR = "can_update_doctor",
  CAN_DELETE_DOCTOR = "can_delete_doctor",
  CAN_EXPORT_DOCTOR = "can_export_doctor",
  // </editor-fold>

  // </editor-fold> // <editor-fold default-state="collapsed" desc="Driver">
  /**
   * Drivers Permissions
   */
  CAN_VIEW_ALL_DOCTOR_RECIPE = "can_view_all_doctor_recipe",
  CAN_VIEW_DOCTOR_RECIPE = "can_view_doctor_recipe",
  CAN_CREATE_DOCTOR_RECIPE = "can_create_doctor_recipe",
  CAN_UPDATE_DOCTOR_RECIPE = "can_update_doctor_recipe",
  CAN_DELETE_DOCTOR_RECIPE = "can_delete_doctor_recipe",
  CAN_EXPORT_DOCTOR_RECIPE = "can_export_doctor_recipe",
  // </editor-fold>

  // </editor-fold> // <editor-fold default-state="collapsed" desc="Driver">
  /**
   * Drivers Permissions
   */
  CAN_VIEW_ALL_RECIPE = "can_view_all_recipe",
  CAN_VIEW_RECIPE = "can_view_recipe",
  CAN_CREATE_RECIPE = "can_create_recipe",
  CAN_UPDATE_RECIPE = "can_update_recipe",
  CAN_DELETE_RECIPE = "can_delete_recipe",
  CAN_EXPORT_RECIPE = "can_export_recipe",
  // </editor-fold>

  // </editor-fold> // <editor-fold default-state="collapsed" desc="Driver">
  /**
   * Drivers Permissions
   */
  CAN_VIEW_ALL_MEDICAL_SPECIALTY = "can_view_all_medical_specialty",
  CAN_VIEW_MEDICAL_SPECIALTY = "can_view_medical_specialty",
  CAN_CREATE_MEDICAL_SPECIALTY = "can_create_medical_specialty",
  CAN_UPDATE_MEDICAL_SPECIALTY = "can_update_medical_specialty",
  CAN_DELETE_MEDICAL_SPECIALTY = "can_delete_medical_specialty",
  CAN_EXPORT_MEDICAL_SPECIALTY = "can_export_medical_specialty",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Employee">
  /**
   * Employees Permissions
   */
  CAN_VIEW_ALL_EMPLOYEES = "can_view_all_employees",
  CAN_VIEW_EMPLOYEE = "can_view_employee",
  CAN_CREATE_EMPLOYEE = "can_create_employee",
  CAN_UPDATE_EMPLOYEE = "can_update_employee",
  CAN_DELETE_EMPLOYEE = "can_delete_employee",
  CAN_EXPORT_EMPLOYEE = "can_export_employee",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="General Notification">
  /**
   * General Notifications Permissions
   */
  CAN_VIEW_ALL_GENERAL_NOTIFICATIONS = "can_view_all_general_notifications",
  CAN_VIEW_GENERAL_NOTIFICATION = "can_view_general_notification",
  CAN_CREATE_GENERAL_NOTIFICATION = "can_create_general_notification",
  CAN_UPDATE_GENERAL_NOTIFICATION = "can_update_general_notification",
  CAN_DELETE_GENERAL_NOTIFICATION = "can_delete_general_notification",
  CAN_EXPORT_GENERAL_NOTIFICATION = "can_export_general_notification",
  CAN_SEND_GENERAL_NOTIFICATION = "can_export_send_notification",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="General Setting">
  /**
   * General Settings Permissions
   */
  CAN_VIEW_GENERAL_SETTING = "can_view_general_setting",
  CAN_UPDATE_GENERAL_SETTING = "can_update_general_setting",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Statistics">
  /**
   * Statistics Permissions
   */
  CAN_VIEW_STATISTICS = "can_view_statistics",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Homepage Listing">
  /**
   * Homepage Listings Permissions
   */
  CAN_VIEW_ALL_HOMEPAGE_LISTING = "can_view_all_homepage_listing",
  CAN_VIEW_HOMEPAGE_LISTING = "can_view_homepage_listing",
  CAN_CREATE_HOMEPAGE_LISTING = "can_create_homepage_listing",
  CAN_UPDATE_HOMEPAGE_LISTING = "can_update_homepage_listing",
  CAN_DELETE_HOMEPAGE_LISTING = "can_delete_homepage_listing",
  CAN_EXPORT_HOMEPAGE_LISTING = "can_export_homepage_listing",
  CAN_VIEW_ACTIVE_HOMEPAGE_LISTING = "can_view_active_homepage_listing",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Investor">
  /**
   * Investors Permissions
   */
  CAN_VIEW_ALL_INVESTORS = "can_view_all_investors",
  CAN_VIEW_INVESTOR = "can_view_investor",
  CAN_CREATE_INVESTOR = "can_create_investor",
  CAN_UPDATE_INVESTOR = "can_update_investor",
  CAN_DELETE_INVESTOR = "can_delete_investor",
  CAN_EXPORT_INVESTOR = "can_export_investor",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Medication Package">
  /**
   * Medication Packages Permissions
   */
  CAN_VIEW_ALL_MEDICATION_PACKAGES = "can_view_all_medication_packages",
  CAN_VIEW_MEDICATION_PACKAGE = "can_view_medication_package",
  CAN_CREATE_MEDICATION_PACKAGE = "can_create_medication_package",
  CAN_UPDATE_MEDICATION_PACKAGE = "can_update_medication_package",
  CAN_DELETE_MEDICATION_PACKAGE = "can_delete_medication_package",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Order">
  /**
   * Orders Permissions
   */
  CAN_VIEW_ALL_ORDERS = "can_view_all_orders",
  CAN_VIEW_ORDER = "can_view_order",
  CAN_CREATE_ORDER = "can_create_order",
  CAN_UPDATE_ORDER = "can_update_order",
  CAN_DELETE_ORDER = "can_delete_order",
  CAN_EXPORT_ORDER = "can_export_order",
  CAN_ACCEPT_ORDER = "can_accept_order",
  CAN_REJECT_ORDER = "can_reject_order",
  CAN_CHECKOUT_ORDER = "can_checkout_order",
  CAN_FINISH_ORDER = "can_finish_order",
  CAN_CANCEL_ORDER = "can_cancel_order",
  CAN_SHOW_ACTIVE_ORDER_FOR_DRIVER_ORDER = "can_show_active_for_driver_order",
  CAN_EMERGENCY_ORDER = "can_emergency_order",
  CAN_COMPLETE_ORDER = "can_complete_order",
  CAN_CHANGE_STATUS_ORDER_TO_PICKUP_PRODUCT = "can_change_status_order_to_pickup_product",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Order Note">
  /**
   * Order Notes Permissions
   */
  CAN_VIEW_ALL_ORDER_NOTES = "can_view_all_order_notes",
  CAN_VIEW_ORDER_NOTE = "can_view_order_note",
  CAN_CREATE_ORDER_NOTE = "can_create_order_note",
  CAN_UPDATE_ORDER_NOTE = "can_update_order_note",
  CAN_DELETE_ORDER_NOTE = "can_delete_order_note",
  CAN_EXPORT_ORDER_NOTE = "can_export_order_note",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Payments Management">
  /**
   * Payments Managements Permissions
   */
  CAN_VIEW_ANY_PAYMENTS_MANAGEMENT = "can_view_any_payments_management",
  CAN_VIEW_PAYMENTS_MANAGEMENT = "can_view_payments_management",
  CAN_CREATE_PAYMENTS_MANAGEMENT = "can_create_payments_management",
  CAN_UPDATE_PAYMENTS_MANAGEMENT = "can_update_payments_management",
  CAN_DELETE_PAYMENTS_MANAGEMENT = "can_delete_payments_management",
  CAN_EXPORT_PAYMENTS_MANAGEMENT = "can_export_payments_management",
  CAN_CONFIRM_PAYMENTS_MANAGEMENT = "can_confirm_payments_management",

  CAN_MANAGE_POLICY_TERM = "can_manage_policy_term",
  CAN_VIEW_POLICY_TERM = "can_view_policy_term",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Product">
  /**
   * Products Permissions
   */
  CAN_VIEW_ALL_PRODUCTS = "can_view_all_products",
  CAN_VIEW_PRODUCT = "can_view_product",
  CAN_CREATE_PRODUCT = "can_create_product",
  CAN_UPDATE_PRODUCT = "can_update_product",
  CAN_DELETE_PRODUCT = "can_delete_product",
  CAN_EXPORT_PRODUCT = "can_export_product",
  CAN_MANAGE_PRODUCT_IN_STORE = "can_manage_product_in_store",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Product in Store">
  /**
   * Product in Store Permissions
   */
  CAN_CREATE_PRODUCT_IN_STORE = "can_create_product_in_store",
  CAN_UPDATE_PRODUCT_IN_STORE = "can_update_product_in_store",
  CAN_DELETE_PRODUCT_IN_STORE = "can_delete_product_in_store",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Rate">
  /**
   * Rates Permissions
   */
  CAN_VIEW_RATE = "can_view_rate",
  CAN_CREATE_RATE = "can_create_rate",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Store">
  /**
   * Stores Permissions
   */
  CAN_VIEW_STORE = "can_view_store",
  CAN_VIEW_ALL_STORES = "can_view_all_stores",
  CAN_CREATE_STORE = "can_create_store",
  CAN_UPDATE_STORE = "can_update_store",
  CAN_DELETE_STORE = "can_delete_store",
  CAN_EXPORT_STORE = "can_export_store",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Tag">
  /**
   * Tags Permissions
   */
  CAN_VIEW_ALL_TAGS = "can_view_all_tags",
  CAN_VIEW_TAG = "can_view_tag",
  CAN_CREATE_TAG = "can_create_tag",
  CAN_UPDATE_TAG = "can_update_tag",
  CAN_DELETE_TAG = "can_delete_tag",
  CAN_EXPORT_TAG = "can_export_tag",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Trademark">
  /**
   * Trademarks Permissions
   */
  CAN_VIEW_ALL_TRADEMARKS = "can_view_all_trademarks",
  CAN_VIEW_TRADEMARK = "can_view_trademark",
  CAN_CREATE_TRADEMARK = "can_create_trademark",
  CAN_UPDATE_TRADEMARK = "can_update_trademark",
  CAN_DELETE_TRADEMARK = "can_delete_trademark",
  CAN_EXPORT_TRADEMARK = "can_export_trademark",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Unit">
  /**
   * Units Permissions
   */
  CAN_VIEW_UNITS = "can_view_units",
  CAN_VIEW_UNIT = "can_view_unit",
  CAN_CREATE_UNIT = "can_create_unit",
  CAN_UPDATE_UNIT = "can_update_unit",
  CAN_DELETE_UNIT = "can_delete_unit",
  CAN_EXPORT_UNIT = "can_export_unit",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Point">
  /**
   * Point Permissions
   */
  CAN_VIEW_POINT = "can_view_point",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Attributes">
  /**
   * Roles Permissions
   */
  CAN_VIEW_ALL_ATTRIBUTES = "can_view_all_attributes",
  CAN_VIEW_ATTRIBUTE = "can_view_attribute",
  CAN_CREATE_ATTRIBUTE = "can_create_attribute",
  CAN_UPDATE_ATTRIBUTE = "can_update_attribute",
  CAN_DELETE_ATTRIBUTE = "can_delete_attribute",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Attributes params">
  /**
   * Roles Permissions
   */
  CAN_VIEW_ALL_ATTRIBUTES_PARAMS = "can_view_all_attributes_params",
  CAN_VIEW_ATTRIBUTE_PARAM = "can_view_attribute_param",
  CAN_CREATE_ATTRIBUTE_PARAM = "can_create_attribute_param",
  CAN_UPDATE_ATTRIBUTE_PARAM = "can_update_attribute_param",
  CAN_DELETE_ATTRIBUTE_PARAM = "can_delete_attribute_param",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="Attributes">
  CAN_VIEW_MEDIUM = "can_view_medium",
  CAN_CREATE_MEDIUM = "can_create_medium",
  // </editor-fold>

  // <editor-fold default-state="collapsed" desc="dashboard">
  CAN_VIEW_SITTINGS = "can_view_sittings",
  CAN_VIEW_GENERAL_DATA = "can_view_general_data",
  CAN_VIEW_APPS_MANAGE = "can_view_apps_manage",
  CAN_VIEW_CLIENTS_STORES = "can_view_clients_stores",
  CAN_VIEW_PHARMACY_TEAM = "can_view_pharmacy_team",
  CAN_VIEW_CATEGORIES_PRODUCTS = "can_view_categories_products",
  CAN_VIEW_CLIENT_SERVICE = "can_view_clients_service",
  CAN_VIEW_GENERAL_PADS = "can_view_general_pads",
  CAN_VIEW_PADS = "can_view_pads",
  CAN_VIEW_CHAT = "can_view_chat",
  CAN_VIEW_FINANCIAL_DATA = "can_view_financial_data",
  CAN_TRACK_USERS = "can_track_users",
  CAN_VIEW_CONVERSATIONS = "can_view_conversations",
  // </editor-fold>
  CAN_SEARCH_USERS_LOCATION = "can_search_users_location",
  //slice
  CAN_VIEW_ALL_SLICES = "can_view_all_slices",
  CAN_VIEW_SLICE = "can_view_slice",
  CAN_CREATE_SLICE = "can_create_slice",
  CAN_UPDATE_SLICE = "can_update_slice",
  CAN_DELETE_SLICE = "can_delete_slice",
  CAN_EXPORT_SLICE = "can_export_slice",
  //
  CAN_VIEW_ALL_AWARDS = "can_view_all_awards",
  CAN_VIEW_AWARD = "can_view_award",
  CAN_CREATE_AWARD = "can_create_award",
  CAN_UPDATE_AWARD = "can_update_award",
  CAN_DELETE_AWARD = "can_delete_award",
  CAN_EXPORT_AWARD = "can_export_award",

  //report
  CAN_VIEW_REPORTS = "can_view_reports",
  CAN_EXPORT_PHARMACIES_ORDERS_REPORT = "can_export_pharmacies_Orders_report",
  CAN_EXPORT_ORDER_USER_DETAILS_REPORT = "can_export_order_user_details_report",
  CAN_EXPORT_ORDER_CANCELLATION_REPORT = "can_export_order_cancellation_report",
  CAN_EXPORT_DRIVER_PERFORMANCE_REPORT = "can_export_driver_performance_report",
  CAN_EXPORT_ORDERS_DAYLE_INFO_REPORT = "can_export_orders_dayle_info_report",
  CAN_EXPORT_ORDER_STATUS_REPORT = "can_export_order_status_report",
  CAN_EXPORT_PAID_ORDERS_JOURNEY_REPORT = "can_export_paid_orders_journey_report",
  CAN_EXPORT_ORDERS_DETAILS_REPORT = "can_export_orders_details_report",

  //order short

  CAN_VIEW_ALL_ORDERS_SHORT = "can_view_all_orders_short",
}
