import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import OrderContext from "../../../context/order/context";
import OrderContextProvider from "../../../context/order/provider";
import Tag from "../../general/antd/tag";
import {
  getOrderStatus,
  getOrderStatusColor,
  getOrderStyle,
  getOrderType,
  getOrderTypeColor,
  getorderSchedulingTypeStyle,
  getorderSchedulingTypeColor,
} from "../../../models/order/enum";
import { ImCheckmark, ImCross } from "react-icons/im";
import OrderStatusContextProvider from "../../../context/order-status/provider";
import OrderHistoryContextProvider from "../../../context/order-history/provider";
import OrderHistoryTable from "../order-history-table";
import OrderStatusTable from "../order-status-table";
import moment from "moment";
import OldImage from "../../form-components/old-image";
import ProductsOrderTable from "../products-order";
import RefreshBtn from "../../general/table-components/actions/refresh-btn";
import WorkerHistoryTable from "../../delivery-worker/delivery-worker-history";
import DeliveryWorkerHistoryContextProvider from "../../../context/delivery-worker-history/provider";
import Marker from "../../general/map/marker";
import StoreContextProvider from "../../../context/store/provider";
const { Panel } = Collapse;
interface Props {}

const Order: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(OrderContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isRefreshed, setIsRefreshed] = useState(false);
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction, isRefreshed]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("client")}
        buttonName={`${t("back_to_orders")}`}
        onClick={() => navigate(`/orders`)}
      >
        <div style={{ maxWidth: "50px", marginBottom: "5px" }}>
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => setIsRefreshed(!isRefreshed)}
          >
            {t("refresh")}
          </RefreshBtn>
        </div>
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("details")} key={1}>
                  <Descriptions size="small" column={2} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("number_id")}>
                      {details?.number}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("client")}>
                      {details?.client?.firstName ?? "-"}{" "}
                      {details?.client?.lastName ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("client_mobile")}>
                      {details?.client?.cleanMobile ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("client_address")}>
                      {details?.clientAddress ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("client")}>
                      {details?.client?.firstName ?? "-"}{" "}
                      {details?.client?.lastName ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("delivery_worker")}>
                      {details?.driver?.firstName ?? "-"}{" "}
                      {details?.driver?.lastName ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("delivery_worker_mobile")}>
                      {details?.driver?.cleanMobile ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("investor")}>
                      {details?.investor?.firstName ?? "-"}{" "}
                      {details?.investor?.lastName ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("store")}>
                      {details?.store?.name ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("store_have_recipe")}>
                      {details?.StoreHaveRecipe?.name ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("order_scheduling_type")}>
                      {
                        <Tag
                          title={getorderSchedulingTypeStyle(
                            details?.orderSchedulingType
                          )}
                          color={getorderSchedulingTypeColor(
                            details?.orderSchedulingType
                          )}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("schedule_time")}>
                      {details?.scheduleTime
                        ? moment(details?.scheduleTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "--"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("request_sourse_type")}>
                      {
                        <Tag
                          title={getOrderStyle(details?.type)}
                          color={getOrderTypeColor(details?.type)}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("current_order_status")}>
                      {
                        <Tag
                          title={getOrderStatus(details?.status)}
                          color={getOrderStatusColor(details?.status)}
                        />
                      }
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("date_and_time")}>
                      {moment(details?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("final_price")}>
                      {details?.finalPrice ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("reason_cancel")}>
                      {details?.reasonCancel ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("emergency")}>
                      {details?.isEmergency ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("order_broadcast_area")}>
                      {details?.orderBroadcastArea ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("order_broadcast_city")}>
                      {details?.orderBroadcastCity ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("product_broadcast_area")}>
                      {details?.productBroadcastArea ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("product_broadcast_city")}>
                      {details?.productBroadcastCity ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("is_recipe")}>
                      {details?.isRecipe ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("do_not_contact")}>
                      {!details?.contact ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("not_ringing_bell")}>
                      {!details?.ringingBell ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("expanded_search")}>
                      {details?.selectedStore ? (
                        <ImCross color="red" />
                      ) : (
                        <ImCheckmark color="green" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item
                      label={t("price_of_products_from_pharmacy")}
                    >
                      {details?.totalPrice}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("total_price_on_client")}>
                      {details?.totalPriceOnClient}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("products_yield")}>
                      {details?.productYield}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("price_delivery_service")}>
                      {details?.priceDeliveryService}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item
                      label={t("price_delivery_Service_on_client")}
                    >
                      {details?.priceDeliveryServiceOnClient}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("price_delivery_yield")}>
                      {details?.priceDeliveryYield}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("discountCode")}>
                      {details?.discountCode?.code}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("discountValueProduct")}>
                      {details?.discountCodeValueForProduct}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("discountValueDelivery")}>
                      {details?.discountCodeValueForDelivery}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("distance")}>
                      {details?.distance}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("applicable_discount_code")}
                    >
                      {details?.discountCode?.name}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("finish_date_time")}>
                      {details?.finishDatetime
                        ? moment(details?.finishDatetime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "--"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("start_datetime_driver")}>
                      {details?.startDatetimeDriver
                        ? moment(details?.startDatetimeDriver).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "--"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("recipe_as_text")}>
                      {details?.recipeAsText ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("recipes_images")} span={2}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "7px",
                        }}
                      >
                        {details?.recipes?.map((img) => {
                          return <OldImage src={img?.url} preview />;
                        })}
                      </div>
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("customer_evaluation_of_the_service")}
                      span={2}
                    >
                      {details?.rating}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("client_rate_note")}
                      span={2}
                    >
                      {details?.clientRateNote}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item
                      label={t("client_note_additional")}
                      span={2}
                    >
                      {details?.clientNote}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item
                      label={t("client_note_delivery")}
                      span={2}
                    >
                      {details?.clientNoteDelivery}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("order_notes")} span={2}>
                      {details?.notes?.map((note, index) => (
                        <div key={note?.id}>
                          <span> {index+1}-</span> {note?.text} : ({" "}
                          {note?.noteForNote} ). 
                        </div>
                      )) ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("managment_note")} span={2}>
                      {details?.note}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("location")} key={1}>
                  <Marker
                    width="75vw"
                    name={
                      details?.client?.firstName +
                      " " +
                      details?.client?.lastName
                    }
                    draggable={false}
                    location={details?.location}
                  />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse defaultActiveKey={1}>
                <Panel header={t("products_order")} key={1}>
                  <ProductsOrderTable data={details?.orderProducts} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("order_history")} key={1}>
                  <OrderHistoryTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("order_status")} key={1}>
                  <OrderStatusTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("history_driver")} key={1}>
                  <WorkerHistoryTable orderAssent={true} />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const OrderDetailesPage = () => {
  return (
    <OrderContextProvider>
      <OrderStatusContextProvider>
        <OrderHistoryContextProvider>
          <DeliveryWorkerHistoryContextProvider>
            <StoreContextProvider>
            <Order />
            </StoreContextProvider>
          </DeliveryWorkerHistoryContextProvider>
        </OrderHistoryContextProvider>
      </OrderStatusContextProvider>
    </OrderContextProvider>
  );
};
export default OrderDetailesPage;

//TODO

// "orderProducts": [],
// "reasonCancel": null
