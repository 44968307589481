import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { IProductQuery } from "../../models/product/query";
import { IPrize } from "../../models/prize/response";
import { ICreatePrize } from "../../models/prize/request";

class PrizeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPrize = (query: IProductQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPrize>>({
      path: `/award`,
      method: "GET",
      query,
      ...params,
    });

  getPrizet = (id: number, params?: RequestParams) =>
    this.http.request<IPrize>({
      path: `/award/${id}`,
      method: "GET",
      ...params,
    });

  createPrizet = (data: ICreatePrize, params?: RequestParams) =>
    this.http.request<IPrize>({
      path: "/award",
      method: "POST",
      body: data,
      ...params,
    });

  deletePrize = (id: number, params?: RequestParams) =>
    this.http.request<IPrize>({
      path: `/award/${id}`,
      method: "DELETE",
      ...params,
    });

}

export default PrizeService;
