import { AxiosRequestConfig } from "axios";
import i18next from "i18next";
import ApiError from "../utils/api/api-error";
import ApiErrorType from "../utils/api/api-error-type";
import { HttpClient } from "../utils/api/http-client";
import eventManager, {
  EVENT_ERORR,
  EVENT_FORBIDDEN,
  EVENT_UNAOUTHORIZED,
} from "../utils/events";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../utils/helpers/constants";

export const httpclient = new HttpClient<{ token?: string }>({
  securityWorker: (data) => {
    return {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
      },
    };
  },
  headers: {
    "accept-language": `${
      localStorage.getItem(LANGUAGE_CODE) ??
      process.env.REACT_APP_DEFAULT_LANGUAGE
    }`,
  },
});

httpclient.instance.interceptors.request.use(
  (config: any) => {
    config.headers["accept-language"] = `${localStorage.getItem(
      LANGUAGE_CODE
    )}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
httpclient.instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw handleError(error);
  }
);

const handleError = (error: ApiError): ApiError => {
  let message: string;
  if (error.response) {
    // The request was made and the server responded with an error status code.
    message = error.response.data.errors[0];

    let type: ApiErrorType;
    switch (error.response.status) {
      case 400:
        type = ApiErrorType.BAD_REQUEST;
        break;
      case 401:
        type = ApiErrorType.UNAUTHORIZED;
        eventManager.emit(EVENT_UNAOUTHORIZED);
        break;
      case 403:
        type = ApiErrorType.FORBIDDEN;
        eventManager.emit(EVENT_FORBIDDEN);
        break;
      case 404:
        type = ApiErrorType.NOT_FOUND;
        break;
      case 409:
        type = ApiErrorType.CONFLICT;
        break;
      case 500:
        type = ApiErrorType.INTERNAL_SERVER_ERROR;
        break;
      default:
        type = ApiErrorType.UNKNOWN;
        break;
    }

    error.errorType = type;
  } else if (error.request) {
    // The request was made but no response was received.
    message = "500";
    error.errorType = ApiErrorType.CONNECTION;
  } else {
    message = "500";
    error.errorType = ApiErrorType.UNKNOWN;
  }

  eventManager.emit(EVENT_ERORR, i18next.t(message));
  return error;
};
