import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import UserContext from "../../../context/user/context";
import UserContextProvider from "../../../context/user/provider";
import Tag from "../../general/antd/tag";
import { getUserStatusColor } from "../../../models/city/enum";
import { getUserStatus } from "../../../models/user/enum";
import OldImage from "../../form-components/old-image";

interface Props {}

const User: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("user")}
        buttonName={`${t("update_user")}`}
        onClick={() => navigate(`/users/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("first_name")}>
              {details?.user?.firstName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("last_name")}>
              {details?.user?.lastName}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("mobile")}>
              {details?.user?.cleanMobile}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("email")}>
              {details?.user?.email}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("address")}>
              {details?.address}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("phone")}>
              {details?.user?.phone}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("status")}>
              {
                <Tag
                  color={getUserStatusColor(details?.user?.status)}
                  title={getUserStatus(details?.user?.status)}
                />
              }
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("roles")}>
              {details?.user?.roles?.map((role, index) => {
                return <Tag title={role?.name} />;
              })}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("note_ar")}>
              {details?.user.translations?.note?.[0]?.value ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note_en")}>
              {details?.user.translations?.note?.[1]?.value ?? "-"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("image")}>
              <OldImage src={details?.user?.image?.url} preview />
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const UserDetailesPage = () => {
  return (
    <UserContextProvider>
      <User />
    </UserContextProvider>
  );
};
export default UserDetailesPage;
