import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IUnit } from "../../models/unit/response";
import { IUnitQuery } from "../../models/unit/query";
import {
  ICreateUnit,
  IExportUnitExcel,
  IUpdateUnit,
} from "../../models/unit/request";

class Unitsservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllUnits = (query: IUnitQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IUnit>>({
      path: `/units`,
      method: "GET",
      query,
      ...params,
    });

  getUnit = (id: number, params?: RequestParams) =>
    this.http.request<IUnit>({
      path: `/units/${id}`,
      method: "GET",
      ...params,
    });

  createUnit = (data: ICreateUnit, params?: RequestParams) =>
    this.http.request<IUnit>({
      path: "/units",
      method: "POST",
      body: data,
      ...params,
    });

  updateUnit = (id: number, data: IUpdateUnit, params?: RequestParams) =>
    this.http.request<IUnit>({
      path: `/units/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteUnit = (id: number, params?: RequestParams) =>
    this.http.request<IUnit>({
      path: `/units/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportUnitExcel, query: IUnitQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/units/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default Unitsservice;
