import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IInvestor } from "../../../models/investor/response";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import InvestorContext from "../../../context/investor/context";
import { getUserStatus } from "../../../models/user/enum";
import { getUserStatusColor } from "../../../models/city/enum";
import Tag from "../../general/antd/tag";
import moment from "moment";
import {
  getInvestorStatus,
  getInvestorType,
  getInvestoreStatusColor,
  getInvestoreTypeColor,
} from "../../../models/investor/enum";

interface IProps {}

const InvestorTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(InvestorContext);

  const columns: ColumnProps<IInvestor>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("first_name"),
      dataIndex: "firstName",
      sorter: {
        multiple: 2,
      },
      align: "center",
      key: "firstName",
      render: (_, record) => {
        return record?.user?.firstName;
      },
    },
    {
      title: t("last_name"),
      dataIndex: "lastName",
      align: "center",
      key: "lastName",
      sorter: {
        multiple: 3,
      },
      render: (_, record) => {
        return record?.user?.lastName;
      },
    },
    {
      title: t("mobile"),
      dataIndex: "mobile",
      align: "center",
      key: "mobile",
      render: (_, record) => {
        return record?.user?.cleanMobile ?? "-";
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            title={getUserStatus(record?.user?.status)}
            color={getUserStatusColor(record?.user?.status)}
          />
        );
      },
    },
    {
      title: t("current_status"),
      dataIndex: "statusInvestor",
      align: "center",
      key: "statusInvestor",
      render: (_, record) => {
        return (
          <Tag
            title={getInvestorStatus(record?.statusInvestor)}
            color={getInvestoreStatusColor(record?.statusInvestor)}
          />
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "type_investore",
      align: "center",
      key: "type",
      render: (_, record) => {
        return (
          <Tag
            title={getInvestorType(record?.type)}
            color={getInvestoreTypeColor(record?.type)}
          />
        );
      },
    },
    {
      title: t("store"),
      dataIndex: "store",
      align: "center",
      key: "type",
      render: (_, record) => {
        return record?.store?.name ?? "-";
      },
    },
    {
      title: t("city"),
      dataIndex: "city",
      align: "center",
      key: "type",
      render: (_, record) => {
        return record?.city?.name ?? "-";
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      align: "center",
      sorter: {
        multiple: 4,
      },
      key: "createdAt",
      render: (_, record) => {
        return moment(record?.createdAt).format("YYYY-MM-DD");
      },
    },
    {
      title: t("actions"),
      width: 200,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteInvestor(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{
          x: 1300,
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default InvestorTable;
