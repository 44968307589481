import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Input,
  List,
  Row,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ViewMarkers from "../../components/general/map/view-markers";
import styles from "./style.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import defaultUserImage from "../.././assets/images/sendMassageIcon1.png";
import bg from "../.././assets/images/chatBg3.svg";
import Icon from "@ant-design/icons/lib/components/Icon";
import MessageCart from "./components/massgeCart/MassageCart";
import UserContext from "../../context/user/context";
import TrackingContext from "../../context/tracking-map/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import moment from "moment";
import { relative } from "path";

const { TextArea } = Input;

const Chat = () => {
  const [selectedConversation, setSelectedConversation] = useState<string>();
  const [message, setMessage] = useState<string>();
  const { messages, conversations, actions } = useContext(TrackingContext);
  const { t } = useTranslation();
  useEffect(() => {
    actions.getConversations();
  }, []);
  const isMounted = useIsMount();
  useEffect(() => {
    selectedConversation && actions.getMessages(selectedConversation);
  }, [selectedConversation]);
  const sendMessage = async () => {
    await actions.sendMessage(message);
    setMessage("");
  };
  return (
    <Row
      style={{
        height: "100%",
        border: "solid 1px rgb(43, 54, 58)",
        borderRadius: "8px",
      }}
    >
      <>
        <Col span={6} style={{ height: "100%" }}>
          <List
            size="small"
            header={
              <div style={{ textAlign: "center" }}>{t("last_chats")}</div>
            }
            style={{
              maxHeight: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            dataSource={conversations?.data ?? []}
            renderItem={(item) => (
              <List.Item>
                <Tooltip
                  overlayStyle={{ fontSize: "1.1rem" }}
                  placement="top"
                  color={"#0d4ea2"}
                >
                  <div
                    className={`${styles.card} ${
                      selectedConversation === item.id ? styles.selected : ""
                    } `}
                    onClick={() => {
                      setSelectedConversation(item.id);
                      actions.setSelectedConversationId(item.id);
                      setMessage("");
                    }}
                  >
                    <div
                      className={`${styles.title} ${
                        moment(item.latestUserMessage.createdAt).isAfter(
                          item.latestEmployeeMessage.createdAt
                        )
                          ? styles.textBold
                          : ""
                      }`}
                    >
                      {item?.user?.infoPayload?.firstName
                        ? item.user?.infoPayload?.firstName +
                          " " +
                          item.user?.infoPayload?.lastName +
                          " " +
                          "(" +
                          item.user?.userType +
                          ")"
                        : item?.user?.infoPayload
                        ? item.user?.infoPayload?.mobile + "+"
                        : "deleted account"}
                    </div>
                    <div className={styles.massage}>
                      {item?.latestUserMessage?.msg}
                    </div>
                  </div>
                </Tooltip>
              </List.Item>
            )}
          />
        </Col>
        <Col span={18} style={{ height: "100%" }}>
          <div
            style={{ backgroundImage: `url(${bg})` }}
            className={styles.container}
          >
            {selectedConversation && (
              <>
                <div className={styles.massagesContainer}>
                  <div className={styles.subContainer}>
                    {/* messages */}
                    {messages?.data?.map((message, t) => (
                      <div
                        style={{ position: "relative" }}
                        className={`  ${
                          message?.sender?.userType !== "client"
                            ? styles.start
                            : styles.end
                        }`}
                      >
                        <MessageCart
                          isLeft={message?.sender?.userType !== "client"}
                          image=""
                          text={message?.msg.replace(/\n/g, "<br>")}
                          isAudio={false}
                          id={t}
                          date={moment(message.createdAt)
                            .format("Y-M-D H:mm")
                            .toString()}
                          name={
                            message?.sender?.infoPayload?.firstName +
                            " " +
                            message?.sender?.infoPayload?.lastName
                          }
                          userType={"(" + message?.sender?.userType + ")"}
                        />
                      </div>
                    ))}
                    <div
                      className={`${styles.messageContainer} ${
                        true ? styles.start : styles.end
                      }`}
                    >
                      <div className="message"></div>
                    </div>
                  </div>
                </div>
                <div className={styles.writeMassageContainer}>
                  <TextArea
                    className={styles.writeMassage}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    value={message}
                    onKeyDown={(e) => {
                      if (message?.trim().length > 0) {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          sendMessage();
                        } else if (e.key === "Enter" && e.shiftKey) {
                          e.preventDefault();
                          setMessage(message + "\n");
                        }
                      }
                    }}
                  />

                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      if (message?.trim().length > 0) {
                        sendMessage();
                      }
                    }}
                    className={styles.button}
                  >
                    <img src={defaultUserImage} className={styles.avatar} />
                  </Button>
                </div>
              </>
            )}
          </div>
        </Col>
      </>
    </Row>
  );
};

export default Chat;
