import { ICityQuery } from "../../models/city/query";
import { IReport } from "../../models/report/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import toggleLoading from "../../utils/helpers/xor";
import paramsReducer from "../base/base-reducer";
import { QueryAction } from "../base/base-reducer";
import { IInternalState, ReportLoading } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: ReportLoading | ReportLoading[] } }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<IReport> } }
  | QueryAction<ICityQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
