import React from "react";
import { List, Row, Col } from "antd";
import { AttributeType } from "../../../../models/attribute/enums";

import cn from "classnames";

import styles from "./style.module.scss";
import TextAttribute from "../render-attributes/text-attribute";
import NumberAttribute from "../render-attributes/number-attribute";
import DateAttribute from "../render-attributes/date-attribute";
import ImageDropdownAttribute from "../render-attributes/image-dropdown-attribute";
import ColorAttribute from "../render-attributes/color-attribute";
import CheckboxAttribute from "../render-attributes/checkbox-attribute";
import TextDropdownAttribute from "../render-attributes/text-dropdown-attribute";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const AttributeValues: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  const SwitchCase: React.FC<{ type: AttributeType }> = ({ type }) => {
    switch (type) {
      case AttributeType.TEXT:
        return <TextAttribute attributeWithValue={attributeWithValue} />;

      case AttributeType.NUMBER:
        return <NumberAttribute attributeWithValue={attributeWithValue} />;

      case AttributeType.DATE:
        return <DateAttribute attributeWithValue={attributeWithValue} />;

      case AttributeType.TEXT_DROP_DOWN:
        return (
          <TextDropdownAttribute attributeWithValue={attributeWithValue} />
        );

      case AttributeType.IMAGE_DROP_DOWN:
        return (
          <ImageDropdownAttribute attributeWithValue={attributeWithValue} />
        );

      case AttributeType.COLOR:
        return <ColorAttribute attributeWithValue={attributeWithValue} />;

      case AttributeType.CHECKBOX:
        return <CheckboxAttribute attributeWithValue={attributeWithValue} />;

      default:
        return <TextAttribute attributeWithValue={attributeWithValue} />;
    }
  };

  return (
    <>
      <List.Item>
        <Row style={{ width: "auto" }}>
          <Col className={cn(styles.mEnd05, styles.flexCenter)}>
            {`${attributeWithValue?.attribute?.name}: `}
          </Col>
          <Col span={12}>
            <SwitchCase type={attributeWithValue?.attribute?.type} />
          </Col>
        </Row>
      </List.Item>
    </>
  );
};

export default AttributeValues;
