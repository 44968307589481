import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import EndPoints from "../../../services/end-points";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getAreaStatus } from "../../../models/area/enum";
import PointContext from "../../../context/point/context";
import { IArea } from "../../../models/area/response";
import PaymentDeliveryWorkerContext from "../../../context/payment-delivery-worker/context";
import { getPaymentStatus } from "../../../models/payment-delivery-worker/enum";

interface IProps { }

const PaymentDeliveryWorkerFilter: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { actions, query } = useContext(PaymentDeliveryWorkerContext);
    const { direction } = useContext(AppContext);
    const [delivery, setDelivery] = useState<any[]>([]);
    const [deliveryLoading, setDeliveryLoading] = useState(true);

    // get all cities from api
    useEffect(() => {
        const getDelivery = async () => {
            try {
                const { data } = await EndPoints.deliveryWorkers.getAllDeliveryWorkers({
                    light: true,
                    perPage: -1,
                });
                setDelivery(data.data);
                setDeliveryLoading(false);
            } catch (error) {
            } finally {
                setDeliveryLoading(false);
            }
        };
        getDelivery();
    }, [direction]);

    return (
        <Form
            formId="payment-delivery-worker-filter"
            onSubmit={(data) => {
                const filter: any = {
                    page: INITIAL_PAGE,
                    perPage: query?.perPage,
                    keyword: data?.keyword,
                    filters: [
                        data?.isConfirmed
                            ? {
                                name: "isConfirmed",
                                value: data.isConfirmed,
                                operation: "eq",
                            }
                            : undefined,
                        data?.driverId
                            ? {
                                name: "driverId",
                                value: data.driverId,
                                operation: "eq",
                            }
                            : undefined,
                        data?.createdAt != undefined &&
                            data.createdAt[0] != ""
                            ? {
                                name: "createdAt",
                                value: data.createdAt[0],
                                operation: "gte",
                            }
                            : undefined,
                        data?.createdAt != undefined &&
                            data.createdAt[1] != ""
                            ? {
                                name: "createdAt",
                                value: data.createdAt[1],
                                operation: "lte",
                            }
                            : undefined,
                    ].filter((f) => f),
                };
                actions.setQuery(filter);
            }}
        >
            <Row justify={"start"} gutter={[10, 0]}>
                <Col span={8}>
                    <FieldBuilder
                        name="isConfirmed"
                        label={`${t("payment_status")}`}
                        width="large"
                        input={{
                            type: "select",
                            options: [1, 2, 3].map((status, index) => {
                                return {
                                    label: t(getPaymentStatus(status) as string),
                                    value: status as number,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="driverId"
                        label={`${t("delivery_worker")}`}
                        width="large"
                        input={{
                            type: "select",
                            allowSearch: true,
                            loading: deliveryLoading,
                            options: delivery.map((delivery, index) => {
                                return {
                                    label: delivery.firstName,
                                    value: delivery.id,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="createdAt"
                        label={`${t("created_at")}`}
                        input={{ type: "range-picker" }}
                        rules={{ required: false }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default PaymentDeliveryWorkerFilter;
