import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ClientContext from "../../../context/clients/client/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import {
  getBooleanStatus,
  getUserStatusColor,
} from "../../../models/city/enum";
import { IClient } from "../../../models/client/response";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import { ImCheckmark, ImCross } from "react-icons/im";

interface IProps { }

const ClientTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(ClientContext);

  const columns: ColumnProps<IClient>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },

      align: "center",
    },
    {
      title: t("first_name"),
      dataIndex: "firstName",
      sorter: {
        multiple: 2,
      },
      align: "center",
      key: "firstName",
      render: (_, record) => {
        return <>{record?.user?.firstName ?? "-"}</>;
      },
    },
    {
      title: t("last_name"),
      dataIndex: "lastName",
      align: "center",
      sorter: {
        multiple: 3,
      },
      key: "lastName",
      render: (_, record) => {
        return <>{record?.user?.lastName ?? "-"}</>;
      },
    },
    {
      title: t("city"),
      dataIndex: "city",
      align: "center",
      key: "city",
      render: (_, record) => {
        return <>{record?.city?.name ?? "-"}</>;
      },
    },
    {
      title: t("accepted_orders_count"),
      dataIndex: "acceptedOrdersCount",
      align: "center",
      key: "acceptedOrdersCount",
      render: (_, record) => {
        return <>{record?.acceptedOrdersCount ?? "-"}</>;
      },
    },
    {
      title: t("hasOneOrder"),
      dataIndex: "hasOneOrder",
      align: "center",
      key: "hasOneOrder",
      render: (_, record) => {
        return (
          <>
            {record.hasOneOrder ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("active_points"),
      dataIndex: "activePoints",
      align: "center",
      key: "activePoints",
      sorter: {
        multiple: 4,
      },
      render: (_, record) => {
        return <>{record?.activePoints ?? "-"}</>;
      },
    },
    {
      title: t("expired_points"),
      dataIndex: "expiredPoints",
      align: "center",
      key: "expiredPoints",
      sorter: {
        multiple: 4,
      },
      render: (_, record) => {
        return <>{record?.expiredPoints ?? "-"}</>;
      },
    },
    {
      title: t("account_status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            title={getBooleanStatus(record?.user?.status)}
            color={getUserStatusColor(record?.user?.status)}
          />
        );
      },
    },

    {
      title: t("phone"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, record) => {
        return <>{record?.user?.cleanMobile}</>;
      },
    },
    {
      title: t("active_points"),
      dataIndex: "activePoints",
      key: "activePoints",
      align: "center",
    },
    {
      title: t("expired_points"),
      dataIndex: "expiredPoints",
      key: "expiredPoints",
      align: "center",
    },
    {
      title: t("subscription_date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD");
      },
    },
    {
      title: t("actions"),
      width: 250,
      fixed: "right",
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteClient(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        scroll={{
          x: 1300,
        }}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ClientTable;
