import moment from "moment";
import { useContext, useReducer } from "react";
import PaymentDeliveryWorkerContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import {
  ICreatePaymentDeliveryWorker,
  IUpdatePaymentDeliveryWorker,
} from "../../models/payment-delivery-worker/request";
import { IPaymentDeliveryWorker } from "../../models/payment-delivery-worker/response";
import { IPaymentDeliveryWorkerQuery } from "../../models/payment-delivery-worker/query";
import AuthContext from "../auth/context";

export interface IProps {
  children: React.ReactNode;
}
const PaymentDeliveryWorkerContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { userDetails: user } = useContext(AuthContext);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
        const userPermissions = localStorage?.getItem("permisionStoroge")?.split(",");
        const { data } =
          await EndPoints.paymentDeliveryWorker.getAllPaymentDeliveryWorkers(
            !userPermissions?.includes("can_view_pads") ? state.query :
            {
              ...state.query,
              filters: [
                ...(state.query?.filters ?? []),
                {
                  name: "collectionPointId",
                  value: user?.collectionPoint?.id,
                  operation: "eq",
                },
              ],
            }
          );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.paymentDeliveryWorker.getPaymentDeliveryWorker(id);
        dispatch({ type: "SET_DETAILSS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IPaymentDeliveryWorker) => {
    // dispatch({ type: 'SET_DETAILSS', payload: { details: data } })
  };

  const createPaymentDeliveryWorker = async (
    request: ICreatePaymentDeliveryWorker
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.paymentDeliveryWorker.createPaymentDeliveryWorker(
          request
        );
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updatePaymentDeliveryWorker = async (
    id: number,
    request: IUpdatePaymentDeliveryWorker
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.paymentDeliveryWorker.updatePaymentDeliveryWorker(
          id,
          request
        );

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deletePaymentDeliveryWorker = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.paymentDeliveryWorker.deletePaymentDeliveryWorker(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IPaymentDeliveryWorkerQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.paymentDeliveryWorker.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `PaymentDeliveryWorkers-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <PaymentDeliveryWorkerContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createPaymentDeliveryWorker,
          updatePaymentDeliveryWorker,
          deletePaymentDeliveryWorker,
          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </PaymentDeliveryWorkerContext.Provider>
  );
};

export default PaymentDeliveryWorkerContextProvider;
