import { Descriptions as AntdDescriptions } from "antd";
import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import Tag from "../../general/antd/tag";
import AppContext from "../../../context/app/context";
import RoleContext from "../../../context/role/context";
import RoleContextProvider from "../../../context/role/provider";

interface Props {}

const Role: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(RoleContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={`${t("roles")}`}
        subtitle={`${t("details")}`}
        buttonName={`${t("update_role")}`}
        onClick={() => navigate(`/roles/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("permissions")}>
              {details?.permissions?.map((p, index) => (
                <Fragment key={index}>
                  <Tag title={p.name} />
                </Fragment>
              ))}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const RoleDetailesPage = () => {
  return (
    <RoleContextProvider>
      <Role />
    </RoleContextProvider>
  );
};
export default RoleDetailesPage;
