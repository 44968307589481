import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import OrderStatusContext from "../../../context/order-status/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IOrderStatus } from "../../../models/order/order-status/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import {
  getOrderStatus,
  getOrderStatusColor,
} from "../../../models/order/enum";
import Tag from "../../general/antd/tag";

interface IProps {}

const OrderStatusTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, query, list } = useContext(OrderStatusContext);
  const { id } = useParams();
  const { direction } = useContext(AppContext);

  useEffect(() => {
    id && actions.getData(Number(id));
  }, [query, direction, id]);

  const columns: ColumnProps<IOrderStatus>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("order_status"),
      dataIndex: "orderStatus",
      key: "orderStatus",
      align: "center",
      render: (orderStatus) => {
        return (
          <Tag
            title={t(getOrderStatus(orderStatus))}
            color={getOrderStatusColor(orderStatus)}
          />
        );
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: {
        multiple: 5,
      },
      render: (createdAt) => {
        return moment(createdAt).format( "HH:mm:ss YYYY-MM-DD ");
      },
    },
    {
      title: t("note"),
      dataIndex: "note",
      key: "note",
      align: "center",
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default OrderStatusTable;
