import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StoreContext from "../../../context/store/context";
import { getStorType } from "../../../models/store/enum";
import { getUserStatus } from "../../../models/user/enum";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import { ICity } from "../../../models/city/response";
import AppContext from "../../../context/app/context";
import EndPoints from "../../../services/end-points";
import { ICategory } from "../../../models/category/response";
import CategoryContext from "../../../context/category/context";
import { useWatch } from "react-hook-form";

interface IProps {}

const CategoryFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query, allCategories } = useContext(CategoryContext);
  const [mainCat, setMainCat] = useState<number>();
  return (
    <Form
      formId="category-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.parentId
              ? {
                  name: "parentId",
                  value: data.parentId,
                  operation: "eq",
                }
              : undefined,
            data?.grandId
              ? {
                  name: "grandId",
                  value: data.grandId,
                  operation: "eq",
                }
              : undefined,

            data?.child
              ? {
                  name: "child",
                  value: data.child,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="grandId"
            label={`${t("main_category")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,

              options: allCategories
                ?.filter((cat) => cat?.parent === null && cat?.grand === null)
                .map((grand, index) => {
                  return {
                    type: "select",
                    label: grand?.name as string,
                    value: grand?.id as number,
                  };
                }),
              onChange: (val) => {
                // alert(val);
                setMainCat(val);
              },
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="parentId"
            label={`${t("parent_category")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,

              options: allCategories
                ?.filter((cat) =>
                  mainCat
                    ? cat?.parent === null && cat?.grand?.id == mainCat
                    : !cat?.parent && cat?.grand
                )
                .map((grand, index) => {
                  return {
                    type: "select",
                    label: grand?.name as string,
                    value: grand?.id as number,
                  };
                }),
            }}
          />
        </Col>
        {/* <Col span={8}>
          <FieldBuilder
            name="child"
            label={`${t("child_category")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((val, index) => {
                return {
                  type: "select",
                  label: val == 1 ? "true" : "false",
                  value: val == 1 ? true : false,
                };
              }),
            }}
          />
        </Col> */}
        {/* <Col span={8}>
          <FieldBuilder
            name="parentId"
            label={`${t("parent_category")}`}
            width="large"
            input={{
              type: "select",
              loading: categoriesLoading,
              options: categories.map((city, index) => {
                return {
                  type: "select",
                  label: city?.name as string,
                  value: city?.id as number,
                };
              }),
            }}
          />
        </Col> */}
      </Row>
    </Form>
  );
};

export default CategoryFilter;
