import { Row, Col, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import GeneralNotificationContext from "../../../context/general-notification/context";
import GeneralNotificationContextProvider from "../../../context/general-notification/provider";
import { IUpdateGeneralNotification } from "../../../models/general-notification/request";
import { getNotificationType } from "../../../models/general-notification/enum";
import EndPoints from "../../../services/end-points";
import { IProduct } from "../../../models/product/response";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(GeneralNotificationContext);
  const { id } = useParams();

  const [products, setProducts] = useState<IProduct[]>([]);
  const [productLoading, setLoadingProduct] = useState(true);

  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState({ page: 1, search: undefined });

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    console.log(scrollTop)
    if (
      scrollHeight - scrollTop === clientHeight &&
      hasMore &&
      !productLoading
    ) {
      setQuery({ ...query, page: query.page + 1 });
    }
  };

  const handleSearch = (value) => {
    setQuery({ page: 1, search: value });
    setProducts([]);
  };

  const getProduct = async () => {
    try {
      const { data } = await EndPoints.product.getAllProducts({
        light: true,
        page: query.page,
        perPage: 25,
        keyword: query?.search == "" ? undefined : query?.search,
      });
      const newOptions = data?.data;
      setProducts((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMore(data.lastPage == query.page ? false : true);
      setLoadingProduct(false);
    } catch (err) {
    } finally {
      setLoadingProduct(false);
    }
  };
  useEffect(() => {
   
    getProduct();
  }, [query]);

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateGeneralNotification(details?.id, data)
          : actions.createGeneralNotification(data);
      }}
      title={
        details
          ? t("update_general_notification")
          : t("add_general_notification")
      }
      defaultValues={
        {
          title: getTranslations(details?.translations?.title),
          note: getTranslations(details?.translations?.note),
          text: getTranslations(details?.translations?.text),
          slide: details?.slide,
          productId: details?.product?.id,
        } as IUpdateGeneralNotification
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`title[${lang?.code}]`}
                    label={`${t("title")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`text[${lang?.code}]`}
                    label={`${t("text")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}

            <Col span={12}>
              <FieldBuilder
                name="slide"
                label={`${t("user_slide")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: [1, 2, 3, 4,5]?.map((type, index) => {
                    return {
                      key: index,
                      label: t(getNotificationType(type)),
                      value: type,
                    };
                  }),
                }}
              />
            </Col>

            <Col span={12}>
              <FieldBuilder
                name="productId"
                label={`${t("product")}`}
                width="large"
                rules={{ required: false }}
                input={{
                  type: "select-pagination",
                  handleScroll: handleScroll,
                  handleSearch: handleSearch,
                  loading: productLoading,
                  allowSearch: true,
                  options: products?.map((product, index) => {
                    return {
                      key: index,
                      label: product.name,
                      value: product.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={16} />
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const GeneralNotificationForm = () => {
  return (
    <GeneralNotificationContextProvider>
      <Form />
    </GeneralNotificationContextProvider>
  );
};
export default GeneralNotificationForm;
