import { Row, Col, Divider, Tree } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import EndPoints from "../../../services/end-points";
import RoleContext from "../../../context/role/context";
import Spin from "../../general/antd/spin";
import RoleContextProvider from "../../../context/role/provider";
//import { Permissions } from "../../../utils/rbac/per";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading, permissions } = useContext(RoleContext);
  const [allPermissions, setAllPermissions] = useState<string[]>();
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  useEffect(() => {
    setAllPermissions(
      permissions?.data?.map((p) => {
        if (p.name !== "can_view_pads") return p.name;
      }).filter(p=>p)
    );
  }, [permissions]);

  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateRole(details?.id, {
              ...data,
              permissions: data.permissions.includes(0)
                ? allPermissions
                : data.permissions,
            })
          : actions.createRole({
              ...data,
              permissions: data?.permissions?.includes(0)
                ? allPermissions
                : data?.permissions,
            });
      }}
      title={details ? t("update_role") : t("create_role")}
      defaultValues={
        {
          name: details?.name,
          permissions: details?.permissions?.map((p) => p.name),
        } as any
      }
    >
      {loading.includes("update") ? (
        <Spin />
      ) : (
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="name"
              label={`${t("name")}`}
              rules={{ required: true }}
              input={{ type: "text" }}
            />
          </Col>

          <Col span={24}>
            <FieldBuilder
              name="permissions"
              label={`${t("permissions")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "multiSelect",

                allowSearch: true,
                options: [
                  { id: 0, name: "get_all_permissions" },
                  ...(permissions?.data ?? []),
                ]?.map((per, index) => {
                  return per.id === 0
                    ? {
                        key: index,
                        label: t(per.name),
                        value: per.id,
                      }
                    : {
                        key: index,
                        label: t(per.name),
                        value: per.name,
                      };
                }),
              }}
            />
          </Col>
        </Row>
      )}
    </MainForm>
  );
};

const RoleForm = () => {
  return (
    <RoleContextProvider>
      <Form />
    </RoleContextProvider>
  );
};
export default RoleForm;
