import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import BannerContext from "../../../context/banner/context";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getUserStatus } from "../../../models/user/enum";
import { getBannerType } from "../../../models/banner/enum";

interface IProps {}

const BannerFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(BannerContext);

  const statuses = [1, 2];

  return (
    <Form
      formId="banner-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getUserStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2, 3, 4, 5, 6].map((status, index) => {
                return {
                  label: t(getBannerType(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default BannerFilter;
