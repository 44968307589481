import {
  Descriptions as AntdDescriptions,
  Col,
  Collapse,
  Row,
  Button,
} from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import StoreContext from "../../../context/store/context";
import StoreContextProvider from "../../../context/store/provider";
import Marker from "../../general/map/marker";
import {
  getStorStatus,
  getStorType,
  getStoreStatusColor,
  getStoreTypeColor,
  getWorkStatus,
  getWorkStatusColor,
} from "../../../models/store/enum";
import Tag from "../../general/antd/tag";
import { ImCheckmark, ImCross } from "react-icons/im";
import OldImage from "../../form-components/old-image";
import ProductStoreContextProvider from "../../../context/product-store/provider";
import ProductStoreTable from "../../product-store/produc-store-table";
import PrizeDetails from "../prize-details";
import PrizeContext from "../../../context/prize/context";
import PrizeContextProvider from "../../../context/prize/provider";

const { Panel } = Collapse;

interface Props {}

const Prize: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(PrizeContext);

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [direction]);
  return (
    <>
      <PageDetails title={t("details")} subtitle={t("prize")}>
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("description")}>
                  {details?.description}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("numbers")}>
                  {details?.numbers}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("store")}>
                  {details?.store?.name}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("points_balance")}>
                  {details?.store?.pointsBalance ?? "-"}
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const PrizeDetailesPage = () => {
  return (
    <PrizeContextProvider>
      <Prize />
    </PrizeContextProvider>
  );
};
export default PrizeDetailesPage;
