import { createContext } from "react";
import { IUpdatePolicyTerm } from "../../models/policy-term/request";
import { IPolicyTerm } from "../../models/policy-term/response";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";

export type PolicyTermLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: PolicyTermLoading[];

  list?: IPolicyTerm;
}

export const internalState: IInternalState = {
  loading: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    getPolicyTerm: () => void;

    updatePolicyTerm: (request: IUpdatePolicyTerm) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getPolicyTerm: DEFAULT_FUNCTION,
    updatePolicyTerm: DEFAULT_FUNCTION,
  },
};

const PolicyTermContext = createContext(externalState);

export default PolicyTermContext;
