import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IPaymentDeliveryWorker,
  IPaymentDeliveryWorkerDetails,
} from "../../models/payment-delivery-worker/response";
import { IPaymentDeliveryWorkerQuery } from "../../models/payment-delivery-worker/query";
import {
  ICreatePaymentDeliveryWorker,
  IUpdatePaymentDeliveryWorker,
} from "../../models/payment-delivery-worker/request";

export type PaymentDeliveryWorkerLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: PaymentDeliveryWorkerLoading[];

  list?: IBaseListingResponse<IPaymentDeliveryWorker>;
  query: IPaymentDeliveryWorkerQuery;

  details?: IPaymentDeliveryWorkerDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IPaymentDeliveryWorker) => void;

    createPaymentDeliveryWorker: (
      request: ICreatePaymentDeliveryWorker
    ) => void;
    updatePaymentDeliveryWorker: (
      id: number,
      request: IUpdatePaymentDeliveryWorker
    ) => void;
    deletePaymentDeliveryWorker: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IPaymentDeliveryWorkerQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createPaymentDeliveryWorker: DEFAULT_FUNCTION,
    updatePaymentDeliveryWorker: DEFAULT_FUNCTION,
    deletePaymentDeliveryWorker: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PaymentDeliveryWorkerContext = createContext(externalState);

export default PaymentDeliveryWorkerContext;
