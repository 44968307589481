import classNames from "classnames";
import background1 from "../../assets/images/background-login1.jpg";
import background2 from "../../assets/images/background-login2.jpg";
import background3 from "../../assets/images/background-login3.jpg";
import background4 from "../../assets/images/background-login4.jpg";
import background5 from "../../assets/images/background-login5.jpg";
import background6 from "../../assets/images/background-login6.jpg";
import logo from "../../assets/images/full-logo.png";
import Image from "../../components/general/antd/image";
import LoginForm from "../../components/login/login-form";
import styles from "./styles.module.scss";
import { random } from "lodash";

interface Props {}

const LoginPage: React.FC<Props> = (props) => {
  const bgs = [
    background1,
    background2,
    background3,
    background4,
    background5,
    background6,
  ];
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${bgs[random(0, 5)]})`,
        textAlign: "center",
      }}
    >
      <div className={classNames("", styles.card)}>
        <Image
          preview={false}
          src={logo}
          alt={"logo"}
          style={{
            width: "100%",
            height: "30vh",
            marginBottom: "1rem",
            marginTop: "-1rem",
          }}
        />
        <div style={{ textAlign: "left" }}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
