import { createContext } from "react";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";
import { User } from "../../models/user/loginResponse";

export type AuthLoading = "login" | "logout" | "change_password" | "roles";

export interface IInternalState {
  loading: AuthLoading[];

  isAuthenticated?: boolean;
  userDetails?: User;
  grantedPermissions: string[];
  userPermissions: string[];
}

export const internalState: IInternalState = {
  loading: [],
  isAuthenticated: true,
  grantedPermissions: [CAN_VIEW_HOME_PAGE],
  userPermissions: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    login: (request: any) => void;
    logout: () => void;
    changePassword: (request: any) => void;
    resetCodeUser: (request: any) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    login: DEFAULT_FUNCTION,
    changePassword: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
    resetCodeUser: DEFAULT_FUNCTION,
  },
};

const AuthContext = createContext(externalState);

export default AuthContext;
