import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import PolicyTermContext from "../../../context/policy-term/context";
import PolicyTermContextProvider from "../../../context/policy-term/provider";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import PageDetails from "../../general/details";

interface Props {}

const PolicyTerm: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { list, actions, loading } = useContext(PolicyTermContext);
  const navigate = useNavigate();
  useEffect(() => {
    const getPolicyTerm = async () => {
      await actions.getPolicyTerm();
    };
    getPolicyTerm();
  }, [direction]);
  return (
    <>
      <PageDetails
        navigate
        title={t("details")}
        subtitle={t("policy_term")}
        buttonName={`${t("update_policy_term")}`}
        onClick={() => navigate(`/policy-term/update`)}
      >
        {loading.includes("list") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={2} bordered>
            <AntdDescriptions.Item label={t("policy")} span={2}>
              {list?.policy ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: list?.policy?.htmlContent,
                  }}
                />
              ) : (
                "-"
              )}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("term")} span={2}>
              {list?.term ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: list?.term?.htmlContent,
                  }}
                />
              ) : (
                "-"
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const PolicyTermDetailesPage = () => {
  return (
    <PolicyTermContextProvider>
      <PolicyTerm />
    </PolicyTermContextProvider>
  );
};
export default PolicyTermDetailesPage;
