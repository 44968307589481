import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import TrademarkContext from "../../context/trademark/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import TrademarkContextProvider from "../../context/trademark/provider";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import TrademarkTable from "../../components/trademark/trademark-table";
import TrademarkFilter from "../../components/trademark/trademark-filter";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import { IExportDeliveryWorkerExcel } from "../../models/delivery-workers/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import {
  IExportTrademarkExcel,
  colomnsExportTrademarkExcel,
} from "../../models/trademark/request";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Trademark: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(TrademarkContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("trademarks")}
        subTitle={t("trademarks_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Divider key={1} type="vertical" />,

          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/trademarks/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_trademark")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-category"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            name: false,
            id: false,

            order: false,
            note: false,
          } as IExportTrademarkExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel({...columnsNames, is_special: false});
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportTrademarkExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="trademark-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <TrademarkFilter />
      </SearchFilterCard>

      <TrademarkTable />
    </div>
  );
};

const TrademarkPage = () => {
  return (
    // ContextProvider
    <TrademarkContextProvider>
      <Trademark />
    </TrademarkContextProvider>
  );
};
export default TrademarkPage;
