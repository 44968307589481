import { useTranslation } from "react-i18next";
import { useContext } from "react";
import Form from "../../form-components/from";
import { ILoginRequest } from "../../../models/auth/request";
import { execute } from "../../../utils/api/api-execute";
import SubmitBtn from "../../form-components/submit-btn";
import styles from "./styles.module.scss";
import AuthContext from "../../../context/auth/context";
import LoginInputs from "../login-inputs";

interface Props {}

const LoginForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { actions, loading } = useContext(AuthContext);

  const onSubmit = async (data: ILoginRequest) => {
    await execute({
      callback: async () => {
        await actions.login(data);
      },
      fallback: (error) => {},
      finallyCallback: () => {},
      throwException: false,
    });
  };

  return (
    <Form<ILoginRequest> onSubmit={onSubmit} formId="login">
      <LoginInputs />
      <SubmitBtn
        className={styles["submit-btn"]}
        loading={loading.includes("login")}
        form="login"
      >
        {t("login")}
      </SubmitBtn>
    </Form>
  );
};

export default LoginForm;
