import { ITranslationRequest } from "../base/translation";

export interface ICreatePoint {
    name: ITranslationRequest
    employId: number
    cityId: number
    areaId: number
    status: number,
    note: string
}

export interface IUpdatePoint extends ICreatePoint {

}


export interface IExportPointCollection {

    id: boolean
    name: boolean
    status: boolean
    debtValue: boolean
    employ: boolean
    city: boolean
    area: boolean
}

export const colomnsExportPointExcel = [
    "id",
    "name",
    "status",
    "debtValue",
    "employ",
    "city",
    "area"
];