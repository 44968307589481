import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";
import { getReportType } from "../../../models/report/enum";
import { useFormContext, useWatch } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";

const Form = () => {
  const { t } = useTranslation();

  const [deliveryWorkers, setDeliveryWorkers] = useState<any[]>();
  const [deliveryWorkersLoading, setDeliveryWorkersLoading] = useState(true);

  const { direction } = useContext(AppContext);

  const type = [
    "export-pharmacies-Orders",
    "export-order-user-details",
    "export-orders-cancellation",
    "export-drivers-Performance",
    "export-orders-daily-info",
    "export-orders-status",
    "export-paid-orders-journey",
    "export-orders-details",
    "export-drivers-payments",
  ];
  const { control, setValue } = useFormContext();

  const typeWatch = useWatch({
    control,
    name: "type",
  });

  // get all getDeliveryWorker from api
  useEffect(() => {
    const getDeliveryWorker = async () => {
      try {
        const { data } = await EndPoints.deliveryWorkers.getAllDeliveryWorkers({
          light: true,
          perPage: -1,
        });
        setDeliveryWorkers(data?.data);
      } catch (error) {
      } finally {
        setDeliveryWorkersLoading(false);
      }
    };
    getDeliveryWorker();
  }, [direction]);

  return (
    <Row gutter={[16, 8]}>
      <Col span={12}>
        <FieldBuilder
          name="type"
          label={`${t("type")}`}
          width="large"
          rules={{ required: true }}
          input={{
            type: "select",
            options: type?.map((el) => {
              return {
                label: t(getReportType(el) as string),
                value: el as string,
              };
            }),
          }}
        />
      </Col>
      <Col span={12}>
        <FieldBuilder
          name="startDate"
          label={`${t("start_date")}`}
          rules={{ required: true }}
          input={{ type: "date-picker", format: "YYYY-MM-DD" }}
        />
      </Col>
      <Col span={12}>
        <FieldBuilder
          name="endDate"
          label={`${t("end_date")}`}
          rules={{ required: true }}
          input={{ type: "date-picker", format: "YYYY-MM-DD" }}
        />
      </Col>
      {typeWatch == "export-drivers-payments" ? (
        <Col span={12}>
          <FieldBuilder
            name="driverId"
            label={`${t("delivery_worker")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              loading: deliveryWorkersLoading,
              options: deliveryWorkers?.map((user, index) => {
                return {
                  key: index,
                  label: user?.firstName + " " + user?.lastName,
                  value: user?.id,
                };
              }),
            }}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

const ReportForm = () => {
  return <Form />;
};
export default ReportForm;
