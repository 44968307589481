import { Col, Row, Tag } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AttributeType,
  getAttributeType,
} from "../../../models/attribute/enums";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import styles from "./style.module.scss";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import { ErrorMessage } from "@hookform/error-message";
import Button from "../../general/antd/button";
import classNames from "classnames";
import { TwitterPicker } from "react-color";
import { IParamDetails } from "../../../models/param/resopnse";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";

interface IProps {
  type: number;
  details?: IParamDetails;
}

const ParamForm: React.FC<IProps> = ({ type, details }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 8]}>
        {languages?.map((lang, index) => {
          return (
            <Col span={12}>
              <FieldBuilder
                key={index}
                name={`name[${lang?.code}]`}
                label={`${t("name")} (${lang?.name})`}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
          );
        })}

        {type == AttributeType.COLOR && (
          <>
            <Tag
              className={classNames(styles.textCenter, styles.w100)}
              color={details?.data?.hex}
            ></Tag>

            <Col span={8}>
              <FieldBuilder
                name="hex"
                label={t("pick_color")}
                input={{ type: "color-picker" }}
                rules={{ required: false }}
              />
            </Col>
          </>
        )}

        {type == AttributeType.IMAGE_DROP_DOWN && (
          <>
            <Col span={24}>
              <FormItem label={t("image")}>
                <Controller
                  name="image"
                  rules={{
                    required: {
                      value: false,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details?.data?.image?.url && (
              <OldImage src={details?.data?.image?.url} />
            )}
          </>
        )}

        {/* {type == AttributeType.COLOR && (
          <FormItem label={t("color")}>
            <Controller
              render={({ field }) => {
                return (
                  <>
                    <Row>
                      <Col span={24}>
                        <Button
                          className={classNames(styles.textCenter, styles.w100)}
                          onClick={() => {
                            setDisplayColorPicker(!displayColorPicker);
                          }}
                        >
                          <Tag
                            className={classNames(
                              styles.textCenter,
                              styles.w100
                            )}
                            color={field?.value}
                          >
                            {details?.data?.hex}
                          </Tag>
                        </Button>
                      </Col>
                    </Row>

                    <Row className={styles.m1}>
                      {displayColorPicker && (
                        <div style={{ position: "absolute", zIndex: 2 }}>
                          <TwitterPicker
                            triangle="top-right"
                            onChangeComplete={(value) => {
                              setColor(value.hex);
                              field?.onChange(value.hex);
                              setDisplayColorPicker(false);
                            }}
                            color={field?.value}
                          />
                        </div>
                      )}
                    </Row>
                  </>
                );
              }}
              name="hex"
              rules={{
                required: `${t("field_is_required_message")}`,
              }}
            ></Controller>
          </FormItem>
        )} */}
      </Row>
    </>
  );
};

export default ParamForm;
