import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import OrderContext from "../../context/order/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import OrderContextProvider from "../../context/order/provider";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import OrderFilter from "../../components/order/order-filter";
import OrderShortTable from "../../components/order/order-table-short";
import OrderShortFilter from "../../components/order/order-short-filter";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const OrderShort: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(OrderContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getDataShort();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("orders_short")}
        subTitle={t("orders_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getDataShort()}
          >
            {t("refresh")}
          </RefreshBtn>,
        ]}
      />
      <SearchFilterCard
        formId="order-short-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <OrderShortFilter></OrderShortFilter>
      </SearchFilterCard>

      <OrderShortTable />
    </div>
  );
};

const OrderShortPage = () => {
  return (
    <OrderContextProvider>
      <OrderShort />
    </OrderContextProvider>
  );
};
export default OrderShortPage;
