import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import PointTable from "../../components/point/point-table";
import PointFilter from "../../components/point/point-filter";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import {
  IExportPointCollection,
  colomnsExportPointExcel,
} from "../../models/point/request";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import RewardContextProvider from "../../context/rewaed/provider";
import RewardContext from "../../context/rewaed/context";
import RewardTable from "../../components/reward/reward-table";

interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const Reward: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { actions, loading, query } = useContext(RewardContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("rewards")}
        subTitle={t("rewards_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_reward")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="reward-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
      </SearchFilterCard>

      {/* <Outlet /> */}
      <RewardTable />
    </div>
  );
};

const RewardPage = () => {
  return (
    // ContextProvider
    <RewardContextProvider>
      <Reward />
    </RewardContextProvider>
  );
};
export default RewardPage;
