import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import Tag from "../../general/antd/tag";
import moment from "moment";
import RewardContextProvider from "../../../context/rewaed/provider";
import RewardContext from "../../../context/rewaed/context";
import {
  getRewardStatus,
  getRewardStatusColor,
  getRewardType,
  getRewardTypeColor,
} from "../../../models/reward/enum";
import RewardHistoryTable from "../reward-history";
const { Panel } = Collapse;
interface Props {}

const Reward: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(RewardContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("reward")}
        buttonName={`${t("update_reward")}`}
        onClick={() => navigate(`/rewards/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("details")} key={1}>
                  <Descriptions size="small" column={2} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("status")}>
                      {
                        <Tag
                          title={getRewardStatus(details?.status)}
                          color={getRewardStatusColor(details?.status)}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type")}>
                      {
                        <Tag
                          title={getRewardType(details?.type)}
                          color={getRewardTypeColor(details?.type)}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("value")}>
                      {details?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("distance")}>
                      {details?.distance}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("start_datetime")}>
                      {details?.type
                        ? moment(details?.startDatetime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "--"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("end_datetime")}>
                      {details?.type
                        ? moment(details?.endDatetime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "--"}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("history_reward")} key={1}>
                  <RewardHistoryTable RewardId={Number(id)} />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const RewardDetailesPage = () => {
  return (
    <RewardContextProvider>
      <Reward />
    </RewardContextProvider>
  );
};
export default RewardDetailesPage;
