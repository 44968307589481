import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderContext from "../../../context/order/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IOrder } from "../../../models/order/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import {
  getOrderStatus,
  getOrderStatusColor,
  getOrderStyle,
  getOrderType,
  getOrderTypeColor,
  getorderSchedulingTypeColor,
  getorderSchedulingTypeStyle,
} from "../../../models/order/enum";
import moment from "moment";
import { ImCheckmark, ImCross } from "react-icons/im";
import EditBtn from "../../general/table-components/actions/edit-btn";
import { useModal } from "react-modal-hook";
import FormHookModal from "../../general/modals/form-hook-modal";

interface IProps {}

const OrderTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(OrderContext);

  const columns: ColumnProps<IOrder>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("number_id"),
      dataIndex: "number",
      key: "number",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("current_order_status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      width: 250,
      render: (_, record) => {
        return (
          <Tag
            title={getOrderStatus(record?.status)}
            color={getOrderStatusColor(record?.status)}
          />
        );
      },
    },
    {
      title: t("order_scheduling_type"),
      dataIndex: "orderSchedulingType",
      align: "center",
      key: "orderSchedulingType",
      width: 200,
      render: (_, record) => {
        return (
          <Tag
            title={getorderSchedulingTypeStyle(record?.orderSchedulingType)}
            color={getorderSchedulingTypeColor(record?.orderSchedulingType)}
          />
        );
      },
    },
    {
      title: t("client"),
      dataIndex: "client",
      align: "center",
      key: "client",
      width: 200,
      render: (_, record) => {
        return (
          <Tag
            title={record?.client?.firstName + " " + record?.client?.lastName}
          />
        );
      },
    },
    {
      title: t("client_mobile"),
      dataIndex: "mobile",
      align: "center",
      key: "mobile",
      width: 200,
      render: (_, record) => {
        return record?.client?.cleanMobile ?? "-";
      },
    },
    {
      title: t("accepted_orders_count"),
      dataIndex: "acceptedOrdersCount",
      align: "center",
      key: "acceptedOrdersCount",
      width: 200,
      render: (_, record) => {
        return record?.client?.acceptedOrdersCount ?? "-";
      },
    },
    {
      title: t("driver"),
      dataIndex: "driver",
      align: "center",
      key: "driver",
      width: 200,
      render: (_, record) => {
        return (
          <Tag
            title={
              (record?.driver?.firstName ?? "-") +
              " " +
              (record?.driver?.lastName ?? "-")
            }
          />
        );
      },
    },
    {
      title: t("driver_mobile"),
      dataIndex: "driver",
      align: "center",
      key: "driver",
      width: 200,
      render: (_, record) => {
        return record?.driver?.cleanMobile ?? "-";
      },
    },
    {
      title: t("store"),
      dataIndex: "store",
      align: "center",
      key: "store",
      width: 200,
      render: (_, record) => {
        return <Tag title={record?.store?.name ?? "-"} />;
      },
    },
    {
      title: t("final_price"),
      dataIndex: "finalPrice",
      align: "center",
      key: "finalPrice",
      width: 200,
      render: (_, record) => {
        return record?.finalPrice ?? "-";
      },
    },
    {
      title: t("emergency"),
      dataIndex: "isEmergency",
      align: "center",
      key: "isEmergency",
      width: 200,
      render: (_, record) => {
        return (
          <>
            {record.isEmergency ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("is_recipe"),
      dataIndex: "isRecipe",
      align: "center",
      key: "isRecipe",
      width: 200,
      render: (_, record) => {
        return (
          <>
            {record.isRecipe ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("request_sourse_type"),
      dataIndex: "type",
      align: "center",
      key: "type",
      width: 200,
      render: (_, record) => {
        return (
          <Tag
            title={getOrderStyle(record?.type)}
            color={getOrderTypeColor(record?.type)}
          />
        );
      },
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      width: 200,
      render: (_, record) => {
        return moment(record?.createdAt).format("YYYY-MM-DD");
      },
    },

    {
      title: t("actions"),
      width: 120,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{
          x: 1700,
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default OrderTable;
