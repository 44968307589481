import { Col, Row } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import HomePageListContext from "../../../context/home-page-list/context";
import { getHomePageStatus, getHomePageType } from "../../../models/home-page-list/enum";

interface IProps { }

const HomePagListFilter: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { actions, query } = useContext(HomePageListContext);
    const { direction } = useContext(AppContext);

    const type = [1, 2, 3, 4, 5]
    const status = [1, 2]

    return (
        <Form
            formId="home-page-list-filter"
            onSubmit={(data) => {
                const filter: any = {
                    page: INITIAL_PAGE,
                    perPage: query?.perPage,
                    keyword: data?.keyword,
                    filters: [
                        data?.status
                            ? {
                                name: "status",
                                value: data.status,
                                operation: "eq",
                            }
                            : undefined,
                        data?.type
                            ? {
                                name: "type",
                                value: data.type,
                                operation: "eq",
                            }
                            : undefined,
                    ].filter((f) => f),
                };
                actions.setQuery(filter);
            }}
        >
            <Row justify={"start"} gutter={[10, 0]}>
                <Col span={8}>
                    <FieldBuilder
                        name="status"
                        label={`${t("status")}`}
                        width="large"
                        input={{
                            type: "select",
                            loading: false,
                            options: status?.map((state, index) => {
                                return {
                                    key: index,
                                    label: t(getHomePageStatus(state)),
                                    value: state,
                                };
                            }),
                        }}
                    />
                </Col>
                <Col span={8}>
                    <FieldBuilder
                        name="type"
                        label={`${t("type")}`}
                        width="large"
                        input={{
                            type: "select",
                            loading: false,
                            options: type?.map((type, index) => {
                                return {
                                    key: index,
                                    label: t(getHomePageType(type)),
                                    value: type,
                                };
                            }),
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default HomePagListFilter;
