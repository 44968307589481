import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IReportQuery } from "../../models/report/query";
import { IReport } from "../../models/report/response";
import { ICreateReport } from "../../models/report/request";

class repotservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllReports = (query: IReportQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IReport>>({
      path: `/report`,
      method: "GET",
      query,
      ...params,
    });

  createReport = (data: ICreateReport, path: string, params?: RequestParams) =>
    this.http.request<IReport>({
      path: path,
      method: "POST",
      body: data,
      ...params,
    });
}

export default repotservice;
