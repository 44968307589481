import { Button, Col, Divider, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import AreaContext from "../../../context/area/context";
import AreaContextProvider from "../../../context/area/provider";
import { getAreaStatus } from "../../../models/area/enum";
import { IUpdateArea } from "../../../models/area/request";
import { ICity } from "../../../models/city/response";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import { execute } from "../../../utils/helpers/execute";
import {
  getDumpTranslations,
  getTranslations,
} from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import MapPolygon from "../../general/map/polygon";
import styles from "./style.module.scss";

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { details, actions, loading } = useContext(AreaContext);
  const { direction } = useContext(AppContext);
  const [cities, setCities] = useState<ICity[]>([]);
  const [citiesLoading, setCitiesLoading] = useState(true);
  const status = [1, 2];
  const { id } = useParams();
  const [area, setArea] = useState([]);
  const [loadingArea, setLoadingArea] = useState(true);

  const getAreas = async (cityId) => {
    try {
      const { data } = await EndPoints.area.getAllAreas({
        perPage: -1,
        filters: [
          {
            name: "cityId",
            value: cityId,
            operation: "eq",
          },
          {
            name: "status",
            value:1,
            operation: "eq",
          }
        ],
      });
      const allPoints = data.data.map((el) => el.points);
      setArea(allPoints);
      setLoadingArea(false);
    } catch (error) {
    } finally {
      setLoadingArea(false);
    }
  };

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    details && getAreas(details?.city?.id);
  }, [details]);

  // get all cities from api
  useEffect(() => {
    const getcities = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getcities();
  }, [direction]);

  const methods = useForm<any>({
    defaultValues: {
      name: getTranslations(details?.translations?.name),
      note: getTranslations(details?.translations?.note) ?? null,
      cityId: details?.city?.id,
      status: details?.status,
    } as IUpdateArea,
  });

  const {
    getValues,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const pointMapChanges = useWatch({
    control,
    name: "points",
  });

  useEffect(() => {
    // console.log(pointMapChanges?.coordinates[0]);
  }, [pointMapChanges]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = async (data: any) => {
    const note = await getDumpTranslations(data?.note);
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.area.updateArea(details?.id, {
              ...data,
              points: {
                type: "Polygon",
                coordinates: data?.points?.coordinates,
              },
              note: note,
            })
          : await EndPoints.area.createArea({
              ...data,
              points: data?.points
                ? {
                    type: "Polygon",
                    coordinates: data?.points?.coordinates,
                  }
                : undefined,

              note: note,
            });
        //Todo un commit
        // actions.getDetails(Number(id));
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  useEffect(() => {
    const prevPints = getValues("points");
    reset({
      name: getTranslations(details?.translations?.name),
      note: getTranslations(details?.translations?.note),
      cityId: details?.city?.id,
      status: details?.status,
      points: details?.points?.coordinates
        ? {
            type: "Polygon",
            coordinates: details?.points?.coordinates,
          }
        : {
            type: "Polygon",
            coordinates: prevPints?.coordinates,
          },
    } as IUpdateArea);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_area") : t("add_area")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 8]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`name[${lang?.code}]`}
                        label={`${t("name")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: true }}
                      />
                    </Col>
                  );
                })}

                <Col span={12}>
                  <FieldBuilder
                    name="cityId"
                    label={`${t("city")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: citiesLoading,
                      allowSearch: true,
                      options: cities?.map((role, index) => {
                        return {
                          key: index,
                          label: role.name,
                          value: role.id,
                        };
                      }),
                      onChange: (val) => {
                        getAreas(val);
                      },
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    name="status"
                    label={`${t("status")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: status?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getAreaStatus(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`note[${lang?.code}]`}
                        label={`${t("note")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}

                <Col span={24}>
                  <FormItem label={t("map")} required>
                    <Controller
                      name="points"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <MapPolygon
                            draggable={true}
                            editable={true}
                            points={details?.points}
                            allPoints={area}
                            showDefault={details ? true : false}
                            onChange={field?.onChange}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}
        </div>

        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const AreaForm = () => {
  return (
    <AreaContextProvider>
      <Form />
    </AreaContextProvider>
  );
};
export default AreaForm;
