import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import TagContext from "../../../context/tag/context";
import TagContextProvider from "../../../context/tag/provider";

interface Props {}

const Tag: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(TagContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("tag")}
        buttonName={`${t("update_tag")}`}
        onClick={() => navigate(`/tags/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name ?? "-"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("color")}>
              <div
                style={{
                  width: "40px",
                  height: "20px",
                  borderRadius: "1px",
                  background: details?.color,
                }}
              />
            </AntdDescriptions.Item>
            {/* to do from back */}

            {/* <AntdDescriptions.Item label={t("note_ar")}>
              {details?.translations?.note[0]?.value ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note_en")}>
              {details?.translations?.note[1]?.value ?? "-"}
            </AntdDescriptions.Item> */}
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const TagDetailesPage = () => {
  return (
    <TagContextProvider>
      <Tag />
    </TagContextProvider>
  );
};
export default TagDetailesPage;
