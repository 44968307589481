import { Avatar, Button, Layout, List, Popover, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BellOutlined, FileExcelOutlined } from "@ant-design/icons";
import { FaMoon, FaSun } from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";
import defaultUserImage from "../../../../assets/images/user.jpg";
import AppContext from "../../../../context/app/context";
import AuthContext from "../../../../context/auth/context";
import ProfileCard from "../../../auth/profile-card";
import styles from "./style.module.scss";
import { languages } from "../../../../utils/helpers/constants";
import NotificationContext from "../../../../context/notification/context";
import ProductContext from "../../../../context/product/context";
const { Header } = Layout;

interface IProps {}
const NavBar: React.FC<IProps> = (props) => {
  const { direction, theme, actions } = useContext(AppContext);

  // Languages
  const [languagePopoverVisible, setLanguagePopoverVisible] = useState(false);
  const [notifyPopoverVisible, setNotfyPopoverVisible] = useState(false);
  const { userDetails: user } = useContext(AuthContext);
  const { actions: NotificationAction, list } = useContext(NotificationContext);
  const { actions: productAxtion } = useContext(ProductContext);
  // User Popover
  const [userPopoverVisible, setUserPopoverVisible] = useState(false);

  const { i18n, t } = useTranslation();

  const toggleDarkMode = () => {
    if (theme === "light") {
      actions.changeTheme("dark");
    } else {
      actions.changeTheme("light");
    }
  };

  useEffect(() => {
    NotificationAction.getMyNotification();
  }, [direction]);

  return (
    <Header className={styles.header}>
      <div className={styles.actionsContainer}>
        {/* <LiveClock /> */}
        <div className={styles.actionContainer}>
          <div className={styles.themeSwitcher}>
            <Switch
              checkedChildren={
                <div className={styles.center}>
                  <FaSun />
                </div>
              }
              unCheckedChildren={
                <div className={styles.center}>
                  <FaMoon />
                </div>
              }
              defaultChecked={theme === "light"}
              onChange={toggleDarkMode}
            />
          </div>
          {/* Languages */}

          <Popover
            open={notifyPopoverVisible}
            onOpenChange={setNotfyPopoverVisible}
            placement="bottom"
            content={
              <>
                <List
                  style={{
                    overflow: "auto",
                    height: "400px",
                    width: "400px",
                  }}
                  size="large"
                  dataSource={list?.data}
                  renderItem={(el: any) => (
                    <List.Item
                      onClick={() => {
                        setNotfyPopoverVisible(false);
                      }}
                    >
                      <List.Item.Meta
                        title={
                          <span className={styles.mStart05}>{el?.title}</span>
                        }
                        description={el?.body}
                      />
                      <Button
                        type="link"
                        onClick={async () => {
                          productAxtion.downloadFileExcel(Number(el.itemId));
                          NotificationAction.readNotification(el.id);
                        }}
                        style={{
                          color: el?.status === 4 ? "#00ff00" : "#0d4ea2",
                        }}
                      >
                        {t("downloadFile")}
                      </Button>
                    </List.Item>
                  )}
                />
              </>
            }
            trigger="click"
          >
            <Button
              onClick={() => {
                NotificationAction.getMyNotification();
              }}
              className={styles.verticalMiddle}
              shape="circle"
              icon={<BellOutlined />}
              type="text"
              style={{ paddingTop: "10px" }}
              size="large"
            />
          </Popover>
        </div>
        {languages?.length > 0 && (
          <Popover
            open={languagePopoverVisible}
            onOpenChange={setLanguagePopoverVisible}
            placement="bottom"
            content={
              <>
                <List
                  size="small"
                  dataSource={languages}
                  renderItem={(language) => (
                    <List.Item
                      className={styles.cursorPointer}
                      onClick={() => {
                        i18n?.changeLanguage(language.code);
                        setLanguagePopoverVisible(false);
                      }}
                    >
                      <span className={styles.mStart05}>{language.name}</span>
                    </List.Item>
                  )}
                />
              </>
            }
            trigger="click"
          >
            <Button
              className={styles.verticalMiddle}
              shape="circle"
              icon={<FiGlobe />}
              type="text"
              style={{ paddingTop: "10px" }}
              size="large"
            />
          </Popover>
        )}
        {/* User */}
        <Popover
          placement={direction === "rtl" ? "bottomLeft" : "bottomRight"}
          content={<ProfileCard />}
          trigger="click"
          open={userPopoverVisible}
          onOpenChange={setUserPopoverVisible}
        >
          <div className={styles.avatarContainer}>
            <div className={styles.userName}>
              <div>
                {user?.firstName + "  " + user?.lastName ?? "Administrator"}
              </div>
              <small>
                {t("roles")}: {user?.roles?.map((r) => <>{r.name}</>) ?? " "}
              </small>
            </div>
            <Avatar size="default" src={user?.image?.url ?? defaultUserImage} />
          </div>
        </Popover>
      </div>
    </Header>
  );
};

export default NavBar;
