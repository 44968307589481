import React, { useState } from "react";
import { Spin, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { isImageTypeSupport } from "../../../utils/helpers/support-images";
import {
  errorNotification,
  successNotification,
} from "../../../utils/helpers/notification";
import { MediumFor, MediumType } from "../../../models/medium/enum";
import { InboxOutlined } from "@ant-design/icons";
import { ACCESS_TOKEN } from "../../../utils/helpers/constants";
import { compressImage } from "../../../utils/helpers/image-compression";
import { IMedium } from "../../../models/medium/response";
import { isPdfTypeSupport } from "../../../utils/helpers/support-pdf";

const { Dragger } = Upload;

interface IProps {
  fileFor: MediumFor;
  onChange: (response: IMedium | null) => void;
  type?: "DRAGGER" | "BUTTON";
  children?: React.ReactNode;
  showUploadList?: boolean;
}

const FileUploader: React.FC<IProps> = ({
  fileFor,
  onChange,
  type,
  children,
  showUploadList,
}) => {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);

  return (
    <>
      {type === "DRAGGER" ? (
        <>
          <Spin spinning={uploading}>
            <Dragger
              accept=".doc,.xml ,.docx,application/pdf ,application/xlsx ,  image/*"
              name="medium"
              listType="text"
              multiple={false}
              onRemove={() => {
                setFileList([]);
                // onChange(null);
              }}
              beforeUpload={(file) => {
                setUploading(true);
                onChange(null);

                // Check File Type.
                const isFileSupport = true; //isPdfTypeSupport(file.type);

                if (!isFileSupport) {
                  errorNotification(t("you_can_only_upload_files"));
                  setUploading(false);
                  return false;
                }

                // Compress Image
                setFileList([file]);
                return file;
              }}
              onChange={({ file }) => {
                if (file.status === "error") {
                  setUploading(false);
                  errorNotification(t("upload_failed"));
                }
                if (file.status === "done") {
                  onChange(file?.response);
                  successNotification(t("upload_success"));
                  setUploading(false);
                  console.log(fileList);
                }
              }}
              fileList={[...fileList]}
              action={process.env.REACT_APP_UPLOAD_FILE_URL}
              headers={{
                Authorization: `Bearer ${
                  localStorage.getItem(ACCESS_TOKEN) ??
                  sessionStorage.getItem(ACCESS_TOKEN)
                }`,
              }}
              data={() => {
                return {
                  type: MediumType.File,
                  for: fileFor,
                };
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("select_or_drag_file")}</p>
            </Dragger>
          </Spin>
        </>
      ) : (
        <>
          <Upload
            showUploadList={false}
            accept=".doc,.docx,application/pdf,application/xlsx"
            name="medium"
            listType="text"
            multiple={false}
            onRemove={() => {
              setFileList([]);
              onChange(null);
            }}
            beforeUpload={(file) => {
              setUploading(true);
              onChange(null);

              // Check File Type.
              const isFileSupport = true; //= isPdfTypeSupport(file.type);

              if (!isFileSupport) {
                errorNotification(t("you_can_only_upload_files"));
                setUploading(false);
                return false;
              }

              // Compress Image
              setFileList([file]);
              return compressImage(file);
            }}
            onChange={({ file }) => {
              if (file.status === "error") {
                setUploading(false);
                errorNotification(t("upload_failed"));
              }
              if (file.status === "done") {
                onChange(file?.response);
                successNotification(t("upload_success"));
                setUploading(false);
              }
            }}
            fileList={[...fileList]}
            action={process.env.REACT_APP_UPLOAD_FILE_URL}
            headers={{
              Authorization: `Bearer ${
                localStorage.getItem(ACCESS_TOKEN) ??
                sessionStorage.getItem(ACCESS_TOKEN)
              }`,
            }}
            data={() => {
              return {
                type: MediumType.File,
                for: fileFor,
              };
            }}
          >
            {children}
          </Upload>
        </>
      )}
    </>
  );
};

export default FileUploader;
