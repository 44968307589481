import { Pagination as AntdPagination, PaginationProps } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/helpers/constants";

interface Props extends PaginationProps {}

const Pagination: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <AntdPagination
      className={classNames("shadow", styles.pagination)}
      showTotal={(total, range) => {
        return `${t("shows")} ${
          range[0] && range[1] ? `${range[0]}-${range[1]} ${t("of")}` : ""
        } ${total} ${t("items")}`;
      }}
      // hideOnSinglePage
      defaultPageSize={DEFAULT_PAGE_SIZE}
      showQuickJumper
      showSizeChanger
      responsive
      {...props}
      pageSize={props.pageSize ?? DEFAULT_PAGE_SIZE}
    />
  );
};

export default Pagination;
