import { ITranslationRequest } from "../base/translation";

export interface ICreatePaymentPoint {
    employId: number
    receiptNumber: number
    value: number
    collectionPointId: number
    note: string
}

export interface IUpdatePaymentPoint extends ICreatePaymentPoint {

}

export interface IExportPaymentPointCollection {

    id: boolean
    employ: boolean
    collectionPoint: boolean
    isConfirmed: boolean
    value: boolean
    receiptNumber: boolean
    note: boolean
    createdAt: boolean
}

export const colomnsExportPaymentPointExcel = [
    "id",
    "employ",
    "collectionPoint",
    "isConfirmed",
    "value",
    "receiptNumber",
    "note",
    "createdAt"
];