import { IClientQuery } from "../../../models/client/query";
import { IClient, IClientDetails } from "../../../models/client/response";
import { IDiscountCode } from "../../../models/discount-code/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import toggleLoading from "../../../utils/helpers/xor";
import paramsReducer from "../../base/base-reducer";
import { QueryAction } from "../../base/base-reducer";
import { IInternalState, ClientLoading } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: ClientLoading | ClientLoading[] } }
  | {
      type: "SET_LIST_DISCOUNT_CODES";
      payload: { listDiscountCodes: IBaseListingResponse<IDiscountCode> };
    }
  | QueryAction<IClientQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }

    case "SET_LIST_DISCOUNT_CODES": {
      return {
        ...state,
        listDiscountCodes: action.payload.listDiscountCodes,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
