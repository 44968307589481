import { LANGUAGE_CODE } from "./constants";
import { notification } from "antd";

export const errorNotification = (message: string, description?: string) => {
  return notification["error"]({
    message: message,
    description: description ?? "",
    style: {
      direction: localStorage.getItem(LANGUAGE_CODE) === "ar" ? "rtl" : "ltr",
    },
    placement:
      localStorage.getItem(LANGUAGE_CODE) === "ar"
        ? "bottomLeft"
        : "bottomRight",
    duration: 2,
  });
};
export const successNotification = (message: string, description?: string) => {
  return notification["success"]({
    message: message,
    description: description ?? "",
    style: {
      direction: localStorage.getItem(LANGUAGE_CODE) === "ar" ? "rtl" : "ltr",
    },
    placement:
      localStorage.getItem(LANGUAGE_CODE) === "ar"
        ? "bottomLeft"
        : "bottomRight",
    duration: 2,
  });
};

export const btnNotification = (
  message: string,
  description?: string,
  buttonText?: any,
) => {
  return notification["info"]({
    message: message,
    description: description ?? "",
    style: {
      direction: localStorage.getItem(LANGUAGE_CODE) === "ar" ? "rtl" : "ltr",
    },
    placement:
      localStorage.getItem(LANGUAGE_CODE) === "ar"
        ? "topLeft"
        : "topRight",
    btn: buttonText ,
  });
};
