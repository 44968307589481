// Status Tags Colors

export enum BooleanStatus {
  WORKING = 1,
  NOT_WORKING = 2,
  BUSY = 3,
}

export const getStatusDeliveryWorkerColor = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.WORKING:
      return "green";
    case BooleanStatus.BUSY:
      return "orange";
    case BooleanStatus.NOT_WORKING:
      return "red";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getStatusDeliveryWorker = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.WORKING:
      return "working";
    case BooleanStatus.NOT_WORKING:
      return "not_working";
    case BooleanStatus.BUSY:
      return "busy";
    default:
      return "not_working";
  }
};

export enum StatusDriver {
  ACCEPT = 1,
  PENDING = 2,
  HAND_REJECT = 3,
  NOT_RESPOND = 4,
  CONDITIONS_NOT_MET = 5,
}

export const getStatusDeliveryHistoryColor = (status: StatusDriver) => {
  switch (status) {
    case StatusDriver.ACCEPT:
      return "green";
    case StatusDriver.PENDING:
      return "orange";
    case StatusDriver.HAND_REJECT:
      return "red";
    case StatusDriver.NOT_RESPOND:
      return "blue";
    case StatusDriver.CONDITIONS_NOT_MET:
      return "purple";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getStatusDeliveryHistoryWorker = (status: StatusDriver) => {
  switch (status) {
    case StatusDriver.ACCEPT:
      return "accept";
    case StatusDriver.PENDING:
      return "pending";
    case StatusDriver.HAND_REJECT:
      return "hand_reject";
    case StatusDriver.NOT_RESPOND:
      return "not_respond";
    case StatusDriver.CONDITIONS_NOT_MET:
      return "conditions_not_met";
  }
};