import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TagContext from "../../../context/tag/context";
import { ITag } from "../../../models/tag/response";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import ProductContext from "../../../context/product/context";
import { ImCheckmark, ImCross } from "react-icons/im";
import { IProduct } from "../../../models/product/response";
import Tag from "../../general/antd/tag";
import {
  getProductStatus,
  getProductStatusColor,
  getProductType,
  getProductTypeColor,
} from "../../../models/product/enum";
import { CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Button, { ButtonProps } from "../../../components/general/antd/button";
import { DeleteOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import Popconfirm from "../../general/antd/pop-confirm";

interface IProps {}

const ProductTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(ProductContext);

  const columns: ColumnProps<IProduct>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("barcode"),
      dataIndex: "barcode",
      key: "barcode",

      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
      sorter: {
        multiple: 2,
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            title={getProductStatus(record?.status)}
            color={getProductStatusColor(record?.status)}
          />
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
      width: 200,
      align: "center",
      key: "type",
      render: (_, record) => {
        return (
          <Tag
            title={getProductType(record?.type)}
            color={getProductTypeColor(record?.type)}
          />
        );
      },
    },
    {
      title: t("main_category"),
      dataIndex: "main_category",
      align: "center",
      key: "main_category",
      render: (_, record) => {
        return <>{record?.mainCategory?.name ?? "-"}</>;
      },
    },
    {
      title: t("parent_category"),
      dataIndex: "parent_category",
      align: "center",
      key: "sub_category",
      render: (_, record) => {
        return <>{record?.parentCategory?.name ?? "-"}</>;
      },
    },
    {
      title: t("new"),
      dataIndex: "isNew",

      align: "center",
      key: "isNew",
      render: (_, record) => {
        return (
          <>
            {record?.isNew ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("trademark"),
      dataIndex: "trademark",
      align: "center",
      key: "trademark",
      render: (_, record) => {
        return <>{record?.trademark?.name ?? "-"}</>;
      },
    },

    {
      title: t("available"),
      dataIndex: "available",
      
      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.available ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("need_recipe"),
      dataIndex: "need_recipe",

      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.needRecipe ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("sold_by_unit"),
      dataIndex: "sold_by_unit",
     
      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.soldByUnit ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "price",
      align: "center",
      key: "price",
    },
    {
      title: t("unit_price"),
      dataIndex: "unit_price",
      align: "center",
      key: "unit_price",
    },
    {
      title: t("special_discount"),
      dataIndex: "specialDiscount",
      align: "center",
      key: "specialDiscount",
      render: (_, record) => {
        return (
          <>
            {record?.specialDiscount ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("value_special_discount") + " " + "(%)",
      dataIndex: "valueSpecialDiscount",
      align: "center",
      key: "valueSpecialDiscount",
    },
    {
      title: t("general_discount"),
      dataIndex: "generalDiscount",

      align: "center",
      key: "generalDiscount",
      render: (_, record) => {
        return (
          <>
            {record?.generalDiscount ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("value_general_discount")+ " " + "(%)",
      dataIndex: "valueGeneralDiscount",
      align: "center",
      key: "valueGeneralDiscount",
    },
    {
      title: t("actions"),
      width: 250,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteProduct(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />

          <Popconfirm
            title={t("confirm")}
            okButtonProps={{
              danger: true,
              loading: loading.includes("update"),
            }}
            icon={<QuestionCircleTwoTone twoToneColor="red" />}
            onConfirm={async () => {
              await actions.circulateProduct(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
          >
            <Tooltip title={t("circulate_price")}>
              <Button type="primary" shape="circle" icon={<CheckOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{
          x: 2100,
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ProductTable;
