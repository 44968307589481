import React, { useRef, useState } from "react";
import styles from "./style.module.scss";
import "./style.css";
import chatIcon from "../../../../assets/images/user.jpg";

import { Tooltip } from "antd";

interface IProps {
  image?: string;
  text?: string;
  date?: string;
  name?: string;
  isLeft?: boolean;
  isAudio: boolean;
  id: number;
  userType?: string;
}
const MessageCart = ({
  image,
  text,
  isLeft,
  isAudio,
  id,
  date,
  name,
  userType,
}: IProps) => {
  const [isPlay, setIsPlay] = useState(false);

  return (
    <div
      className={`${styles.messageCartContainer} ${styles.gap} ${
        styles.itemsCenter
      } ${isLeft ? "" : styles.reverse}`}
    >
      <img
        className={`${styles.image} ${styles.cursorPointer}`}
        src={chatIcon}
        alt=""
      />

      <Tooltip title={date ?? "none"}>
        <div
          className={`${styles.textContainer} ${styles.rounded} ${
            isLeft ? styles.paddingLeft : styles.paddingRight
          }`}
        >
          <p className={styles.neme}>
            {name ?? "User"} {userType ?? ""}
          </p>

          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </Tooltip>
    </div>
  );
};

export default MessageCart;
