import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";

interface Props {
  required?: boolean;
}

const EmailInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { required = true } = props;
  return (
    <FieldBuilder
      name="email"
      label={t("email")}
      input={{
        type: "email",
      }}
      formItemLabelStyle={{ color: "gray" }}
      rules={{
        required,
        pattern: { value: /^\S+@\S+$/i, message: t("invalid_email_address") },
      }}
    />
  );
};

export default EmailInput;
