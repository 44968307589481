import axios from "axios";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../utils/api/http-client";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import { IInvestorQuery } from "../../models/investor/query";
import {
  ICreateInvestor,
  IExportInvestorExcel,
  IUpdateInvestor,
} from "../../models/investor/request";
import { IInvestor, IInvestorDetails } from "../../models/investor/response";

class Investorsservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllInvestors = (query: IInvestorQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IInvestor>>({
      path: `/investors`,
      method: "GET",
      query,
      ...params,
    });

  getInvestor = (id: number, params?: RequestParams) =>
    this.http.request<IInvestorDetails>({
      path: `/investors/${id}`,
      method: "GET",
      ...params,
    });

  createInvestor = (data: ICreateInvestor, params?: RequestParams) =>
    this.http.request<IInvestor>({
      path: "/investors",
      method: "POST",
      body: data,
      ...params,
    });

  updateInvestor = (
    id: number,
    data: IUpdateInvestor,
    params?: RequestParams
  ) =>
    this.http.request<IInvestor>({
      path: `/investors/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteInvestor = (id: number, params?: RequestParams) =>
    this.http.request<IInvestor>({
      path: `/investors/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportInvestorExcel, query: IInvestorQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/investors/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default Investorsservice;
