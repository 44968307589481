import React, { useContext, useState } from "react";
import { Layout, Menu, Tooltip } from "antd";
// import AppContext from '../../../../contexts/app/context'
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";
import FullLogo from "../../../../assets/images/full-logo.png";
import SamllLogo from "../../../../assets/images/full-logo.png";
import { FaRegCircle, FaRegDotCircle } from "react-icons/fa";

import styles from "./style.module.scss";
import { check } from "../../../../utils/rbac/can";
import AppContext from "../../../../context/app/context";
import AuthContext from "../../../../context/auth/context";

const { Sider } = Layout;
export function isSubset(
  componentPermissions,
  userPermissions,
  isMainRoute = false
) {
  // return true;
  if (userPermissions?.includes("can_view_pads") && isMainRoute) {
    return componentPermissions.includes("can_view_pads");
  }

  for (const item of componentPermissions) {
    if (!userPermissions?.includes(item)) {
      return false;
    }
  }
  return true;
}
const SideBar: React.FC = (props) => {
  const { t } = useTranslation();
  const { direction, screenSize, theme } = useContext(AppContext);
  const { userPermissions: permissions } = useContext(AuthContext);
  let userPermissions =
    permissions.length > 0
      ? permissions
      : localStorage?.getItem("permisionStoroge")?.split(",");
  if (!userPermissions) userPermissions = [];

  const location = useLocation();

  // To Route
  const navigate = useNavigate();

  // Get Keys To Open SubMenu
  const paths = location.pathname.split("/");
  const keys = paths.splice(1, paths.length);

  const [collapsed, setCollapsed] = useState(false);
  const [triggered, setTriggered] = useState(true);

  return (
    <Sider
      width={"250px"}
      theme={theme}
      className={cn(styles.sider, {
        [styles.siderLeft]: direction === "ltr",
        [styles.siderRight]: direction === "rtl",
      })}
      // collapsed={collapsed}
      collapsed={screenSize === "mobileOrTablet" || collapsed}
      collapsible={screenSize !== "mobileOrTablet"}
      onCollapse={setCollapsed}
      trigger={null}
      onMouseEnter={() => {
        setCollapsed(false);
      }}
      onMouseLeave={() => {
        setCollapsed(triggered ? false : true);
      }}
    >
      <div
        className={cn(styles.brand, {
          [styles.collapsedBrand]: collapsed,
        })}
      >
        {collapsed ? (
          <img className={styles.brandImage} src={SamllLogo} alt="logo" />
        ) : (
          <img className={styles.brandImage} src={FullLogo} alt="logo" />
        )}
        <div
          className={cn(styles.triggerContainer, {
            [styles.hide]: collapsed,
          })}
          onClick={() => {
            setTriggered(!triggered);
          }}
        >
          {triggered ? (
            <FaRegDotCircle className={styles.trigger} />
          ) : (
            <FaRegCircle className={styles.trigger} />
          )}
        </div>
      </div>
      <Menu
        mode="inline"
        theme={theme}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={keys?.map((key) => `/${key}`)}
        className={cn(styles.menu, {
          [styles.collapsedMenu]: collapsed,
        })}
        items={routes?.map((route) => {
          if (
            !route?.hasSubMenus &&
            isSubset(route?.permissions, userPermissions)
          ) {
            return {
              key: route?.path,
              label: (
                <Tooltip
                  color="#3458A4"
                  title={t(route.labelKey)}
                  placement="left"

                  // placement={direction === "rtl" ? "right" : "left"}
                >
                  {t(route.labelKey)}
                </Tooltip>
              ),

              icon: route.icon,
              onClick: () => {
                navigate(route.path);
              },
            };
          }
          if (isSubset(route.permissions, userPermissions, true)) {
            return {
              key: route.path,
              label: (
                <Tooltip
                  color="#3458A4"
                  title={t(route.labelKey)}
                  placement="left"

                  // placement={direction === "rtl" ? "right" : "left"}
                >
                  {t(route.labelKey)}
                </Tooltip>
              ),
              icon: route.icon,
              children: route.subMenus?.map((subRoute) => {
                if (isSubset(subRoute.permissions, userPermissions))
                  return {
                    key: subRoute.path,
                    label: (
                      <Tooltip
                        color="#3458A4"
                        title={t(subRoute.labelKey)}
                        mouseEnterDelay={0.01}
                        // placement={direction === "rtl" ? "right" : "left"}
                        placement="left"
                      >
                        {t(subRoute.labelKey)}
                      </Tooltip>
                    ),

                    icon: subRoute.icon,
                    onClick: () => {
                      navigate(subRoute.path);
                    },
                  };
              }),
            };
          }
        })}
      />
    </Sider>
  );
};

export default SideBar;
