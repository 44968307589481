import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { ICommenQuestionQuery } from "../../models/commen-question/query";
import {
  ICreateCommenQuestion,
  IExportCommenQuestionExcel,
  IUpdateCommenQuestion,
} from "../../models/commen-question/request";
import {
  ICommenQuestion,
  ICommenQuestionDetails,
} from "../../models/commen-question/response";

class CommenQuestionService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCommenQuestion = (
    query: ICommenQuestionQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ICommenQuestion>>({
      path: `/common-question`,
      method: "GET",
      query,
      ...params,
    });

  getCommenQuestion = (id: number, params?: RequestParams) =>
    this.http.request<ICommenQuestionDetails>({
      path: `/common-question/${id}`,
      method: "GET",
      ...params,
    });

  createCommenQuestion = (
    data: ICreateCommenQuestion,
    params?: RequestParams
  ) =>
    this.http.request<ICommenQuestion>({
      path: "/common-question",
      method: "POST",
      body: data,
      ...params,
    });

  updateCommenQuestion = (
    id: number,
    data: IUpdateCommenQuestion,
    params?: RequestParams
  ) =>
    this.http.request<ICommenQuestion>({
      path: `/common-question/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCommenQuestion = (id: number, params?: RequestParams) =>
    this.http.request<ICommenQuestion>({
      path: `/common-question/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportCommenQuestionExcel,
    query: ICommenQuestionQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/common-question/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default CommenQuestionService;
