import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import PaymentDeliveryWorkerContext from "../../../context/payment-delivery-worker/context";
import PaymentDeliveryWorkerContextProvider from "../../../context/payment-delivery-worker/provider";
import { IUpdatePaymentDeliveryWorker } from "../../../models/payment-delivery-worker/request";
import EndPoints from "../../../services/end-points";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { IPoint } from "../../../models/point/response";
import AuthContext from "../../../context/auth/context";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(
    PaymentDeliveryWorkerContext
  );
  const { id } = useParams();
  const [deliveryWorkers, setDeliveryWorkers] = useState<any[]>();
  const [deliveryWorkersLoading, setDeliveryWorkersLoading] = useState(true);

  const [collectionPoint, setCollectionPoint] = useState<IPoint[]>([]);
  const [collectionPointLoading, setCollectionPointLoading] = useState(true);

  const [users, setUsers] = useState<any[]>();
  const [usersLoading, setUsersLoading] = useState(true);
  const { direction } = useContext(AppContext);
  const { userDetails: user } = useContext(AuthContext);
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // get all getDeliveryWorker from api
  useEffect(() => {
    const getDeliveryWorker = async () => {
      try {
        const { data } = await EndPoints.deliveryWorkers.getAllDeliveryWorkers({
          light: true,
          perPage: -1,
        });
        setDeliveryWorkers(data?.data);
      } catch (error) {
      } finally {
        setDeliveryWorkersLoading(false);
      }
    };
    getDeliveryWorker();
  }, [direction]);

  // get all getDeliveryWorker from api
  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await EndPoints.user.getAllUsers({
          light: true,
          perPage: -1,
        });
        setUsers(data?.data);
      } catch (error) {
      } finally {
        setUsersLoading(false);
      }
    };
  }, [direction]);

  // get all getCollectionPoint from api
  useEffect(() => {
    const getCollectionPoint = async () => {
      try {
        const { data } = await EndPoints.point.getAllPoints({
          light: true,
          perPage: -1,
        });
        setCollectionPoint(data?.data);
      } catch (error) {
      } finally {
        setCollectionPointLoading(false);
      }
    };
  }, [direction]);

  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updatePaymentDeliveryWorker(details?.id, data)
          : actions.createPaymentDeliveryWorker(data);
      }}
      title={
        details
          ? t("update_payment_delivery_worker")
          : t("add_payment_delivery_worker")
      }
      defaultValues={
        {
          driverId: details?.driver?.id,
          employId: user?.employee?.id,
          receiptNumber: details?.receiptNumber,
          value: details?.value,
          collectionPointId: user?.collectionPoint?.id,
          note:  details?.note,
        } as IUpdatePaymentDeliveryWorker
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <FieldBuilder
                name="driverId"
                label={`${t("delivery_worker")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: deliveryWorkersLoading,
                  options: deliveryWorkers?.map((user, index) => {
                    return {
                      key: index,
                      label: user?.firstName + " " + user?.lastName
                      ,
                      value: user?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="value"
                label={t("value")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="receiptNumber"
                label={t("receipt_number")}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="note"
                label={t("note")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const PaymentDeliveryWorkerForm = () => {
  return (
    <PaymentDeliveryWorkerContextProvider>
      <Form />
    </PaymentDeliveryWorkerContextProvider>
  );
};
export default PaymentDeliveryWorkerForm;
