import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Tag,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import DiscountCodeContext from "../../../context/discount-code/context";
import DiscountCodeContextProvider from "../../../context/discount-code/provider";
import { IUpdateDiscountCode } from "../../../models/discount-code/request";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import {
  getDiscountStatus,
  getDiscountType,
  getDiscountTypeApp,
  getDiscountTypeValue,
} from "../../../models/discount-code/enum";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
import { getTranslations } from "../../../utils/helpers/translations";
import { execute } from "../../../utils/helpers/execute";
import moment from "moment";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import styles from "./style.module.scss";

const Option = Select.Option;

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { details, actions, loading } = useContext(DiscountCodeContext);
  const { id } = useParams();
  const status = [1, 2];
  const types = [1, 2];

  const methods = useForm<any>({
    defaultValues: {
      code: details?.code,
      codeValidity: details?.codeValidity,
      discountValueProduct: details?.discountValueProduct,
      discountValueDelivery: details?.discountValueDelivery,
      maxOfUse: details?.maxOfUse,
      imageId: details?.image?.id ?? undefined,
      note: getTranslations(details?.translations?.note),
      numberPoints: details?.numberPoints,
      status: details?.status,
      type: details?.type,
      validityDate: moment(details?.validityDate).format("YYYY-MM-DD"),
      name: getTranslations(details?.translations?.name),
      showInApp: details?.showInApp ?? false,
      typeApp: details?.typeApp,
      typeValue: details?.typeValue,
      canBePurchased: details?.canBePurchased ?? false,
    } as IUpdateDiscountCode,
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        if (data.type == 1) {
          data.numberPoints = null;
          data.codeValidity = null;
        }
        data.discountValueProduct =
          typeApp == 1 ? undefined : data.discountValueProduct;
        data.discountValueDelivery =
          typeApp == 2 ? undefined : data.discountValueDelivery;
        setLoadingSubmit(true);
        details
          ? await actions.updateDiscountCode(details?.id, data)
          : await actions.createDiscountCode(data);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  const type = useWatch({
    control,
    name: "type",
  });
  const typeApp = useWatch({
    control,
    name: "typeApp",
  });

  useEffect(() => {
    reset({
      code: details?.code,
      codeValidity: details?.codeValidity ?? null,
      discountValueProduct: details?.discountValueProduct,
      discountValueDelivery: details?.discountValueDelivery,
      maxOfUse: details?.maxOfUse,
      imageId: details?.image?.id ?? undefined,
      note: getTranslations(details?.translations?.note),
      numberPoints: details?.numberPoints ?? null,
      status: details?.status,
      type: details?.type,
      validityDate: moment(details?.validityDate).format("YYYY-MM-DD") ?? null,
      name: getTranslations(details?.translations?.name),
      showInApp: details?.showInApp ?? false,
      typeApp: details?.typeApp,
      typeValue: details?.typeValue,
      minimumLimit: details?.minimumLimit,
      upperLimit: details?.upperLimit,
      numberOfUseAllowed: details?.numberOfUseAllowed,
      canBePurchased: details?.canBePurchased ?? false,
    } as IUpdateDiscountCode);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_discount_code") : t("add_discount_code")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 8]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`name[${lang?.code}]`}
                        label={`${t("name")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
                <Col span={8}>
                  <FieldBuilder
                    name={"code"}
                    label={t("code")}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>

                <Col span={8}>
                  <FieldBuilder
                    name={"codeValidity"}
                    label={t("code_validity")}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                    disabled={type == 1 ? true : false}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name={"numberPoints"}
                    label={t("number_points")}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                    disabled={type == 1 ? true : false}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name={"maxOfUse"}
                    label={t("frequency_of_use")}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="validityDate"
                    rules={{ required: false }}
                    label={`${t("validity_date")}`}
                    input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                  />
                </Col>

                <Col span={8}>
                  <FieldBuilder
                    name="status"
                    label={`${t("status_discount")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: status?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getDiscountStatus(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>

                <Col span={8}>
                  <FieldBuilder
                    name="type"
                    label={`${t("type_discount")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: types?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getDiscountType(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>

                <Col span={8}>
                  <FieldBuilder
                    name="typeValue"
                    label={`${t("type_of_discount_value")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2]?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getDiscountTypeValue(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="typeApp"
                    label={`${t("type_of_discount_application")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2, 3]?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getDiscountTypeApp(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name={"discountValueProduct"}
                    label={t("discountValueProduct")}
                    input={{ type: "number" }}
                    disabled={typeApp == 1 ? true : false}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name={"discountValueDelivery"}
                    label={t("discountValueDelivery")}
                    input={{ type: "number" }}
                    disabled={typeApp == 2 ? true : false}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="minimumLimit"
                    label={t("minimum_limit")}
                    input={{ type: "number" }}
                    rules={{ required: true }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="upperLimit"
                    label={t("upper_limit")}
                    input={{ type: "number" }}
                    rules={{ required: true }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="numberOfUseAllowed"
                    label={t("numberOfUseAllowed")}
                    input={{ type: "number" }}
                  />
                </Col>

                <Col span={12}>
                  <FieldBuilder
                    label={`${t("show_in_app")}`}
                    name="showInApp"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    label={`${t("canBePurchased")}`}
                    name="canBePurchased"
                    input={{ type: "checkBox" }}
                  />
                </Col>

                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`note[${lang?.code}]`}
                        label={`${t("note")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
                <Col span={24}>
                  <FormItem label={t("image")}>
                    <Controller
                      name="imageId"
                      rules={{
                        required: {
                          value: false,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <ImageUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              // setfileId(response);
                              field.onChange(response?.id);
                            }}
                            imageFor={MediumFor.UserProfile}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {details?.image && <OldImage src={details?.image?.url} />}
              </Row>
            </div>
          )}
        </div>

        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
const DiscountCodeForm = () => {
  return (
    <DiscountCodeContextProvider>
      <Form />
    </DiscountCodeContextProvider>
  );
};
export default DiscountCodeForm;
