import { IChronicDiseasesQuery } from "../../models/chronic-diseases/query";
import {
  IChronicDiseases,
  IChronicDiseasesDetails,
} from "../../models/chronic-diseases/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import toggleLoading from "../../utils/helpers/xor";
import paramsReducer from "../base/base-reducer";
import { QueryAction } from "../base/base-reducer";
import { IInternalState, ChronicDiseasesLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: ChronicDiseasesLoading | ChronicDiseasesLoading[] };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IChronicDiseases> };
    }
  | { type: "SET_DETAILS"; payload: { details?: IChronicDiseasesDetails } }
  | QueryAction<IChronicDiseasesQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
