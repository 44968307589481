import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Tag,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import CategoryContext from "../../../context/category/context";
import ProductContext from "../../../context/product/context";
import ProductContextProvider from "../../../context/product/provider";
import { AttributeType } from "../../../models/attribute/enums";
import { IAttributeDetails } from "../../../models/attribute/resopnse";
import { ICategory } from "../../../models/category/response";
import { getProductStatus, getProductType } from "../../../models/product/enum";
import { IUpdateProduct } from "../../../models/product/request";
import { ITrademark } from "../../../models/trademark/response";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import { execute } from "../../../utils/helpers/execute";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import styles from "./style.module.scss";
import { ErrorMessage } from "@hookform/error-message";
import dayjs from "dayjs";
import { includes, isArray } from "lodash";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import Image from "../../general/antd/image";
import OldImage from "../../form-components/old-image";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import RichEditor from "../../form-components/rich-text-editor";
import { ITag } from "../../../models/tag/response";
import { IUnit } from "../../../models/unit/response";
import FileUploader from "../../form-components/file-uploader";
import { ISlice } from "../../../models/slice/response";

const Option = Select.Option;

const Form = () => {
  const { details, actions, loading } = useContext(ProductContext);
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const { allCategories } = useContext(CategoryContext);
  const [subCategories, setsubCategories] = useState<ICategory[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagsLoading, setTagsLoading] = useState(true);

  const [slice, setSlice] = useState<ISlice[]>([]);
  const [sliceLoading, setSliceLoading] = useState(true);

  const [units, setUnits] = useState<IUnit[]>([]);
  const [unitsLoading, setUnitsLoading] = useState(true);

  const methods = useForm<any>({
    defaultValues: {
      mainCategoryId: details?.mainCategory?.id,
      childCategoryId: details?.childCategories?.map((sub) => sub.id),
      parentCategoryId: details?.parentCategory?.id,
      available: details?.available ?? false,
      soldByUnit: details?.soldByUnit ?? false,
      needRecipe: details?.needRecipe ?? false,
      imageId: details?.image?.id ?? undefined,
      attributes: details?.attributes,
      description: getTranslations(details?.translations?.description) ?? null,
      extraName: getTranslations(details?.translations?.extraName),
      name: getTranslations(details?.translations?.name),
      note: getTranslations(details?.translations?.note),
      status: details?.status,
      trademarkId: details?.trademark?.id,
      type: details?.type,
      tagIds: details?.tags?.map((tag) => tag?.id),
      isNew: details?.isNew ?? false,
      unitId: details?.unit?.id,
      generalDiscount: details?.generalDiscount,
      specialDiscount: details?.specialDiscount,
      valueGeneralDiscount: details?.valueGeneralDiscount ?? undefined,
      valueSpecialDiscount: details?.valueSpecialDiscount ?? undefined,
      price: details?.price ?? undefined,
      unitPrice: details?.unitPrice ?? undefined,
      sliceId: details?.slice?.id ?? undefined,
    } as IUpdateProduct,
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const [trademark, setTrademark] = useState<ITrademark[]>([]);
  const [trademarkLoading, setTrademarkLoading] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<IAttributeDetails[]>(
    details?.attributes ?? null
  );
  const [keyword, setKeyword] = useState<string>();
  const [tagKeyword, setTagKeyword] = useState<string>();

  const pointMapChanges = useWatch({
    control,
    name: "soldByUnit",
  });
  useEffect(() => {
    // alert(JSON.stringify(trademark));
  }, [trademark]);

  // const Stat;
  useEffect(() => {
    const getTrademarks = async () => {
      setTrademarkLoading(true);

      try {
        const { data } = await EndPoints.trademark.getAllTrademark({
          keyword: keyword ? `${keyword}` : undefined,
          light: true,
          perPage: -1,
        });
        setTrademark(data.data);
      } catch (error) {
      } finally {
        setTrademarkLoading(false);
      }
    };
    getTrademarks();
  }, [direction, keyword]);

  useEffect(() => {
    const getTags = async () => {
      try {
        const { data } = await EndPoints.tag.getAllTags({
          light: true,
          perPage: -1,
        });
        setTags(data.data);
      } catch (error) {
      } finally {
        setTagsLoading(false);
      }
    };
    getTags();
  }, [direction]);

  useEffect(() => {
    const getUnits = async () => {
      try {
        const { data } = await EndPoints.unit.getAllUnits({
          light: true,
          perPage: -1,
        });
        setUnits(data?.data);
      } catch (error) {
      } finally {
        setUnitsLoading(false);
      }
    };
    getUnits();
  }, [direction]);

  useEffect(() => {
    const getSlice = async () => {
      try {
        const { data } = await EndPoints.slice.getAllSlice({
          light: true,
          perPage: -1,
        });
        setSlice(data?.data);
      } catch (error) {
      } finally {
        setSliceLoading(false);
      }
    };
    getSlice();
  }, [direction]);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  const mainCategoryId = useWatch({
    control,
    name: "mainCategoryId",
  });

  const parentCategoryId = useWatch({
    control,
    name: "parentCategoryId",
  });

  useEffect(() => {
    parentCategoryId &&
      setAttributes(
        allCategories?.filter((cat) => cat.id === parentCategoryId)[0]
          ?.attributes
      );
  }, [parentCategoryId]);

  useEffect(() => {
    mainCategoryId &&
      setsubCategories(
        allCategories?.filter(
          (cat) => cat?.grand?.id === mainCategoryId && cat?.parent === null
        )
      );
  }, [mainCategoryId]);

  const [childCategoriess, setChildCategoriess] = useState<ICategory[]>([]);
  useEffect(() => {
    parentCategoryId &&
      setChildCategoriess(
        allCategories?.filter(
          (cat) =>
            cat?.grand?.id === mainCategoryId &&
            cat?.parent?.id === parentCategoryId
        )
      );
  }, [parentCategoryId]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    data.valueSpecialDiscount == ""
      ? (data.valueSpecialDiscount = 0)
      : (data.valueSpecialDiscount = data.valueSpecialDiscount > 0 ?  Math.trunc(data.valueSpecialDiscount )  : undefined);
    data.valueGeneralDiscount == ""
      ? (data.valueGeneralDiscount = 0)
      : (data.valueGeneralDiscount =  data.valueGeneralDiscount > 0 ? Math.trunc(data.valueGeneralDiscount )   : undefined);
    await execute({
      callback: async () => {
        data.v;
        setLoadingSubmit(true);
        details
          ? await EndPoints.product.updateProduct(details?.id, data)
          : await EndPoints.product.createProduct(data);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  const discount = useWatch({
    control,
    name: ["generalDiscount", "specialDiscount"],
  });

  useEffect(() => {
    reset({
      available: details?.available,
      mainCategoryId: details?.mainCategory?.id,
      childCategoryId: details?.childCategories?.map((sub) => sub.id),
      parentCategoryId: details?.parentCategory?.id,
      extraName: getTranslations(details?.translations?.extraName),
      description: getTranslations(details?.translations?.description) ?? null,
      note: getTranslations(details?.translations?.note) ?? null,
      name: getTranslations(details?.translations?.name),
      soldByUnit: details?.soldByUnit,
      status: details?.status,
      type: details?.type,
      trademarkId: details?.trademark?.id,
      attributes: details?.attributes?.sort((a) => a.order)?.sort((a) => a.id),
      needRecipe: details?.needRecipe,
      imageId: details?.image?.id ?? undefined,
      tagIds: details?.tags?.map((tag) => tag?.id),
      isNew: details?.isNew,
      unitId: details?.unit?.id,
      generalDiscount: details?.generalDiscount,
      specialDiscount: details?.specialDiscount,
      barcode: details?.barcode,
      valueGeneralDiscount: details?.valueGeneralDiscount ?? undefined,
      valueSpecialDiscount: details?.valueSpecialDiscount ?? undefined,
      price: details?.price ?? undefined,
      unitPrice: details?.unitPrice ?? undefined,
      sliceId: details?.slice?.id ?? undefined
    } as IUpdateProduct);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.title}>
            {details ? t("update_product") : t("add_product")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[16, 8]}>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`name[${lang?.code}]`}
                        label={`${t("name")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: true }}
                      />
                    </Col>
                  );
                })}
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`extraName[${lang?.code}]`}
                        label={`${t("extra_name")} (${lang?.name})`}
                        input={{ type: "text" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
                <Col span={24}>
                  <FieldBuilder
                    name="barcode"
                    label={`${t("barcode")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "text",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    name="price"
                    label={`${t("price")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "number",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    name="unitPrice"
                    label={`${t("unit_price")}`}
                    width="large"
                    rules={{ required: false }}
                    input={{
                      type: "number",
                    }}
                  />
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    name="type"
                    label={`${t("type")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2]?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getProductType(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    name="status"
                    label={`${t("status")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: false,
                      options: [1, 2]?.map((state, index) => {
                        return {
                          key: index,
                          label: t(getProductStatus(state)),
                          value: state,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={6}>
                  <FormItem label={t("slice")}>
                    <Controller
                      name="sliceId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={sliceLoading}
                            filterOption={false}
                            {...field}
                            onSearch={(val) => {
                              setKeyword(val ? val : undefined);
                            }}
                            showSearch
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("slice")}
                            options={
                              slice?.map((t) => {
                                return {
                                  value: t?.id,
                                  label: t?.name,
                                };
                              }) ?? []
                            }
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("trademark")}>
                    <Controller
                      name="trademarkId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={trademarkLoading}
                            filterOption={false}
                            {...field}
                            onSearch={(val) => {
                              setKeyword(val ? val : undefined);
                            }}
                            showSearch
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("trademark")}
                            options={
                              trademark?.map((t) => {
                                return {
                                  value: t.id,
                                  label: t.name,
                                };
                              }) ?? []
                            }
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("tags")}>
                    <Controller
                      name="tagIds"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={tagsLoading}
                            {...field}
                            onSearch={(val) => {
                              setTagKeyword(val);
                            }}
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            placeholder={t("tags")}
                            filterOption={false}
                          >
                            {tags
                              .filter((tr) =>
                                tagKeyword
                                  ? tr?.name?.includes(tagKeyword)
                                  : true
                              )
                              .map((tr, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={tr?.id}
                                    label={
                                      <Tag color={tr?.color}>{tr.name}</Tag>
                                    }
                                  >
                                    <Tag color={tr?.color}>{tr?.name}</Tag>
                                  </Option>
                                );
                              })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("main_category")}>
                    <Controller
                      name="mainCategoryId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={false}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            onSelect={() => {
                              setValue("parentCategoryId", null);
                              setValue("childCategoryId", []);
                            }}
                            placeholder={t("categories")}
                            {...field}
                            options={allCategories
                              ?.filter(
                                (cat) =>
                                  cat?.parent === null && cat?.grand === null
                              )
                              ?.map((tr, index) => ({
                                label: tr.name,
                                value: tr.id,
                              }))}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("parent_category")}>
                    <Controller
                      name="parentCategoryId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            showSearch
                            loading={false}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("categories")}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            options={subCategories?.map((tr, index) => ({
                              label: tr.name,
                              value: tr.id,
                            }))}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("childern_category")}>
                    <Controller
                      name="childCategoryId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={false}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            mode="multiple"
                            placeholder={t("categories")}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            options={childCategoriess?.map((tr, index) => ({
                              label: tr.name,
                              value: tr.id,
                            }))}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    name="unitId"
                    label={`${t("unit")}`}
                    width="large"
                    rules={{ required: pointMapChanges }}
                    input={{
                      type: "select",
                      allowSearch: true,
                      loading: unitsLoading,
                      options: units?.map((unit, index) => {
                        return {
                          key: index,
                          label: unit.name,
                          value: unit.id,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    label={`${t("need_recipe")}`}
                    name="needRecipe"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    label={`${t("sold_by_unit")}`}
                    name="soldByUnit"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    label={`${t("available")}`}
                    name="available"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={6}>
                  <FieldBuilder
                    label={`${t("is_new")}`}
                    name="isNew"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={6}>
                  <Controller
                    name="generalDiscount"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => {
                      return (
                        <>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            className="shadow"
                          >
                            {t("general_discount")}
                          </Checkbox>
                        </>
                      );
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name="specialDiscount"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => {
                      return (
                        <>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            className="shadow"
                          >
                            {t("special_discount")}
                          </Checkbox>
                        </>
                      );
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    label={`${t("value_general_discount")}(%)`}
                    name="valueGeneralDiscount"
                    input={{ type: "number" }}
                    disabled={discount[0] ? false : true}
                  />
                </Col>
                <Col span={12}>
                  <FieldBuilder
                    label={`${t("value_special_discount")}(%)`}
                    name="valueSpecialDiscount"
                    input={{ type: "number" }}
                    disabled={discount[1] ? false : true}
                  />
                </Col>
                {languages?.map((lang, index) => {
                  return (
                    <Col span={12}>
                      <FieldBuilder
                        key={index}
                        name={`note[${lang?.code}]`}
                        label={`${t("note")} (${lang?.name})`}
                        input={{ type: "text-area" }}
                        rules={{ required: false }}
                      />
                    </Col>
                  );
                })}
                {languages?.map((lang, index) => {
                  return (
                    <Col span={24}>
                      <div>
                        <FormItem label={`${t("description")} (${lang?.name})`}>
                          <Controller
                            name={`description[${lang?.code}]`}
                            rules={{
                              required: {
                                value: false,
                                message: `${t("field_is_required_message")}`,
                              },
                            }}
                            render={({ field: { ref, ...field } }) => {
                              return (
                                <RichEditor
                                  value={field?.value ?? ""}
                                  onChange={field?.onChange}
                                />
                              );
                            }}
                          />
                        </FormItem>
                      </div>
                    </Col>
                  );
                })}
                {attributes
                  ?.sort((a) => a.order)
                  ?.sort((a) => a.id)
                  ?.map((attribute, index) => {
                    return (
                      <>
                        <Controller
                          name={`attributes[${index}].attributeId`}
                          control={control}
                          defaultValue={attribute?.id}
                          render={({ field: { ...field } }) => {
                            return <Input {...field} type="hidden" />;
                          }}
                        />
                        <Col span={8}>
                          <FormItem label={attribute?.name}>
                            <Controller
                              name={`attributes[${index}].values[]`}
                              control={control}
                              rules={{
                                required: {
                                  value:
                                    attribute?.type === AttributeType.CHECKBOX
                                      ? false
                                      : attribute?.isRequired,
                                  message: `${t("field_is_required_message")}`,
                                },
                              }}
                              render={({ field }) => {
                                switch (attribute?.type) {
                                  case AttributeType.NUMBER:
                                    return (
                                      <Input
                                        type="number"
                                        onBlur={field.onBlur}
                                        defaultValue={field.value}
                                        onChange={(e) => {
                                          field.onChange(
                                            e?.target?.value
                                              ? [e?.target?.value]
                                              : null
                                          );
                                        }}
                                      />
                                    );
                                  case AttributeType.COLOR:
                                    return (
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder={t("please_select")}
                                        onChange={(value) => {
                                          field.onChange(
                                            value
                                              ? isArray(value)
                                                ? value
                                                : [value]
                                              : null
                                          );
                                        }}
                                        value={
                                          isArray(field.value)
                                            ? !attribute?.isMultiple
                                              ? field.value[0]
                                              : field.value
                                            : field.value
                                        }
                                        onBlur={field.onBlur}
                                        allowClear
                                        optionLabelProp="label"
                                        showSearch
                                        optionFilterProp="label"
                                        mode={
                                          attribute?.isMultiple
                                            ? "multiple"
                                            : null
                                        }
                                      >
                                        {attribute?.attributeParams?.map(
                                          (param, index) => {
                                            return (
                                              <Option
                                                key={index}
                                                value={`${param?.id}`}
                                                label={
                                                  <Tag color={param?.data?.hex}>
                                                    {param?.data?.hex} assa
                                                  </Tag>
                                                }
                                              >
                                                <Tag color={param?.data?.hex}>
                                                  {param?.data?.hex}assa
                                                </Tag>
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    );
                                  case AttributeType.TEXT_DROP_DOWN:
                                    return (
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder={t("please_select")}
                                        onChange={(value) => {
                                          field.onChange(
                                            value
                                              ? isArray(value)
                                                ? value
                                                : [value]
                                              : null
                                          );
                                        }}
                                        value={
                                          isArray(field.value)
                                            ? !attribute?.isMultiple
                                              ? field.value[0]
                                              : field.value
                                            : field.value
                                        }
                                        onBlur={field.onBlur}
                                        allowClear
                                        optionLabelProp="label"
                                        showSearch
                                        optionFilterProp="label"
                                        mode={
                                          attribute?.isMultiple
                                            ? "multiple"
                                            : null
                                        }
                                        options={attribute?.attributeParams?.map(
                                          (param, index) => {
                                            return {
                                              value: `${param?.id}`,
                                              label: param?.name as string,
                                            };
                                          }
                                        )}
                                      />
                                    );
                                  case AttributeType.IMAGE_DROP_DOWN:
                                    return (
                                      <Select
                                        style={{ width: "100%" }}
                                        placeholder={t("please_select")}
                                        onChange={(value) => {
                                          field.onChange(
                                            value
                                              ? isArray(value)
                                                ? value
                                                : [value]
                                              : null
                                          );
                                        }}
                                        value={
                                          isArray(field.value)
                                            ? !attribute?.isMultiple
                                              ? field.value[0]
                                              : field.value
                                            : field.value
                                        }
                                        onBlur={field.onBlur}
                                        allowClear
                                        optionLabelProp="label"
                                        showSearch
                                        optionFilterProp="label"
                                        mode={
                                          attribute?.isMultiple
                                            ? "multiple"
                                            : null
                                        }
                                      >
                                        {attribute?.attributeParams?.map(
                                          (param, index) => {
                                            return (
                                              <Option
                                                key={index}
                                                value={`${param?.id}`}
                                                label={
                                                  <Image
                                                    src={
                                                      param?.data?.image?.url
                                                    }
                                                    className={
                                                      styles.optionImage
                                                    }
                                                    preview={false}
                                                  />
                                                  // param?.name
                                                }
                                              >
                                                <div>
                                                  <span>
                                                    <Image
                                                      src={
                                                        param?.data?.image?.url
                                                      }
                                                      className={
                                                        styles.optionImage
                                                      }
                                                      preview={false}
                                                    />
                                                  </span>
                                                  <span className={styles.mx1}>
                                                    {param?.name}
                                                  </span>
                                                </div>
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    );
                                  case AttributeType.CHECKBOX:
                                    return (
                                      <Checkbox
                                        onChange={(e) => {
                                          field.onChange([
                                            `${e.target.checked}`,
                                          ]);
                                        }}
                                        defaultChecked={
                                          field.value
                                            ? isArray(field.value)
                                              ? field.value[0] === "true"
                                              : field.value === "true"
                                            : false
                                        }
                                      >
                                        {attribute?.name}
                                      </Checkbox>
                                    );
                                  case AttributeType.DATE:
                                    return (
                                      <DatePicker
                                        style={{ width: "100%" }}
                                        format=""
                                        onBlur={field.onBlur}
                                        onChange={(value) => {
                                          field.onChange(
                                            value ? [value] : null
                                          );
                                        }}
                                        defaultValue={
                                          field.value
                                            ? isArray(field.value)
                                              ? dayjs(field.value[0]) // Use dayjs() instead of moment()
                                              : dayjs(field.value) // Use dayjs() instead of moment()
                                            : undefined // Use dayjs() instead of moment()
                                        }
                                      />
                                    );
                                  case AttributeType.NUMBER:
                                    return (
                                      <Input
                                        type="number"
                                        onBlur={field.onBlur}
                                        defaultValue={field.value}
                                        onChange={(e) => {
                                          field.onChange(
                                            e?.target?.value
                                              ? [e?.target?.value]
                                              : null
                                          );
                                        }}
                                      />
                                    );

                                  default:
                                    return (
                                      <Input
                                        onBlur={field.onBlur}
                                        defaultValue={field.value}
                                        onChange={(e) => {
                                          field.onChange(
                                            e?.target?.value
                                              ? [e?.target?.value]
                                              : null
                                          );
                                        }}
                                      />
                                    );
                                }
                              }}
                            />

                            <ErrorMessage
                              errors={errors}
                              name={`attributes[${index}].values[]`}
                              render={({ message }) => (
                                <p className={styles.alert}>{message}</p>
                              )}
                            />
                          </FormItem>
                        </Col>
                      </>
                    );
                  })}
                <Col span={24}>
                  <FormItem label={t("file")}>
                    <Controller
                      name="pdfId"
                      rules={{
                        required: {
                          value: false,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <FileUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              field.onChange(response?.id);
                            }}
                            fileFor={MediumFor.PRODUCT_PDF}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                {details?.pdf && (
                  <a target="_blank" href={details?.pdf?.url}>
                    download pdf
                  </a>
                )}
                <Col span={24}>
                  <FormItem label={t("image")}>
                    <Controller
                      name="imageId"
                      rules={{
                        required: {
                          value: false,
                          message: `${t("field_is_required_message")}`,
                        },
                      }}
                      render={({ field: { ref, ...field } }) => {
                        return (
                          <ImageUploader
                            {...field}
                            type="DRAGGER"
                            onChange={(response) => {
                              field.onChange(response?.id);
                            }}
                            imageFor={MediumFor.UserProfile}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {details?.image && <OldImage src={details?.image?.url} />}
              </Row>
            </div>
          )}
        </div>

        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const ProductForm = () => {
  return (
    <ProductContextProvider>
      <Form />
    </ProductContextProvider>
  );
};
export default ProductForm;
