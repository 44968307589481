import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IOrderNote,
  IOrderNoteDetails,
} from "../../models/order-note/response";
import { IOrderNoteQuery } from "../../models/order-note/query";
import {
  ICreateOrderNote,
  IUpdateOrderNote,
} from "../../models/order-note/request";

export type OrderNoteLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OrderNoteLoading[];

  list?: IBaseListingResponse<IOrderNote>;
  query: IOrderNoteQuery;

  details?: IOrderNoteDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IOrderNoteDetails) => void;

    createOrderNote: (request: ICreateOrderNote) => void;
    updateOrderNote: (id: number, request: IUpdateOrderNote) => void;
    deleteOrderNote: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IOrderNoteQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createOrderNote: DEFAULT_FUNCTION,
    updateOrderNote: DEFAULT_FUNCTION,
    deleteOrderNote: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const OrderNoteContext = createContext(externalState);

export default OrderNoteContext;
