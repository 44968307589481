import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { getAttributeType } from "../../../models/attribute/enums";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { IAttributeDetails } from "../../../models/attribute/resopnse";

interface IProps {
  details?: IAttributeDetails;
}

const AttributeForm: React.FC<IProps> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 8]}>
        {languages?.map((lang, index) => {
          return (
            <Col span={12}>
              <FieldBuilder
                key={index}
                name={`name[${lang?.code}]`}
                label={`${t("name")} (${lang?.name})`}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
          );
        })}

        <Col span={12}>
          <FieldBuilder
            label={`${t("order")}`}
            name="order"
            input={{ type: "number" }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            disabled={details ? true : false}
            rules={{ required: true }}
            input={{
              type: "select",
              loading: false,
              allowSearch: true,
              options: [1, 2, 3, 4, 5, 6]?.map((type, index) => {
                return {
                  key: type,
                  label: t(getAttributeType(type)),
                  value: type,
                };
              }),
            }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            label={`${t("isRequired")}`}
            name="isRequired"
            input={{ type: "checkBox" }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            label={`${t("isMultiple")}`}
            name="isMultiple"
            input={{ type: "checkBox" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default AttributeForm;
