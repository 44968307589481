import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import EndPoints from "../../../services/end-points";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";

import PrizeContext from "../../../context/prize/context";
import { IStore } from "../../../models/store/response";

interface IProps {}

const PrizeFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(PrizeContext);
  const { direction } = useContext(AppContext);
  const [store, setSorts] = useState<IStore[]>([]);
  const [storeLoading, setStoreLoading] = useState(true);

  useEffect(() => {
    const getStore = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          light: true,
          perPage: -1,
        });
        setSorts(data.data);
        setStoreLoading(false);
      } catch (error) {
      } finally {
        setStoreLoading(false);
      }
    };
    getStore();
  }, [direction]);

  return (
    <Form
      formId="prize-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.storeId
              ? {
                  name: "storeId",
                  value: data.storeId,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="storeId"
            label={`${t("store")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: storeLoading,
              options: store.map((jop, index) => {
                return {
                  label: jop.name,
                  value: jop.id,
                };
              }),
            }}
          />
        </Col>
       
      </Row>
    </Form>
  );
};

export default PrizeFilter;
