export enum InvestorStatus {
  WORK = 1,
  NOT_WORK = 2,
}

export const getInvestoreStatusColor = (status: InvestorStatus) => {
  switch (status) {
    case InvestorStatus.WORK:
      return "green";
    case InvestorStatus.NOT_WORK:
      return "red";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getInvestorStatus = (status: InvestorStatus) => {
  switch (status) {
    case InvestorStatus.WORK:
      return "working";
    case InvestorStatus.NOT_WORK:
      return "not_working";
    default:
      return "working";
  }
};

export enum InvestorType {
  pharmacist = 1,
  Investor = 2,
}

export const getInvestoreTypeColor = (Type: InvestorType) => {
  switch (Type) {
    case InvestorType.pharmacist:
      return "blue";
    case InvestorType.Investor:
      return "orange";
    default:
      return "green";
  }
};

// Type Boolean stuts
export const getInvestorType = (Type: InvestorType) => {
  switch (Type) {
    case InvestorType.pharmacist:
      return "pharmacist";
    case InvestorType.Investor:
      return "investore";
    default:
      return "Investore";
  }
};
