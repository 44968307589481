import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import PointContext from "../../../context/point/context";
import Tag from "../../general/antd/tag";
import { getPointStatus, getPointStatusColor } from "../../../models/point/enum";
import PointContextProvider from "../../../context/point/provider";
import PaymentPointContextProvider from "../../../context/payment-point/provider";
import PaymentPointContext from "../../../context/payment-point/context";
import { getPaymentStatus, getPaymentStatusColor } from "../../../models/payment-delivery-worker/enum";
import moment from "moment";

interface Props { }

const PaymentPoint: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(PaymentPointContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("payment_point")}
        subtitle={t("details")}
        buttonName={`${t("update_payment_point")}`}
        onClick={() => navigate(`/payment-point/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("employ")}>
              {details?.employ?.user?.firstName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("point")}>
              {details?.collectionPoint?.name}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("value")}>
              {details?.value}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("receipt_number")}>
              {details?.receiptNumber}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("created_at")}>
              {moment(details?.createdAt).format("YYYY-MM-DD") ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const PaymentPointDetailsPage = () => {
  return (
    <PaymentPointContextProvider>
      <PaymentPoint />
    </PaymentPointContextProvider>
  );
};
export default PaymentPointDetailsPage;
