import { Tag as TagAntd } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
interface IProps {
  title?: any;
  // children?:React.ReactNode
  color?: string;
  style?: any;
}

const Tag: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <TagAntd {...props} className={styles.tag}>
        {t(props.title ?? "")}
      </TagAntd>
    </>
  );
};
export default Tag;
