export enum NotificationType {
  CLIENT = 1,
  DRIVER = 2,
  PHARMACISTS = 3,
  ALL = 4,
  NEW_CLIENT = 5
}

export const getNotificationColor = (status: NotificationType) => {
  switch (status) {
    case NotificationType.ALL:
      return "green";
    case NotificationType.CLIENT:
      return "red";
    case NotificationType.DRIVER:
      return "blue";
    case NotificationType.PHARMACISTS:
      return "orange";
      case NotificationType.NEW_CLIENT:
        return "purple";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getNotificationType = (status: NotificationType) => {
  switch (status) {
    case NotificationType.ALL:
      return "all";
    case NotificationType.CLIENT:
      return "client";
    case NotificationType.DRIVER:
      return "driver";
    case NotificationType.PHARMACISTS:
      return "pharmacist";
      case NotificationType.NEW_CLIENT:
        return "new_client";
    default:
      return "driver";
  }
};
