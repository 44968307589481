import { Descriptions as AntdDescriptions, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import AreaContext from "../../../context/area/context";
import AreaContextProvider from "../../../context/area/provider";
import { getAreaStatus, getAreaStatusColor } from "../../../models/area/enum";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import Tag from "../../general/antd/tag";
import PageDetails from "../../general/details";
import MapPolygon from "../../general/map/polygon";
import EndPoints from "../../../services/end-points";

interface Props {}

const Area: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(AreaContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [area, setArea] = useState([]);
  const [loadingArea, setLoadingArea] = useState(true);

  const getAreas = async () => {
    try {
      const { data } = await EndPoints.area.getAllAreas({
        perPage: -1,
        filters: [
          {
            name: "cityId",
            value: details?.city?.id,
            operation: "eq",
          },
          {
            name: "status",
            value:1,
            operation: "eq",
          }
        ],
      });
      const allPoints = data.data.map((el) => el.points);
      setArea(allPoints);
      setLoadingArea(false);
    } catch (error) {
    } finally {
      setLoadingArea(false);
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  useEffect(() => {
    details && getAreas();
  }, [details]);

  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("area")}
        buttonName={`${t("update_area")}`}
        onClick={() => navigate(`/areas/update/${id}`)}
      >
        {loadingArea ? (
          <Spin />
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("name_ar")}>
                  {details?.translations?.name[0]?.value ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("name_en")}>
                  {details?.translations?.name[1]?.value ?? "-"}
                </AntdDescriptions.Item>

                {details?.translations?.note && (
                  <>
                    <AntdDescriptions.Item label={t("note_ar")}>
                      {details?.translations?.note[0]?.value ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("note_en")}>
                      {details?.translations?.note[1]?.value ?? "-"}
                    </AntdDescriptions.Item>
                  </>
                )}

                <AntdDescriptions.Item label={t("status")}>
                  <Tag
                    title={t(getAreaStatus(details?.status))}
                    color={t(getAreaStatusColor(details?.status))}
                  />
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("city")}>
                  <Tag title={details?.city?.name} />
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <MapPolygon
                showDefault={true}
                draggable={false}
                editable={false}
                points={details?.points}
                allPoints={area}
              />
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const AreaDetailesPage = () => {
  return (
    <AreaContextProvider>
      <Area />
    </AreaContextProvider>
  );
};
export default AreaDetailesPage;
