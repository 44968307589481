import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { useState } from "react";
import { languages } from "../../../utils/helpers/constants";
import defaultImage from "../../../assets/images/empty_1.png";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
import { getTranslations } from "../../../utils/helpers/translations";
import MedicalSspecialtiesContext from "../../../context/medical-specialties/context";
import MedicalSspecialtiesContextProvider from "../../../context/medical-specialties/provider";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(MedicalSspecialtiesContext);
  const { id } = useParams();
  const { direction } = useContext(AppContext);
  const [image, setImage] = useState(defaultImage);

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateMedical(details?.id, {
            ...data,
            imageId: data?.imageId?.id,
          })
          : await actions.createMedical({
            ...data,
            imageId: data?.imageId?.id,
          });
      }}
      title={details ? t("update_medical_specialties") : t("add_medical_specialties")}
      defaultValues={
        {
          imageId: details?.image?.id ?? undefined,
          name: getTranslations(details?.translations?.name) ?? null,
        } as any
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={24}>
              <FormItem label={t("image")}>
                <Controller
                  name="imageId"
                  rules={{
                    required: {
                      value: false,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details?.image && (
              <OldImage src={details?.image?.url} />
            )}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const MedicalSspecialtiesForm = () => {
  return (
    <MedicalSspecialtiesContextProvider>
      <Form />
    </MedicalSspecialtiesContextProvider>
  );
};
export default MedicalSspecialtiesForm;
