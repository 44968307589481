import { Descriptions as AntdDescriptions, Col, Row, Collapse } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import DiscountCodeContextProvider from "../../../context/discount-code/provider";
import DiscountCodeContext from "../../../context/discount-code/context";
import Tag from "../../general/antd/tag";
import {
  getDiscountStatus,
  getDiscountStatusColor,
  getDiscountType,
  getDiscountTypeApp,
  getDiscountTypeValue,
  getDiscounteTypeAppColor,
  getDiscounteTypeColor,
  getDiscounteTypeValueColor,
} from "../../../models/discount-code/enum";
import moment from "moment";
import OldImage from "../../form-components/old-image";
import { ImCheckmark, ImCross } from "react-icons/im";
import DiscountCodeClientsTable from "../discount-code-clients";
const { Panel } = Collapse;
interface Props {}

const DiscountCode: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(DiscountCodeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("discount_code")}
        buttonName={`${t("update_discount_code")}`}
        onClick={() => navigate(`/discount-codes/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("details")} key={1}>
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("name_ar")}>
                      {details?.translations?.name?.[0]?.value ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("name_en")}>
                      {details?.translations?.name?.[1]?.value ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("code")}>
                      {details?.code}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("code_validity")}>
                      {details?.codeValidity}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("discountValueProduct")}>
                      {details?.discountValueProduct}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("discountValueDelivery")}>
                      {details?.discountValueDelivery}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("frequency_of_use")}>
                      {details?.maxOfUse}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("numberOfUseAllowed")}>
                      {details?.numberOfUseAllowed}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("minimum_limit")}>
                      {details?.minimumLimit}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("upper_limit")}>
                      {details?.upperLimit}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("status_discount")}>
                      {
                        <Tag
                          title={getDiscountStatus(details?.status)}
                          color={getDiscountStatusColor(details?.status)}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type_discount")}>
                      {
                        <Tag
                          title={getDiscountType(details?.type)}
                          color={getDiscounteTypeColor(details?.type)}
                        />
                      }
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item
                      label={t("type_of_discount_application")}
                    >
                      {
                        <Tag
                          title={getDiscountTypeApp(details?.typeApp)}
                          color={getDiscounteTypeAppColor(details?.typeApp)}
                        />
                      }
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("type_of_discount_value")}>
                      {
                        <Tag
                          title={getDiscountTypeValue(details?.typeValue)}
                          color={getDiscounteTypeValueColor(details?.typeValue)}
                        />
                      }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("show_in_app")}>
                      {details?.showInApp ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("canBePurchased")}>
                      {details?.canBePurchased ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("number_points")}>
                      {details?.numberPoints}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("validity_date")}>
                      {moment(details?.validityDate).format("YYYY-MM-DD")}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("image")}>
                      {details?.image ? (
                        <OldImage src={details?.image?.url} />
                      ) : (
                        "-"
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("clients")} key={1}>
                  <DiscountCodeClientsTable></DiscountCodeClientsTable>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const DiscountCodeDetailesPage = () => {
  return (
    <DiscountCodeContextProvider>
      <DiscountCode />
    </DiscountCodeContextProvider>
  );
};
export default DiscountCodeDetailesPage;
