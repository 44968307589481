import React from "react";
import { Breadcrumb, Divider, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageHeader as AntdPageHeader } from "@ant-design/pro-layout";
import { HomeOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.scss";
interface IProps {
  children?: React.ReactNode;
  title?: any;
  subTitle?: any;
  extra?: React.ReactNode;
  onBack?: () => void;
}

const PageHeader: React.FC<IProps> = (props) => {
  return (
    <>
      <AntdPageHeader
        {...props}
        ghost={false}
        className={styles.header}
        title={
          <span className={styles.title}>
            <Tooltip title={props?.title} color="#2f4e8e">
              {props?.title}
            </Tooltip>
          </span>
        }
        subTitle={
          <span className={styles.title}>
            {
              <Tooltip title={props?.subTitle} color="#2f4e8e">
                {props?.subTitle}
              </Tooltip>
            }
          </span>
        }
        // breadcrumb={<PathsBreadcrumb />}
      />
      <Divider style={{ marginTop: "0" }} />
    </>
  );
};

export default PageHeader;

const PathsBreadcrumb: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const paths = location.pathname.split("/");

  const handleBreadcrumbClick = (path: string) => {
    const currentPath = location.pathname;
    const newPath = currentPath.endsWith("/")
      ? `${currentPath}${path}`
      : `${currentPath}/${path}`;

    navigate(newPath);
  };

  return (
    <Breadcrumb>
      {[
        {
          path: "/",
          breadcrumbName: <HomeOutlined />,
        },
        ...paths
          ?.filter((p) => !isEmpty(p))
          ?.map((path, _) => {
            const breadcrumbName = t(path.replace("-", "_"));
            return {
              breadcrumbName,
              path,
            };
          }),
      ].map((val, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={val.path} onClick={() => handleBreadcrumbClick(val.path)}>
            {val.breadcrumbName}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
