import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import styles from "./style.module.scss";
import SearchFilterCard from "../../components/general/filter-card";
import Can from "../../utils/rbac/can";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import CommenQuestionContext from "../../context/commen-question/context";
import CommenQuestionContextProvider from "../../context/commen-question/provider";
import CommenQuestionTable from "../../components/commen-question/commen-question-table";
interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const CommenQuestion: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(CommenQuestionContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("commen_questions")}
        subTitle={t("commen_questions_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/commen-questions/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_commen_question")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="commen-question-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        {/* <UserFilter /> */}
      </SearchFilterCard>

      <CommenQuestionTable />
    </div>
  );
};

const CommenQuestionPage = () => {
  return (
    // ContextProvider
    <CommenQuestionContextProvider>
      <CommenQuestion />
    </CommenQuestionContextProvider>
  );
};
export default CommenQuestionPage;
