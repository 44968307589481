import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import PaymentDeliveryWorkerContext from "../../../context/payment-delivery-worker/context";
import PaymentDeliveryWorkerContextProvider from "../../../context/payment-delivery-worker/provider";
import Tag from "../../general/antd/tag";
import {
  getPaymentStatus,
  getPaymentStatusColor,
} from "../../../models/payment-delivery-worker/enum";
import moment from "moment";

interface Props { }

const PaymentDeliveryWorker: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(
    PaymentDeliveryWorkerContext
  );
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("payment_delivery_worker")}
        buttonName={`${t("update_payment_delivery_worker")}`}
        onClick={() => navigate(`/payment-delivery-worker/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("delivery_worker")}>
              {details?.driver?.firstName + " " + details?.driver?.lastName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("emplyee")}>
              {details?.user?.user?.firstName + " " + details?.user?.user?.lastName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("receipt_number")}>
              {details?.receiptNumber}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("value")}>
              {details?.value}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("point")}>
              {details?.collectionPoint?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("payment_status")}>
              <Tag
                title={getPaymentStatus(details?.isConfirmed)}
                color={getPaymentStatusColor(details?.isConfirmed)}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("created_at")}>
              {moment(details?.createdAt).format("YYYY-MM-DD") ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ?? "-"}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const PaymentDeliveryWorkerDetailesPage = () => {
  return (
    <PaymentDeliveryWorkerContextProvider>
      <PaymentDeliveryWorker />
    </PaymentDeliveryWorkerContextProvider>
  );
};
export default PaymentDeliveryWorkerDetailesPage;
