import { createContext } from "react";
import { IClientQuery } from "../../../models/client/query";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import { IDiscountCode } from "../../../models/discount-code/response";

export type ClientLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: ClientLoading[];
  listDiscountCodes?: IBaseListingResponse<IDiscountCode>;
  query: IClientQuery;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getListDiscountCodes: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IClientQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getListDiscountCodes: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DiscountCodesClientContext = createContext(externalState);

export default DiscountCodesClientContext;
