import { IStatistics } from "../../models/statistcs/respons";
import { HttpClient, RequestParams } from "../../utils/api/http-client";

class StatisticsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStatistics = (params?: RequestParams) =>
    this.http.request<IStatistics>({
      path: `/general-statistic`,
      method: "GET",
      ...params,
    });
}

export default StatisticsService;
