import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import GeneralSettingContext from "../../../context/general-setting/context";
import GeneralSettingContextProvider from "../../../context/general-setting/provider";
import { IUpdateGeneralSetting } from "../../../models/general-setting/request";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { getTranslations } from "../../../utils/helpers/translations";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import OldImage from "../../form-components/old-image";
import EndPoints from "../../../services/end-points";
import { IDiscountCode } from "../../../models/discount-code/response";

const Form = () => {
  const { t } = useTranslation();
  const [discountCode, setDiscountCode] = useState<IDiscountCode[]>([]);
  const [discountCodeLoading, setDiscountCodeLoading] = useState(true);
  const { direction } = useContext(AppContext);
  const { list, actions, loading } = useContext(GeneralSettingContext);

  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState({ page: 1, search: undefined });

  useEffect(() => {
    const getGeneralSetting = async () => {
      await actions.getGeneralSetting();
    };
    getGeneralSetting();
  }, [direction]);

  // get all discount code  from api
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollHeight - scrollTop === clientHeight &&
      hasMore &&
      !discountCodeLoading
    ) {
      setQuery({ ...query, page: query.page + 1 });
    }
  };

  const handleSearch = (value) => {
    setQuery({ page: 1, search: value });
    setDiscountCode([]);
  };

  const getDiscountCode = async () => {
    try {
      const { data } = await EndPoints.discountCode.getAllDiscountCode({
        light: true,
        page: query.page,
        perPage: 200,
        keyword: query?.search == "" ? undefined : query?.search,
      });
      const newOptions = data?.data;
      setDiscountCode((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMore(data.lastPage == query.page ? false : true);
      setDiscountCodeLoading(false);
    } catch (error) {
    } finally {
      setDiscountCodeLoading(false);
    }
  };

  useEffect(() => {
    getDiscountCode();
  }, [direction, query]);

  return (
    <MainForm
      onSubmit={async (data) => {
        await actions.updateGeneralSetting({
          ...data,
          clientAcceptanceTime: Number(data?.clientAcceptanceTime),
          welcomeCoupon: data?.welcomeCoupon || null,
        });
      }}
      title={t("update_general_settings")}
      defaultValues={
        {
          activatingPublicService: list?.activatingPublicService ?? false,
          activatePublicMessageClient:
            list?.activatePublicMessageClient ?? false,
          publicMessageClient: getTranslations(
            list?.translations.publicMessageClient
          ),
          activatePublicMessagePharmacist:
            list?.activatePublicMessagePharmacist ?? false,
          publicMessagePharmacist: getTranslations(
            list?.translations.publicMessagePharmacist
          ),
          activatePublicMessageDelivery:
            list?.activatePublicMessageDelivery ?? false,
          publicMessageDelivery: getTranslations(
            list?.translations?.publicMessageDelivery
          ),
          maxNumberPharmaciesFindMedicine:
            list?.maxNumberPharmaciesFindMedicine,
          scoreValidity: list?.scoreValidity,
          chargingCustomerForReachStartCostOrder: true,
          kilometerCost: list?.kilometerCost,
          priceKilometerCustomer: list?.priceKilometerCustomer,
          currencySymbol: getTranslations(list?.translations?.currencySymbol),
          maxNumberPharmaciesFindPharmacie:
            list?.maxNumberPharmaciesFindPharmacie,
          pointMultiplicationFactor: list?.pointMultiplicationFactor,
          numberTimesIgnoredAllowed: list?.numberTimesIgnoredAllowed,
          showListPharmacies: list?.showListPharmacies,
          commission: list?.commission,
          deliveryWorkerAcceptanceTime: list?.deliveryWorkerAcceptanceTime,
          pharmacyAcceptanceTime: list?.pharmacyAcceptanceTime,
          timeBroadcast: list?.timeBroadcast,
          freeDelivery: list?.freeDelivery,
          freeDeliveryPharmacyPromotion: list?.freeDeliveryPharmacyPromotion,
          driverTimeBroadcast: list?.driverTimeBroadcast,
          maxDriversInOrder: list?.maxDriversInOrder,
          radiusSearchForDriver: list?.radiusSearchForDriver,
          homeHeaderClientEn: list?.homeHeaderClientEn?.id,
          homeHeaderClientAr: list?.homeHeaderClientAr?.id,
          homeHeaderDriverEn: list?.homeHeaderDriverEn?.id,
          homeHeaderDriverAr: list?.homeHeaderDriverAr?.id,
          homeHeaderInvestorEn: list?.homeHeaderInvestorEn?.id,
          homeHeaderInvestorAr: list?.homeHeaderInvestorAr?.id,
          homeHeaderClient: list?.homeHeaderClient?.id,
          homeHeaderDriver: list?.homeHeaderDriver?.id,
          homeHeaderInvestor: list?.homeHeaderInvestor?.id,
          facebook: list?.facebook,
          loyaltyProgramLink: list?.loyaltyProgramLink,
          delayedOrderSchedulingTime: list?.delayedOrderSchedulingTime,
          phone: list?.phone,
          telegram: list?.telegram,
          whatsApp: list?.whatsApp,
          instagram: list?.instagram,
          minimumLimit: list?.minimumLimit,
          upperLimit: list?.upperLimit,
          firstOrderFree: list?.firstOrderFree ?? false,
          clientAcceptanceTime: Number(list?.clientAcceptanceTime),
          pointPrice: list?.pointPrice,
          showMedicalSpecialties: list?.showMedicalSpecialties ?? false,
          showCompanies: list?.showCompanies ?? false,
          welcomeCouponActive: list?.welcomeCouponActive ?? false,
          welcomeCoupon: list?.welcomeCoupon?.id,
          welcomeMessageActivation: list?.welcomeMessageActivation ?? false,
          welcomeMessage: getTranslations(list?.translations?.welcomeMessage),
          maxNumOfUnResponseOrder: list?.maxNumOfUnResponseOrder,
          hotLine: list?.hotLine,
          fixedDeliveryPriceOnClient: list?.fixedDeliveryPriceOnClient,
        } as IUpdateGeneralSetting
      }
    >
      {/* TODO 
        add the new value
      */}
      {loading.includes("list") || loading.includes("update") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`publicMessagePharmacist[${lang?.code}]`}
                    label={`${t("publicMessagePharmacist")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`publicMessageClient[${lang?.code}]`}
                    label={`${t("publicMessageClient")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`publicMessageDelivery[${lang?.code}]`}
                    label={`${t("publicMessageDlivery")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`currencySymbol[${lang?.code}]`}
                    label={`${t("currencySymbol")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="clientAcceptanceTime"
                label={t("client_acceptance_time")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="fixedDeliveryPriceOnClient"
                label={t("fixed_delivery_price_on_client")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />{" "}
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="kilometerCost"
                label={t("kilometerCost")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="priceKilometerCustomer"
                label={t("priceKilometerCustomer")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="maxNumberPharmaciesFindPharmacie"
                label={t("maxNumberPharmaciesFindPharmacie")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />{" "}
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="maxNumOfUnResponseOrder"
                label={t("maxNumOfUnResponseOrder")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="pointMultiplicationFactor"
                label={t("pointMultiplicationFactor")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="numberTimesIgnoredAllowed"
                label={t("numberTimesIgnoredAllowed")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="maxNumberPharmaciesFindMedicine"
                label={t("maxNumberPharmaciesFindMedicine")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="scoreValidity"
                label={t("scoreValidity")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="commission"
                label={t("commission")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="deliveryWorkerAcceptanceTime"
                label={t("deliveryWorkerAcceptanceTime")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="pharmacyAcceptanceTime"
                label={t("pharmacyAcceptanceTime")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="timeBroadcast"
                label={t("timeBroadcast")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="maxDriversInOrder"
                label={t("maxDriversInOrder")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="driverTimeBroadcast"
                label={t("driverTimeBroadcast")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="radiusSearchForDriver"
                label={t("radiusSearchForDriver")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="pointPrice"
                label={t("pointPrice")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="facebook"
                label={t("facebook")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="instagram"
                label={t("instagram")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="phone"
                label={t("phone")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="hotLine"
                label={t("hotLine")}
                input={{ type: "text" }}
                rules={{ required: false, minLength: 4, maxLength: 7 }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="telegram"
                label={t("telegram")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="whatsApp"
                label={t("whatsApp")}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="loyaltyProgramLink"
                label={t("loyaltyProgramLink")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="delayedOrderSchedulingTime"
                label={t("delayedOrderSchedulingTime")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="minimumLimit"
                label={t("minimum_limit")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="upperLimit"
                label={t("upper_limit")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="welcomeCoupon"
                label={`${t("welcomeCoupon")}`}
                width="large"
                input={{
                  type: "select-pagination",
                  handleScroll: handleScroll,
                  handleSearch: handleSearch,
                  loading: discountCodeLoading,
                  allowSearch: true,
                  options: discountCode?.map((code, index) => {
                    return {
                      key: index,
                      label: code.name,
                      value: code.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("welcomeCouponActive")}`}
                name="welcomeCouponActive"
                input={{ type: "checkBox" }}
              />
            </Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`welcomeMessage[${lang?.code}]`}
                    label={`${t("welcome_message")} (${lang?.name})`}
                    input={{ type: "text" }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                label={`${t("welcomeMessageActivation")}`}
                name="welcomeMessageActivation"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("showMedicalSpecialties")}`}
                name="showMedicalSpecialties"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("showCompanies")}`}
                name="showCompanies"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("first_order_free")}`}
                name="firstOrderFree"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("activatingPublicService")}`}
                name="activatingPublicService"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("activatePublicMessageClient")}`}
                name="activatePublicMessageClient"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("activatePublicMessagePharmacist")}`}
                name="activatePublicMessagePharmacist"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("activatePublicMessageDelivery")}`}
                name="activatePublicMessageDelivery"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("showListPharmacies")}`}
                name="showListPharmacies"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                label={`${t("freeDeliveryPharmacyPromotion")}`}
                name="freeDeliveryPharmacyPromotion"
                input={{ type: "checkBox" }}
              />
            </Col>{" "}
            <Col span={8}>
              <FieldBuilder
                label={`${t("freeDelivery")}`}
                name="freeDelivery"
                input={{ type: "checkBox" }}
              />
            </Col>
            <Col span={24}>
              <FormItem label={t("homeHeaderClientEn")} required>
                <Controller
                  name="homeHeaderClientEn"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderClientEn && (
              <OldImage src={list?.homeHeaderClientEn?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderClientAr")} required>
                <Controller
                  name="homeHeaderClientAr"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderClientAr && (
              <OldImage src={list?.homeHeaderClientAr?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderDriverEn")} required>
                <Controller
                  name="homeHeaderDriverEn"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderDriverEn && (
              <OldImage src={list?.homeHeaderDriverEn?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderDriverAr")} required>
                <Controller
                  name="homeHeaderDriverAr"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderDriverAr && (
              <OldImage src={list?.homeHeaderDriverAr?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderInvestorEn")} required>
                <Controller
                  name="homeHeaderInvestorEn"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderInvestorEn && (
              <OldImage src={list?.homeHeaderInvestorEn?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderInvestorAr")} required>
                <Controller
                  name="homeHeaderInvestorAr"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderInvestorAr && (
              <OldImage src={list?.homeHeaderInvestorAr?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderClient")} required>
                <Controller
                  name="homeHeaderClient"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderClient && (
              <OldImage src={list?.homeHeaderClient?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderDriver")} required>
                <Controller
                  name="homeHeaderDriver"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderDriver && (
              <OldImage src={list?.homeHeaderDriver?.url} />
            )}
            <Col span={24}>
              <FormItem label={t("homeHeaderInvestor")} required>
                <Controller
                  name="homeHeaderInvestor"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {list?.homeHeaderInvestor && (
              <OldImage src={list?.homeHeaderInvestor?.url} />
            )}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const GeneralSettingForm = () => {
  return (
    <GeneralSettingContextProvider>
      <Form />
    </GeneralSettingContextProvider>
  );
};
export default GeneralSettingForm;
