import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { ITag, ITagDetails } from "../../models/tag/response";
import { ITagQuery } from "../../models/tag/query";
import { ICreateTag, IUpdateTag } from "../../models/tag/request";

export type TagLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: TagLoading[];

  list?: IBaseListingResponse<ITag>;
  query: ITagQuery;

  details?: ITagDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ITagDetails) => void;

    createTag: (request: ICreateTag) => void;
    updateTag: (id: number, request: IUpdateTag) => void;
    deleteTag: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ITagQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createTag: DEFAULT_FUNCTION,
    updateTag: DEFAULT_FUNCTION,
    deleteTag: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const TagContext = createContext(externalState);

export default TagContext;
