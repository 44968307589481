import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";
import { IDoctorQuery } from "../../models/doctors/query";
import { IDoctor, IDoctorDetails } from "../../models/doctors/response";
import { ICreateDoctor, IUpdateDoctor } from "../../models/doctors/request";
import { IMedicalSpecialties } from "../../models/medical-specialties/response";

class MedicalSspecialtiesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllMedicalSspecialties = (query: IDoctorQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IMedicalSpecialties>>({
      path: `/medical-specialties`,
      method: "GET",
      query,
      ...params,
    });

  getMedicalSspecialties = (id: number, params?: RequestParams) =>
    this.http.request<any>({
      path: `/medical-specialties/${id}`,
      method: "GET",
      ...params,
    });

  createMedicalSspecialties = (data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: "/medical-specialties",
      method: "POST",
      body: data,
      ...params,
    });

  updateMedicalSspecialties = (id: number, data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/medical-specialties/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteMedicalSspecialties = (id: number, params?: RequestParams) =>
    this.http.request<any>({
      path: `/medical-specialties/${id}`,
      method: "DELETE",
      ...params,
    });

  // exportExcel = (data: IExportDoctorExcel, query: IDoctorQuery) =>
  //   axios.post(` ${process.env.REACT_APP_BASE_API_URL}/doctor/export`, data, {
  //     headers: {
  //       authorization: `Bearer ${
  //         localStorage.getItem(ACCESS_TOKEN) ||
  //         sessionStorage.getItem(ACCESS_TOKEN)
  //       }`,
  //       "Content-Type": "application/json",
  //       "accept-language": `${
  //         localStorage.getItem(LANGUAGE_CODE) ??
  //         process.env.REACT_APP_DEFAULT_LANGUAGE
  //       }`,
  //     },
  //     params: query,
  //     responseType: "arraybuffer",
  //   });
}

export default MedicalSspecialtiesService;
