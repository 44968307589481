import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../context/app/context";
import { useIsMount } from "../../utils/hooks/is-mount";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import styles from "./style.module.scss";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";

import TemplateExportExcel from "../../components/general/excel/expor-excel";
import DoctorContext from "../../context/doctor/context";
import { IExportPaymentDeliveryWorkerExcel } from "../../models/payment-delivery-worker/request";
import DoctorFilter from "../../components/doctor/doctor-filter";
import DoctorTable from "../../components/doctor/doctor-table";
import DoctorContextProvider from "../../context/doctor/provider";
import {
  IExportDoctorExcel,
  columnsExportDoctorExcel,
} from "../../models/doctors/request";
interface IProps {
  children?: React.ReactNode;
}

const Doctor: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(DoctorContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("doctors")}
        subTitle={t("doctors_management")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/doctor/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_doctor")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-category"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="50vw"
        defaultValues={
          {
            firstName: false,
            lastName: false,
            status: false,
            createdAt: false,
            mobile: false,
            specialty: false,
            id: false,
            endWorkingTime: false,
            startWorkingTime: false,
            offDays: false,
            workingStatus: false,
            address: false,
            street: false,
            building: false,
            apartmentNo: false,
            near: false,
            moreDetails: false,
            location: false,
            birthDate: false,
            city: false,
            medicalSpecialty: false,
            user: false,
          } as IExportDoctorExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={columnsExportDoctorExcel} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="delivery-worker-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <DoctorFilter />
      </SearchFilterCard>

      <DoctorTable />
    </div>
  );
};

const DoctorPage = () => {
  return (
    <DoctorContextProvider>
      <Doctor />
    </DoctorContextProvider>
  );
};
export default DoctorPage;
