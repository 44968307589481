// Status Tags Colors

export enum UserStatus {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const getUserStatusColor = (status: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return "green";
    case UserStatus.INACTIVE:
      return "red";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getUserStatus = (status: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return "active";
    case UserStatus.INACTIVE:
      return "inactive";
    default:
      return "inactive";
  }
};
