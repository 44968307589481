import { Button, Col, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useModal } from "react-modal-hook";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import ProductStoreContext from "../../../context/product-store/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import {
  ICreateProductStore,
  IUpdateProductStore,
} from "../../../models/product-store/request";
import {
  getProductStatus,
  getProductStatusColor,
  getProductType,
  getProductTypeColor,
} from "../../../models/product/enum";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import FormHookModal from "../../general/modals/form-hook-modal";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import Table from "../../general/table-components/table";
import ProductStoreForm from "../product-store-form";

interface IProps {}

const ProductStoreTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { list, actions, loading, query, details } =
    useContext(ProductStoreContext);
  const [infoId, setInfoId] = useState();
  const { id } = useParams();

  useEffect(() => {
    id && actions.getData(Number(id));
  }, [direction, query]);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const columns: ColumnProps<any>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
      sorter: {
        multiple: 2,
      },
    },
    {
      title: t("grand"),
      dataIndex: "grand",
      align: "center",
      key: "sub_category",
      render: (_, record) => {
        return <>{record?.mainCategory?.name ?? "-"}</>;
      },
    },
    {
      title: t("parent_category"),
      dataIndex: "parent_category",
      align: "center",
      key: "sub_category",
      render: (_, record) => {
        return <>{record?.parentCategory?.name ?? "-"}</>;
      },
    },
    {
      title: t("price"),
      dataIndex: "price",
      align: "center",
      key: "price",
      sorter: {
        multiple: 3,
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <Tag
            title={getProductStatus(record?.status)}
            color={getProductStatusColor(record?.status)}
          />
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (_, record) => {
        return (
          <Tag
            title={getProductType(record?.type)}
            color={getProductTypeColor(record?.type)}
          />
        );
      },
    },

    {
      title: t("available"),
      dataIndex: "available",
      width: 200,
      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.available ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },

    {
      title: t("sold_by_unit"),
      dataIndex: "sold_by_unit",
      width: 200,
      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.soldByUnit ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
    {
      title: t("actions"),
      width: 150,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <EditBtn
            onClick={() => {
              setInfoId(record?.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteProductStore(record?.id);
              eventManager.emit(EVENT_SUCCESS);
              actions.getData(Number(id));
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="sss"
        confirmButtonText={t("save")}
        loading={loading.includes("create") || loading.includes("details")}
        title={details ? t("update_product_store") : t("create_product_store")}
        hideModal={() => {
          actions.setDetails(undefined);
          setInfoId(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "-5rem" }}
        defaultValues={
          {
            storeId: Number(id),
            available: details?.available ?? false,
            discountPercentage: details?.discountPercentage,
            isDiscount: details?.isDiscount ?? false,
            note: details?.note,
            price: details?.price,
            productId: details?.product?.id,
            unitPrice: details?.unitPrice,
            mainCategoryId: details?.product?.mainCategory?.id,
            parentCategoryId: details?.product?.parentCategory?.id,
          } as IUpdateProductStore
        }
        width={"70vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updateProductStore(infoId, data)
            : await actions.createProductStore(data);
          actions.setDetails(undefined);
          setInfoId(undefined);
          actions.getData(Number(id));
        }}
      >
        <ProductStoreForm />
      </FormHookModal>
    ),
    [infoId, loading]
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button onClick={() => showFormModal()}>{t("add_product")}</Button>
      </Col>
      <Col span={24}>
        <Table<any>
          rowKey="id"
          showPagination={true}
          columns={columns}
          dataSource={list?.data ?? []}
          loading={loading.includes("list")}
          size="small"
          total={list?.total}
          pageSize={query.perPage}
          page={query.page}
          onPaginationChange={(page, pageSize) => {
            actions.setQuery({
              ...query,
              page,
              perPage: pageSize,
            });
          }}
          scroll={{
            x: 1600,
          }}
          onChange={(_, tableFilters, tabelSorters) => {
            tableOnChange(
              tableFilters,
              tabelSorters,
              tableFiltersProps,
              setSorts,
              setStaticFilters
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default ProductStoreTable;
