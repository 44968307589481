import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StoreContext from "../../../context/store/context";
import { getStorType, getStorWorkingStatus } from "../../../models/store/enum";
import { getUserStatus } from "../../../models/user/enum";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import { ICity } from "../../../models/city/response";
import AppContext from "../../../context/app/context";
import EndPoints from "../../../services/end-points";

interface IProps {}

const StoreFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(StoreContext);
  const [cities, setCities] = useState<ICity[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);
  // get all roles from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getRoles();
  }, [direction]);
  return (
    <Form
      formId="store-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.workStatus
              ? {
                  name: "workStatus",
                  value: data.workStatus,
                  operation: "eq",
                }
              : undefined,
            data?.pharmacyPromotion
              ? {
                  name: "pharmacyPromotion",
                  value: data.pharmacyPromotion,
                  operation: "eq",
                }
              : undefined,
            data?.freeDelivery
              ? {
                  name: "freeDelivery",
                  value: data.freeDelivery,
                  operation: "eq",
                }
              : undefined,
              data?.cityId
              ? {
                  name: "cityId",
                  value: data.cityId,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  label: t(getUserStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  label: t(getStorType(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="cityId"
            label={`${t("city")}`}
            width="large"
            input={{
              type: "select",
              loading: citiesLoading,
              options: cities.map((city, index) => {
                return {
                  type: "select",
                  label: city?.name as string,
                  value: city?.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>

      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="workStatus"
            label={`${t("work_status")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  label: t(getStorWorkingStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="freeDelivery"
            label={`${t("free_delivery")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2].map((status, index) => {
                return {
                  label: status == 1 ? t("active") : t("inactive"),
                  value: status == 1 ? 1 : "0",
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="pharmacyPromotion"
            label={`${t("pharmacy_promotion")}`}
            width="large"
            input={{
              type: "select",
              loading: citiesLoading,
              options: [1, 2].map((status, index) => {
                return {
                  label: status == 1 ? t("active") : t("inactive"),
                  value: status == 1 ? 1 : "0",
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default StoreFilter;
