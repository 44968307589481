import { Input, Collapse, Button, Space } from "antd";
import React, { useState, Fragment } from "react";
import {
  ClearOutlined,
  CaretRightOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import SubmitBtn from "../../form-components/submit-btn";
import { debounce } from "lodash";
import { Controller, useForm } from "react-hook-form";
import RefreshBtn from "../table-components/actions/refresh-btn";

const { Search } = Input;
const { Panel } = Collapse;

interface ISearchFilterCard {
  onReset: () => void;
  resetLoading: boolean;
  applyLoading?: boolean;
  formId?: string;
  visiableSearch?: boolean;
  searchValue: string | undefined;
  setSearchValue: (searchKey: any) => void;
  children?: React.ReactNode;
  paddingStyle?: boolean;
}

const SearchFilterCard: React.FC<ISearchFilterCard> = ({
  children,
  onReset,
  formId,
  resetLoading,
  searchValue,
  setSearchValue,
  visiableSearch,
  paddingStyle,
  applyLoading,
}) => {
  const { t } = useTranslation();
  const handleChange = debounce(({ target }) => {
    if (target.value) {
      setSearchValue(target.value);
    } else {
      setSearchValue(undefined);
    }
  }, 500);

  // const { permissions, loading } = useContext(AppContext)
  const [showFilters, setShowFilters] = useState(false);

  const { control, reset } = useForm({ defaultValues: { keyword: undefined } });
  return (
    <div className={styles.filterCard}>
      <div
        style={{
          padding: paddingStyle ? "0" : "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
        className={React.Children.count(children) > 0 ? styles.cardHeader : ""}
      >
        {!visiableSearch && (
          <>
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => {
                return (
                  <Search
                    {...field}
                    placeholder={`${t("search_hint")}`}
                    style={{ width: "30%" }}
                    enterButton
                    onSearch={(value) => {
                      value ? setSearchValue(value) : setSearchValue(undefined);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      handleChange(e);
                    }}
                    allowClear
                  />
                );
              }}
            />

            <Button
              danger
              type="default"
              loading={resetLoading}
              icon={<ClearOutlined />}
              onClick={(e) => {
                reset({ keyword: undefined });
                setSearchValue(undefined);
                e.stopPropagation();
                onReset();
              }}
            >
              {t("reset")}
            </Button>
          </>
        )}
      </div>
      {children && (
        <Collapse
          bordered={true}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          onChange={(key) => {
            setShowFilters(key?.includes("1"));
          }}
          style={{ marginTop: "15px" }}
          accordion
        >
          <Panel
            extra={
              <Fragment>
                {showFilters && (
                  <Space>
                    <SubmitBtn
                      icon={<FilterOutlined />}
                      loading={applyLoading}
                      onClick={(e) => e.stopPropagation()}
                      form={formId}
                    >
                      {t("apply")}
                    </SubmitBtn>
                  </Space>
                )}
              </Fragment>
            }
            header={t("filters")}
            key="1"
          >
            {children}
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default SearchFilterCard;
