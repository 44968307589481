import { ITranslationRequest } from "../base/translation";
export interface ICreateArea {
  name: ITranslationRequest;
  note: ITranslationRequest;
  cityId: number;
  status: number;
  points: ICoordinates;
}

export interface IUpdateArea extends ICreateArea {}

export interface IExportAreaExcel {
  name: boolean;
  note: boolean;
  status: boolean;
  city: boolean;
}

export const colomnsExportAreaExcel = ["name", "note", "status", "city"];

export interface ICoordinates {
  type: string;
  coordinates: number[][][];
}
