import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import DeliveryWorkerContext from "../../../context/delivery-worker/context";
import DeliveryWorkerContextProvider from "../../../context/delivery-worker/provider";
import Descriptions from "../../general/antd/descriptions";
import Spin from "../../general/antd/spin";
import PageDetails from "../../general/details";
import moment from "moment";
import {
  getStatusDeliveryWorker,
  getStatusDeliveryWorkerColor,
} from "../../../models/delivery-workers/enum";
import Tag from "../../general/antd/tag";
import { getUserStatus, getUserStatusColor } from "../../../models/user/enum";
import DeliveryWorkerHistoryContextProvider from "../../../context/delivery-worker-history/provider";
import WorkerHistoryTable from "../delivery-worker-history";
import OldImage from "../../form-components/old-image";
import RewardHistoryTable from "../../reward/reward-history";
import RewardContextProvider from "../../../context/rewaed/provider";

const { Panel } = Collapse;
interface Props {}

const DeliveryWorker: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(DeliveryWorkerContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("delivery_worker")}
        buttonName={`${t("update_delivery_worker")}`}
        onClick={() => navigate(`/delivery-worker/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("address")}>
                  {details?.address}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("first_name")}>
                  {details?.user?.firstName}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("last_name")}>
                  {details?.user?.lastName}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("mobile")}>
                  {details?.user?.cleanMobile}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("phone")}>
                  {details?.user?.phone}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("birthDate")}>
                  {details?.birthDate
                    ? moment(details?.birthDate).format("YYYY-MM-DD")
                    : "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("city")}>
                  {details?.city?.name}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("created_at")}>
                  {details?.createdAt
                    ? moment(details?.createdAt).format("YYYY-MM-DD")
                    : "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("national_identity_number")}>
                  {details?.nationalIdentityNumber}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("status_worker")}>
                  <Tag
                    title={getStatusDeliveryWorker(details?.statusWorker)}
                    color={getStatusDeliveryWorkerColor(details?.statusWorker)}
                  />
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("status")}>
                  <Tag
                    title={getUserStatus(details?.user?.status)}
                    color={getUserStatusColor(details?.user?.status)}
                  />
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("debt")}>
                  {details?.debt}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("wallet")}>
                  {details?.wallet}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("total_benefit")}>
                  {details?.totalBenefit}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("number_ignore")}>
                  {details?.numberIgnore}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("orders_count")}>
                  {details?.ordersCount}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("image")}>
                  <OldImage src={details?.user?.image?.url} preview />
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("history_accept")} key={1}>
                  <WorkerHistoryTable status={1} orderAssent={false} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("history_not_accept")} key={1}>
                  <WorkerHistoryTable orderAssent={false} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("history_reward")} key={1}>
                  <RewardHistoryTable driverId={Number(id)} />
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const DeliveryWorkerDetailsPage = () => {
  return (
    <DeliveryWorkerContextProvider>
      <DeliveryWorkerHistoryContextProvider>
        <RewardContextProvider>
          <DeliveryWorker />
        </RewardContextProvider>
      </DeliveryWorkerHistoryContextProvider>
    </DeliveryWorkerContextProvider>
  );
};
export default DeliveryWorkerDetailsPage;
