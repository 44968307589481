import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IComplaint, IComplaintDetails } from "../../models/complaint/response";
import { IComplaintQuery } from "../../models/complaint/query";
import {
  ICreateComplaint,
  IUpdateComplaint,
} from "../../models/complaint/request";

export type ComplaintLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ComplaintLoading[];

  list?: IBaseListingResponse<IComplaint>;
  query: IComplaintQuery;

  details?: IComplaintDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IComplaintDetails) => void;

    createComplaint: (request: ICreateComplaint) => void;
    updateComplaint: (id: number, request: IUpdateComplaint) => void;
    deleteComplaint: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IComplaintQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createComplaint: DEFAULT_FUNCTION,
    updateComplaint: DEFAULT_FUNCTION,
    deleteComplaint: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ComplaintContext = createContext(externalState);

export default ComplaintContext;
