import moment from "moment";
import { useReducer } from "react";
import ClientContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../../utils/helpers/execute";
import EndPoints from "../../../services/end-points";
import { ICreateClient, IUpdateClient } from "../../../models/client/request";
import { IClient } from "../../../models/client/response";
import { IClientQuery } from "../../../models/client/query";
import ClientPointsContext from "./context";
import DiscountCodesClientContext from "./context";

export interface IProps {
  children: React.ReactNode;
}
const DiscountCodesClientContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getListDiscountCodes = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.client.getDiscountCodesClient(
          id,
          state.query
        );

        dispatch({
          type: "SET_LIST_DISCOUNT_CODES",
          payload: { listDiscountCodes: data },
        });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };
  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IClientQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <DiscountCodesClientContext.Provider
      value={{
        ...state,
        actions: {
          getListDiscountCodes,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DiscountCodesClientContext.Provider>
  );
};

export default DiscountCodesClientContextProvider;
