import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import UserContext from "../../../context/user/context";
import UserContextProvider from "../../../context/user/provider";
import { getUserStatus } from "../../../models/user/enum";
import { IUpdateUser } from "../../../models/user/request";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import OldImage from "../../form-components/old-image";
import FormItem from "../../general/form-item";
import Controller from "../../form-components/controller";
import ImageUploader from "../../form-components/image-uploader";
import { MediumFor } from "../../../models/medium/enum";
import EndPoints from "../../../services/end-points";
import { IRole } from "../../../models/role/response";
import { useState } from "react";
import AppContext from "../../../context/app/context";
import { getTranslations } from "../../../utils/helpers/translations";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(UserContext);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);

  const { id } = useParams();
  const status = [1, 2];
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // get all roles from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.role.getAllroles({
          light: true,
          perPage: -1,
        });
        setRoles(data.data);
        setRolesLoading(false);
      } catch (error) {
      } finally {
        setRolesLoading(false);
      }
    };
    getRoles();
  }, [direction]);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateUser(details?.id, data)
          : await actions.createUser(data);
      }}
      title={details ? t("update_user") : t("add_user")}
      defaultValues={
        {
          address: details?.address,
          email: details?.user?.email,
          firstName: details?.user?.firstName,
          imageId: details?.user?.image?.id ?? undefined,
          lastName: details?.user?.lastName,
          mobile: details?.user?.cleanMobile,
          note: getTranslations(details?.user?.translations?.note) ?? null,
          phone: details?.user?.phone,
          roles: details?.user?.roles?.map((role) => role?.name),
          status: details?.user?.status,
          password: details?.user.password,
        } as IUpdateUser
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="firstName"
                label={t("first_name")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="lastName"
                label={t("last_name")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="email"
                label={t("email")}
                input={{ type: "email" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="password"
                label={t("password")}
                input={{ type: "password" }}
                rules={{ required: details?.id ? false : true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mobile"
                label={t("mobile")}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="phone"
                label={t("phone")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={`${t("status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: false,
                  options: status?.map((state, index) => {
                    return {
                      key: index,
                      label: t(getUserStatus(state)),
                      value: state,
                    };
                  }),
                }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="address"
                label={t("address")}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="roles"
                label={`${t("roles")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "multiSelect",
                  loading: rolesLoading,
                  allowSearch: true,
                  options: roles?.map((role, index) => {
                    return {
                      key: index,
                      label: role.name,
                      value: role.name,
                    };
                  }),
                }}
              />
            </Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
            <Col span={24}>
              <FormItem label={t("image")}>
                <Controller
                  name="imageId"
                  rules={{
                    required: {
                      value: false,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details?.user?.image && (
              <OldImage src={details?.user?.image?.url} />
            )}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const UserForm = () => {
  return (
    <UserContextProvider>
      <Form />
    </UserContextProvider>
  );
};
export default UserForm;
