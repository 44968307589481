import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IGeneralNotification,
  IGeneralNotificationDetails,
} from "../../models/general-notification/response";
import { IGeneralNotificationQuery } from "../../models/general-notification/query";
import {
  ICreateGeneralNotification,
  IUpdateGeneralNotification,
} from "../../models/general-notification/request";

export type GeneralNotificationLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "notifi";

export interface IInternalState {
  loading: GeneralNotificationLoading[];

  list?: IBaseListingResponse<IGeneralNotification>;
  query: IGeneralNotificationQuery;

  details?: IGeneralNotificationDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IGeneralNotificationDetails) => void;
    sendNotification: (id: number) => void;
    createGeneralNotification: (request: ICreateGeneralNotification) => void;
    updateGeneralNotification: (
      id: number,
      request: IUpdateGeneralNotification
    ) => void;
    deleteGeneralNotification: (id: number) => void;
    // exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IGeneralNotificationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    // exportExcel: DEFAULT_FUNCTION,
    sendNotification: DEFAULT_FUNCTION,
    createGeneralNotification: DEFAULT_FUNCTION,
    updateGeneralNotification: DEFAULT_FUNCTION,
    deleteGeneralNotification: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const GeneralNotificationContext = createContext(externalState);

export default GeneralNotificationContext;
