import { Row, Col, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import { IUpdateSlice } from "../../../models/slice/request";
import SliceContextProvider from "../../../context/slice/provider";
import PrizeContext from "../../../context/prize/context";
import { IStore } from "../../../models/store/response";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";
import PrizeContextProvider from "../../../context/prize/provider";

const Form = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(PrizeContext);
  const { id } = useParams();

  const [store, setSorts] = useState<IStore[]>([]);
  const [storeLoading, setStoreLoading] = useState(true);

  useEffect(() => {
    const getStore = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          light: true,
          perPage: -1,
        });
        setSorts(data.data);
        setStoreLoading(false);
      } catch (error) {
      } finally {
        setStoreLoading(false);
      }
    };
    getStore();
  }, [direction]);

  return (
    <MainForm
      onSubmit={(data) => {
        actions.createPrize(data);
      }}
      title={t("add_prize")}
      defaultValues={{} as IUpdateSlice}
    >
      <>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <FieldBuilder
              name="storeId"
              label={`${t("store")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                allowSearch: true,
                loading: storeLoading,
                options: store?.map((jop, index) => {
                  return {
                    label: jop?.name,
                    value: jop?.id,
                  };
                }),
              }}
            />
          </Col>
          <Col span={12}>
            <FieldBuilder
              name="numbers"
              label={`${t("numbers")}`}
              input={{ type: "number" }}
              rules={{ required: true }}
            />
          </Col>
          {languages?.map((lang, index) => {
            return (
              <Col span={12}>
                <FieldBuilder
                  key={index}
                  name={`description[${lang?.code}]`}
                  label={`${t("description")} (${lang?.name})`}
                  input={{ type: "text" }}
                  rules={{ required: true }}
                />
              </Col>
            );
          })}
        </Row>
      </>
    </MainForm>
  );
};

const PrizeForm = () => {
  return (
    <PrizeContextProvider>
      <Form />
    </PrizeContextProvider>
  );
};
export default PrizeForm;
