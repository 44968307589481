import { useReducer } from "react";
import { IClientQuery } from "../../../models/client/query";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import MedicationPackageClientContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const MedicationPackageClientContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getListMedicationPackage = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.client.getMedicationPackage(
          id,
          state.query
        );

        dispatch({
          type: "SET_MEDICATION_PACKAGE",
          payload: { listMedicationPackage: data },
        });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };
  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IClientQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <MedicationPackageClientContext.Provider
      value={{
        ...state,
        actions: {
          getListMedicationPackage,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </MedicationPackageClientContext.Provider>
  );
};

export default MedicationPackageClientContextProvider;
