import { Tag } from "antd"
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoryContext from "../../../../../context/category/context";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const TextDropdownAttribute: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { allCategories } = useContext(CategoryContext);
  const fetchParam = (id: number) => {
    let param =
      attributeWithValue?.attribute?.attributeParams?.filter((el) => el.id == id)
    //allCategories
    // ?.filter(
    //  (cat) => cat.id == attributeWithValue?.attribute?.category?.id
    //)[0]
    //?.attributes?.filter(
    // (attr) => attr.id == attributeWithValue?.attribute?.id
    //)[0]
    //.attributeParams.filter((param) => param?.id == id)[0];
    //console.log(attributeWithValue, allCategories)
    console.log(param[0])
    return <>{param[0]?.name}</>;
  };

  return (
    <div>
      {attributeWithValue?.values?.map((value, index) => {
        return <Tag key={index}> {fetchParam(Number(value))} </Tag>;
      })}
    </div>
  );
};

export default TextDropdownAttribute;
