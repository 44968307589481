import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IReport } from "../../models/report/response";
import { IReportQuery } from "../../models/report/query";
import { ICreateReport } from "../../models/report/request";

export type ReportLoading = "list" | "create" ;

export interface IInternalState {
  loading: ReportLoading[];

  list?: IBaseListingResponse<IReport>;
  query: IReportQuery;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    
    createReport: (request: ICreateReport, path: string) => void;
  
    setSearch: (search?: string) => void;
    setQuery: (query: IReportQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
   
    createReport: DEFAULT_FUNCTION,
  
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ReportContext = createContext(externalState);

export default ReportContext;
