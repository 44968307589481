import {
  AppstoreOutlined,
  HomeOutlined,
  TagsOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiFlag, FiLayers } from "react-icons/fi";
import Spin from "../../components/general/antd/spin";
import PageHeader from "../../components/general/layout/page-header";
import Card from "../../components/home/cards";
import EndPoints from "../../services/end-points";
import Chart from "../../components/general/charts/pie";
import Marker from "../../components/general/map/marker";
import { ILocation, IStore } from "../../models/store/response";
import ViewMarkers from "../../components/general/map/view-markers";
import { IStatistics } from "../../models/statistcs/respons";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
interface IProps {}

const Home: React.FC<IProps> = (props) => {
  const [stors, setStors] = useState<IStore[]>([]);
  const { t } = useTranslation();

  const [coordinates, setCoordinates] = useState<IStore[]>([]);

  const [statistics, setStatistics] = useState<IStatistics>();
  const [loading, setLoading] = useState(true);

  const getAllStatistics = async () => {
    try {
      setLoading(true);
      const a = await EndPoints.statistics.getAllStatistics({});
      setStatistics(a?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStatistics();
  }, []);

  // store
  useEffect(() => {
    const getStores = async () => {
      try {
        const { data } = await EndPoints.store.getAllStores({
          perPage: -1,
        });
        setStors(data.data);

        setCoordinates(data?.data.map((data) => data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    getStores();
  }, []);

  return (
    <>
      <PageHeader
        title={t("home_title")}
        subTitle={t("home_sub_title")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading}
            onClick={() => getAllStatistics()}
          >
            {t("refresh")}
          </RefreshBtn>,
        ]}
      />
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row gutter={[8, 8]}>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("ClientCount")}
                title={t("ClientCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.ClientCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("DeliveryCount")}
                title={t("DeliveryCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.DeliveryCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("DeliveryCountWork")}
                title={t("DeliveryCountWork")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.DeliveryCountWork}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("OrderCount")}
                title={t("OrderCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.OrderCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("OrderInProgressCount")}
                title={t("OrderInProgressCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.OrderInProgressCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("StoreCount")}
                title={t("StoreCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.StoreCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("StoreActiveCount")}
                title={t("StoreActiveCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.StoreActiveCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("PharmacistsCount")}
                title={t("PharmacistsCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.PharmacistsCount}
              />
            </Col>
            <Col xl={8} lg={12} xs={24}>
              <Card
                toolTip={t("PharmacistsActiveCount")}
                title={t("PharmacistsActiveCount")}
                icon={<UsergroupAddOutlined />}
                count={statistics?.PharmacistsActiveCount}
              />
            </Col>
          </Row>
          <Divider orientation="center">{t("statistics")}</Divider>
          <Row gutter={[28, 28]}>
            <Col sm={8} xs={12}>
              <Chart
                lables={[t("DeliveryCount"), t("DeliveryCountWork")]}
                count={[
                  statistics?.DeliveryCount,
                  statistics?.DeliveryCountWork,
                ]}
              />
            </Col>
            <Col sm={8} xs={12}>
              <Chart
                lables={[t("OrderCount"), t("OrderInProgressCount")]}
                count={[
                  statistics?.OrderCount,
                  statistics?.OrderInProgressCount,
                ]}
              />
            </Col>
            <Col sm={8} xs={12}>
              <Chart
                lables={[t("StoreCount"), t("StoreActiveCount")]}
                count={[statistics?.StoreCount, statistics?.StoreActiveCount]}
              />
            </Col>
            {/* {stors[0]?.location && (
              <Col span={24}>
                <ViewMarkers
                  location={coordinates.map((s) => ({
                    latitude: s.location.coordinates[1],
                    longitude: s.location.coordinates[0],
                  }))}
                />
              </Col>
            )} */}
          </Row>
        </>
      )}
    </>
  );
};

export default Home;
