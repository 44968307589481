import { Col, Empty, InputNumber, List, Row, Select } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Controller from "../../components/form-components/controller";
import Button from "../../components/general/antd/button";
import Spin from "../../components/general/antd/spin";
import PageHeader from "../../components/general/layout/page-header";
import Marker from "../../components/general/map/marker";
import ViewMarkers from "../../components/general/map/view-markers";
import TrackingContext from "../../context/tracking-map/context";
import { IResponseTracking } from "../../models/map/response";
import styles from "./style.module.scss";
import { IStore } from "../../models/store/response";
import EndPoints from "../../services/end-points";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { filter } from "lodash";
import { CoffeeOutlined } from "@ant-design/icons";
const { Option } = Select;
interface IProps {}

export interface IAllActiveDriver {
  subject: string;
  longitude: number;
  latitude: number;
  distance: number;
  user_info: UserInfo;
}

export interface UserInfo {
  auth_id: string;
  user_type: string;
  info_payload: InfoPayload;
}

export interface InfoPayload {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  phone: string;
  client: any;
  investor: any;
  driver: Driver;
  employee: any;
}

export interface Driver {
  id: number;
}

const PublicSurveillance: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, users, userChangesLocations, deletedUser } =
    useContext(TrackingContext);
  const [changeArea, setChangeArea] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [usersTracking, setUsersTracking] = useState<IResponseTracking[]>();
  const [coordinates, setCoordinates] = useState<IStore[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IResponseTracking>(null);
  const [usersTrackingStatic, setUsersTrackingStatic] =
    useState<IResponseTracking[]>();
  const [type, setType] = useState<string>(null);

  const getStores = async () => {
    try {
      const { data } = await EndPoints.store.getAllStores({
        perPage: -1,
      });

      setCoordinates(data?.data.map((data) => data));
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  const methods = useForm<any>({
    defaultValues: {
      user_type: "driver",
      radius: 1,
      count: 100,
      unit: "km",
      map: {
        lng: 36.2759328,

        lat: 33.5135258,
      },
    } as any,
  });
  useEffect(() => {
    if (usersTracking) {
      const updatedData = usersTracking?.filter(
        (item) => item?.subject !== userChangesLocations?.subject
      );
      setUsersTracking([...updatedData, userChangesLocations]);
      setUsersTrackingStatic([...updatedData, userChangesLocations]);
    }
  }, [userChangesLocations]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  // socket test

  const onSubmit = async (data: any) => {
    users && actions.unSubscribeFromUserUpdates(users);
    const params = {
      user_type: data?.user_type,
      longitude: data?.map.lng,
      latitude: data?.map.lat,
      radius: data?.radius,
      unit: data?.unit || 0,
      count: data?.count || 0,
    };
    try {
      setLoadingSubmit(true);

      const { data } = await axios.get<IAllActiveDriver[]>(
        `${process.env.REACT_APP_BASE_API_URL_ORION}/nearby-search`,
        {
          params: params,

          headers: {
            authorization: `Bearer ${
              localStorage.getItem(ACCESS_TOKEN) ||
              sessionStorage.getItem(ACCESS_TOKEN)
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      //@ts-ignore
      let stepData: IResponseTracking[] = data?.map((u) => ({
        ...u,
        user_data: u.user_info,
      }));
      actions.setAllUsers(stepData);
      if (type !== "store") {
        setUsersTracking(stepData);
      } else {
        setUsersTracking([]);
      }
      setUsersTrackingStatic(stepData);
      const mappedUsers = stepData?.map((item) => ({
        user_type: item?.user_data?.user_type,
        auth_id: item?.user_data?.auth_id,
      }));
      await actions.setUsers(mappedUsers);
    } catch (error) {
    } finally {
      setChangeArea(false);
      setLoadingSubmit(false);
    }
  };
  useEffect(() => {
    if (deletedUser) {
      const updatedData = usersTracking?.filter(
        (item) => item?.subject !== deletedUser?.sid
      );
      setUsersTracking(updatedData);
      setUsersTrackingStatic(updatedData);
    }
  }, [deletedUser]);

  const filter = (value: string) => {
    setType(value);
    if (value == "store") {
      getStores();
      setUsersTracking([]);
    } else if (value == "driver") {
      setCoordinates([]);
      setUsersTracking(usersTrackingStatic);
    } else {
      getStores();
      setUsersTracking(usersTrackingStatic);
    }
  };

  return (
    <>
      <PageHeader
        title={t("public_surveillance")}
        subTitle={t("public_surveillance")}
      />
      {false ? (
        <Spin />
      ) : (
        <>
          <FormProvider {...methods}>
            <form
              className={styles.container}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.children}>
                <Row gutter={[16, 16]}>
                  <Col span={5}>
                    <Select
                      disabled={!changeArea ? true : false}
                      style={{ width: 200 }}
                      placeholder="Select a person"
                      onChange={filter}
                      options={[
                        {
                          value: "store",
                          label: t("store"),
                        },
                        {
                          value: "driver",
                          label: t("driver"),
                        },
                        {
                          value: "all",
                          label: t("all"),
                        },
                      ]}
                    />
                  </Col>
                  {type !== "store" ? (
                    <>
                      <Col span={5}>
                        <Controller
                          name="radius"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { ...field } }) => {
                            return (
                              <InputNumber
                                addonAfter={
                                  <Controller
                                    name="unit"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field: { ...field } }) => {
                                      return (
                                        <Select
                                          disabled={!changeArea ? true : false}
                                          defaultValue="km"
                                          style={{ width: 75 }}
                                          onChange={field.onChange}
                                        >
                                          <Option value="km">km</Option>
                                          <Option value="m">m</Option>
                                        </Select>
                                      );
                                    }}
                                  />
                                }
                                min={1}
                                disabled={!changeArea ? true : false}
                                onChange={field.onChange}
                                style={{ width: "100%" }}
                                required
                                placeholder={t(
                                  "choose_the_diameter_of_the_circle"
                                )}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col span={4}>
                        <Controller
                          name="count"
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { ...field } }) => {
                            return (
                              <InputNumber
                                min={1}
                                disabled={!changeArea ? true : false}
                                onChange={field.onChange}
                                style={{ width: "100%" }}
                                placeholder={t("count_show")}
                              />
                            );
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}

                  <Col
                    span={10}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      loading={loadingSubmit}
                      htmlType="submit"
                      type="primary"
                      disabled={!changeArea ? true : false}
                      onClick={() => {
                        handleSubmit;
                      }}
                    >
                      {t("ok")}
                    </Button>
                    <Button
                      loading={false}
                      type="primary"
                      disabled={changeArea ? true : false}
                      onClick={async () => {
                        setChangeArea(true);
                      }}
                    >
                      {t("chose_new_area")}
                    </Button>
                  </Col>

                  <Col span={24}>
                    <Row gutter={[15, 15]}>
                      {changeArea ? (
                        <Col span={24}>
                          <div
                            style={{
                              width: "100% !important",
                            }}
                          >
                            <Controller
                              name="map"
                              control={control}
                              rules={{ required: false }}
                              render={({ field: { ...field } }) => {
                                return (
                                  <Marker
                                    width="78vw"
                                    draggable={true}
                                    onChange={field.onChange}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        <>
                          {(usersTrackingStatic && usersTrackingStatic[0]) ||
                          (coordinates && coordinates[0]) ? (
                            <>
                              {type == "driver" || type == "all" ? (
                                <Col span={6}>
                                  <List
                                    size="small"
                                    header={
                                      <div style={{ textAlign: "center" }}>
                                        {t("active_drivers")}
                                      </div>
                                    }
                                    bordered
                                    style={{
                                      maxHeight: "55vh",
                                      overflowY: "scroll",
                                      overflowX: "hidden",
                                    }}
                                    dataSource={usersTracking.sort((a, b) =>
                                      a?.user_data?.info_payload?.firstName.localeCompare(
                                        b?.user_data?.info_payload?.firstName
                                      )
                                    )}
                                    renderItem={(item) => (
                                      <List.Item>
                                        <Button
                                          onClick={() => {
                                            setSelectedDriver(item);
                                          }}
                                          type="text"
                                          style={{
                                            padding: "0px 15px",
                                            height: "100%",
                                            backgroundColor:
                                              item?.user_data?.info_payload
                                                ?.id ===
                                              selectedDriver?.user_data
                                                .info_payload?.id
                                                ? "blue"
                                                : "",
                                          }}
                                        >
                                          {
                                            item?.user_data?.info_payload
                                              ?.firstName
                                          }
                                          {"    "}
                                          {
                                            item?.user_data?.info_payload
                                              ?.lastName
                                          }
                                        </Button>
                                      </List.Item>
                                    )}
                                  />
                                </Col>
                              ) : (
                                <></>
                              )}
                              <Col span={18}>
                                <ViewMarkers
                                  width="59vw"
                                  location={usersTracking}
                                  stores={coordinates}
                                  selectedDriver={selectedDriver}
                                  setSelectedDriver={setSelectedDriver}
                                />
                              </Col>
                            </>
                          ) : (
                            <Col span={24} className={styles.empty}>
                              <Empty>
                                <Button
                                  type="primary"
                                  onClick={() => setChangeArea(true)}
                                >
                                  {t("chose_new_area")}
                                </Button>
                              </Empty>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};
export default PublicSurveillance;
