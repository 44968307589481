import { Row, Col, Divider } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import TagContext from "../../../context/tag/context";
import TagContextProvider from "../../../context/tag/provider";
import { IUpdateTag } from "../../../models/tag/request";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(TagContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateTag(details?.id, data)
          : actions.createTag(data);
      }}
      title={details ? t("update_tag") : t("add_tag")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          note: getTranslations(details?.translations?.note),
          color: details?.color,
        } as IUpdateTag
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="color"
                label={t("pick_color")}
                input={{ type: "color-picker" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const TagForm = () => {
  return (
    <TagContextProvider>
      <Form />
    </TagContextProvider>
  );
};
export default TagForm;
