import {
  Descriptions as AntdDescriptions,
  Button,
  Col,
  Collapse,
  Input,
  List,
  Row,
  Tabs,
} from "antd";
import { ColumnProps } from "antd/es/table";
import TabPane from "antd/es/tabs/TabPane";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import {
  IAttribute,
  IAttributeDetails,
} from "../../../models/attribute/resopnse";
import Descriptions from "../../general/antd/descriptions";
import Table from "../../general/table-components/table";
import ParamContextProvider from "../../../context/param/provider";
import { useContext, useEffect, useState } from "react";
import ParamContext from "../../../context/param/context";
import { SearchOutlined } from "@ant-design/icons";
import { AttributeType } from "../../../models/attribute/enums";
import { IParam } from "../../../models/param/resopnse";
import AttributeParam from "../attribute-param";
import { useModal } from "react-modal-hook";
import FormHookModal from "../../general/modals/form-hook-modal";
import { getTranslations } from "../../../utils/helpers/translations";
import { ICreateParam, IUpdateParam } from "../../../models/param/request";
import ParamForm from "../param-form";
import type { TabsProps } from "antd";
interface IProps {
  attribute: IAttributeDetails;
}
const { Panel } = Collapse;

const Attribute: React.FC<IProps> = ({ attribute }) => {
  const { t } = useTranslation();
  const { actions, loading, details, list } = useContext(ParamContext);
  // get id selected param
  const [infoId, setInfoId] = useState(undefined);

  // get details attrebue for refresh data when add new param
  useEffect(() => {
    attribute?.id && actions.getData(attribute?.id);
  }, [attribute]);

  useEffect(() => {
    infoId && actions.getDetails(attribute?.id, infoId);
  }, [infoId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="3"
        confirmButtonText={t("save")}
        loading={
          loading.includes("create") ||
          loading.includes("details") ||
          loading.includes("update")
        }
        title={details ? t("update_param") : t("add_param")}
        hideModal={() => {
          hideFormodal();
          setInfoId(undefined);
          actions.setDetails(undefined);
        }}
        width={"50vw"}
        style={{
          marginTop: "1.6rem",
          height: "59vh",
        }}
        onSubmit={async (data) => {
          details
            ? await actions.updateParam(attribute?.id, infoId, {
                name: data.name,
                data: {
                  hex: data?.hex ?? details?.data?.hex,
                  image: {
                    id: data?.image?.id ?? details?.data?.image?.id,
                    url: data?.image?.url ?? details?.data?.image?.url,
                  },
                },
              })
            : await actions.createParam(attribute?.id, {
                name: data.name,
                data: {
                  hex: data?.hex ?? "#43434",
                  image: {
                    id: data?.image?.id ?? 0,
                    url: data?.image?.url ?? "http://localhost:3000",
                  },
                },
              });
          await setInfoId(undefined);
          await actions.setDetails(null);
          actions.getData(attribute?.id);
        }}
        defaultValues={
          {
            name: getTranslations(details?.translations?.name),
            data: {
              hex: details?.data?.hex ?? "#43434",
              image: {
                id: 32,
                url: "https://crimson-resonance-988035.postman.co/workspace/pharmacy~c0df6296-7a58-4a83-bafc-d947cfd551f0/request/29138591-8dd7221b-032f-44fc-b605-fdd008a9fc0b",
              },
            },
          } as IUpdateParam
        }
      >
        <ParamForm type={attribute?.type} details={details} />
      </FormHookModal>
    ),
    [loading, infoId]
  );

  const items1: TabsProps["items"] = [
    {
      key: "1",
      label: t("details"),
      children: (
        <>
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {attribute?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {attribute?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("order")}>
              {attribute?.order}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("isMultiple")}>
              {attribute?.isMultiple ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("isRequired")}>
              {attribute?.isRequired ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        </>
      ),
    },
  ];

  const items2: TabsProps["items"] = [
    {
      key: "2",
      label: t("params"),
      children: (
        <>
          <List
            loading={
              loading.includes("create") ||
              loading.includes("list") ||
              loading.includes("delete")
            }
            bordered
            dataSource={list?.data ?? []}
            renderItem={(param: IParam) => (
              <AttributeParam
                onEdit={() => {
                  setInfoId(param?.id);
                  showFormModal();
                }}
                onDelete={async () => {
                  await actions.deleteParam(attribute?.id, param?.id);
                  actions?.setDetails(null);
                  actions.getData(attribute?.id);
                }}
                attributeType={attribute?.type}
                param={param}
              />
            )}
          />
        </>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={
          attribute?.type == AttributeType.TEXT_DROP_DOWN ||
          attribute?.type == AttributeType.IMAGE_DROP_DOWN ||
          attribute?.type == AttributeType.COLOR
            ? 8
            : 24
        }
      >
        <Tabs defaultActiveKey="1" animated size="small" items={items1} />
      </Col>
      <Col
        span={16}
        style={{
          height: "48vh",
          overflow: "scroll",
        }}
      >
        {(attribute?.type == AttributeType.TEXT_DROP_DOWN ||
          attribute?.type == AttributeType.IMAGE_DROP_DOWN ||
          attribute?.type == AttributeType.COLOR) && (
          <Tabs
            defaultActiveKey="1"
            animated
            size="small"
            items={items2}
            tabBarExtraContent={
              <Button
                onClick={async () => {
                  await actions?.setDetails(undefined);
                  showFormModal();
                }}
              >
                {t("add_param")}
              </Button>
            }
          />
        )}
      </Col>
    </Row>
  );
};

const AttributeDetails: React.FC<IProps> = (props) => {
  return (
    <ParamContextProvider>
      <Attribute {...props} />
    </ParamContextProvider>
  );
};

export default AttributeDetails;
