import { useReducer } from "react";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { IPointQuery } from "../../models/point/query";
import { IRewardDetails } from "../../models/reward/response";
import { ICreateReward, IUpdateReward } from "../../models/reward/request";
import RewardContext, { internalState } from "./context";

export interface IProps {
  children: React.ReactNode;
}
const RewardContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.reward.getAllRewards(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getHistory = async (RewardId?: number, driverId?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.reward.getHistory({
          ...state.query,
          filters: [
            ...(state.query?.filters ?? []),
            {
              name: RewardId ? "rewardId" : "driverId",
              value: RewardId ?? driverId,
              operation: "eq",
            },
          ],
        });

        dispatch({ type: "SET_HISTORY", payload: { history: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.reward.getReward(id);
        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IRewardDetails) => {
    // dispatch({ type: 'SET_DETAILSS', payload: { details: data } })
  };

  const createReward = async (request: ICreateReward) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.reward.createReward(request);
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateReward = async (id: number, request: IUpdateReward) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.reward.updateReward(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteReward = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.reward.deleteReward(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IPointQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <RewardContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          getHistory,
          setDetails,

          createReward,
          updateReward,
          deleteReward,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </RewardContext.Provider>
  );
};

export default RewardContextProvider;
