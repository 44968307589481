import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import TrademarkContext from "../../../context/trademark/context";
import TrademarkContextProvider from "../../../context/trademark/provider";
import { ImCheckmark, ImCross } from "react-icons/im";
import Image from "../../general/antd/image";
import Tag from "../../general/antd/tag";

interface Props {}

const Trademark: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(TrademarkContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("trademark")}
        buttonName={`${t("update_trademark")}`}
        onClick={() => navigate(`/trademarks/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("trademark_name")}>
              {details?.name ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("order")}>
              {details?.order ?? "-"}
            </AntdDescriptions.Item>
            {/* <AntdDescriptions.Itpem label={t("is_special")}>
              {details?.isSpecial ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item> */}
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("image")}>
              {details?.image ? (
                <Image
                  preview
                  height={80}
                  style={{
                    objectFit: "cover",
                  }}
                  width={80}
                  src={details?.image?.url}
                  alt={details?.image?.for}
                />
              ) : (
                <Tag color="red" title={t("not_found")} />
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const TrademarkDetailsPage = () => {
  return (
    <TrademarkContextProvider>
      <Trademark />
    </TrademarkContextProvider>
  );
};
export default TrademarkDetailsPage;
