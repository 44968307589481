import imageCompression from 'browser-image-compression'

export const compressImage: (file: any) => Promise<any> = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    onProgress: (p: any) => {},
  }

  return await imageCompression(file, options)
}
