export enum AttributeType {
  TEXT = 1,
  NUMBER = 2,
  DATE = 3,
  TEXT_DROP_DOWN = 4,
  IMAGE_DROP_DOWN = 5,
  COLOR = 6,
  CHECKBOX = 7,
}

export const getAttributeTypeColor = (status: AttributeType) => {
  switch (status) {
    case AttributeType.CHECKBOX:
      return "red";
    case AttributeType.COLOR:
      return "blue";
    case AttributeType.DATE:
      return "orange";
    case AttributeType.IMAGE_DROP_DOWN:
      return "green";
    case AttributeType.NUMBER:
      return "yellow";
    case AttributeType.TEXT:
      return "pink";
    case AttributeType.TEXT_DROP_DOWN:
      return "brown";
    default:
      return "red";
  }
};

// Status Boolean stuts
export const getAttributeType = (status: AttributeType) => {
  switch (status) {
    case AttributeType.CHECKBOX:
      return "checkbox";
    case AttributeType.COLOR:
      return "color";
    case AttributeType.DATE:
      return "date";
    case AttributeType.IMAGE_DROP_DOWN:
      return "image_drop_down";
    case AttributeType.NUMBER:
      return "number";
    case AttributeType.TEXT:
      return "text";
    case AttributeType.TEXT_DROP_DOWN:
      return "text_drop_down";
    default:
      return "checkbox";
  }
};
