import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import { IUserQuery } from "../../models/user/query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IUser } from "../../models/user/response";
import {
  ICreateUser,
  IExportUserExcel,
  IUpdateUser,
} from "../../models/user/request";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

class UserService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllUsers = (query: IUserQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IUser>>({
      path: `/employees`,
      method: "GET",
      query,
      ...params,
    });

  getUser = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/employees/${id}`,
      method: "GET",
      ...params,
    });

  createUser = (data: ICreateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: "/employees",
      method: "POST",
      body: data,
      ...params,
    });

  updateUser = (id: number, data: IUpdateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/employees/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteUser = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/employees/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportUserExcel, query: IUserQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/employees/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default UserService;
