import React from "react";

interface IAttributeParamProps {
  attributeWithValue: any;
}

const NumberAttribute: React.FC<IAttributeParamProps> = ({
  attributeWithValue,
}) => {
  return <div>{attributeWithValue?.values[0]}</div>;
};

export default NumberAttribute;
