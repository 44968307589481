import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import OrderHistoryContext from "../../../context/order-history/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { IOrderHistory } from "../../../models/order/order-history/response";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import Tag from "../../general/antd/tag";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import { Divider, Modal, Spin ,Button} from "antd";
import {
  getOrderHistoryTypeColor,
  getOrderHistoryTypeStatus,
} from "../../../models/order/enum";
import InfoStoreModel from "../info-store";
import StoreContext from "../../../context/store/context";

interface IProps {}

const OrderHistoryTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, query, list } = useContext(OrderHistoryContext);
  const { actions: actionStore, loading: loadingStore } =
    useContext(StoreContext);

  const { id } = useParams();
  const { direction } = useContext(AppContext);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  useEffect(() => {
    id && actions.getData(Number(id));
  }, [query, direction, id]);

  const columns: ColumnProps<IOrderHistory>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("store"),
      dataIndex: "store",
      key: "store",
      align: "center",
      render: (_, recod) => {
        return <Tag title={recod?.store?.name} />;
      },
    },
    {
      title: t("store_city"),
      dataIndex: "store",
      key: "store",
      align: "center",
      width: 200,
      render: (_, recod) => {
        return <Tag title={recod?.store?.address ?? "-"} />;
      },
    },
    {
      title: t("pharmacist"),
      dataIndex: "pharmacist",
      key: "pharmacist",
      align: "center",
      render: (_, recod) => {
        return (
          <Tag
            title={
              (recod?.pharmacist?.firstName ?? "-") +
              " " +
              (recod?.pharmacist?.lastName ?? "-")
            }
          />
        );
      },
    },
    {
      title: t("pharmacist_mobile"),
      dataIndex: "pharmacist",
      key: "pharmacist",
      align: "center",
      render: (_, recod) => {
        return <Tag title={recod?.pharmacist?.cleanMobile ?? "-"} />;
      },
    },
    {
      title: t("first_phone"),
      dataIndex: "firstPhone",
      key: "firstPhone",
      align: "center",
      render: (_, recod) => {
        return recod?.store?.firstPhone ?? "-";
      },
    },
    {
      title: t("second_phone"),
      dataIndex: "secondPhone",
      key: "secondPhone",
      align: "center",
      render: (_, recod) => {
        return recod?.store?.secondPhone ?? "-";
      },
    },
    {
      title: t("pricesSum"),
      dataIndex: "pricesSum",
      key: "secondPhone",
      align: "center",
      render: (_, recod) => {
        return recod?.pricesSum ?? "-";
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (type) => {
        return (
          <Tag
            title={t(getOrderHistoryTypeStatus(type))}
            color={getOrderHistoryTypeColor(type)}
          />
        );
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: t("note"),
      dataIndex: "note",
      key: "note",
      align: "center",
    },
    {
      title: t("actions"),
      width: 120,
      dataIndex: "",
      align: "center",
      fixed: "right",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              actionStore.getDetails(record?.store?.id);
              setIsInfoModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{
          x: 1800,
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
      <Modal
        destroyOnClose
        title={t("store_details")}
        open={isInfoModalVisible}
        onCancel={() => {
          setIsInfoModalVisible(false);
        }}
        centered={true}
        bodyStyle={{
          minHeight: 200,
          maxHeight: "60vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        width={1000}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => setIsInfoModalVisible(false)}
              style={{ marginInlineEnd: 8 }}
            >
              {t("cancel")}
            </Button>
          </div>
        }
      >
        <Divider style={{ margin: "8px 0 8px 0" }} />
        <Spin spinning={loadingStore.includes("details")}>
          <InfoStoreModel></InfoStoreModel>
        </Spin>
      </Modal>
    </>
  );
};

export default OrderHistoryTable;
