import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IAttributeQuery } from "../../models/attribute/query";
import { IAttribute, IAttributeDetails } from "../../models/attribute/resopnse";
import {
  ICreateAttribute,
  IUpdateAttribute,
} from "../../models/attribute/request";

export type AttributeLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: AttributeLoading[];

  list?: IBaseListingResponse<IAttribute>;
  query: IAttributeQuery;

  details?: IAttributeDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (catId: number, id: number) => void;
    setDetails: (data?: IAttributeDetails) => void;

    createAttribute: (catId: number, request: ICreateAttribute) => void;
    updateAttribute: (
      catId: number,
      id: number,
      request: IUpdateAttribute
    ) => void;
    deleteAttribute: (catId: number, id: number) => void;
    // exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IAttributeQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    // exportExcel: DEFAULT_FUNCTION,

    createAttribute: DEFAULT_FUNCTION,
    updateAttribute: DEFAULT_FUNCTION,
    deleteAttribute: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const AttributeContext = createContext(externalState);

export default AttributeContext;
