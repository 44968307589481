import { List } from "antd";
import React from "react";

import AttributeValues from "../attribute-values";
interface IProductAttributesProps {
  attributes: any[];
}

const ProductAttributes: React.FC<IProductAttributesProps> = ({
  attributes,
}) => {
  return (
    <List bordered>
      {attributes?.map((productAttribute, index) => (
        <AttributeValues key={index} attributeWithValue={productAttribute} />
      ))}
    </List>
  );
};

export default ProductAttributes;
