import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import ChronicDiseasesContext from "../../../context/chronic-diseases/context";
import ChronicDiseasesContextProvider from "../../../context/chronic-diseases/provider";

interface Props {}

const ChronicDiseases: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(ChronicDiseasesContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("chronic_diseases")}
        buttonName={`${t("update_chronic_disease")}`}
        onClick={() => navigate(`/chronic-diseases/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ?? "-"}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const ChronicDiseasesDetailesPage = () => {
  return (
    <ChronicDiseasesContextProvider>
      <ChronicDiseases />
    </ChronicDiseasesContextProvider>
  );
};
export default ChronicDiseasesDetailesPage;
