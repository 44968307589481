import { HttpClient, RequestParams } from "../../utils/api/http-client";

import { IUpdateGeneralSetting } from "../../models/general-setting/request";
import { IGeneralSetting } from "../../models/general-setting/response";

class GeneralSettingervice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getGeneralSetting = (params?: RequestParams) =>
    this.http.request<IGeneralSetting>({
      path: `/general-settings`,
      method: "GET",
      ...params,
    });

  updateGeneralSetting = (
    data: IUpdateGeneralSetting,
    params?: RequestParams
  ) =>
    this.http.request<IGeneralSetting>({
      path: "/general-settings",
      method: "PUT",
      body: data,
      ...params,
    });
}

export default GeneralSettingervice;
