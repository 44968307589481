import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IReward, IRewardDetails } from "../../models/reward/response";
import { IRewardQuery } from "../../models/reward/query";
import { ICreateReward, IUpdateReward } from "../../models/reward/request";

export type RewardLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: RewardLoading[];

  list?: IBaseListingResponse<IReward>;
  query: IRewardQuery;

  details?: IRewardDetails;
  history?: IBaseListingResponse<any>;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    getHistory : (RewardId?:number, driverId?:number) => void;
    setDetails: (data?: IRewardDetails) => void;

    createReward: (request: ICreateReward) => void;
    updateReward: (id: number, request: IUpdateReward) => void;
    deleteReward: (id: number) => void;
    
    setSearch: (search?: string) => void;
    setQuery: (query: IRewardQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    getHistory: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
 
    createReward: DEFAULT_FUNCTION,
    updateReward: DEFAULT_FUNCTION,
    deleteReward: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const RewardContext = createContext(externalState);

export default RewardContext;
