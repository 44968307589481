import axios from "axios";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";

import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../utils/helpers/constants";

import {
  ICreateBanner,
  IExportBannerExcel,
  IUpdateBanner,
} from "../../models/banner/request";
import { IBanner, IBannerDetails } from "../../models/banner/response";
import { IBannerQuery } from "../../models/banner/query";

class BannerService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllBanners = (query: IBannerQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IBanner>>({
      path: `/banners`,
      method: "GET",
      query,
      ...params,
    });

  getBanner = (id: number, params?: RequestParams) =>
    this.http.request<IBannerDetails>({
      path: `/banners/${id}`,
      method: "GET",
      ...params,
    });

  createBanner = (data: ICreateBanner, params?: RequestParams) =>
    this.http.request<IBanner>({
      path: "/banners",
      method: "POST",
      body: data,
      ...params,
    });

  updateBanner = (id: number, data: IUpdateBanner, params?: RequestParams) =>
    this.http.request<IBanner>({
      path: `/banners/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteBanner = (id: number, params?: RequestParams) =>
    this.http.request<IBanner>({
      path: `/banners/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportBannerExcel, query: IBannerQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/banners/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default BannerService;
