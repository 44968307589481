import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import {
  IProductStore,
  IProductStoreDetails,
} from "../../models/product-store/response";
import { IProductStoreQuery } from "../../models/product-store/query";
import {
  ICreateProductStore,
  IUpdateProductStore,
} from "../../models/product-store/request";

export type ProductStoreLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ProductStoreLoading[];

  list?: IBaseListingResponse<IProductStore>;
  query: IProductStoreQuery;

  details?: IProductStoreDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IProductStoreDetails) => void;

    createProductStore: (request: ICreateProductStore) => void;
    updateProductStore: (id: number, request: IUpdateProductStore) => void;
    deleteProductStore: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IProductStoreQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createProductStore: DEFAULT_FUNCTION,
    updateProductStore: DEFAULT_FUNCTION,
    deleteProductStore: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ProductStoreContext = createContext(externalState);

export default ProductStoreContext;
