import { Divider, Space as AntdSpace, SpaceProps as AntdSpaceProps } from 'antd'

export interface SpaceProps extends AntdSpaceProps {}

const Space: React.FC<SpaceProps> = (props) => {
  return (
    <AntdSpace
      size='small'
      split={
        <Divider
          type={props.direction === 'vertical' ? 'horizontal' : 'vertical'}
        />
      }
      {...props}
    >
      {props.children}
    </AntdSpace>
  )
}

export default Space
