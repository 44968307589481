import { EditOutlined } from '@ant-design/icons'
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd'

export interface ButtonProps extends AntdButtonProps {
  onClick?:() => void
}

const EditeButton: React.FC<ButtonProps> = (props) => {
  return <AntdButton type='primary'  icon={<EditOutlined />} onClick={props.onClick}  disabled={props.loading === true} {...props} />
}
export default EditeButton