import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PaymentDeliveryWorkerContext from "../../../context/payment-delivery-worker/context";
import { IPaymentDeliveryWorker } from "../../../models/payment-delivery-worker/response";
import Space from "../../general/antd/space";
import ViewBtn from "../../general/table-components/actions/view-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Table from "../../general/table-components/table";
import { ImCheckmark, ImCross } from "react-icons/im";
import moment from "moment";
import Tag from "../../general/antd/tag";
import {
  getPaymentStatus,
  getPaymentStatusColor,
} from "../../../models/payment-delivery-worker/enum";

interface IProps {}

const PaymentDeliveryWorkerTable: React.FC<IProps> = (props) => {
  const userPermissions = localStorage?.getItem("permisionStoroge")?.split(",");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(
    PaymentDeliveryWorkerContext
  );

  const columns: ColumnProps<IPaymentDeliveryWorker>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("emplyee"),
      dataIndex: "user",
      align: "center",
      key: "user",
      render: (_, record) => {
        return (
          <>
            {record?.user?.user?.firstName +
              "  " +
              record?.user?.user?.lastName ?? "-"}
          </>
        );
      },
    },
    {
      title: t("delivery_worker"),
      dataIndex: "delivery_worker",
      align: "center",
      key: "delivery_worker",
      render: (_, record) => {
        return (
          <>
            {record?.driver?.firstName + " " + record?.driver?.lastName ?? "-"}
          </>
        );
      },
    },
    {
      title: t("value"),
      dataIndex: "value",
      key: "value",
      align: "center",
      sorter: {
        multiple: 2,
      },
    },
    {
      title: t("receipt_number"),
      dataIndex: "receiptNumber",
      key: "receipt_number",
      align: "center",
    },
    {
      title: t("payment_status"),
      dataIndex: "isConfirmed",
      width: 200,
      align: "center",
      key: "isConfirmed",
      render: (isConfirmed) => {
        return (
          <Tag
            title={getPaymentStatus(isConfirmed)}
            color={getPaymentStatusColor(isConfirmed)}
          />
        );
      },
    },
    {
      title: t("note"),
      dataIndex: "note",
      key: "note",
      align: "center",
      
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: {
        multiple: 3,
      },
      render: (createdAt) => {
        return moment(createdAt).format("YYYY-MM-DD");
      },
    },
  ];

  const columnNoAction: ColumnProps<IPaymentDeliveryWorker>[] = [
    ...columns,
    {
      title: t("point"),
      dataIndex: "collectionPoint",
      key: "collectionPoint",
      align: "center",
      render: (_, record) => {
        return <>{record?.collectionPoint?.name}</>;
      },
    },
  ];

  const columnAction: ColumnProps<IPaymentDeliveryWorker>[] = [
    ...columns,
    {
      title: t("actions"),
      width: 220,
      dataIndex: "actions",
      fixed: "right",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            disabled={record?.isConfirmed == 1 ? true : false }
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            disabled={record?.isConfirmed == 1 ? true : false }
            onConfirm={async () => {
              await actions.deletePaymentDeliveryWorker(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];
  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={
          userPermissions?.includes("can_view_pads")
            ? columnAction
            : columnNoAction
        }
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        scroll={{
          x: 1550,
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default PaymentDeliveryWorkerTable;
