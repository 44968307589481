import { ITranslationRequest } from "../base/translation";
export interface ICreateTrademark {
  name: ITranslationRequest;
  note: ITranslationRequest;
  imageId: number;
  isSpecial: boolean;
  order: number;
}

export interface IUpdateTrademark extends ICreateTrademark {}

export interface IExportTrademarkExcel {
  name: boolean;
  id: boolean;

  order: boolean;
  note: boolean;
}

export const colomnsExportTrademarkExcel = ["name", "id", "order", "note"];
