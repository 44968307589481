import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IAreaQuery } from "../../models/area/query";
import { IArea, IAreaDetails } from "../../models/area/response";
import { ICreateArea, IUpdateArea } from "../../models/area/request";

export type AreaLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: AreaLoading[];

  list?: IBaseListingResponse<IArea>;
  query: IAreaQuery;

  details?: IAreaDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IArea) => void;

    createArea: (request: ICreateArea) => void;
    updateArea: (id: number, request: IUpdateArea) => void;
    deleteArea: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IAreaQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createArea: DEFAULT_FUNCTION,
    updateArea: DEFAULT_FUNCTION,
    deleteArea: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const AreaContext = createContext(externalState);

export default AreaContext;
