import { Col, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/app/context";
import EndPoints from "../../../services/end-points";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import OrderContext from "../../../context/order/context";
import { IDeliveryWorker } from "../../../models/delivery-workers/response";
import { Update } from "../../../models/order/request";
import MainForm from "../../form-components/main-form";
import OrderContextProvider from "../../../context/order/provider";
import { getOrderStatus } from "../../../models/order/enum";

const Option = Select.Option;

const Form = () => {
  const { details, actions, loading } = useContext(OrderContext);
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [driver, setDriver] = useState<any[]>([]);
  const [driverLoading, setDriverLoading] = useState(true);
  const status = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  useEffect(() => {
    const getDeliveryWorker = async () => {
      try {
        const { data } = await EndPoints.deliveryWorkers.getAllDeliveryWorkers({
          light: true,
          perPage: -1,
        });
        setDriver(data?.data);
      } catch (error) {
      } finally {
        setDriverLoading(false);
      }
    };
    getDeliveryWorker();
  }, [direction]);

  return (
    <MainForm
      onSubmit={(data) => {
        data.workerId
          ? (data.workerId = data.workerId)
          : (data.workerId = null);
        data.status != details?.status
          ? (data.status = data.status)
          : (data.status = undefined);
        data.workerId != details?.driver?.id
          ? (data.workerId = data.workerId)
          : (data.workerId = undefined);
        data.note != details?.note
          ? (data.note = data.note)
          : (data.note = undefined);
        actions.updateOrder(details?.id, data);
      }}
      title={t("update_order")}
      defaultValues={
        {
          workerId: details?.driver?.id,
          status: details?.status,
          note: details?.note,
        } as Update
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <FieldBuilder
                name="workerId"
                label={`${t("delivery_worker")}`}
                width="large"
                input={{
                  type: "select",
                  loading: driverLoading,
                  allowSearch: true,
                  options: driver?.map((driver, index) => {
                    return {
                      key: index,
                      label:
                        driver?.user?.firstName ||
                        driver?.firstName + " " + driver?.lastName,
                      value: driver.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="status"
                label={`${t("status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: status?.map((statu, index) => {
                    return {
                      key: index,
                      label: t(getOrderStatus(statu)),
                      value: statu,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                width="large"
                rules={{ required: false }}
                input={{
                  type: "text-area",
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const OrderForm = () => {
  return (
    <OrderContextProvider>
      <Form />
    </OrderContextProvider>
  );
};
export default OrderForm;
