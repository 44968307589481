import { IDeliveryWorkerQuery } from "../../models/delivery-workers/query";
import {
  IDeliveryWorker,
  IDeliveryWorkerDetails,
} from "../../models/delivery-workers/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import toggleLoading from "../../utils/helpers/xor";
import paramsReducer from "../base/base-reducer";
import { QueryAction } from "../base/base-reducer";
import { IInternalState, deliveryWorkerLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: deliveryWorkerLoading | deliveryWorkerLoading[] };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<History> };
    }
  | {
      type: "SET_LIST_ACCEPT";
      payload: { listAccept: IBaseListingResponse<History> };
    }
  | {
      type: "SET_QUERY_NOT_ACCEPT";
      payload: { queryNotAccept?: any };
    }
  | QueryAction<IDeliveryWorkerQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }
    case "SET_LIST_ACCEPT": {
      return {
        ...state,
        listAccept: action.payload.listAccept,
      };
    }
    case "SET_QUERY_NOT_ACCEPT": {
      return {
        ...state,
        queryNotAccept: action.payload.queryNotAccept,
      };
    }
    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
