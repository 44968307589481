import { Fragment } from "react";
import EmailInput from "../username-input";
import PasswordInput from "../password-input";

interface Props {}

const LoginInputs: React.FC<Props> = (props) => {
  return (
    <Fragment>
      <EmailInput />
      <PasswordInput />
    </Fragment>
  );
};

export default LoginInputs;
