import {
  GoogleMap,
  Polygon,
  useJsApiLoader,
  DrawingManager,
} from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { ICoordinates } from "../../../../models/area/request";
import { MAP_DEFAULT_LOCATION } from "../../../../utils/helpers/constants";

const containerStyle = {
  width: "100%",
  height: "60vh",
};
interface Center {
  lat: number;
  lng: number;
}
interface IProps {
  points?: ICoordinates;
  allPoints?: any;
  editable: boolean;
  draggable: boolean;
  showDefault?: boolean;
  onChange?: (e: any) => void;
}

const GOOGLE_MAPS_LIBRARIES: any = ["drawing"];
const MapPolygon: React.FC<IProps> = ({
  onChange,
  points,
  editable,
  draggable,
  showDefault,
  allPoints,
}) => {
  // Store default Polygon path in state
  const [path, setPath] = useState<Center[]>([]);
  const [allPath, setAllPath] = useState<any>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const coords: number[][][] = [[]];
    if (path.length > 0) {
      path?.map((p) => {
        // Access the second-level array and push a value into it
        coords[0].push([Number(p.lng.toFixed(6)), Number(p.lat.toFixed(6))]);
      });
      if (
        coords[0][0][0] != coords[0][coords[0].length - 1][0] &&
        coords[0][0][1] != coords[0][coords[0].length - 1][1]
      ) {
        coords[0][coords[0].length] = [
          Number(coords[0][0][0]),
          Number(coords[0][0][1]),
        ];
      }
    }
    if (onChange)
      onChange({
        type: "Polygon",
        coordinates: coords,
      });
  }, [path]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // full state path  from points from back
  useEffect(() => {
    points?.coordinates?.length > 0 &&
      setPath(
        points?.coordinates[0].map((coordinate) => ({
          lat: coordinate[1],
          lng: coordinate[0],
        }))
      );
    let Alldata = [];
    allPoints?.length > 0 &&
      allPoints?.map((el) => {
        el?.coordinates?.length > 0 &&
        JSON.stringify(el?.coordinates[0]) !==
          JSON.stringify(points?.coordinates[0])
          ? (Alldata[Alldata.length] = el?.coordinates[0].map((coordinate) => ({
              lat: coordinate[1],
              lng: coordinate[0],
            })))
          : "";
      });
    setAllPath(Alldata);
  }, [points, allPoints]);

  // Assuming you want to push a value into the third level of coords
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setPath(nextPath);
      console.log("path", path);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
      console.log(path);
    },

    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const { isLoaded: isApiLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_KEY}`,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  useEffect(() => {
    setIsLoaded(isApiLoaded);
  }, [isApiLoaded]);

  const drawingManagerRef = useRef(null);

  const edit = (path) => {
    const pathNew = path.getArray().map((latLng) => {
      return { lat: latLng.lat(), lng: latLng.lng() };
    });
    setPath(pathNew);
    console.log(pathNew);
  };

  const onPolygonComplete = useCallback((polygon) => {
    const paths = polygon.getPath();
    const pathNew = paths.getArray().map((latLng) => {
      return { lat: latLng.lat(), lng: latLng.lng() };
    });
    drawingManagerRef.current.setDrawingMode(null);
    setPath(pathNew);
    console.log(pathNew);
  }, []);

  const onMapLoad = useCallback(
    (map) => {
      if (!showDefault) {
        drawingManagerRef.current =
          new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            map: map,
            polygonOptions: {
              fillOpacity: 0.5,
              strokeWeight: 2,
              clickable: true,
              editable: true,
              draggable: true,
              zIndex: 1,
            },
          });

        window.google.maps.event.addListener(
          drawingManagerRef.current,
          "polygoncomplete",
          onPolygonComplete
        );
        window.google.maps.event.addListener(
          drawingManagerRef.current,
          "overlaycomplete",
          function (event) {
            if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
              polygonRef.current = polygon;
              var polygon = event.overlay;
              var path = polygon.getPath();
              listenersRef.current.push(
                path.addListener("set_at", function () {
                  edit(path);
                }),
                path.addListener("insert_at", function () {
                  edit(path);
                }),
                path.addListener("remove_at", function () {
                  edit(path);
                })
              );
            }
          }
        );
      }
    },
    [onPolygonComplete, showDefault, onEdit]
  );

  return (
    <div>
      {isLoaded && (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
              lat: path[0]?.lat ?? 33.522267,
              lng: path[0]?.lng ?? 36.286857,
            }}
            zoom={15}
            onLoad={onMapLoad}
          >
            {allPath?.map((el, index) => {
              return (
                <Polygon
                  // Make the Polygon editable / draggable
                  editable={false}
                  draggable={false}
                  // Event used when manipulating and adding points
                  path={el}
                  options={{
                    fillColor: `#${Math.floor(Math.random() * 256).toString(
                      16
                    )}${Math.floor(Math.random() * 256).toString(
                      16
                    )}${Math.floor(Math.random() * 256).toString(16)}`, // Red color
                    fillOpacity: 0.4, // Opacity value between 0 and 1
                  }}
                />
              );
            })}
            {showDefault && path && (
              <Polygon
                // Make the Polygon editable / draggable
                editable={editable}
                draggable={draggable}
                // Event used when manipulating and adding points
                path={path}
                options={{
                  fillColor: `#000`, // Red color
                  fillOpacity: 0.6, // Opacity value between 0 and 1
                }}
              />
            )}
            {showDefault && path && editable && (
              <Polygon
                // Make the Polygon editable / draggable
                editable={editable}
                draggable={draggable}
                // Event used when manipulating and adding points
                path={path}
                onMouseUp={onEdit}
                // Event used when dragging the whole Polygon
                onDragEnd={onEdit}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  fillColor: `#000`, // Red color
                  fillOpacity: 0.6, // Opacity value between 0 and 1
                }}
              />
            )}
          </GoogleMap>
        </>
      )}
    </div>
  );
};

export default MapPolygon;
