import { Descriptions as AntdDescriptions, Divider, List, Rate } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import ProductContext from "../../../context/product/context";
import ProductContextProvider from "../../../context/product/provider";
import { ImCheckmark, ImCross } from "react-icons/im";
import Tag from "../../general/antd/tag";
import {
  getProductStatus,
  getProductStatusColor,
  getProductType,
  getProductTypeColor,
} from "../../../models/product/enum";
import ProductAttributes from "../attribute/product-attributes";
import OldImage from "../../form-components/old-image";

interface Props {}
const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const Product: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(ProductContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={t("details")}
        subtitle={t("product")}
        buttonName={`${t("update_product")}`}
        onClick={() => navigate(`/products/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            <Descriptions size="small" column={1} bordered>
              <AntdDescriptions.Item label={t("id")}>
                {details?.id}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("barcode")}>
                {details?.barcode}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("name")}>
                {details?.name ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("extra_name")}>
                {details?.extraName ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("price")}>
                {details?.price ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("unit_price")}>
                {details?.unitPrice ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("special_discount")}>
                {details?.specialDiscount ? (
                  <ImCheckmark color="green" />
                ) : (
                  <ImCross color="red" />
                )}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item
                label={t("value_special_discount") + " " + "(%)"}
              >
                {details?.valueSpecialDiscount ?? "-"}
              </AntdDescriptions.Item>

              <AntdDescriptions.Item label={t("ratesCount")}>
                {details?.ratesCount ?? "-"}
              </AntdDescriptions.Item>

              <AntdDescriptions.Item label={t("rate")}>
                <Rate tooltips={desc} disabled defaultValue={details?.rates} />
              </AntdDescriptions.Item>

              <AntdDescriptions.Item label={t("general_discount")}>
                {details?.generalDiscount ? (
                  <ImCheckmark color="green" />
                ) : (
                  <ImCross color="red" />
                )}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item
                label={t("value_general_discount") + " " + "(%)"}
              >
                {details?.valueGeneralDiscount ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("available")}>
                {details?.available ? (
                  <ImCheckmark color="green" />
                ) : (
                  <ImCross color="red" />
                )}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("need_recipe")}>
                {details?.needRecipe ? (
                  <ImCheckmark color="green" />
                ) : (
                  <ImCross color="red" />
                )}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("sold_by_unit")}>
                {details?.soldByUnit ? (
                  <ImCheckmark color="green" />
                ) : (
                  <ImCross color="red" />
                )}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("note")}>
                {details?.note ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("description")}>
                {details?.description ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: details?.description }}
                  />
                ) : (
                  "-"
                )}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("slice")}>
                {details?.slice?.name ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("main_category")}>
                {details?.mainCategory?.name ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("parent_category")}>
                {details?.parentCategory?.name ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("childern_category")}>
                {details?.childCategories?.map((child) => {
                  return <Tag title={child?.name} />;
                }) ?? "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("status")}>
                <Tag
                  color={getProductStatusColor(details?.status)}
                  title={t(getProductStatus(details?.status))}
                />
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("unit")}>
                <Tag title={details?.unit?.name ?? "-"} />
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("type")}>
                <Tag
                  color={getProductTypeColor(details?.type)}
                  title={t(getProductType(details?.type))}
                />
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("tags")}>
                {details?.tags?.length > 0
                  ? details?.tags?.map((tag) => {
                      return <Tag title={tag?.name} color={tag?.color} />;
                    })
                  : "-"}
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("image")}>
                <OldImage src={details?.image?.url} preview />
              </AntdDescriptions.Item>
              <AntdDescriptions.Item label={t("file")}>
                <a target="_blank" href={details?.pdf?.url}>
                  download pdf
                </a>
              </AntdDescriptions.Item>
            </Descriptions>

            <Divider />
            <Descriptions column={1} size="small" layout="vertical">
              <AntdDescriptions.Item label={t("attributes")} span={24}>
                <List bordered size="small" style={{ width: "100%" }}>
                  <ProductAttributes attributes={details?.attributes} />
                </List>
              </AntdDescriptions.Item>
            </Descriptions>
          </>
        )}
      </PageDetails>
    </>
  );
};

const ProductDetailesPage = () => {
  return (
    <ProductContextProvider>
      <Product />
    </ProductContextProvider>
  );
};
export default ProductDetailesPage;
