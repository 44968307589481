import { useReducer } from "react";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import NotificationContext, { internalState } from "./context";
import reducer from "./reducer";
export interface IProps {
  children: React.ReactNode;
}
const NotificationContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const getMyNotification = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.notification.getMyNotification({
          ...state.query,
          perPage: -1,
        });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const readNotification = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "list" },
        });
        await EndPoints.notification.redNotification(id);

        getMyNotification();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "list" },
        });
      },
      throwException: true,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        actions: {
          getMyNotification,
          readNotification
        },
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
