import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/app/context";
import { ICity } from "../../../models/city/response";
import BannerContext from "../../../context/banner/context";
import Form from "../../form-components/from";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import { getUserStatus } from "../../../models/user/enum";
import { getBannerType } from "../../../models/banner/enum";
import { getStatusDeliveryWorker } from "../../../models/delivery-workers/enum";
import EndPoints from "../../../services/end-points";
import DeliveryWorkerContext from "../../../context/delivery-worker/context";

interface IProps {}

const DeliveryWorkerFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(DeliveryWorkerContext);

  const statuses = [1, 2];
  const [cities, setCities] = useState<ICity[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const { direction } = useContext(AppContext);
  // get all roles from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.city.getAllCities({
          light: true,
          perPage: -1,
        });
        setCities(data.data);
        setCitiesLoading(false);
      } catch (error) {
      } finally {
        setCitiesLoading(false);
      }
    };
    getRoles();
  }, [direction]);
  return (
    <Form
      formId="delivery-worker-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.statusWorker
              ? {
                  name: "statusWorker",
                  value: data.statusWorker,
                  operation: "eq",
                }
              : undefined,
            data?.cityId
              ? {
                  name: "cityId",
                  value: data.cityId,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getUserStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="statusWorker"
            label={`${t("current_status")}`}
            width="large"
            input={{
              type: "select",
              options: [1, 2, 3].map((status, index) => {
                return {
                  label: t(getStatusDeliveryWorker(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="cityId"
            label={`${t("city")}`}
            width="large"
            input={{
              type: "select",
              loading: citiesLoading,
              options: cities.map((city, index) => {
                return {
                  type: "select",
                  label: city?.name as string,
                  value: city?.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DeliveryWorkerFilter;
