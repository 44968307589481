import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TrademarkContext from "../../../context/trademark/context";
import TrademarkContextProvider from "../../../context/trademark/provider";
import { MediumFor } from "../../../models/medium/enum";
import { IUpdateTrademark } from "../../../models/trademark/request";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import Controller from "../../form-components/controller";
import FieldBuilder from "../../form-components/field-builder";
import ImageUploader from "../../form-components/image-uploader";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import FormItem from "../../general/form-item";
import { useState } from "react";
import OldImage from "../../form-components/old-image";
import defaultImage from "../../../assets/images/empty_1.png";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(TrademarkContext);
  const { id } = useParams();
  const [image, setImage] = useState(defaultImage);

  useEffect(() => {
    details?.image?.url && setImage(details?.image?.url);
  }, [details]);

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateTrademark(details?.id, data)
          : await actions.createTrademark(data);
      }}
      title={details ? t("update_trademark") : t("add_trademark")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          note: getTranslations(details?.translations?.note) ?? null,
          imageId: details?.image?.id ?? null,
          isSpecial: false,
          order: details?.order,
        } as IUpdateTrademark
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={12}>
              <FieldBuilder
                name="order"
                label={t("order")}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            {/* <Col span={24}>
              <FieldBuilder
                label={`${t("is_special")}`}
                name="isSpecial"
                input={{ type: "checkBox" }}
              />
            </Col> */}
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`note[${lang?.code}]`}
                    label={`${t("note")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: false }}
                  />
                </Col>
              );
            })}
            <Col span={24}>
              <FormItem label={t("image")} required>
                <Controller
                  name="imageId"
                  rules={{
                    required: {
                      value: true,
                      message: `${t("field_is_required_message")}`,
                    },
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <ImageUploader
                        {...field}
                        type="DRAGGER"
                        onChange={(response) => {
                          // setfileId(response);
                          field.onChange(response?.id);
                        }}
                        imageFor={MediumFor.UserProfile}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            {details && <OldImage src={details?.image?.url} />}
          </Row>
        </>
      )}
    </MainForm>
  );
};

const TrademarkForm = () => {
  return (
    <TrademarkContextProvider>
      <Form />
    </TrademarkContextProvider>
  );
};
export default TrademarkForm;
