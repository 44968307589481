import { createContext } from "react";
import { IOrderQuery } from "../../models/order/query";
import { IOrder, IOrderDetails, IOrderShort } from "../../models/order/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION, DEFAULT_QUERY } from "../../utils/helpers/constants";
import { Update } from "../../models/order/request";

export type OrderLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: OrderLoading[];

  list?: IBaseListingResponse<IOrder>;
  listShort?: IBaseListingResponse<IOrderShort>;
  query: IOrderQuery;

  details?: IOrderDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDataShort: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IOrderDetails) => void;
    updateOrder: (id: number, data?: Update) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IOrderQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDataShort: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,
    updateOrder: DEFAULT_FUNCTION,
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const OrderContext = createContext(externalState);

export default OrderContext;
